import { Badge, Box, Card, CardActionArea, CardHeader, Stack, Typography } from "@mui/material";
import { SyntheticEvent, useCallback } from "react";

import T from "../locallization/T";
import { ROUTE_ID_CHAT_ID, ROUTES } from "../navigation/Router";
import OutputPhoto from "../outputs/basic/OutputPhoto";
import { useLocationName } from "../outputs/basic/useLocationName";
import { useProfileName } from "../outputs/basic/useProfileName";
import TherapistOutputSpecialty from "../outputs/therapist/TherapistOutputSpecialty";
import { useTherapistLocations } from "../outputs/therapist/useTherapistLocations";
import { useAppContext } from "../root/AppContext";
import { boldStyles } from "../theme/theme";
import { METUPAL_USER_INFO, THERAPIST_USER_INFO, USER_INFO } from "../user/userInfo";
import { CHAT, getChatUid } from "./chatUpdates";
import { formatMessageDate } from "./messages/MessagesList";

export default function ChatCard({
    chat,
    matchedUserInfo,
    matchIsTherapist,
    onChatClick,
}:
    | {
          chat: CHAT;
          matchedUserInfo: THERAPIST_USER_INFO;
          matchIsTherapist: true;
          onChatClick?: (event: SyntheticEvent, matchedUserInfo: USER_INFO) => void;
      }
    | {
          chat: CHAT;
          matchedUserInfo: METUPAL_USER_INFO;
          matchIsTherapist: false;
          onChatClick?: (event: SyntheticEvent, matchedUserInfo: USER_INFO) => void;
      }) {
    const { userInfo } = useAppContext();
    const name = useProfileName({ targetUserInfo: matchedUserInfo, isTargetTherapist: matchIsTherapist });
    const beautifiedMetupalLocation = useLocationName(matchIsTherapist ? "" : matchedUserInfo?.locationName || "");
    const beautifiedTherapistLocation = useTherapistLocations({ newUserInfo: matchedUserInfo });

    const messagesHref = ROUTES.MESSAGES.replace(ROUTE_ID_CHAT_ID, getChatUid(chat.metupalUids[0], chat.therapistUid));

    const onChatClickInner = useCallback(
        (event: SyntheticEvent) => {
            onChatClick && onChatClick(event, matchedUserInfo);
        },
        [onChatClick, matchedUserInfo]
    );

    return (
        <>
            <Card>
                <CardActionArea href={messagesHref} onClick={onChatClickInner}>
                    <CardHeader
                        avatar={
                            <Badge
                                badgeContent={(userInfo?.messagesCounts || {})[matchedUserInfo?.uid || ""] || 0}
                                color="primary">
                                <OutputPhoto
                                    newUserInfo={matchedUserInfo}
                                    isTargetTherapist={matchIsTherapist}
                                    size={54}
                                />
                            </Badge>
                        }
                        title={
                            <Stack mb={1}>
                                <Stack flexDirection="row" gap={1} justifyContent="space-between">
                                    <Typography variant="h3" sx={{ ...boldStyles }}>
                                        {matchedUserInfo ? (
                                            name
                                        ) : (
                                            <T overrideTherapist={!matchIsTherapist}>profile deleted</T>
                                        )}
                                    </Typography>
                                    {chat.lastMessageTime && (
                                        <bdi>{formatMessageDate(chat.lastMessageTime, { short: true })}</bdi>
                                    )}
                                </Stack>
                                {matchIsTherapist && !matchedUserInfo?.consult && (
                                    <TherapistOutputSpecialty newUserInfo={matchedUserInfo} />
                                )}
                            </Stack>
                        }
                        subheader={
                            <Box
                                sx={{
                                    overflow: "hidden",
                                    display: "-webkit-box",
                                    WebkitLineClamp: "1",
                                    WebkitBoxOrient: "vertical",
                                }}>
                                {chat.lastMessageContent ? (
                                    chat.lastMessageContent
                                ) : matchedUserInfo?.consult ? (
                                    <T params={{ name }}>consult first message</T>
                                ) : matchIsTherapist ? (
                                    // It should be just one in the array, but be safe
                                    beautifiedTherapistLocation.join("\n")
                                ) : (
                                    beautifiedMetupalLocation
                                )}
                            </Box>
                        }
                    />
                </CardActionArea>
            </Card>
        </>
    );
}
