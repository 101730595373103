import { Stack, SxProps, Theme, Typography } from "@mui/material";

import T from "../../locallization/T";
import { boldStyles } from "../../theme/theme";
import { THERAPIST_OUTPUT_PROPS } from "../basic/OutputProps";
import { useProfileName } from "../basic/useProfileName";
import TherapistOutputSpecialty from "./TherapistOutputSpecialty";

export default function TherapistTitle({ newUserInfo, sx = [] }: THERAPIST_OUTPUT_PROPS & { sx?: SxProps<Theme> }) {
    const name = useProfileName({
        targetUserInfo: newUserInfo,
        isTargetTherapist: true,
    });

    return (
        <Stack textAlign="center" sx={sx} gap={0.5}>
            <Typography variant="h2" sx={{ ...boldStyles }}>
                {name}
            </Typography>
            <Typography variant="h3">
                <TherapistOutputSpecialty newUserInfo={newUserInfo} />
            </Typography>
            <Typography variant="caption">
                <T params={{ license: newUserInfo.license }}>therapist page title caption</T>
            </Typography>
        </Stack>
    );
}
