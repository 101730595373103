import { Stack } from "@mui/material";

import SearchInputGender from "../../search/inputs/SearchInputGender";
import SearchInputPhoto from "../../search/inputs/SearchInputPhoto";
import SearchInputTalkGender from "../../search/inputs/SearchInputTalkGender";
import SearchInputEmail from "../../search/therapistInputs/SearchInputEmail";
import SearchInputFirstName from "../../search/therapistInputs/SearchInputFirstName";
import SearchInputLastName from "../../search/therapistInputs/SearchInputLastName";
import { SETTINGS_PROPS } from "./SettingsProps";

export default function SettingsGeneral({ onData, newUserInfo }: SETTINGS_PROPS) {
    return (
        <Stack gap={2}>
            <SearchInputPhoto newUserInfo={newUserInfo} onData={onData} />
            <SearchInputFirstName newUserInfo={newUserInfo} onData={onData} />
            <SearchInputLastName newUserInfo={newUserInfo} onData={onData} />
            <SearchInputEmail newUserInfo={newUserInfo} onData={onData} isDisabled />
            <SearchInputGender newUserInfo={newUserInfo} onData={onData} />
            <SearchInputTalkGender newUserInfo={newUserInfo} onData={onData} />
        </Stack>
    );
}
