import { CacheProvider } from "@emotion/react";
import { ThemeProvider } from "@mui/material/styles";

import { RTL_LOCALES } from "../locallization/useLocale";
import { useAppContext } from "../root/AppContext";
import { cacheRtl, ltrTheme, rtlTheme } from "./theme";

export default function AppTheme({ children }) {
    const { locale } = useAppContext();

    return RTL_LOCALES.has(locale || "") ? (
        <CacheProvider value={cacheRtl}>
            <ThemeProvider theme={rtlTheme}>{children}</ThemeProvider>
        </CacheProvider>
    ) : (
        <ThemeProvider theme={ltrTheme}>{children}</ThemeProvider>
    );
}
