import T from '../../locallization/T';
import { metupalQuestionIcons, metupalQuestionIconsColorful } from '../../onboarding/OnboardingQuestionsMetupal';
import { METUPAL_OUTPUT_PROPS } from '../basic/OutputProps';
import OutputRow from '../basic/OutputRow';

export default function MetupalOutputTypes({ newUserInfo, colorful, ...props }: METUPAL_OUTPUT_PROPS) {
    return (
        <OutputRow
            {...props}
            title={<T>metupal output types</T>}
            value={
                newUserInfo?.therapyType ? (
                    <T children={`onboarding question type ${newUserInfo?.therapyType}`} />
                ) : (
                    <T>output was not mentioned</T>
                )
            }
            Icon={colorful ? metupalQuestionIconsColorful.type : metupalQuestionIcons.type}
        />
    );
}
