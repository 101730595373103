import { onSnapshot, orderBy, query } from "firebase/firestore";
import { useSnackbar } from "notistack";
import { useEffect, useState } from "react";

import T from "../../locallization/T";
import { getMessageCollection, MESSAGE } from "../chatUpdates";

export function useMessagesService({ chatId }: { chatId: string }) {
    const [loading, setLoading] = useState(true);
    const [messages, setMessages] = useState<MESSAGE[]>([]);
    const { enqueueSnackbar } = useSnackbar();

    useEffect(() => {
        setLoading(true);

        const q = query(getMessageCollection(chatId), orderBy("sentDate", "desc"));

        const unsubscribe = onSnapshot(
            q,
            (querySnapshot) => {
                setMessages(querySnapshot.docs.map((doc) => doc.data() as MESSAGE));
                setLoading(false);
            },
            (error) => {
                console.error(error);
                setLoading(false);
                enqueueSnackbar(<T params={{ code: 151 }}>general error</T>, { variant: "warning" });
            }
        );

        return () => unsubscribe();
    }, [chatId, enqueueSnackbar]);

    return { loading, messages };
}
