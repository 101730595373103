import { ChevronLeftOutlined } from "@mui/icons-material";
import {
    Badge,
    Box,
    Button,
    Card,
    CardActions,
    CardContent,
    CardHeader,
    CardHeaderProps,
    Typography,
} from "@mui/material";
import { ReactNode, useCallback } from "react";

import T from "../locallization/T";
import { useResetUnreadCount } from "../notifications/useResetUnreadCount";
import OutputPhoto from "../outputs/basic/OutputPhoto";
import { useProfileName } from "../outputs/basic/useProfileName";
import { useAppContext } from "../root/AppContext";
import { boldStyles } from "../theme/theme";
import WithThemeIcon from "../theme/WithThemeIcon";
import { BASIC_USER_INFO } from "../user/userInfo";

export default function MatchCard({
    basicUserInfo,
    title,
    subheader,
    onClick,
    action,
    actionSide,
    children,
    headerAction,
    HeaderProps,
}: {
    basicUserInfo: BASIC_USER_INFO;
    title?: ReactNode;
    subheader?: ReactNode;
    onClick?: () => void;
    action?: ReactNode;
    actionSide?: ReactNode;
    children?: ReactNode;
    headerAction?: ReactNode;
    HeaderProps?: CardHeaderProps;
}) {
    const { userInfo } = useAppContext();
    const name = useProfileName({
        targetUserInfo: basicUserInfo,
        isTargetTherapist: !userInfo?.isTherapist,
    });

    const resetUnreadCount = useResetUnreadCount({ userInfoKey: "matchesCounts", targetUid: basicUserInfo.uid || "" });

    const onCardClick = useCallback(() => {
        resetUnreadCount();
        onClick && onClick();
    }, [resetUnreadCount, onClick]);

    return (
        <Card sx={{ borderRadius: 4 }}>
            <CardHeader
                avatar={
                    <Badge
                        badgeContent={
                            (userInfo?.matchesCounts || {})[basicUserInfo.uid || ""] ||
                            (userInfo?.messagesCounts || {})[basicUserInfo.uid || ""] ||
                            0
                        }
                        color="primary"
                        variant="dot">
                        <OutputPhoto newUserInfo={basicUserInfo} isTargetTherapist={!userInfo?.isTherapist} size={54} />
                    </Badge>
                }
                title={
                    title || (
                        <Typography variant="h3" sx={{ ...boldStyles }}>
                            {name}
                        </Typography>
                    )
                }
                subheader={subheader}
                action={headerAction}
                {...HeaderProps}
            />
            {children ? (
                <>
                    <Box
                        sx={{
                            height: 16,
                            background:
                                "linear-gradient(180deg, rgba(0, 0, 0, 0.05) 0%, rgba(255, 255, 255, 0.05) 100%)",
                            mb: -1.5,
                        }}
                    />
                    <CardContent>{children}</CardContent>
                </>
            ) : null}
            {children || action ? (
                <CardActions
                    sx={{
                        gap: 1,
                        justifyContent: "space-between",
                        alignItems: "center",
                        p: 2.5,
                        pt: children ? 2.5 : 0,
                    }}>
                    {actionSide}
                    {action || (
                        <Button
                            sx={{ ml: "auto" }}
                            endIcon={<WithThemeIcon Icon={ChevronLeftOutlined} />}
                            onClick={onCardClick}
                            variant="contained"
                            size="large">
                            <T>match for details</T>
                        </Button>
                    )}
                </CardActions>
            ) : null}
        </Card>
    );
}
