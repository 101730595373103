import styled from "@emotion/styled/macro";
import { Divider } from "@mui/material";

// first-child is not working in emotions, so the second line is a hack to replace it
const NonEmptyDivider = styled(Divider)`
    & + *:empty,
    &:first-of-type:not(style):not(:first-of-type ~ *),
    &:has(+ *:empty:not(${() => NonEmptyDivider})),
    &:last-child,
    & + *:empty + ${() => NonEmptyDivider}, & + ${() => NonEmptyDivider} {
        display: none;
    }
`;
export default NonEmptyDivider;
