import { Checkbox, FormControlLabel, Link, Typography } from "@mui/material";
import { Dispatch, SetStateAction, useCallback, useMemo, useState } from "react";

import AppDialog from "../../common/dialog/AppDialog";
import T from "../../locallization/T";
import { usePageText } from "../../locallization/usePageTexts";
import AppSpinner from "../../navigation/AppSpinner";
import TopNav from "../../navigation/TopNav";

export default function TermsAck({
    checked,
    setChecked = () => false,
}: {
    checked?: boolean;
    setChecked?: Dispatch<SetStateAction<boolean>>;
}) {
    const [showTerms, setShowTerms] = useState(false);
    const [renderedTerms, setRenderedTerms] = useState(false);
    const { text } = usePageText("terms_of_use", { skip: !renderedTerms });

    const setTrueShowTerms = useCallback((event) => {
        event.preventDefault();
        setShowTerms(true);
        setRenderedTerms(true);
    }, []);
    const setFalseShowTerms = useCallback(() => setShowTerms(false), []);

    const toggleChecked = useCallback(() => setChecked((checked) => !checked), [setChecked]);
    const TermsPrompt = useMemo(
        () => (
            <>
                <T
                    params={{
                        terms: (
                            <Link component="a" onClick={setTrueShowTerms} color="inherit" sx={{ cursor: "pointer" }}>
                                <T>login start privacy terms</T>
                            </Link>
                        ),
                    }}>
                    login start privacy consent
                </T>
            </>
        ),
        [setTrueShowTerms]
    );
    return (
        <>
            {typeof checked === "boolean" ? (
                <FormControlLabel
                    control={<Checkbox checked={checked} onChange={toggleChecked} />}
                    label={TermsPrompt}
                />
            ) : (
                <Typography>{TermsPrompt}</Typography>
            )}
            <AppDialog
                open={showTerms}
                setOpen={setShowTerms}
                fullScreen
                topNav={<TopNav onBack={setFalseShowTerms} title={<T>contact action usage</T>} />}>
                {text ? <Typography>{text}</Typography> : <AppSpinner />}
            </AppDialog>
        </>
    );
}
