import { ArrowBack } from "@mui/icons-material";
import { Button, CircularProgress, Stack, Typography } from "@mui/material";
import { Container } from "@mui/system";
import { isEmpty } from "lodash";
import { useCallback, useState } from "react";

import NonEmptyDivider from "../common/NonEmptyDivider";
import PageTitle from "../common/PageTitle";
import T from "../locallization/T";
import AppSpinner from "../navigation/AppSpinner";
import BottomNav from "../navigation/BottomNav";
import PageContainer from "../navigation/PageContainer";
import { ROUTES } from "../navigation/Router";
import { usePageSizes } from "../navigation/usePageSizes";
import { useAppContext } from "../root/AppContext";
import { getSpacedButtonIconProps } from "../theme/buttons";
import WithThemeIcon from "../theme/WithThemeIcon";
import { useSearchFormValidation } from "./basic/useSearchFormValidation";
import SearchInputAge from "./inputs/SearchInputAge";
import SearchInputCost from "./inputs/SearchInputCost";
import SearchInputKupa from "./inputs/SearchInputKupa";
import SearchInputLocationDistance from "./inputs/searchInputLocationDistance";
import SearchInputLocationGeo from "./inputs/SearchInputLocationGeo";
import SearchInputLocationSelector from "./inputs/SearchInputLocationSelector";
import SearchInputMessagePref from "./inputs/SearchInputMessagePref";
import SearchInputOthers from "./inputs/SearchInputOthers";
import SearchInputReasons from "./inputs/SearchInputReasons";
import SearchInputTime from "./inputs/SearchInputTime";
import SearchInputType from "./inputs/SearchInputType";
import SearchInputZoom from "./inputs/SearchInputZoom";
import SearchExisting from "./SearchExisting";
import SearchInputAges from "./therapistInputs/SearchInputAges";
import SearchInputCharity from "./therapistInputs/SearchInputCharity";
import SearchInputKupot from "./therapistInputs/SearchInputKupot";
import SearchInputTherapistCost from "./therapistInputs/SearchInputTherapistCost";
import SearchInputTherapistLocations from "./therapistInputs/SearchInputTherapistLocations";
import SearchInputTherapistMinutes from "./therapistInputs/SearchInputTherapistMinutes";
import SearchInputTherapistTimes from "./therapistInputs/SearchInputTherapistTimes";
import SearchInputTherapistTypes from "./therapistInputs/SearchInputTherapistTypes";
import { useSaveSearch } from "./useSaveSearch";

export default function Search() {
    const { userInfo } = useAppContext();
    const { contentSx } = usePageSizes();
    const [showExistingSearchPage, setShowExistingSearchPage] = useState(
        !userInfo?.isTherapist && userInfo?.enableSearch
    );
    const verifyExistingSearchPage = useCallback(() => setShowExistingSearchPage(false), []);

    const { saveData, loading, onData, updatedData, newUserInfo } = useSaveSearch();
    const [hasLocation, setHasLocation] = useState(!!userInfo?.locationGeo);

    const errorMessage = useSearchFormValidation({ newUserInfo });

    return (
        <PageContainer
            pageTitleKey={userInfo?.isTherapist ? "title therapist search" : "title search"}
            pagePath={ROUTES.SEARCH}
            hideTopNav
            BotNavComponent={
                <>
                    {showExistingSearchPage ? (
                        <Container>
                            <Stack sx={{ pt: 2, pb: 2, ...contentSx }} gap={1}>
                                <Button
                                    variant="contained"
                                    size="huge"
                                    fullWidth
                                    onClick={verifyExistingSearchPage}
                                    {...getSpacedButtonIconProps()}
                                    endIcon={<WithThemeIcon Icon={ArrowBack} />}>
                                    <T>search new button</T>
                                </Button>
                            </Stack>
                        </Container>
                    ) : null}
                    <BottomNav />
                </>
            }
            backgrounded={!showExistingSearchPage}>
            {loading ? (
                <AppSpinner />
            ) : (
                <Stack gap={3}>
                    <PageTitle gap={3}>
                        {userInfo?.isTherapist ? <T>search title therapist</T> : <T>search title</T>}
                    </PageTitle>

                    {userInfo?.isTherapist ? (
                        <>
                            <SearchInputTherapistLocations
                                onData={onData}
                                userInfo={userInfo}
                                newUserInfo={newUserInfo}
                            />
                            <SearchInputZoom onData={onData} newUserInfo={newUserInfo} isTherapist />
                            <SearchInputTherapistTypes onData={onData} newUserInfo={newUserInfo} />
                            <SearchInputTherapistMinutes onData={onData} newUserInfo={newUserInfo} />
                            <SearchInputAges onData={onData} newUserInfo={newUserInfo} />
                            <SearchInputTherapistTimes onData={onData} newUserInfo={newUserInfo} />
                            <SearchInputTherapistCost onData={onData} newUserInfo={newUserInfo} />
                            <SearchInputKupot onData={onData} newUserInfo={newUserInfo} />
                            <SearchInputCharity onData={onData} newUserInfo={newUserInfo} />
                        </>
                    ) : showExistingSearchPage ? (
                        <SearchExisting />
                    ) : (
                        <>
                            <SearchInputLocationSelector
                                newUserInfo={newUserInfo}
                                onData={onData}
                                setHasLocation={setHasLocation}
                            />
                            {hasLocation && (
                                <>
                                    <SearchInputLocationGeo
                                        newUserInfo={newUserInfo}
                                        onData={onData}
                                        userInfo={userInfo}
                                    />
                                    <SearchInputLocationDistance newUserInfo={newUserInfo} onData={onData} />
                                </>
                            )}
                            <SearchInputType newUserInfo={newUserInfo} onData={onData} />
                            <SearchInputAge newUserInfo={newUserInfo} onData={onData} />
                            <SearchInputTime newUserInfo={newUserInfo} onData={onData} />
                            <SearchInputCost newUserInfo={newUserInfo} onData={onData} />
                            <SearchInputKupa newUserInfo={newUserInfo} onData={onData} />
                            <NonEmptyDivider sx={{ mx: -2, my: 1 }} />
                            <SearchInputReasons newUserInfo={newUserInfo} onData={onData} />
                            <NonEmptyDivider sx={{ mx: -2, my: 1 }} />
                            <SearchInputOthers newUserInfo={newUserInfo} onData={onData} />
                            <SearchInputMessagePref newUserInfo={newUserInfo} onData={onData} />
                        </>
                    )}
                    {showExistingSearchPage ? null : (
                        <Stack gap={1}>
                            {errorMessage && <Typography color="error">{errorMessage}</Typography>}
                            <Button
                                disabled={isEmpty(updatedData) || loading || !!errorMessage}
                                variant="contained"
                                size="huge"
                                onClick={saveData}
                                {...getSpacedButtonIconProps()}
                                endIcon={loading ? <CircularProgress size={24} /> : <WithThemeIcon Icon={ArrowBack} />}>
                                {userInfo?.isTherapist ? <T>search button therapist</T> : <T>search button</T>}
                            </Button>
                        </Stack>
                    )}
                </Stack>
            )}
        </PageContainer>
    );
}
