import { AccessibleOutlined } from "@mui/icons-material";
import { Button, Checkbox, FormControlLabel, Stack } from "@mui/material";
import { GeoPoint } from "firebase/firestore";
import { useCallback, useEffect, useMemo, useState } from "react";

import { PlaceType } from "../../inputs/GoogleAutocomplete";
import InputLocationGeo from "../../inputs/InputLocationGeo";
import T from "../../locallization/T";
import WithThemeIcon from "../../theme/WithThemeIcon";
import { SEARCH_INPUT_PROPS } from "../inputs/SearchInputProps";

const MAX_LOCATIONS = 3;

export default function SearchInputTherapistLocations({ onData, newUserInfo }: SEARCH_INPUT_PROPS) {
    const initialPlaceValues = useMemo(() => {
        const items = [...(newUserInfo.therapistLocationNames || [])];
        if (items.length === 0) {
            items.push("");
        }
        return items;
    }, [newUserInfo.therapistLocationNames]);
    const [placeValues, setPlaceValues] = useState<(PlaceType | null)[]>(
        initialPlaceValues.map((name) => ({ description: name }))
    );
    const [geos, setGeos] = useState<(GeoPoint | null)[]>(newUserInfo.therapistLocationGeos || []);
    const [accessibles, setAccessibles] = useState<boolean[]>(newUserInfo.therapistLocationAccessibles || []);

    const updatePlaceValue = useCallback((newPlaceValue: PlaceType | null, index: number) => {
        setPlaceValues((placeValues) => {
            const newPlaceValues = [...placeValues];
            newPlaceValues[index] = newPlaceValue;
            return newPlaceValues;
        });
    }, []);
    const updateGeo = useCallback(
        (newGeo: GeoPoint | null, index: number) => {
            setGeos((geos) => {
                if (geos[index] === newGeo) {
                    return geos;
                }
                if (!newGeo && placeValues[index]) {
                    // ignore null when place exists.
                    return geos;
                }
                const newGeos = [...geos];
                newGeos[index] = newGeo;
                return newGeos;
            });
        },
        [placeValues]
    );

    const updateAccessible = useCallback((index: number) => {
        setAccessibles((accessibles) => {
            const newAccessibles = [...accessibles];
            newAccessibles[index] = !newAccessibles[index];
            return newAccessibles;
        });
    }, []);

    const showMoreInputs = useCallback(() => {
        setPlaceValues((listValue) => [...listValue, { description: "" }]);
    }, []);

    useEffect(() => {
        const therapistLocationNames = placeValues?.map((placeValue) => placeValue?.description?.trim());
        const therapistLocationGeos = [...geos];
        const therapistLocationAccessibles = [...accessibles].map((value) => !!value);
        // remove excess values
        while (therapistLocationAccessibles.length < therapistLocationNames.length) {
            therapistLocationAccessibles.push(false);
        }
        while (therapistLocationAccessibles.length > therapistLocationNames.length) {
            therapistLocationAccessibles.shift();
        }
        if (therapistLocationNames.length !== therapistLocationGeos.length) {
            // the map api is slightly delayed, so don't update anything until the values have synced.
            return;
        }
        const indexesToRemoveFromLast: number[] = [];
        for (let index = 0; index < therapistLocationNames.length; index++) {
            if (!therapistLocationNames[index] || !therapistLocationGeos[index]) {
                indexesToRemoveFromLast.unshift(index);
            }
        }
        for (const index of indexesToRemoveFromLast) {
            therapistLocationNames.splice(index, 1);
            therapistLocationGeos.splice(index, 1);
            therapistLocationAccessibles.splice(index, 1);
        }
        onData({
            therapistLocationGeos: therapistLocationGeos as GeoPoint[],
            therapistLocationNames: therapistLocationNames as string[],
            therapistLocationAccessibles,
        });
    }, [placeValues, geos, accessibles, onData]);

    return (
        <Stack gap={2}>
            {placeValues.map((placeValue, index) => (
                <Stack key={index} gap={0.5}>
                    <InputLocationGeo
                        placeValue={placeValue}
                        setPlaceValue={(newPlaceValue) => updatePlaceValue(newPlaceValue, index)}
                        setGeo={(newGeo) => updateGeo(newGeo, index)}
                    />
                    {(placeValues[index] || {}).description && (
                        <FormControlLabel
                            sx={{ alignSelf: "baseline" }}
                            control={
                                <Checkbox checked={!!accessibles[index]} onChange={() => updateAccessible(index)} />
                            }
                            label={
                                <Stack flexDirection="row" gap={0.5} alignItems="center">
                                    <WithThemeIcon Icon={AccessibleOutlined} reverse />
                                    <T>search input therapist location accessible</T>
                                </Stack>
                            }
                        />
                    )}
                </Stack>
            ))}
            {placeValues.length < MAX_LOCATIONS && placeValues[placeValues.length - 1]?.description && (
                <Button onClick={showMoreInputs} variant="text" sx={{ alignSelf: "baseline", mt: -1 }}>
                    <T>search input therapist location more button</T>
                </Button>
            )}
        </Stack>
    );
}
