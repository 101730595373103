import { Stack, Typography } from "@mui/material";
import { useMemo } from "react";

import T from "../../locallization/T";
import { boldStyles } from "../../theme/theme";
import { METUPAL_USER_INFO } from "../../user/userInfo";

export default function MetupalSummaryCost({ newUserInfo }: { newUserInfo: METUPAL_USER_INFO }) {
    const [title, subtitle] = useMemo(() => {
        if (newUserInfo?.cost) {
            return [
                <T>metupal output cost</T>,
                <T params={{ cost: newUserInfo?.cost }}>onboarding question cost ils</T>,
            ];
        } else {
            return [];
        }
    }, [newUserInfo]);

    return (
        <Stack>
            <Typography>{title}</Typography>
            <Typography variant="h3" sx={{ ...boldStyles }}>
                {subtitle}
            </Typography>
        </Stack>
    );
}
