import { Button, CircularProgress, Stack, Typography } from "@mui/material";
import { useSnackbar } from "notistack";
import { useCallback } from "react";

import T from "../../locallization/T";
import { signInWithApple } from "../../user/signInPopups";
import { useIsAppWebview, useIsInWebview, useIsIos, useIsNonAppWebview } from "../../webview/useIsWebviewApp";
import { ReactComponent as AppleLogoSvg } from "../svg/appleLogo.svg";

export default function AppleSignInButton({ signInLoading, setSignInLoading, disabled = false }) {
    const { enqueueSnackbar } = useSnackbar();

    const isInNonAppWebview = useIsNonAppWebview();
    const isInAppWebview = useIsAppWebview();
    const isWebview = useIsInWebview();
    const isIos = useIsIos();

    const signInWithAppleHandler = useCallback(() => {
        setSignInLoading(true);
        signInWithApple(isInAppWebview)
            .then(() => {
                // Should be redirected here from another place.
                enqueueSnackbar(<T>login form success</T>, { variant: "success" });
            })
            .catch((error) => {
                setSignInLoading(false);
                console.error(error);
                enqueueSnackbar(<T>login form error</T>, { variant: "warning" });
            });
    }, [enqueueSnackbar, setSignInLoading, isInAppWebview]);

    if (isWebview && !isIos) {
        // non-ios webviews (e.g. android app) shouldn't see sign in with apple
        return null;
    }

    return (
        <Stack gap={0.5}>
            <Button
                size="huge"
                startIcon={signInLoading ? <CircularProgress size={24} /> : <AppleLogoSvg />}
                color="secondary"
                variant="contained"
                disabled={isInNonAppWebview || disabled || signInLoading}
                onClick={signInWithAppleHandler}>
                <T>login form button apple</T>
            </Button>
            {isInNonAppWebview ? (
                <Typography variant="caption">
                    <T>login form webview closed</T>
                </Typography>
            ) : null}
        </Stack>
    );
}
