import { Button } from "@mui/material";
import { useSnackbar } from "notistack";
import { useEffect } from "react";

import T from "../locallization/T";
import { ROUTES } from "../navigation/Router";
import ConfirmPayTermsModal from "../user/ConfirmPayTermsModal";
import { useAppContext } from "./AppContext";

export default function GlobalMessages({ children }) {
    const { userInfo } = useAppContext();
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();

    useEffect(() => {
        if (userInfo?.ban) {
            const snackbarId = enqueueSnackbar(<T>global warning banned</T>, {
                variant: "error",
                persist: true,
                action: (
                    <Button
                        href={ROUTES.CONTACT}
                        onClick={() => closeSnackbar(snackbarId)}
                        color="inherit"
                        sx={{ textDecoration: "underline" }}>
                        <T>profile setting contact</T>
                    </Button>
                ),
            });
        }
    }, [userInfo?.ban, enqueueSnackbar, closeSnackbar]);

    return (
        <>
            {children}
            <ConfirmPayTermsModal />
        </>
    );
}
