import { GeoPoint } from "firebase/firestore";
import { useSnackbar } from "notistack";
import { useEffect, useState } from "react";

import T from "../locallization/T";
import GoogleMaps, { PlaceType } from "./GoogleAutocomplete";
import { getMapsGeocodeService } from "./useLoadMaps";

type PROPS = {
    placeValue: PlaceType | null;
    setPlaceValue: (placeValue: PlaceType | null) => void;
    setGeo: (geo: GeoPoint | null) => void;
};

export default function InputLocationGeo({ placeValue, setPlaceValue, setGeo }: PROPS) {
    const { enqueueSnackbar } = useSnackbar();
    const [currentGeo, setCurrentGeo] = useState<GeoPoint | null>();

    useEffect(() => {
        if (currentGeo !== undefined) {
            setGeo(currentGeo);
        }
    }, [currentGeo, setGeo]);

    useEffect(() => {
        if (placeValue && placeValue.place_id) {
            getMapsGeocodeService()
                .geocode({ placeId: placeValue.place_id })
                .then(({ results }) => {
                    const loc = results[0]?.geometry?.location;
                    if (loc) {
                        setCurrentGeo(new GeoPoint(loc.lat(), loc.lng()));
                    } else {
                        return Promise.reject(new Error("Got empty results from google maps"));
                    }
                })
                .catch((error) => {
                    console.error(error);
                    enqueueSnackbar(<T params={{ code: 153 }}>general error</T>, { variant: "warning" });
                });
        } else if (!placeValue) {
            setCurrentGeo(null);
        }
    }, [enqueueSnackbar, placeValue]);

    return <GoogleMaps value={placeValue} setValue={setPlaceValue} />;
}
