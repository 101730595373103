import { getTalkGender } from "../../common/gender/getTalkGender";
import { useT } from "../../locallization/T";
import { BASIC_USER_INFO } from "../../user/userInfo";
import { useShowSensitiveOutput } from "./useShowSensitiveOutput";

export function useProfileName({
    targetUserInfo,
    isTargetTherapist,
}: {
    targetUserInfo?: BASIC_USER_INFO | null;
    isTargetTherapist?: boolean;
}) {
    const fallback = useT({
        textKey: "profile no name",
        overrideGender: getTalkGender(targetUserInfo),
    }).join("");
    const showSensitive = useShowSensitiveOutput({ targetUserInfo, isTargetTherapist });

    let name = targetUserInfo?.firstName || "";
    if (showSensitive) {
        name = `${name} ${targetUserInfo?.lastName || ""}`;
    }

    return name.trim() || fallback;
}
