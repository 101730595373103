import { Stack } from "@mui/material";
import { Dispatch, SetStateAction, useCallback, useEffect, useState } from "react";

import T from "../../locallization/T";
import { userInfoKupotWithNone } from "../../search/inputs/SearchInputKupa";
import { convertToUserInfoMap, USER_INFO, USER_INFO_KUPA } from "../../user/userInfo";
import AppToggleButton from "../buttonGroup/AppToggleButton";
import OnboardingQuestionMultiple from "./helpers/OnboardingQuestionMultiple";
import { ONBOARDING_QUESTION_PROPS } from "./OnboardingQuestionProps";

export function alreadyHasTherapistKupot(userInfo: USER_INFO) {
    return !!userInfo.therapistKupot;
}

export function useUpdateTherapistKupotOptions(setSelectedOptions: Dispatch<SetStateAction<Set<USER_INFO_KUPA>>>) {
    return useCallback(
        (
            newSelectedOptionsHandler: (existing: Set<USER_INFO_KUPA>) => Set<USER_INFO_KUPA>,
            onData?: (data: Set<USER_INFO_KUPA>) => void
        ) => {
            setSelectedOptions((existingOptions) => {
                const newSelectedOptions = newSelectedOptionsHandler(existingOptions);
                const newValue = new Set(newSelectedOptions);
                if (existingOptions.has("none")) {
                    newValue.delete("none");
                } else if (newSelectedOptions.has("none")) {
                    newValue.clear();
                    newValue.add("none");
                }
                onData && onData(newValue);
                return newValue;
            });
        },
        [setSelectedOptions]
    );
}

export default function OnboardingQuestionTherapistKupot({ onDataProgress }: ONBOARDING_QUESTION_PROPS) {
    const [selectedOptions, setSelectedOptions] = useState(new Set<USER_INFO_KUPA>([]));

    const updateSelectedOptions = useUpdateTherapistKupotOptions(setSelectedOptions);

    useEffect(() => {
        if (selectedOptions.size > 0) {
            onDataProgress({
                therapistKupot: convertToUserInfoMap(Array.from(selectedOptions)),
            });
        } else {
            onDataProgress({});
        }
    }, [selectedOptions, onDataProgress]);

    return (
        <Stack gap={2}>
            <OnboardingQuestionMultiple />
            {userInfoKupotWithNone.map(({ value: kupa, ImagePng, textKey }) => (
                <AppToggleButton
                    key={kupa}
                    value={kupa}
                    selectedOptions={selectedOptions}
                    setSelectedOptions={updateSelectedOptions}>
                    {textKey ? <T>{textKey}</T> : <img src={ImagePng} alt={kupa} />}
                </AppToggleButton>
            ))}
        </Stack>
    );
}
