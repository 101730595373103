import { getDownloadURL, getStorage, ref, StorageReference } from "firebase/storage";
import { useSnackbar } from "notistack";
import { useEffect, useState } from "react";

import T from "../locallization/T";
import { BASIC_USER_INFO } from "../user/userInfo";

export const PHOTO_KEY_1 = "p1";
export const PHOTO_KEY_2 = "p2";

export function getProfileImageRef(uid: string, photoKey: string) {
    return ref(getStorage(), `profile-photos/${uid}/${photoKey}.jpg`);
}

export type STORAGE_CACHE_MAP = Map<string, { [photoKey: string]: string }>;

const photosCacheMap: STORAGE_CACHE_MAP = new Map();

export function useDownloadProfilePhotoUrl(userInfo: BASIC_USER_INFO | null, options: { skip?: boolean } = {}) {
    return useDownloadUrl(userInfo, "photoKey", getProfileImageRef, photosCacheMap, options);
}

export function useDownloadUrl(
    userInfo: BASIC_USER_INFO | null,
    userInfoStorageKey: string,
    getStorageRef: (uid: string, key: string) => StorageReference,
    cacheMap: STORAGE_CACHE_MAP,
    { skip = false }: { skip?: boolean } = {}
) {
    const [url, setUrl] = useState("");
    const [loadingAsset, setLoadingAsset] = useState(!!(userInfo || {})[userInfoStorageKey]);
    const { enqueueSnackbar } = useSnackbar();

    useEffect(() => {
        const uid = userInfo?.uid || "temp";
        const storageKey = (userInfo || {})[userInfoStorageKey];
        if (storageKey && !skip) {
            setLoadingAsset(true);
            const cachedPhoto = cacheMap.get(uid) || {};
            if (cachedPhoto[storageKey]) {
                setUrl(cachedPhoto[storageKey]);
                setLoadingAsset(false);
                return;
            }
            const assetRef = getStorageRef(uid, storageKey);
            getDownloadURL(assetRef)
                .then((photoUrl) => {
                    setUrl(photoUrl);
                    cacheMap.set(uid, { ...cachedPhoto, [storageKey || ""]: photoUrl });
                    setLoadingAsset(false);
                })
                .catch((error) => {
                    console.error(error);
                    setLoadingAsset(false);
                    enqueueSnackbar(<T params={{ code: 184 }}>general error</T>, { variant: "warning" });
                });
        } else {
            cacheMap.delete(uid);
            setUrl("");
            setLoadingAsset(false);
        }
    }, [enqueueSnackbar, userInfo, userInfoStorageKey, getStorageRef, cacheMap, skip]);

    return { url, loadingAsset };
}
