import { useCallback } from 'react';

import T from '../../locallization/T';
import { userInfoTypes } from '../../onboarding/questions/OnboardingQuestionType';
import SearchInputBasicSelect from '../basic/SearchInputBasicSelect';
import { SEARCH_INPUT_PROPS } from './SearchInputProps';

export default function SearchInputType({ onData, newUserInfo }: SEARCH_INPUT_PROPS) {
    const getValueLabel = useCallback((value: string) => <T children={`onboarding question type ${value}`} />, []);

    return (
        <SearchInputBasicSelect
            onData={onData}
            newUserInfo={newUserInfo}
            userInfoKey="therapyType"
            label={<T>search input type</T>}
            allValues={userInfoTypes}
            getValueLabel={getValueLabel}
        />
    );
}
