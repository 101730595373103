import { CheckCircleOutline, DoNotDisturbOnOutlined, SearchOffOutlined, SearchOutlined } from "@mui/icons-material";
import { useState } from "react";

import T from "../../locallization/T";
import { useAppContext } from "../../root/AppContext";
import ConfirmPayTermsModal from "../../user/ConfirmPayTermsModal";
import InlineSettingToggleSearch from "../inlineSettings/InlineSettingToggleSearch";
import SettingSearch from "../settings/SettingSearch";
import SettingRowBase from "./SettingRowBase";

export default function SettingRowSearch() {
    const { userInfo } = useAppContext();
    const [showConfirmTermsDialog, setShowConfirmTermsDialog] = useState(false);

    return (
        <>
            <SettingRowBase
                text={
                    userInfo?.isTherapist ? (
                        userInfo?.therapistEnableSearch ? (
                            <T>profile setting toggle therapist search</T>
                        ) : (
                            <T>profile setting toggle therapist search off</T>
                        )
                    ) : userInfo?.enableSearch ? (
                        <T>profile setting toggle metupal search</T>
                    ) : (
                        <T>profile setting toggle metupal search off</T>
                    )
                }
                Icon={
                    userInfo?.isTherapist
                        ? userInfo?.therapistEnableSearch
                            ? CheckCircleOutline
                            : DoNotDisturbOnOutlined
                        : userInfo?.enableSearch
                        ? SearchOutlined
                        : SearchOffOutlined
                }
                InlineSettingContent={InlineSettingToggleSearch}
                SettingContent={userInfo?.therapistTermsAck ? SettingSearch : undefined}
                onClick={userInfo?.therapistTermsAck ? undefined : () => setShowConfirmTermsDialog(true)}
                hideTopNav
            />
            {showConfirmTermsDialog && <ConfirmPayTermsModal onClose={() => setShowConfirmTermsDialog(false)} />}
        </>
    );
}
