import { CancelOutlined, PasswordOutlined, SaveOutlined } from "@mui/icons-material";
import { CircularProgress } from "@mui/material";
import { updatePassword } from "firebase/auth";
import { noop } from "lodash";
import { useSnackbar } from "notistack";
import { useCallback, useMemo, useState } from "react";

import { ActionButtonPrimary, ActionButtonSecondary } from "../../common/ActionButtons";
import AppDialog from "../../common/dialog/AppDialog";
import PasswordInput from "../../common/PasswordInput";
import T from "../../locallization/T";
import { useProfileEmail } from "../../outputs/basic/useProfileEmail";
import { useAppContext } from "../../root/AppContext";
import { AUTH_PROVIDERS, getAuthProviders, reauthenticationError } from "../../user/Reauthenticate";
import SettingRowBase from "../settingRows/SettingRowBase";

export default function ProfileEditPasswordButton() {
    const { user, userInfo, reauthenthicate } = useAppContext();
    const [loading, setLoading] = useState(false);
    const { enqueueSnackbar } = useSnackbar();
    const [showDialog, setShowDialog] = useState(false);
    const [newPassword, setNewPassword] = useState("");
    const [newPasswordConfirm, setNewPasswordConfirm] = useState("");

    const providers = useMemo(() => (user ? getAuthProviders(user) : new Set<AUTH_PROVIDERS>()), [user]);
    const hasPassword = providers.has("password");

    const displayDialog = useCallback(() => {
        setNewPassword("");
        setNewPasswordConfirm("");
        setShowDialog(true);
    }, []);

    const hideDialog = useCallback(() => {
        if (!loading) {
            setShowDialog(false);
        }
    }, [loading]);

    const resetPasswordHandler = useCallback(() => {
        if (!user) {
            return;
        }
        if (newPassword !== newPasswordConfirm) {
            return enqueueSnackbar(<T>login form forgot error mismatch</T>, { variant: "warning" });
        }
        setLoading(true);
        reauthenthicate()
            .then(() => updatePassword(user, newPassword))
            .then(() => {
                setLoading(false);
                enqueueSnackbar(<T>login form reset confirm</T>, { variant: "success" });
                setShowDialog(false);
            })
            .catch((error) => {
                setLoading(false);
                reauthenticationError(error, enqueueSnackbar);
            });
    }, [user, newPassword, newPasswordConfirm, reauthenthicate, enqueueSnackbar]);

    const title = useMemo(
        () =>
            hasPassword ? <T>profile setting password edit button</T> : <T>profile setting password create button</T>,
        [hasPassword]
    );

    const email = useProfileEmail({ newUserInfo: userInfo });

    if (!email) {
        // Apple users sometimes don't share their email, so they aren't allowed to add password to their account.
        return null;
    }

    return (
        <>
            <SettingRowBase text={title} Icon={PasswordOutlined} onClick={loading ? noop : displayDialog} />
            <AppDialog
                open={showDialog}
                setOpen={setShowDialog}
                dialogTitle={title}
                actions={
                    <>
                        <ActionButtonSecondary onClick={hideDialog} endIcon={<CancelOutlined />} disabled={loading}>
                            <T>profile delete dialog cancel button</T>
                        </ActionButtonSecondary>
                        <ActionButtonPrimary
                            onClick={resetPasswordHandler}
                            disabled={!newPassword || !newPasswordConfirm || loading}
                            endIcon={loading ? <CircularProgress size={24} /> : <SaveOutlined />}>
                            <T>profile setting button save</T>
                        </ActionButtonPrimary>
                    </>
                }>
                <PasswordInput
                    type="new"
                    disabled={loading}
                    password={newPassword}
                    setPassword={setNewPassword}
                    margin="dense"
                />
                <PasswordInput
                    type="confirm new"
                    disabled={loading}
                    password={newPasswordConfirm}
                    setPassword={setNewPasswordConfirm}
                    margin="dense"
                />
            </AppDialog>
        </>
    );
}
