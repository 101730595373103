import { useCallback } from 'react';

import { saveLocalization } from '../locallization/localizationSaver';
import { useAppContext } from '../root/AppContext';
import TextsEditor from './TextsEditor';

export default function TextsEditorSecret() {
    const { texts = {}, locale = "" } = useAppContext();

    const onSave = useCallback(
        (updates: {}) => {
            return saveLocalization(updates, locale);
        },
        [locale]
    );

    return <TextsEditor texts={texts} onSave={onSave} />;
}
