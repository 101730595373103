import T from '../../locallization/T';
import { userInfoAllowCharity } from '../../onboarding/questions/OnboardingQuestionTherapistCost';
import SearchInputBasicSelect from '../basic/SearchInputBasicSelect';
import { SEARCH_INPUT_PROPS } from '../inputs/SearchInputProps';

export default function SearchInputCharity({ onData, newUserInfo }: SEARCH_INPUT_PROPS) {
    return (
        <SearchInputBasicSelect
            onData={onData}
            newUserInfo={newUserInfo}
            userInfoKey="therapistAllowCharity"
            label={<T>therapist output charity</T>}
            allValues={userInfoAllowCharity}
            defaultValue={userInfoAllowCharity[1].value}
        />
    );
}
