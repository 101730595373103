import { useMemo } from "react";

import { useAppContext } from "../root/AppContext";

export function useReadyMatches() {
    const { userInfo } = useAppContext();
    return useMemo(() => {
        const readyMatches = {};
        for (let uid in userInfo?.readyMatches || {}) {
            if (!(userInfo?.rejectedTherapists || {})[uid] || userInfo?.consult) {
                readyMatches[uid] = (userInfo?.readyMatches || {})[uid];
            }
        }
        return readyMatches;
    }, [userInfo?.readyMatches, userInfo?.consult, userInfo?.rejectedTherapists]);
}
