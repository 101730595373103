import T from '../../locallization/T';
import SearchInputBasicText from '../basic/SearchInputBasicText';
import { SEARCH_INPUT_PROPS } from '../inputs/SearchInputProps';
import { MAX_SOCIAL_URL_LENGTH } from './SearchInputSocialFacebook';

export default function SearchInputSocialSite({ onData, newUserInfo }: SEARCH_INPUT_PROPS) {
    return (
        <SearchInputBasicText
            label={<T>search input social site</T>}
            maxLength={MAX_SOCIAL_URL_LENGTH}
            userInfoKey="socialSite"
            onData={onData}
            newUserInfo={newUserInfo}
            dir="ltr"
        />
    );
}
