import { addDoc, collection, doc, FieldValue, serverTimestamp, setDoc } from "firebase/firestore";

import { getFirebaseFirestoreDb } from "../firebase/firebase";
import { THERAPIST_USER_INFO, USER_INFO } from "../user/userInfo";

export type CHAT = {
    metupalUids: string[];
    therapistUid: string;
    initiator?: string;
    lastMessageTime?: FieldValue;
    lastMessageContent?: string;
};

export type MESSAGE = {
    text: string;
    sender: string;
    sentDate?: FieldValue;
};

function getChatRef(uid: string) {
    return doc(getFirebaseFirestoreDb(), "chats", uid);
}
export function getChatCollection() {
    return collection(getFirebaseFirestoreDb(), "chats");
}

export function getChatUid(metupalUid: string, therapistUid: string) {
    return metupalUid + therapistUid;
}

export function createChat(userInfo: USER_INFO, targetUserInfo: THERAPIST_USER_INFO) {
    const metupalUid = userInfo.isTherapist ? targetUserInfo.uid : userInfo.uid;
    const therapistUid = userInfo.isTherapist ? userInfo.uid : targetUserInfo.uid;
    const uid = getChatUid(metupalUid || "", therapistUid || "");
    const chat: CHAT = {
        metupalUids: [metupalUid || ""],
        therapistUid: therapistUid || "",
        initiator: userInfo.uid,
    };

    return setDoc(getChatRef(uid), chat);
}

export function getMessageCollection(chatUid: string) {
    return collection(getFirebaseFirestoreDb(), "chats", chatUid, "messages");
}

export function createMessage(chatUid: string, uid: string, text: string) {
    const message: MESSAGE = {
        text,
        sender: uid,
        sentDate: serverTimestamp(),
    };

    return addDoc(getMessageCollection(chatUid), message);
}
