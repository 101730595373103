import { PsychologyOutlined } from '@mui/icons-material';

import SeparatedList from '../../common/SeparatedList';
import T from '../../locallization/T';
import { allMethods } from '../../search/therapistInputs/SearchInputMethods';
import { THERAPIST_OUTPUT_PROPS } from '../basic/OutputProps';
import OutputRow from '../basic/OutputRow';
import { useSortedOutput } from '../basic/useSortedOutput';

export default function TherapistOutputMethods({ newUserInfo, ...props }: THERAPIST_OUTPUT_PROPS) {
    const sortedMethods = useSortedOutput(newUserInfo?.therapistMethods, allMethods);

    return sortedMethods.length ? (
        <OutputRow
            {...props}
            title={<T>search input methods</T>}
            value={
                <SeparatedList>
                    {sortedMethods.map((method) => (
                        <T key={method} children={`method ${method}`} />
                    ))}
                </SeparatedList>
            }
            Icon={PsychologyOutlined}
        />
    ) : null;
}
