import { httpsCallable } from "firebase/functions";

import { getFirebaseFunctions } from "../../firebase/firebase";

export type ADMIN_GROUP_STATS = { [key: string]: number | { [key: string]: number } };
export type ADMIN_COUNTS = {
    registered: number;
    therapists: number;
    metupalim: number;
    metupalStats: ADMIN_GROUP_STATS;
    therapistStats: ADMIN_GROUP_STATS;
};

export function getAdminCountsFunction() {
    return httpsCallable<null, ADMIN_COUNTS>(getFirebaseFunctions(), "onCallAdminCounts");
}
