import { range } from "lodash";
import { useCallback } from "react";

import T from "../../locallization/T";
import { USER_INFO_LANGS } from "../../user/userInfo";
import SearchInputBasicNumberedSet from "../basic/SearchInputBasicNumeredSet";
import { SEARCH_INPUT_PROPS } from "../inputs/SearchInputProps";

export const allLangs = range(1, USER_INFO_LANGS + 1);

export default function SearchInputLangs({ onData, newUserInfo }: SEARCH_INPUT_PROPS) {
    const getValueLabel = useCallback((lang: number) => <T children={`lang ${lang}`} />, []);

    return (
        <SearchInputBasicNumberedSet
            onData={onData}
            newUserInfo={newUserInfo}
            userInfoKey="therapistLangs"
            label={<T>search input langs</T>}
            allValues={allLangs}
            getValueLabel={getValueLabel}
        />
    );
}
