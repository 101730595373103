import { ChevronLeftOutlined } from "@mui/icons-material";
import { Button, CircularProgress } from "@mui/material";
import { useSnackbar } from "notistack";
import { useCallback, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";

import T from "../../locallization/T";
import { ROUTES } from "../../navigation/Router";
import { useAppContext } from "../../root/AppContext";
import WithThemeIcon from "../../theme/WithThemeIcon";
import { USER_INFO } from "../../user/userInfo";
import { updateUserInfo } from "../../user/userUpdates";

export function getUpdatesForSignAs(userInfo: USER_INFO): USER_INFO {
    if (userInfo.accountHasMetupal && userInfo.accountHasTherapist) {
        return {
            isTherapist: !userInfo.isTherapist,
        };
    }
    return {
        onboarded: false,
        timesSearched: false,
        isTherapist: !userInfo.isTherapist,
    };
}
export function getUpdatesForBackOfSignAs(userInfo: USER_INFO): USER_INFO {
    return {
        onboarded: true,
        timesSearched: true,
        isTherapist: !userInfo.isTherapist,
    };
}

export default function ProfileLoginAs() {
    const { user, userInfo } = useAppContext();
    const [loading, setLoading] = useState(false);
    const { enqueueSnackbar } = useSnackbar();
    const navigate = useNavigate();

    const text = useMemo(() => {
        if (userInfo?.isTherapist) {
            if (userInfo?.accountHasMetupal) {
                return <T>profile login as metupal</T>;
            }
            return <T>profile register as metupal</T>;
        }
        if (userInfo?.accountHasTherapist) {
            return <T>profile login as therapist</T>;
        }
        return <T>profile register as therapist</T>;
    }, [userInfo]);

    const signAs = useCallback(() => {
        if (!user || !userInfo) {
            return;
        }
        setLoading(true);
        const updates = getUpdatesForSignAs(userInfo);
        const wasTherapist = userInfo.isTherapist;
        updateUserInfo(user.uid, userInfo, updates)
            .then(() => {
                if (userInfo.accountHasMetupal && userInfo.accountHasTherapist) {
                    const text = wasTherapist ? (
                        <T>profile login as metupal success</T>
                    ) : (
                        <T>profile login as therapist success</T>
                    );
                    enqueueSnackbar(text, { variant: "success" });
                    setLoading(false);
                } else {
                    navigate(ROUTES.HOME);
                }
            })
            .catch((error) => {
                setLoading(false);
                console.error(error);
                enqueueSnackbar(<T params={{ code: 169 }}>general error</T>, { variant: "warning" });
            });
    }, [navigate, enqueueSnackbar, user, userInfo]);

    return (
        <Button
            disabled={loading}
            endIcon={loading ? <CircularProgress size={24} /> : <WithThemeIcon Icon={ChevronLeftOutlined} />}
            variant="text"
            color="inherit"
            onClick={signAs}
            sx={{ alignSelf: "start" }}>
            {text}
        </Button>
    );
}
