import { Button, CircularProgress, Stack, Typography } from "@mui/material";
import { useSnackbar } from "notistack";
import { useCallback } from "react";

import T from "../../locallization/T";
import { signInWithGoogle, signInWithGoogleToken } from "../../user/signInPopups";
import { useIsAppWebview, useIsNonAppWebview } from "../../webview/useIsWebviewApp";
import { registerToAppEvent, sendAppEvent } from "../../webview/webviewAppAdaptor";
import { ReactComponent as GoogleLogoSvg } from "../svg/googleLogo.svg";

const APP_EVENT_SIGN_IN_WITH_GOOGLE = "sign-in-with-google";

export default function GoogleSignInButton({ signInLoading, setSignInLoading, disabled = false }) {
    const { enqueueSnackbar } = useSnackbar();

    const signInWithGoogleHandler = useCallback(() => {
        setSignInLoading(true);
        signInWithGoogle()
            .then(() => {
                // Should be redirected here from another place.
                enqueueSnackbar(<T>login form success</T>, { variant: "success" });
            })
            .catch((error) => {
                setSignInLoading(false);
                console.error(error);
                enqueueSnackbar(<T>login form error</T>, { variant: "warning" });
            });
    }, [enqueueSnackbar, setSignInLoading]);

    const signInOnWebviewAppHandler = useCallback(() => {
        setSignInLoading(true);
        // TODO timeout sign in with google in app?
        const unregister = registerToAppEvent(APP_EVENT_SIGN_IN_WITH_GOOGLE, ({ data, errorCode }) => {
            unregister();
            if (errorCode) {
                setSignInLoading(false);
                console.error(errorCode);
                enqueueSnackbar(<T>login form error</T>, { variant: "warning" });
            } else {
                const idToken = (data as { idToken: string })?.idToken || "";
                signInWithGoogleToken(idToken)
                    .then(() => {
                        // Should be redirected here from another place.
                        enqueueSnackbar(<T>login form success</T>, { variant: "success" });
                    })
                    .catch((error) => {
                        setSignInLoading(false);
                        console.error(error);
                        enqueueSnackbar(<T>login form error</T>, { variant: "warning" });
                    });
            }
        });

        sendAppEvent(APP_EVENT_SIGN_IN_WITH_GOOGLE);
    }, [enqueueSnackbar, setSignInLoading]);

    const isInWebview = useIsNonAppWebview();
    const isInAppWebview = useIsAppWebview();

    return (
        <Stack gap={0.5}>
            <Button
                size="huge"
                startIcon={signInLoading ? <CircularProgress size={24} /> : <GoogleLogoSvg />}
                color="secondary"
                variant="contained"
                disabled={isInWebview || disabled || signInLoading}
                onClick={isInAppWebview ? signInOnWebviewAppHandler : signInWithGoogleHandler}>
                <T>login form button google</T>
            </Button>
            {isInWebview ? (
                <Typography variant="caption">
                    <T>login form webview closed</T>
                </Typography>
            ) : null}
        </Stack>
    );
}
