import { WbSunnyOutlined } from "@mui/icons-material";
import { Box, Stack, SvgIcon } from "@mui/material";
import { isEmpty } from "lodash";
import { lazy, Suspense, useEffect, useMemo, useState } from "react";

import PageTitle from "../common/PageTitle";
import T from "../locallization/T";
import LinkToDeletedMatches from "../matches/LinkToDeletedMatches";
import MatchedMetupalim from "../matches/MatchedMetupalim";
import Matches from "../matches/Matches";
import { useChatsWithMetupalim } from "../matches/useChatsWithMetupalim";
import { useCheckMet } from "../matches/useCheckMet";
import { useCheckMetupalMet } from "../matches/useCheckMetupalMet";
import { useMatchedMetupalim } from "../matches/useMatchedMetupalim";
import { useMetMetupalim } from "../matches/useMetMetupalim";
import { usePaymentDue } from "../matches/usePaymentDue";
import { useReadyMatches } from "../matches/useReadyMatches";
import BottomNav from "../navigation/BottomNav";
import PageContainer from "../navigation/PageContainer";
import { ROUTES } from "../navigation/Router";
import SettingRowSearch from "../profile/settingRows/SettingRowSearch";
import { useAppContext } from "../root/AppContext";
import { userInfoTimes } from "../search/inputs/SearchInputTime";
import { USER_INFO_TIME } from "../user/userInfo";
import { NeedConsultCard } from "./NeedConsultCard";
import SearchShortened from "./SearchShortened";

const LazyLottieBunnySearch = lazy(() => import("../search/loader/LottieBunnySearch"));
const LazyLottieBunnySit = lazy(() => import("../login/home/LottieBunnySit"));

export default function Home() {
    const { userInfo } = useAppContext();
    const [matchesCount, setMatchesCount] = useState<number>();
    const [showSearchShortened, setShowSearchShortened] = useState(
        !userInfo?.isTherapist && userInfo?.noMatchesIndicator
    );
    const readyMatches = useReadyMatches();
    const checkMetupalMet = useCheckMetupalMet();

    const matchedMetupalim = useMatchedMetupalim();
    const chatsWithMetupalim = useChatsWithMetupalim();
    const metMetupalim = useMetMetupalim();
    const checkMetMetupalim = useCheckMet();
    const paymentDue = usePaymentDue();

    const timeOfDay = useMemo<USER_INFO_TIME>(() => {
        const hour = new Date().getHours();
        if (hour >= 5 && hour <= 11) {
            return "morning";
        } else if (hour >= 12 && hour <= 15) {
            return "noon";
        } else if (hour >= 16 && hour <= 18) {
            return "afternoon";
        } else {
            return "evening";
        }
    }, []);

    const Icon: typeof SvgIcon = useMemo(() => {
        return userInfoTimes.find(({ value: time }) => time === timeOfDay)?.Icon || WbSunnyOutlined;
    }, [timeOfDay]);

    const hasMatchedMetupalim =
        userInfo?.isTherapist &&
        (userInfo?.therapistEnableSearch
            ? !isEmpty(matchedMetupalim) ||
              !isEmpty(chatsWithMetupalim) ||
              !isEmpty(metMetupalim) ||
              !isEmpty(checkMetMetupalim) ||
              !isEmpty(paymentDue)
            : !isEmpty(chatsWithMetupalim) ||
              !isEmpty(metMetupalim) ||
              !isEmpty(checkMetMetupalim) ||
              !isEmpty(paymentDue));
    const hasMatches = !userInfo?.isTherapist && (!isEmpty(readyMatches) || !isEmpty(checkMetupalMet));
    const hasSearchEnable = userInfo?.isTherapist ? userInfo?.therapistEnableSearch : userInfo?.enableSearch;

    const showEmptyState = userInfo?.isTherapist
        ? !hasMatchedMetupalim
        : !showSearchShortened && (!hasMatches || matchesCount === 0);

    useEffect(() => {
        // unsetting noMatchesIndicator doesn't change the ui until next visit
        if (!userInfo?.isTherapist && userInfo?.noMatchesIndicator) {
            setShowSearchShortened(true);
        }
    }, [userInfo?.isTherapist, userInfo?.noMatchesIndicator]);

    return (
        <PageContainer
            pageTitleKey="title home logged in"
            pagePath={ROUTES.HOME_SIGNED_IN}
            hideTopNav
            backgrounded
            BotNavComponent={
                <>
                    {showEmptyState && (
                        <Stack overflow="hidden" flexShrink={0} alignItems="flex-end">
                            {hasSearchEnable ? (
                                <Box
                                    height={304}
                                    sx={{
                                        transform: "translateX(60px)",
                                    }}>
                                    <Suspense>
                                        <LazyLottieBunnySearch />
                                    </Suspense>
                                </Box>
                            ) : (
                                <Box
                                    width={160}
                                    height={290}
                                    sx={{
                                        transform: "translateX(-24px)",
                                    }}>
                                    <Suspense>
                                        <LazyLottieBunnySit />
                                    </Suspense>
                                </Box>
                            )}
                        </Stack>
                    )}
                    <BottomNav />
                </>
            }>
            <Stack gap={2}>
                <PageTitle
                    gap={userInfo?.isTherapist ? 4 : 2}
                    subtitle={
                        showSearchShortened ? (
                            <T>home search shortened</T>
                        ) : showEmptyState ? (
                            hasSearchEnable ? (
                                userInfo?.isTherapist ? (
                                    <T>home empty state therapist</T>
                                ) : (
                                    <T>home empty state</T>
                                )
                            ) : userInfo?.isTherapist ? (
                                <T>home empty state no search therapist</T>
                            ) : (
                                <T>home empty state no search</T>
                            )
                        ) : (
                            (hasMatchedMetupalim || hasMatches) &&
                            (userInfo?.isTherapist ? (
                                hasSearchEnable ? (
                                    <T>home has results searching therapist</T>
                                ) : (
                                    <T>home empty state no search therapist</T>
                                )
                            ) : (
                                matchesCount &&
                                (hasSearchEnable ? (
                                    <T params={{ count: matchesCount }}>home has results searching</T>
                                ) : (
                                    <T params={{ count: matchesCount }}>home has results</T>
                                ))
                            ))
                        )
                    }>
                    <T children={`home title ${timeOfDay}`} /> <Icon sx={{ verticalAlign: "middle" }} />
                </PageTitle>
                {userInfo?.isTherapist && <SettingRowSearch />}
                {showSearchShortened ? (
                    <SearchShortened />
                ) : hasMatchedMetupalim ? (
                    <MatchedMetupalim setMatchesCount={setMatchesCount} mt={2} />
                ) : hasMatches ? (
                    <Matches setMatchesCount={setMatchesCount} />
                ) : null}
                {userInfo?.isTherapist && !hasMatchedMetupalim && <LinkToDeletedMatches />}
                {!userInfo?.isTherapist && !userInfo?.consult && <NeedConsultCard mt={showEmptyState ? -1 : 1} />}
            </Stack>
        </PageContainer>
    );
}
