import { keys, sortBy } from "lodash";
import { useMemo } from "react";

import { USER_INFO_MAP } from "../user/userInfo";

export function useSortedMatches(matchesToScore?: USER_INFO_MAP<string, number>) {
    return useMemo(() => {
        return sortBy(keys(matchesToScore || {}), (userId) => {
            const zigmond = (matchesToScore || {})[userId] || 0;
            return -zigmond;
        });
    }, [matchesToScore]);
}
