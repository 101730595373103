import {
    Box,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentProps,
    DialogProps,
    DialogTitle,
    Paper,
} from "@mui/material";
import { isEqual, uniqueId } from "lodash";
import { Dispatch, ReactNode, SetStateAction, useCallback, useEffect } from "react";
import { useNavigate } from "react-router-dom";

import { usePageSizes } from "../../navigation/usePageSizes";
import ActionButtons from "../ActionButtons";

export type AppDialogProps = {
    setOpen: Dispatch<SetStateAction<boolean>>;
    dialogTitle?: ReactNode;
    topNav?: ReactNode;
    actions?: ReactNode;
    disableGutters?: boolean;
    elevateActions?: boolean;
    ContentProps?: DialogContentProps;
} & DialogProps;

export default function AppDialog({
    setOpen,
    dialogTitle,
    topNav,
    children,
    actions,
    sx = [],
    ContentProps,
    disableGutters = false,
    elevateActions,
    ...props
}: AppDialogProps) {
    const { containerSx, contentSx } = usePageSizes();
    const navigate = useNavigate();
    const handleClose = useCallback(() => setOpen(false), [setOpen]);

    useEffect(() => {
        if (props.open) {
            const openerState = window.history.state;
            window.history.pushState({ name: uniqueId("dialog-") }, "");
            let closingFromHistoryBack = false;
            const onBackHandler = (event: PopStateEvent) => {
                // nested dialogs create additional states - ensure it's this one
                if (isEqual(event.state, openerState)) {
                    closingFromHistoryBack = true;
                    handleClose();
                }
            };
            window.addEventListener("popstate", onBackHandler);

            const dialogPathname = window.location.pathname;

            return () => {
                window.removeEventListener("popstate", onBackHandler);
                if (
                    !closingFromHistoryBack &&
                    // If navigated away from the page, don't navigate back.
                    dialogPathname === window.location.pathname
                ) {
                    navigate(-1);
                }
            };
        }
    }, [props.open, navigate, handleClose]);

    return (
        <Dialog
            onClose={handleClose}
            fullWidth
            maxWidth="xs"
            sx={{ ...(props.fullScreen ? containerSx : {}), ...sx }}
            {...props}>
            {topNav}
            {dialogTitle && <DialogTitle>{dialogTitle}</DialogTitle>}
            <DialogContent sx={{ ...(disableGutters ? { p: 0 } : {}), ...ContentProps?.sx }} {...ContentProps}>
                <Box sx={props.fullScreen ? contentSx : {}}>{children}</Box>
            </DialogContent>
            {actions && (
                <Paper elevation={elevateActions ? 3 : 0}>
                    <DialogActions sx={{ p: 0 }}>
                        <ActionButtons sx={props.fullScreen ? contentSx : {}}>{actions}</ActionButtons>
                    </DialogActions>
                </Paper>
            )}
        </Dialog>
    );
}
