import { Box, SxProps, Theme, Typography } from "@mui/material";
import { ReactNode } from "react";

import { boldStyles } from "../../theme/theme";
import { METUPAL_OUTPUT_PROPS } from "../basic/OutputProps";
import { useProfileName } from "../basic/useProfileName";

export default function MetupalTitle({
    newUserInfo,
    subtitle,
    sx = [],
}: METUPAL_OUTPUT_PROPS & { sx?: SxProps<Theme>; subtitle?: ReactNode }) {
    const name = useProfileName({
        targetUserInfo: newUserInfo,
        isTargetTherapist: false,
    });

    return (
        <Box textAlign="center" sx={sx}>
            <Typography variant="h2" sx={{ ...boldStyles }}>
                {name}
            </Typography>
            {subtitle && <Typography variant="h4">{subtitle}</Typography>}
        </Box>
    );
}
