import { Typography } from "@mui/material";

import T from "../../../locallization/T";

export default function OnboardingQuestionMultiple() {
    return (
        <Typography variant="subtitle1">
            <T>onboarding questions multiple</T>
        </Typography>
    );
}
