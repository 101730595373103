import { Stack, Typography } from "@mui/material";
import { Fragment, useCallback, useEffect, useMemo, useState } from "react";

import T from "../../locallization/T";
import SearchInputBasicChips from "../../search/basic/SearchInputBasicChips";
import { userInfoTherapistTypes } from "../../search/therapistInputs/SearchInputTherapistTypes";
import { USER_INFO, USER_INFO_MAP, USER_INFO_THERAPY_TYPE } from "../../user/userInfo";
import AppToggleButton from "../buttonGroup/AppToggleButton";
import OnboardingQuestionMultiple from "./helpers/OnboardingQuestionMultiple";
import { ONBOARDING_QUESTION_PROPS } from "./OnboardingQuestionProps";

export const SUGGESTED_THERAPY_TIMES = [45, 50, 60, 75, 90, 120];
export const DEFAULT_THERAPIST_TIME = SUGGESTED_THERAPY_TIMES[1];
export const THERAPY_TYPES_WITH_TIMES = new Set<USER_INFO_THERAPY_TYPE>([
    "couple",
    "family",
    "group",
    "parents",
    "personal",
]);

export function alreadyHasTherapistType(userInfo: USER_INFO) {
    return !!userInfo.therapistTherapyTypes;
}

export default function OnboardingQuestionTherapistType({ onDataProgress }: ONBOARDING_QUESTION_PROPS) {
    const [selectedOptions, setSelectedOptions] = useState(new Set<USER_INFO_THERAPY_TYPE>([]));
    const [minutesObj, setMinutesObj] = useState<USER_INFO_MAP<USER_INFO_THERAPY_TYPE, number>>({});

    const selectMinutes = useCallback((type: USER_INFO_THERAPY_TYPE, time: number) => {
        setMinutesObj((minutesObj) => ({ ...minutesObj, [type]: time }));
    }, []);

    const displayMinutesObj = useMemo(() => {
        let resultMinutesObj = {};
        for (const type of Array.from(selectedOptions)) {
            resultMinutesObj[type] = minutesObj[type] || DEFAULT_THERAPIST_TIME;
        }
        return resultMinutesObj;
    }, [minutesObj, selectedOptions]);

    useEffect(() => {
        if (selectedOptions.size > 0) {
            onDataProgress({
                therapistTherapyTypes: Array.from(selectedOptions),
                therapistMinutes: displayMinutesObj,
            });
        } else {
            onDataProgress({});
        }
    }, [selectedOptions, displayMinutesObj, onDataProgress]);

    const getValueLabel = useCallback((time: number) => <T params={{ time }}>time minutes</T>, []);

    return (
        <Stack gap={2}>
            <OnboardingQuestionMultiple />
            {userInfoTherapistTypes.map(({ value: type, Icon }) => (
                <Fragment key={type}>
                    <AppToggleButton
                        value={type}
                        selectedOptions={selectedOptions}
                        setSelectedOptions={setSelectedOptions}
                        Icon={<Icon />}>
                        <T children={`onboarding question type ${type}`} />
                    </AppToggleButton>
                    {selectedOptions.has(type) && THERAPY_TYPES_WITH_TIMES.has(type) && (
                        <Stack gap={1}>
                            <Typography>
                                <T suffix=":">onboarding question type time</T>
                            </Typography>
                            <SearchInputBasicChips
                                allValues={SUGGESTED_THERAPY_TIMES}
                                getValueLabel={getValueLabel}
                                isValueSelected={(time) => displayMinutesObj[type] === time}
                                onValueClick={(time) => selectMinutes(type, time)}
                            />
                        </Stack>
                    )}
                </Fragment>
            ))}
        </Stack>
    );
}
