import T from '../../locallization/T';
import SearchInputBasicText from '../basic/SearchInputBasicText';
import { SEARCH_INPUT_PROPS } from '../inputs/SearchInputProps';

export const MAX_SOCIAL_URL_LENGTH = 750;

export default function SearchInputSocialFacebook({ onData, newUserInfo }: SEARCH_INPUT_PROPS) {
    return (
        <SearchInputBasicText
            label={<T>search input social facebook</T>}
            maxLength={MAX_SOCIAL_URL_LENGTH}
            userInfoKey="socialFacebook"
            onData={onData}
            newUserInfo={newUserInfo}
            dir="ltr"
        />
    );
}
