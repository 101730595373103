import { Switch, SwitchProps } from '@mui/material';
import { ChangeEvent, useCallback, useMemo, useState } from 'react';

import { useAppContext } from '../../root/AppContext';
import { INLINE_SETTINGS_PROPS } from './InlineSettingsProps';

export default function InlineSettingToggleSearch({
    onData,
    newUserInfo,
    size = "small",
}: INLINE_SETTINGS_PROPS & SwitchProps) {
    const { userInfo } = useAppContext();
    const toggleSearchKey = useMemo(
        () => (userInfo?.isTherapist ? "therapistEnableSearch" : "enableSearch"),
        [userInfo?.isTherapist]
    );
    const [isEnabledState, setIsEnabled] = useState(newUserInfo[toggleSearchKey] || false);
    const isEnabled = onData ? isEnabledState : !!newUserInfo[toggleSearchKey];

    const toggleEnabled = useCallback(
        (event: ChangeEvent<HTMLInputElement>) => {
            if (onData) {
                setIsEnabled(event.target.checked);
                onData({ [toggleSearchKey]: event.target.checked });
            }
        },
        [onData, toggleSearchKey]
    );

    return <Switch checked={isEnabled} onChange={toggleEnabled} size={size} />;
}
