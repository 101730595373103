import { DeleteForeverOutlined } from "@mui/icons-material";
import { CircularProgress, DialogContentText, SxProps, Theme } from "@mui/material";
import { noop } from "lodash";
import { useSnackbar } from "notistack";
import { useCallback, useState } from "react";

import { ActionButtonPrimary, ActionButtonSecondary } from "../../common/ActionButtons";
import AppDialog from "../../common/dialog/AppDialog";
import T from "../../locallization/T";
import { ROUTES } from "../../navigation/Router";
import { useAppContext } from "../../root/AppContext";
import { reauthenticationError } from "../../user/Reauthenticate";
import { useIsInWebview } from "../../webview/useIsWebviewApp";
import SettingRowBase from "../settingRows/SettingRowBase";

export default function ProfileDeleteAccountButton({ sx = [] }: { sx?: SxProps<Theme> }) {
    const { initiateDeleteUser, userInfo } = useAppContext();
    const [deleteLoading, setDeleteLoading] = useState(false);
    const { enqueueSnackbar } = useSnackbar();
    const [warningDialog, setWarningDialog] = useState(false);
    const isWebview = useIsInWebview();

    const showWarningDialog = useCallback(() => {
        setWarningDialog(true);
    }, []);

    const hideWarningDialog = useCallback(() => {
        if (!deleteLoading) {
            setWarningDialog(false);
        }
    }, [deleteLoading]);

    const deleteUserAccount = useCallback(() => {
        setDeleteLoading(true);

        initiateDeleteUser()
            .then(() => {
                enqueueSnackbar(<T>profile delete success</T>, { variant: "info" });
            })
            .catch((error) => {
                setDeleteLoading(false);
                reauthenticationError(error, enqueueSnackbar);
            });
    }, [enqueueSnackbar, initiateDeleteUser]);

    return (
        <>
            <SettingRowBase
                text={<T>profile delete account button</T>}
                Icon={DeleteForeverOutlined}
                onClick={deleteLoading ? noop : showWarningDialog}
            />
            <AppDialog
                open={warningDialog}
                setOpen={setWarningDialog}
                dialogTitle={<T>profile delete dialog title</T>}
                actions={
                    isWebview ? (
                        <ActionButtonPrimary
                            LinkComponent="a"
                            href={ROUTES.PROFILE}
                            // @ts-ignore: button does support target idk why it warns
                            target="_blank"
                            color="primary">
                            <T>profile delete dialog webview button</T>
                        </ActionButtonPrimary>
                    ) : (
                        <>
                            <ActionButtonSecondary onClick={hideWarningDialog} autoFocus disabled={deleteLoading}>
                                <T>profile delete dialog cancel button</T>
                            </ActionButtonSecondary>
                            <ActionButtonPrimary
                                onClick={deleteUserAccount}
                                color="error"
                                disabled={deleteLoading || userInfo?.consult}
                                endIcon={deleteLoading ? <CircularProgress size={24} /> : null}>
                                <T>profile delete dialog confirm button</T>
                            </ActionButtonPrimary>
                        </>
                    )
                }>
                <DialogContentText>
                    {isWebview ? <T>profile delete dialog webview subtitle</T> : <T>profile delete dialog subtitle</T>}
                </DialogContentText>
            </AppDialog>
        </>
    );
}
