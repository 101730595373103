import { Stack, TextField, Typography } from "@mui/material";
import { useCallback, useState } from "react";

import T from "../../locallization/T";
import { isValidYear } from "../../search/basic/SearchInputBasicYear";
import { USER_INFO } from "../../user/userInfo";
import { ONBOARDING_QUESTION_PROPS } from "./OnboardingQuestionProps";

export function alreadyHasAge(userInfo: USER_INFO) {
    return !!userInfo.year;
}

export default function OnboardingQuestionAge({ onDataProgress }: ONBOARDING_QUESTION_PROPS) {
    const [yearValue, setYearValue] = useState<string>("");

    const updateAgeValue = useCallback(
        (event) => {
            setYearValue(event.target.value);
            const year = +event.target.value;
            if (isValidYear(year)) {
                onDataProgress({
                    year,
                });
            } else {
                onDataProgress({});
            }
        },
        [onDataProgress]
    );

    return (
        <Stack>
            <Typography variant="subtitle1" sx={{ mb: 3 }}>
                <T>onboarding question age prompt</T>
            </Typography>
            <TextField
                label={<T>onboarding question age input</T>}
                fullWidth
                variant="outlined"
                type="text"
                inputProps={{
                    inputMode: "tel",
                }}
                value={yearValue}
                onChange={updateAgeValue}
            />
        </Stack>
    );
}
