import { VideocamTwoTone } from '@mui/icons-material';

import T from '../../locallization/T';
import { userInfoZoom } from '../../onboarding/questions/OnboardingQuestionLocation';
import { METUPAL_OUTPUT_PROPS } from '../basic/OutputProps';
import OutputRow from '../basic/OutputRow';

export default function MetupalOutputZoom({ newUserInfo, expanded, colorful, ...props }: METUPAL_OUTPUT_PROPS) {
    if (expanded) {
        return (
            <OutputRow
                {...props}
                expanded={expanded}
                title={<T>metupal output zoom</T>}
                Icon={colorful ? VideocamTwoTone : userInfoZoom[0].Icon}
                value={newUserInfo?.locationZoom === "Y" ? <T>yes</T> : <T>no</T>}
            />
        );
    }
    return newUserInfo?.locationZoom === "Y" ? (
        <OutputRow
            {...props}
            title={<T>metupal output zoom</T>}
            Icon={colorful ? VideocamTwoTone : userInfoZoom[0].Icon}
        />
    ) : null;
}
