import { Box, Stack, SvgIcon, Typography } from "@mui/material";

import { boldStyles } from "../../theme/theme";

export default function OutputRow({
    title,
    value,
    Icon,
    short,
    expanded,
    hideOnEmpty = false,
    spaceAsIcon = false,
}: {
    title: React.ReactNode;
    value?: React.ReactNode;
    Icon?: typeof SvgIcon;
    short?: boolean;
    expanded?: boolean;
    hideOnEmpty?: boolean;
    spaceAsIcon?: boolean;
}) {
    if (hideOnEmpty && !value) {
        return null;
    }
    const hasValue = value || value === 0;
    return (
        <Stack
            flexDirection="row"
            whiteSpace="pre-line"
            alignItems={expanded ? "center" : "initial"}
            sx={{ wordBreak: "break-word" }}>
            {Icon && !short ? (
                <Icon fontSize={expanded ? "large" : "medium"} />
            ) : spaceAsIcon ? (
                <Box width={24} />
            ) : null}
            <Stack ml={short ? 0 : 1.5} sx={short ? { display: "block", width: "100%" } : {}}>
                <Typography variant="h4" sx={{ ...boldStyles, whiteSpace: "pre", float: "left" }}>
                    {title}
                    {hasValue && short ? ": " : ""}
                </Typography>
                {hasValue ? <Typography variant="h4">{value}</Typography> : null}
            </Stack>
        </Stack>
    );
}
