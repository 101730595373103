import { Box, Card, CardContent, Stack } from '@mui/material';
import { ReactNode } from 'react';

import ZigmondBunnyPng from '../login/png/zigmondBunny.png';
import BunnyWritingPng from '../therapist_page/png/bunnyWriting.png';

const BUNNY_HEIGHT = 200;
const BUNNY_OFFSET = 32;

export default function CardedPage({
    children,
    bunny = "default",
}: {
    children: ReactNode;
    bunny?: "writing" | "default";
}) {
    return (
        <Stack pb={1} alignItems="center">
            <Box
                height={BUNNY_HEIGHT}
                component="img"
                src={bunny === "default" ? ZigmondBunnyPng : BunnyWritingPng}
                alt="Zigmond"
                position="relative"
                sx={{ transform: `translateY(${BUNNY_OFFSET}px)`, mt: `-${BUNNY_OFFSET}px` }}
            />
            <Card
                sx={{
                    width: "100%",
                    textAlign: "center",
                    borderRadius: 4,
                    overflow: "initial",
                    mb: 1,
                }}
                elevation={2}>
                <CardContent>{children}</CardContent>
            </Card>
        </Stack>
    );
}
