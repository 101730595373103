import { Stack, Typography } from "@mui/material";
import { useMemo } from "react";

import T from "../../locallization/T";
import { useAppContext } from "../../root/AppContext";
import { boldStyles } from "../../theme/theme";
import { THERAPIST_USER_INFO } from "../../user/userInfo";

export default function TherapistSummaryCost({ newUserInfo }: { newUserInfo: THERAPIST_USER_INFO }) {
    const { userInfo } = useAppContext();

    const [title, subtitle] = useMemo(() => {
        const therapyType =
            userInfo?.therapyType === "child" || !userInfo?.therapyType ? "personal" : userInfo?.therapyType;
        if ((newUserInfo?.therapistCosts || {})[therapyType]) {
            return [
                <T>therapist output cost</T>,
                <T params={{ cost: (newUserInfo?.therapistCosts || {})[therapyType] }}>
                    onboarding question cost range ils
                </T>,
            ];
        } else {
            return [];
        }
    }, [newUserInfo, userInfo]);

    return (
        <Stack>
            <Typography>{title}</Typography>
            <Typography variant="h3" sx={{ ...boldStyles }}>
                {subtitle}
            </Typography>
        </Stack>
    );
}
