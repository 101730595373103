import { memo } from "react";
import { BrowserRouter } from "react-router-dom";

import LoadingContainer from "../loading/LoadingContainer";
import Router from "../navigation/Router";
import AppTheme from "../theme/AppTheme";
import AppContextProvider from "./AppContext";
import ErrorBoundary from "./ErrorBoundry";
import GlobalMessages from "./GlobalMessages";
import { SnackbarContainer } from "./SnackbarContainer";

export default memo(function App() {
    return (
        <BrowserRouter>
            <AppContextProvider>
                <AppTheme>
                    <SnackbarContainer>
                        <ErrorBoundary>
                            <LoadingContainer>
                                <GlobalMessages>
                                    <Router />
                                </GlobalMessages>
                            </LoadingContainer>
                        </ErrorBoundary>
                    </SnackbarContainer>
                </AppTheme>
            </AppContextProvider>
        </BrowserRouter>
    );
});
