import { FormControl, InputLabel, ListItemIcon, ListItemText, MenuItem, Select, Stack } from "@mui/material";
import { useCallback, useMemo, useState } from "react";

import T from "../../locallization/T";
import AppToggleButton from "../../onboarding/buttonGroup/AppToggleButton";
import { USER_INFO_KUPA } from "../../user/userInfo";
import ClalitPng from "../png/kupa/clalit.png";
import LeumitPng from "../png/kupa/leumit.png";
import MacabiPng from "../png/kupa/macabi.png";
import MeuhedetPng from "../png/kupa/meuhedet.png";
import { SEARCH_INPUT_PROPS } from "./SearchInputProps";

export const userInfoKupot: { value: USER_INFO_KUPA; ImagePng?: string; textKey?: string }[] = [
    { value: "clalit", ImagePng: ClalitPng },
    { value: "macabi", ImagePng: MacabiPng },
    { value: "meuhedet", ImagePng: MeuhedetPng },
    { value: "leumit", ImagePng: LeumitPng },
];
export const userInfoKupotWithNone = (
    [{ value: "none", textKey: "onboarding question kupa none" }] as {
        value: USER_INFO_KUPA;
        ImagePng?: string;
        textKey?: string;
    }[]
).concat(userInfoKupot);

export default function SearchInputKupa({
    onData,
    newUserInfo,
    expanded,
}: SEARCH_INPUT_PROPS & { expanded?: boolean }) {
    const [kupa, setKupa] = useState<USER_INFO_KUPA | undefined>(newUserInfo?.kupa);

    const selectedOptions = useMemo(() => new Set<USER_INFO_KUPA>(kupa ? [kupa] : []), [kupa]);

    const selectKupa = useCallback(
        (kupa?: USER_INFO_KUPA) => {
            setKupa(kupa);
            onData({ kupa });
        },
        [onData]
    );
    const updatedSelectedKupa = useCallback(
        (newSelectedOptionsHandler: (existing: Set<USER_INFO_KUPA>) => Set<USER_INFO_KUPA>) => {
            const newSelectedOptions = newSelectedOptionsHandler(selectedOptions);
            const options = Array.from(newSelectedOptions);
            selectKupa(options[0]);
        },
        [selectedOptions, selectKupa]
    );
    const updateKupa = useCallback(
        (event) => {
            selectKupa(event.target.value);
        },
        [selectKupa]
    );

    if (expanded) {
        return (
            <Stack gap={2}>
                {userInfoKupot.map(({ value: kupa, ImagePng, textKey }) => (
                    <AppToggleButton
                        key={kupa}
                        value={kupa}
                        limitToOne
                        selectedOptions={selectedOptions}
                        setSelectedOptions={updatedSelectedKupa}>
                        {textKey ? <T>{textKey}</T> : <img src={ImagePng} alt={kupa} />}
                    </AppToggleButton>
                ))}
            </Stack>
        );
    }

    return (
        <FormControl>
            <InputLabel>
                <T>search input kupa</T>
            </InputLabel>
            <Select value={kupa} onChange={updateKupa} label={<T>search input kupa</T>}>
                {userInfoKupotWithNone.map(({ value: kupa, ImagePng, textKey }) => (
                    <MenuItem key={kupa} value={kupa}>
                        {ImagePng ? (
                            <ListItemIcon>
                                <img src={ImagePng} alt={kupa} />
                            </ListItemIcon>
                        ) : (
                            <ListItemText primary={<T>{textKey}</T>} />
                        )}
                    </MenuItem>
                ))}
            </Select>
        </FormControl>
    );
}
