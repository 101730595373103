import { ArrowBack } from "@mui/icons-material";
import { Button, CircularProgress } from "@mui/material";
import { useSnackbar } from "notistack";
import { useCallback, useEffect, useState } from "react";

import EmailInput from "../../common/EmailInput";
import PasswordInput from "../../common/PasswordInput";
import { saveStorage } from "../../common/storage";
import T from "../../locallization/T";
import { ROUTES } from "../../navigation/Router";
import { getSpacedButtonIconProps } from "../../theme/buttons";
import WithThemeIcon from "../../theme/WithThemeIcon";
import { signUpWithUsername } from "../../user/signInPopups";
import AlreadyHasAccountLink from "../AlreadyHasAccountLink";
import AppleSignInButton from "./AppleSignInButton";
import { sendVerifyEmail } from "./EmailVerifiedHolder";
import GoogleSignInButton from "./GoogleSignInButton";
import SignInFormContainer from "./SignInFormContainer";
import TermsAck from "./TermsAck";

export const MAX_PASSWORD_LENGTH = 64;
export const STORAGE_KEY_REGISTER_AS_THERAPIST = "register-as-therapist";
export const STORAGE_KEY_REGISTER_AS_THERAPIST_TRUE = "1";
const STORAGE_KEY_REGISTER_AS_THERAPIST_FALSE = "0";

export default function RegisterForm({ therapist = false }) {
    const [signInLoading, setSignInLoading] = useState(false);
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const { enqueueSnackbar } = useSnackbar();

    useEffect(() => {
        saveStorage(
            STORAGE_KEY_REGISTER_AS_THERAPIST,
            therapist ? STORAGE_KEY_REGISTER_AS_THERAPIST_TRUE : STORAGE_KEY_REGISTER_AS_THERAPIST_FALSE
        );
    }, [therapist]);
    const signup = useCallback(() => {
        if (email.includes("+") && !email.includes("+zigmond@")) {
            enqueueSnackbar(<T>register form error plus</T>, { variant: "warning" });
            return;
        }
        setSignInLoading(true);
        signUpWithUsername(email.trim(), password)
            .then(() => {
                return sendVerifyEmail(email.trim());
            })
            .then(() => {
                enqueueSnackbar(<T>register form success</T>, { variant: "success" });
            })
            .catch((error) => {
                setSignInLoading(false);
                console.error(error);
                let errorMessage = <T>register form error</T>;
                if (error.message.includes("auth/invalid-email")) {
                    errorMessage = <T>register form error invalid email</T>;
                }
                if (error.message.includes("auth/email-already-in-use")) {
                    errorMessage = <T>register form error used email</T>;
                }
                if (error.message.includes("auth/weak-password")) {
                    errorMessage = <T>register form error weak password</T>;
                }
                enqueueSnackbar(errorMessage, { variant: "warning" });
            });
    }, [email, password, enqueueSnackbar]);

    return (
        <SignInFormContainer
            pageTitleKey={therapist ? "register therapist form title" : "register form title"}
            pagePath={therapist ? ROUTES.REGISTER_THRAPIST : ROUTES.REGISTER}>
            <EmailInput disabled={signInLoading} email={email} setEmail={setEmail} />
            <PasswordInput disabled={signInLoading} password={password} setPassword={setPassword} />
            <TermsAck />
            <Button
                size="huge"
                color="primary"
                variant="contained"
                onClick={signup}
                {...getSpacedButtonIconProps({ sx: { mb: 4 } })}
                disabled={signInLoading || !email || password.length < 6}
                endIcon={signInLoading ? <CircularProgress size={24} /> : <WithThemeIcon Icon={ArrowBack} />}>
                <T>register form button enter</T>
            </Button>
            <GoogleSignInButton signInLoading={signInLoading} setSignInLoading={setSignInLoading} />
            <AppleSignInButton signInLoading={signInLoading} setSignInLoading={setSignInLoading} />
            <AlreadyHasAccountLink sx={{ mb: 2, mt: 4 }} />
            {/* <FacebookSignInButton signInLoading={signInLoading} setSignInLoading={setSignInLoading} /> */}
        </SignInFormContainer>
    );
}
