import { EditNotificationsOutlined } from '@mui/icons-material';

import T from '../../locallization/T';
import SettingNotifications from '../settings/SettingNotifications';
import SettingRowBase from './SettingRowBase';

export default function SettingRowNotifications({ startOpen }: { startOpen?: boolean }) {
    return (
        <SettingRowBase
            text={<T>profile setting notifications</T>}
            Icon={EditNotificationsOutlined}
            SettingContent={SettingNotifications}
            startOpen={startOpen}
        />
    );
}
