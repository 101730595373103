import { CloseOutlined, VisibilityOutlined } from "@mui/icons-material";
import { Button, IconButton, SxProps, Theme } from "@mui/material";
import { useCallback, useState } from "react";

import { ActionButtonSecondary } from "../common/ActionButtons";
import AppDialog from "../common/dialog/AppDialog";
import { getTalkGender } from "../common/gender/getTalkGender";
import T from "../locallization/T";
import { USER_INFO } from "../user/userInfo";
import TherapistPageView from "./TherapistPageView";

export default function TherapistPagePreviewButton({
    newUserInfo,
    asIcon,
    sx = [],
}: {
    newUserInfo: USER_INFO;
    asIcon?: boolean;
    sx?: SxProps<Theme>;
}) {
    const [showPreview, setShowPreview] = useState(false);

    const toggleShowPreview = useCallback(() => {
        setShowPreview((show) => !show);
    }, []);

    return (
        <>
            {asIcon ? (
                <IconButton onClick={toggleShowPreview} sx={sx} color="inherit">
                    <VisibilityOutlined />
                </IconButton>
            ) : (
                <Button
                    onClick={toggleShowPreview}
                    sx={sx}
                    variant="text"
                    color="inherit"
                    startIcon={<VisibilityOutlined />}>
                    <T overrideGender={getTalkGender(newUserInfo)}>therapist page preview button</T>
                </Button>
            )}
            <AppDialog
                open={showPreview}
                setOpen={setShowPreview}
                fullScreen
                disableGutters
                elevateActions
                actions={
                    <ActionButtonSecondary onClick={toggleShowPreview} endIcon={<CloseOutlined />}>
                        <T>dialog button close</T>
                    </ActionButtonSecondary>
                }>
                <TherapistPageView therapistUserInfo={newUserInfo} onBack={toggleShowPreview} />
            </AppDialog>
        </>
    );
}
