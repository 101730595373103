import { useMemo } from "react";

export function useLocationName(locations: string): string;
export function useLocationName(locations: string[]): string[];
export function useLocationName(locations: string | string[]): string | string[] {
    return useMemo(() => {
        if (typeof locations === "string") {
            return beautifyLocation(locations);
        }
        return locations.map(beautifyLocation);
    }, [locations]);
}

function beautifyLocation(location?: string): string {
    return (location || "").replace(", ישראל", "").replace(", Israel", "");
}
