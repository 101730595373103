import { Stack } from "@mui/material";

import SearchInputTherapistTimes from "../../search/therapistInputs/SearchInputTherapistTimes";
import { USER_INFO } from "../../user/userInfo";
import OnboardingQuestionMultiple from "./helpers/OnboardingQuestionMultiple";
import { ONBOARDING_QUESTION_PROPS } from "./OnboardingQuestionProps";

export function alreadyHasTherapistTimes(userInfo: USER_INFO) {
    return !!userInfo.therapistTimes;
}

export default function OnboardingQuestionTherapistTimes({ onDataProgress, newUserInfo }: ONBOARDING_QUESTION_PROPS) {
    return (
        <Stack gap={2}>
            <OnboardingQuestionMultiple />
            <SearchInputTherapistTimes onData={onDataProgress} newUserInfo={newUserInfo} expanded preventEmpty />
        </Stack>
    );
}
