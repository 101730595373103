import {
    AccountCircleOutlined,
    ChildCareOutlined,
    EscalatorWarningOutlined,
    GroupsOutlined,
    HouseOutlined,
    SocialDistanceOutlined,
} from "@mui/icons-material";
import { Stack, SvgIcon } from "@mui/material";
import { useEffect, useMemo, useState } from "react";

import T from "../../locallization/T";
import { USER_INFO, USER_INFO_THERAPY_TYPE } from "../../user/userInfo";
import AppToggleButton from "../buttonGroup/AppToggleButton";
import { ONBOARDING_QUESTION_PROPS } from "./OnboardingQuestionProps";

export const userInfoTypes: { value: USER_INFO_THERAPY_TYPE; Icon: typeof SvgIcon }[] = [
    { value: "personal", Icon: AccountCircleOutlined },
    { value: "child", Icon: ChildCareOutlined },
    { value: "parents", Icon: EscalatorWarningOutlined },
    { value: "couple", Icon: SocialDistanceOutlined },
    { value: "family", Icon: HouseOutlined },
    { value: "group", Icon: GroupsOutlined },
];

export function alreadyHasType(userInfo: USER_INFO) {
    return !!userInfo.therapyType;
}

export default function OnboardingQuestionType({ onDataProgress, newUserInfo }: ONBOARDING_QUESTION_PROPS) {
    const [selectedOptions, setSelectedOptions] = useState(
        new Set<USER_INFO_THERAPY_TYPE>(newUserInfo?.therapyType ? [newUserInfo?.therapyType] : [])
    );

    const therapyTypeValue = useMemo(() => {
        const therapyTypeArrayValue = Array.from(selectedOptions);
        return therapyTypeArrayValue.length > 0 ? therapyTypeArrayValue[0] : undefined;
    }, [selectedOptions]);

    useEffect(() => {
        if (therapyTypeValue) {
            onDataProgress({
                therapyType: therapyTypeValue,
            });
        } else {
            onDataProgress({});
        }
    }, [therapyTypeValue, onDataProgress]);

    return (
        <Stack gap={2}>
            {userInfoTypes.map(({ value: type, Icon }) => (
                <AppToggleButton
                    limitToOne
                    key={type}
                    value={type}
                    selectedOptions={selectedOptions}
                    setSelectedOptions={setSelectedOptions}
                    Icon={<Icon />}>
                    <T children={`onboarding question type ${type}`} />
                </AppToggleButton>
            ))}
        </Stack>
    );
}
