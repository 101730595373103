import { useMemo } from "react";

import { useAppContext } from "../../root/AppContext";

const NUMBER_REGEX = /(^|[^0-9])(?:-?\d){10,12}([^0-9]|$)/g;

export function useNoNumberText(targetUid?: string, text?: string) {
    const { userInfo } = useAppContext();

    return useMemo(() => {
        if (targetUid === userInfo?.uid) {
            // don't obfuscate yourself
            return text;
        }
        return (text || "").replace(NUMBER_REGEX, (matches, group1, group2) => `${group1}XXXX-XXX-XXX${group2}`);
    }, [text, targetUid, userInfo?.uid]);
}
