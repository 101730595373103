import { map } from 'lodash';

import SeparatedList from '../../common/SeparatedList';
import T from '../../locallization/T';
import {
    therapistQuestionIcons,
    therapistQuestionIconsColorful,
} from '../../onboarding/therapist/OnboardingQuestionsTherapist';
import { METUPAL_OUTPUT_PROPS } from '../basic/OutputProps';
import OutputRow from '../basic/OutputRow';

export default function MetupalOutputTime({ newUserInfo, colorful, ...props }: METUPAL_OUTPUT_PROPS) {
    return (
        <OutputRow
            {...props}
            title={<T>metupal output time</T>}
            value={
                newUserInfo?.times ? (
                    <SeparatedList>
                        {map(newUserInfo?.times, (value, time) => (
                            <T key={time} children={`search input time short ${time}`} />
                        ))}
                    </SeparatedList>
                ) : (
                    <T>output was not mentioned</T>
                )
            }
            Icon={colorful ? therapistQuestionIconsColorful.therapist_times : therapistQuestionIcons.therapist_times}
        />
    );
}
