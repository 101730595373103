import { isEmpty } from 'lodash';
import { useCallback } from 'react';

import { savePagesLocalization } from '../locallization/localizationSaver';
import { usePages } from '../locallization/usePageTexts';
import TextsEditor from './TextsEditor';

export default function PagesTextEditorSecret() {
    const { pages = {}, locale = "" } = usePages();

    const onSave = useCallback(
        (updates: {}) => {
            return savePagesLocalization(updates, locale);
        },
        [locale]
    );

    if (isEmpty(pages)) {
        // loading =p
        return null;
    }

    return <TextsEditor texts={pages} onSave={onSave} showAll accordions />;
}
