import {
    ChildCareOutlined,
    EmailOutlined,
    EventAvailableOutlined,
    PeopleOutlined,
    SchoolOutlined,
    SendOutlined,
} from "@mui/icons-material";
import {
    Button,
    Chip,
    CircularProgress,
    FormControl,
    FormHelperText,
    InputLabel,
    ListItemIcon,
    ListItemText,
    MenuItem,
    Select,
    Stack,
    TextField,
} from "@mui/material";
import imageCompression from "browser-image-compression";
import { httpsCallable } from "firebase/functions";
import { useSnackbar } from "notistack";
import { useCallback, useMemo, useState } from "react";

import { getFirebaseFunctions, URL } from "../../firebase/firebase";
import T from "../../locallization/T";

const EMAIL_TARGET_OPTIONS = [
    { target: "emails", text: "לרשימת אימיילים", Icon: EmailOutlined },
    { target: "therapists", text: "לכל המטפלים (פסיכולוגים)", Icon: SchoolOutlined },
    { target: "therapists_available", text: "לכל המטפלים הזמינים", Icon: EventAvailableOutlined },
    { target: "metupalim", text: "לכל המטופלים (אנשים רגילים)", Icon: ChildCareOutlined },
    { target: "all", text: "לכולם", Icon: PeopleOutlined },
];

const MAX_FILES_MB = 8;
const MAX_FILES_SIZE = MAX_FILES_MB * 1024 * 1024;

type SERVER_FILE = {
    name: string;
    type: string;
    base64: string;
};

export default function EmailSenderSecret() {
    const [title, setTitle] = useState("");
    const [saving, setSaving] = useState(false);
    const updateTitle = useCallback((event) => setTitle(event.target.value), []);
    const [body, setBody] = useState("");
    const updateBody = useCallback((event) => setBody(event.target.value), []);
    const [action, setAction] = useState("");
    const updateAction = useCallback((event) => setAction(event.target.value), []);
    const [url, setUrl] = useState(URL);
    const updateUrl = useCallback((event) => setUrl(event.target.value), []);
    const [emails, setEmails] = useState("");
    const updateEmails = useCallback((event) => setEmails(event.target.value), []);
    const [to, setTo] = useState(EMAIL_TARGET_OPTIONS[0].target);
    const updateTo = useCallback((event) => setTo(event.target.value), []);
    const [attachments, setAttachments] = useState<File[]>([]);
    const updateAttachments = useCallback(({ target }) => {
        setAttachments([...(target?.files || [])]);
    }, []);
    const { enqueueSnackbar } = useSnackbar();

    const sendEmailCallback = useMemo(
        () =>
            httpsCallable<
                {
                    subject: string;
                    body: string;
                    action: string;
                    url?: string;
                    emails: string[];
                    to: string;
                    files: SERVER_FILE[];
                },
                null
            >(getFirebaseFunctions(), "onCallCustomEmail"),
        []
    );

    const sendEmail = useCallback(
        async (event) => {
            setSaving(true);

            const files: SERVER_FILE[] = [];
            if (attachments.length) {
                let readyAttachments: File[] = [];
                let attachmentSizes = 0;
                for (const attachment of attachments) {
                    attachmentSizes += attachment.size;
                }

                if (attachmentSizes > MAX_FILES_SIZE) {
                    const maxSizePerFileMb = MAX_FILES_MB / attachments.length;
                    for (const attachment of attachments) {
                        const compressed = await imageCompression(attachment, {
                            maxSizeMB: maxSizePerFileMb,
                            useWebWorker: true,
                        });
                        readyAttachments.push(compressed);
                    }
                } else {
                    readyAttachments = attachments;
                }
                for (const attachment of readyAttachments) {
                    const base64 = await getBase64(attachment);
                    files.push({
                        name: attachment.name,
                        type: attachment.type,
                        base64,
                    });
                }
            }

            sendEmailCallback({
                subject: title.trim(),
                body: body.trim(),
                url: url.trim(),
                action: action.trim(),
                emails: emails.split(",").map((email) => email.trim()),
                to,
                files,
            })
                .then((response) => {
                    setSaving(false);
                    enqueueSnackbar("האימייל נשלח בהצלחה", { variant: "success" });
                    setEmails("");
                    setTo(EMAIL_TARGET_OPTIONS[0].target);
                })
                .catch((error) => {
                    setSaving(false);
                    console.error(error);
                    enqueueSnackbar(<T params={{ code: 174 }}>general error</T>, { variant: "warning" });
                });
        },
        [title, body, action, url, emails, to, attachments, sendEmailCallback, enqueueSnackbar]
    );

    return (
        <Stack gap={2}>
            <TextField
                value={title}
                onChange={updateTitle}
                color="primary"
                fullWidth
                autoFocus
                label="כותרת האימייל"
                required
            />
            <TextField
                value={body}
                onChange={updateBody}
                color="primary"
                fullWidth
                multiline
                label="תוכן האימייל"
                required
            />
            <Stack flexDirection="row" gap={1} flexWrap="wrap">
                <Button variant="outlined" component="label" disabled={saving}>
                    צירוף קבצים
                    <input hidden accept="image/*" multiple type="file" onChange={updateAttachments} />
                </Button>

                {attachments.map((file, index) => (
                    <Chip key={index} label={file.name} color="primary" variant="outlined" />
                ))}
                {attachments.length > 0 && (
                    <FormHelperText>כדי לשים תמונה בתוך האימייל, העתק את השם שלה לשם</FormHelperText>
                )}
            </Stack>

            <TextField value={action} onChange={updateAction} color="primary" fullWidth label="טקסט בכפתור" required />
            <TextField
                value={url}
                onChange={updateUrl}
                color="primary"
                fullWidth
                label="כתובת בכפתור"
                inputProps={{
                    sx: {
                        unicodeBidi: "plaintext",
                        textAlign: "right",
                    },
                }}
            />

            <FormControl required>
                <InputLabel shrink>למי לשלוח</InputLabel>
                <Select
                    value={to}
                    onChange={updateTo}
                    label="למי לשלוח"
                    displayEmpty
                    renderValue={(selected) =>
                        EMAIL_TARGET_OPTIONS.map(({ target, text }) => (target === selected ? text : null))
                    }>
                    {EMAIL_TARGET_OPTIONS.map(({ target, text, Icon }) => (
                        <MenuItem key={target} value={target}>
                            <ListItemIcon>
                                <Icon fontSize="small" />
                            </ListItemIcon>
                            <ListItemText primary={text} />
                        </MenuItem>
                    ))}
                </Select>
            </FormControl>
            <TextField
                required
                dir="ltr"
                value={emails}
                onChange={updateEmails}
                disabled={to !== EMAIL_TARGET_OPTIONS[0].target}
                color="primary"
                fullWidth
                multiline
                label="כתובות לשליחת האימייל"
                helperText="רשימת אימיילים מופרדת בפסיקים"
            />

            <Button
                variant="contained"
                onClick={sendEmail}
                disabled={saving || !title || !body || (to === EMAIL_TARGET_OPTIONS[0].target && !emails)}
                endIcon={saving ? <CircularProgress size={24} /> : <SendOutlined />}>
                שליחת אימייל
            </Button>
        </Stack>
    );
}

function getBase64(file: File): Promise<string> {
    return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve((reader.result as string).split(";base64,")[1]);
        reader.onerror = (error) => reject(error);
    });
}
