import { onValue, ref } from "firebase/database";
import { useCallback, useEffect, useState } from "react";

import { getFirebaseRealtimeDb } from "../firebase/firebase";

export function useDbConstant<T>(constantKey: string, defaultValue: T, { skip = false } = {}) {
    const [constantValue, setConstantValue] = useState<T>(defaultValue);

    const onLoadData = useCallback((data: T) => {
        setConstantValue(data);
    }, []);

    useEffect(() => {
        if (skip) {
            return;
        }
        const unsubscribe = loadConstant<T>(constantKey, onLoadData);
        return () => unsubscribe();
    }, [constantKey, onLoadData, skip]);

    return constantValue;
}

function loadConstant<T>(key: string, onLoad: (data: T) => void) {
    const instanceRef = ref(getFirebaseRealtimeDb(), key);

    return onValue(instanceRef, (snapshot) => {
        const data = snapshot.val();
        onLoad(data);
    });
}
