import { Stack } from "@mui/material";
import { useMemo } from "react";

import { getTalkGender } from "../../common/gender/getTalkGender";
import SeparatedList from "../../common/SeparatedList";
import T from "../../locallization/T";
import { allOthers, OTHER_TO_TITLE_MAP } from "../../search/inputs/SearchInputOthers";
import { PRIVATE_OUTPUT_PROPS } from "./OutputProps";
import OutputRow from "./OutputRow";
import { useSortedOutput } from "./useSortedOutput";

export default function OutputOthers({ newUserInfo, therapist }: PRIVATE_OUTPUT_PROPS & { therapist: boolean }) {
    const sortedOthers = useSortedOutput(
        therapist ? newUserInfo?.therapistOthersList : newUserInfo?.othersList,
        allOthers
    ) as unknown[] as number[];

    const othersList = useMemo(() => {
        const list: { title: number; items: number[] }[] = [];
        for (const other of sortedOthers) {
            const matchingTitle = OTHER_TO_TITLE_MAP[other] || 1;
            if (!list.length || list[list.length - 1].title !== matchingTitle) {
                list.push({ title: matchingTitle, items: [] });
            }
            list[list.length - 1].items.push(other);
        }
        return list;
    }, [sortedOthers]);

    return (
        <Stack>
            {sortedOthers.length ? (
                <SeparatedList separator={"\n"}>
                    {othersList.map(({ title, items }) => (
                        <OutputRow
                            key={title}
                            title={
                                <T children={therapist ? `other title therapist ${title}` : `other title ${title}`} />
                            }
                            value={
                                <SeparatedList>
                                    {items.map((other) => (
                                        <T
                                            key={other}
                                            overrideGender={getTalkGender(newUserInfo)}
                                            children={therapist ? `other therapist ${other}` : `other ${other}`}
                                        />
                                    ))}
                                </SeparatedList>
                            }
                            short
                        />
                    ))}
                </SeparatedList>
            ) : null}
        </Stack>
    );
}
