import T from "../../locallization/T";
import { useProfileEmail } from "../../outputs/basic/useProfileEmail";
import SearchInputBasicText from "../basic/SearchInputBasicText";
import { SEARCH_INPUT_PROPS } from "../inputs/SearchInputProps";

export default function SearchInputEmail({ onData, newUserInfo, isDisabled }: SEARCH_INPUT_PROPS) {
    const email = useProfileEmail({ newUserInfo });
    if (!email) {
        return null;
    }

    return (
        <SearchInputBasicText
            label={<T>search input email</T>}
            userInfoKey="email"
            onData={onData}
            newUserInfo={newUserInfo}
            disabled={isDisabled}
        />
    );
}
