import { useMemo } from "react";

export function useYearsToAge(year?: number) {
    return useMemo(() => {
        if (!year) {
            return null;
        }
        // 0 is rounded to 1
        return Math.max(new Date().getFullYear() - year, 1);
    }, [year]);
}
