import { size } from "lodash";
import { useSnackbar } from "notistack";
import { useCallback } from "react";

import { useNavigateToChat } from "../chat/useNavigateToChat";
import T from "../locallization/T";
import { CONSULT_UID } from "../user/consult";
import { usePaymentDue } from "./usePaymentDue";

const PAYMENT_URL_PREFIX = "https://pages.greeninvoice.co.il/payments/links/";
const PAYMENT_ID_PER_SIZE = [
    "53b88108-4b8a-4c0f-9841-8c2c8f6fcaa7",
    "8db3263b-17ec-42db-9a6a-4c5e0fe5fcd6",
    "bf30f4a4-5760-4382-923b-8962f47d3137",
    "f34c90b4-314e-4bf3-81a9-1b8680acf6d8",
    "f3d32f41-f637-4ef9-8dbd-ff14fcc3b608",
    "c95f2812-4f75-4dfd-9883-408dd20d24eb",
];

export function useOpenPayment({ prepay = false }: { prepay?: boolean } = {}) {
    const paymentDue = usePaymentDue({ prepay });
    const navigateToConsult = useNavigateToChat(CONSULT_UID);
    const { enqueueSnackbar } = useSnackbar();

    const openPayment = useCallback(() => {
        const paymentSize = size(paymentDue);
        if (paymentSize > PAYMENT_ID_PER_SIZE.length) {
            navigateToConsult();
            enqueueSnackbar(<T>error payment size big</T>, { variant: "warning" });
            return;
        }
        const paymentId = PAYMENT_ID_PER_SIZE[paymentSize - 1];
        const url = PAYMENT_URL_PREFIX + paymentId;
        window.open(url, "_blank");
    }, [enqueueSnackbar, navigateToConsult, paymentDue]);

    return { openPayment };
}
