import { ArrowBack } from "@mui/icons-material";
import { Button, CircularProgress } from "@mui/material";
import { useSnackbar } from "notistack";
import { useCallback, useState } from "react";
import { Navigate, useLocation, useNavigate } from "react-router-dom";

import EmailInput from "../../common/EmailInput";
import PasswordInput from "../../common/PasswordInput";
import T from "../../locallization/T";
import { FORGOT_PASSWORD_EMAIL_STATE, LOGIN_STATE_CONTINUE_URL, ROUTES } from "../../navigation/Router";
import { useAppContext } from "../../root/AppContext";
import { getSpacedButtonIconProps } from "../../theme/buttons";
import WithThemeIcon from "../../theme/WithThemeIcon";
import { signInWithUsername } from "../../user/signInPopups";
import AppleSignInButton from "./AppleSignInButton";
import GoogleSignInButton from "./GoogleSignInButton";
import SignInFormContainer from "./SignInFormContainer";
import TermsAck from "./TermsAck";

export default function LoginForm() {
    const location = useLocation();
    const [signInLoading, setSignInLoading] = useState(false);
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const { enqueueSnackbar } = useSnackbar();
    const navigate = useNavigate();
    const { user } = useAppContext();

    const login = useCallback(() => {
        setSignInLoading(true);
        signInWithUsername(email.trim(), password)
            .then(() => {
                enqueueSnackbar(<T>login form success</T>, { variant: "success" });
            })
            .catch((error) => {
                setSignInLoading(false);
                console.error({ error: error.message });
                let errorMessage = <T>login form error</T>;
                if (error.message.includes("auth/invalid-email")) {
                    errorMessage = <T>login form error no email</T>;
                }
                if (error.message.includes("auth/wrong-password")) {
                    errorMessage = <T>login form error no password</T>;
                }
                enqueueSnackbar(errorMessage, { variant: "warning" });
            });
    }, [email, password, enqueueSnackbar]);

    const navigateToForgotPassword = useCallback(
        (event) => {
            event.preventDefault();
            navigate(ROUTES.FORGOT_PASSWORD, { state: { [FORGOT_PASSWORD_EMAIL_STATE]: email } });
        },
        [navigate, email]
    );

    if (user) {
        const stateContinueUrl = ((location.state as {}) || {})[LOGIN_STATE_CONTINUE_URL];
        return <Navigate to={stateContinueUrl || ROUTES.HOME} />;
    }

    return (
        <SignInFormContainer pageTitleKey="login form title" pagePath={ROUTES.LOGIN}>
            <EmailInput disabled={signInLoading} email={email} setEmail={setEmail} />
            <PasswordInput disabled={signInLoading} password={password} setPassword={setPassword} />
            <Button
                sx={{ alignSelf: "flex-start", mt: -2 }}
                href={ROUTES.FORGOT_PASSWORD}
                onClick={navigateToForgotPassword}
                disabled={signInLoading}>
                <T>login form forgot caption</T>
            </Button>
            <TermsAck />
            <Button
                size="huge"
                color="primary"
                variant="contained"
                onClick={login}
                {...getSpacedButtonIconProps({ sx: { mb: 4 } })}
                disabled={signInLoading || !email || password.length < 6}
                endIcon={signInLoading ? <CircularProgress size={24} /> : <WithThemeIcon Icon={ArrowBack} />}>
                <T>login form button enter</T>
            </Button>
            <GoogleSignInButton signInLoading={signInLoading} setSignInLoading={setSignInLoading} />
            <AppleSignInButton signInLoading={signInLoading} setSignInLoading={setSignInLoading} />
            {/* <FacebookSignInButton signInLoading={signInLoading} setSignInLoading={setSignInLoading} /> */}
        </SignInFormContainer>
    );
}
