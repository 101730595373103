import { httpsCallable } from "firebase/functions";
import { useSnackbar } from "notistack";
import { Dispatch, SetStateAction, useCallback } from "react";

import { getFirebaseFunctions } from "../firebase/firebase";
import T from "../locallization/T";

export function useRestoreMetupal({
    metupalUid,
    setLoading,
    onRestore,
}: {
    metupalUid: string;
    setLoading: Dispatch<SetStateAction<boolean>>;
    onRestore?: () => void;
}) {
    const { enqueueSnackbar } = useSnackbar();

    const restoreMetupal = useCallback(() => {
        setLoading(true);
        const unrejectMetupalCallback = httpsCallable<{ metupalUid: string }, {}>(
            getFirebaseFunctions(),
            "onCallUnrejectMetupal"
        );

        unrejectMetupalCallback({ metupalUid: metupalUid })
            .then(() => {
                setLoading(false);
                enqueueSnackbar(<T>match metupal restore success</T>, {
                    variant: "success",
                });
                onRestore && onRestore();
            })
            .catch((error) => {
                setLoading(false);
                console.error(error);
                enqueueSnackbar(<T params={{ code: 196 }}>general error</T>, { variant: "warning" });
            });
    }, [metupalUid, enqueueSnackbar, setLoading, onRestore]);
    return { restoreMetupal };
}
