import { useMemo } from "react";
import { useSearchParams } from "react-router-dom";

export const UNRELEASED_FEATURE_TEXT_KEYS = "keys";
const STORAGE_PREFIX = "unreleased-feature_";

export function useUnreleasedFeature(urlParam: string) {
    let [searchParams] = useSearchParams();

    return useMemo(() => {
        let value: string | null = null;
        const storageKey = STORAGE_PREFIX + urlParam;
        value = searchParams.get(urlParam);
        if (typeof value === "string") {
            try {
                sessionStorage.setItem(storageKey, value);
            } catch {}
        } else {
            try {
                value = sessionStorage.getItem(storageKey);
            } catch {}
        }
        return value || "";
    }, [searchParams, urlParam]);
}
