import { CancelOutlined, CheckOutlined } from "@mui/icons-material";
import { Button, Checkbox, FormControlLabel, Stack, Typography } from "@mui/material";
import { serverTimestamp } from "firebase/firestore";
import { noop } from "lodash";
import { useSnackbar } from "notistack";
import { useCallback, useEffect, useState } from "react";

import { ActionButtonPrimary } from "../common/ActionButtons";
import AppDialog from "../common/dialog/AppDialog";
import T from "../locallization/T";
import { usePageText } from "../locallization/usePageTexts";
import AppSpinner from "../navigation/AppSpinner";
import TopNav from "../navigation/TopNav";
import { getUpdatesForBackOfSignAs } from "../profile/settingButtons/ProfileLoginAs";
import { useAppContext } from "../root/AppContext";
import { updateUserInfo } from "./userUpdates";

export default function ConfirmPayTermsModal({ onClose = noop }: { onClose?: () => void }) {
    const { user, userInfo, initialDeleteUserInfo } = useAppContext();
    const [checked, setChecked] = useState(false);
    const [loading, setLoading] = useState(false);
    const [showDialog, setShowDialog] = useState(false);
    const [renderedTerms, setRenderedTerms] = useState(false);
    const { text } = usePageText("therapist_agreement", { skip: !renderedTerms });
    const { enqueueSnackbar } = useSnackbar();

    const toggleChecked = useCallback(() => setChecked((checked) => !checked), []);

    useEffect(() => {
        if (userInfo?.isTherapist && !userInfo?.therapistTermsAck && user?.emailVerified) {
            // signed in or after onboarding - show verify terms
            setShowDialog(true);
            setRenderedTerms(true);
        }
    }, [userInfo?.isTherapist, userInfo?.therapistTermsAck, user?.emailVerified]);

    const onConfirm = useCallback(() => {
        setLoading(true);
        updateUserInfo(userInfo?.uid || "", userInfo, { therapistTermsAck: serverTimestamp() })
            .then(() => {
                setShowDialog(false);
                onClose();
            })
            .catch((error) => {
                setLoading(false);
                console.error(error);
                enqueueSnackbar(<T params={{ code: 189 }}>general error</T>, { variant: "warning" });
            });
    }, [userInfo, enqueueSnackbar]);

    const onReject = useCallback(() => {
        setLoading(true);
        updateUserInfo(userInfo?.uid || "", userInfo, { therapistEnableSearch: false })
            .then(() => {
                setShowDialog(false);
                onClose();
            })
            .catch((error) => {
                setLoading(false);
                console.error(error);
                enqueueSnackbar(<T params={{ code: 190 }}>general error</T>, { variant: "warning" });
            });
    }, [userInfo, enqueueSnackbar, onClose]);

    const onBackOnboardingUser = useCallback(() => {
        if (!user || loading) {
            return;
        }
        setLoading(true);
        let promise;
        if (userInfo?.accountHasMetupal || userInfo?.accountHasTherapist) {
            promise = updateUserInfo(user.uid, userInfo, getUpdatesForBackOfSignAs(userInfo));
        } else {
            promise = initialDeleteUserInfo();
        }

        promise.catch((error) => {
            setLoading(false);
            console.error(error);
            enqueueSnackbar(<T params={{ code: 191 }}>general error</T>, { variant: "warning" });
        });
    }, [user, userInfo, initialDeleteUserInfo, enqueueSnackbar, loading]);

    const isInOnboarding = !userInfo?.accountHasTherapist;

    if (!userInfo?.isTherapist || userInfo?.therapistTermsAck) {
        return null;
    }

    return (
        <AppDialog
            open={showDialog}
            setOpen={setShowDialog}
            // can't close this modal manually
            onClose={() => {}}
            fullScreen
            topNav={
                <TopNav
                    hideBack={!isInOnboarding}
                    onBack={isInOnboarding ? onBackOnboardingUser : undefined}
                    title={<T>confirm pay title</T>}
                />
            }
            actions={
                <ActionButtonPrimary
                    onClick={onConfirm}
                    disabled={loading || !checked}
                    endIcon={loading ? <AppSpinner /> : <CheckOutlined />}>
                    <T>confirm pay approve</T>
                </ActionButtonPrimary>
            }>
            <Stack gap={2} height="100%">
                {!isInOnboarding && (
                    <Typography>
                        <T>confirm pay existing users pretext</T>
                    </Typography>
                )}
                {text ? <Typography>{text}</Typography> : <AppSpinner />}
                <FormControlLabel
                    control={<Checkbox checked={checked} onChange={toggleChecked} />}
                    label={<T>confirm pay check</T>}
                />

                {!isInOnboarding && (
                    <Button
                        disabled={loading}
                        variant="text"
                        onClick={onReject}
                        color="error"
                        sx={{ alignSelf: "center", mt: 3 }}
                        startIcon={<CancelOutlined />}>
                        <T>confirm pay cancel</T>
                    </Button>
                )}
            </Stack>
        </AppDialog>
    );
}
