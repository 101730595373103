import { AccountCircleOutlined, ExpandMoreOutlined, StarOutlined, StarOutlineOutlined } from '@mui/icons-material';
import { Accordion, AccordionDetails, AccordionSummary, Box, IconButton, Stack, Tooltip, Typography } from '@mui/material';
import { get, isEmpty, isNumber, keys, sortBy } from 'lodash';
import { ReactNode, useCallback, useEffect, useMemo, useState } from 'react';

import { getStorageJSON, saveStorageJSON } from '../../common/storage';
import T, { getT } from '../../locallization/T';
import AppSpinner from '../../navigation/AppSpinner';
import OutputRow from '../../outputs/basic/OutputRow';
import { useAppContext } from '../../root/AppContext';
import { ADMIN_COUNTS, ADMIN_GROUP_STATS, getAdminCountsFunction } from './adminCounts';
import {
    ACTIVITY_DAILY,
    ACTIVITY_GLOBAL,
    ACTIVITY_MONTHLY,
    ACTIVITY_WEEKLY,
    useGroupActivities,
} from './useGroupActivities';

const STARRED_STATS_KEY = "admin-starred-stats";
const STAT_STORAGE_JOINED = "##";

const ACTIVITY_GROUP_NAMES = {
    therapistStats: "מטפלים",
    metupalStats: "מטופלים",
    [ACTIVITY_DAILY]: "יומי",
    [ACTIVITY_WEEKLY]: "שבועי",
    [ACTIVITY_MONTHLY]: "חודשי",
    [ACTIVITY_GLOBAL]: "הכל",
};

let storageStats = getStorageJSON(STARRED_STATS_KEY);

export default function AdminStats() {
    const [counts, setCounts] = useState<ADMIN_COUNTS | null>(null);
    const groupActivities = useGroupActivities();

    useEffect(() => {
        const getCountsCallback = getAdminCountsFunction();
        getCountsCallback().then((data) => setCounts(data.data));
    }, []);

    return !counts || !groupActivities ? (
        <AppSpinner />
    ) : (
        <Stack gap={1}>
            <OutputRow title="משתמשים" value={counts.registered} Icon={AccountCircleOutlined} />
            <StarredStats title={`שמורים`} counts={counts} groupActivities={groupActivities} />
            <AdminStatsGroup
                title={`מספרי מטפלים (${counts.therapists})`}
                groupStats={counts.therapistStats}
                path={["counts", "therapistStats"]}
            />
            <AdminStatsGroup
                title={`מספרי מטופלים (${counts.metupalim})`}
                groupStats={counts.metupalStats}
                path={["counts", "metupalStats"]}
            />
            <AdminStatsGroup
                title="מספרים כללים (יומי)"
                groupStats={groupActivities[ACTIVITY_DAILY]}
                path={["activities", ACTIVITY_DAILY]}
            />
            <AdminStatsGroup
                title="מספרים כללים (שבועי)"
                groupStats={groupActivities[ACTIVITY_WEEKLY]}
                path={["activities", ACTIVITY_WEEKLY]}
            />
            <AdminStatsGroup
                title="מספרים כללים (חודשי)"
                groupStats={groupActivities[ACTIVITY_MONTHLY]}
                path={["activities", ACTIVITY_MONTHLY]}
            />
            <AdminStatsGroup
                title="מספרים כללים (הכל)"
                groupStats={groupActivities[ACTIVITY_GLOBAL]}
                path={["activities", ACTIVITY_GLOBAL]}
            />
        </Stack>
    );
}

function AdminStatsGroup({
    groupStats,
    title,
    path = [],
}: {
    groupStats: ADMIN_GROUP_STATS;
    title: string;
    path: string[];
}) {
    return (
        <Accordion>
            <AccordionSummary expandIcon={<ExpandMoreOutlined />}>
                <Typography>{title}</Typography>
            </AccordionSummary>
            <AccordionDetails>
                <Stack gap={1}>
                    {keys(groupStats).map((statTitle) =>
                        isNumber(groupStats[statTitle]) ? (
                            <StatRow
                                key={statTitle}
                                statTitle={statTitle}
                                value={groupStats[statTitle] as number}
                                path={[...path, statTitle]}
                            />
                        ) : (
                            <StatMap
                                key={statTitle}
                                statTitle={statTitle}
                                value={groupStats[statTitle] as {}}
                                path={[...path, statTitle]}
                            />
                        )
                    )}
                </Stack>
            </AccordionDetails>
        </Accordion>
    );
}

function StatMap({ statTitle, value, path }: { statTitle: string | ReactNode; value: {}; path: string[] }) {
    const objectKeys = Object.keys(value);
    const [expanded, setExpanded] = useState(objectKeys.length <= 10 ? true : false);
    const { texts = {} } = useAppContext();

    return (
        <Stack>
            <Stack flexDirection="row" alignItems="center">
                <IconButton
                    size="small"
                    color="primary"
                    onClick={() => setExpanded((expanded) => !expanded)}
                    sx={{ p: 0 }}>
                    <ExpandMoreOutlined sx={{ transform: expanded ? "rotate(180deg)" : undefined }} />
                </IconButton>
                <Typography>
                    {statTitle}
                    {expanded ? ": " : " (מוסתר)"}
                </Typography>
            </Stack>
            {expanded &&
                sortBy(objectKeys, (statKey) => -value[statKey]).map((statKey) => (
                    <StatRow
                        key={statKey}
                        statTitle={texts[statKey] ? <T children={statKey} /> : statKey}
                        value={value[statKey]}
                        path={[...path, statKey]}
                    />
                ))}
        </Stack>
    );
}

function StatRow({
    statTitle,
    path,
    value,
}: {
    statTitle: string | ReactNode;
    path: string[];
    value: number | string;
}) {
    const statPath = useMemo(() => path.join(STAT_STORAGE_JOINED), [path]);
    const [starred, setStarred] = useState<boolean>(!!storageStats[statPath]);
    const toggleStar = useCallback(() => {
        setStarred((starred) => {
            const newStarred = !starred;
            if (newStarred) {
                storageStats[statPath] = true;
            } else {
                delete storageStats[statPath];
            }
            // for rerenders and such
            storageStats = { ...storageStats };
            saveStorageJSON(STARRED_STATS_KEY, storageStats);
            return newStarred;
        });
    }, [statPath]);

    return (
        <Stack flexDirection="row" alignItems="center" gap={0.5}>
            <Tooltip title={starred ? "הסר מ'שמורים' למעלה" : "הצג את זה ב'שמורים' למעלה"} placement="right">
                <IconButton onClick={toggleStar} size="small" sx={{ p: 0 }}>
                    {starred ? <StarOutlined /> : <StarOutlineOutlined />}
                </IconButton>
            </Tooltip>
            <Box width="100%">
                <OutputRow title={statTitle} value={value} short />
            </Box>
        </Stack>
    );
}

function StarredStats({
    title,
    counts,
    groupActivities,
}: {
    title: string;
    counts: ADMIN_COUNTS;
    groupActivities: {};
}) {
    const [localStats, setLocalStats] = useState(storageStats);
    useEffect(() => {
        // we are updating storage outside react so just rerender every few seconds. It's OK, it's only an admin page.
        const timerId = setInterval(() => setLocalStats(storageStats), 1000);
        return () => clearInterval(timerId);
    }, []);
    return (
        <Accordion defaultExpanded>
            <AccordionSummary expandIcon={<ExpandMoreOutlined />}>
                <Typography>{title}</Typography>
            </AccordionSummary>
            <AccordionDetails>
                <Stack gap={0.5}>
                    {isEmpty(localStats) ? (
                        <Typography>אין כרגע שמורים. לחץ על הכוכב ליד השורות כדי לשמור שורות מועדפות.</Typography>
                    ) : (
                        keys(localStats).map((statPath) => (
                            <StarredStat
                                key={statPath}
                                statPath={statPath}
                                counts={counts}
                                groupActivities={groupActivities}
                            />
                        ))
                    )}
                </Stack>
            </AccordionDetails>
        </Accordion>
    );
}

function StarredStat({
    statPath,
    counts,
    groupActivities,
}: {
    statPath: string;
    counts: ADMIN_COUNTS;
    groupActivities: {};
}) {
    const { texts } = useAppContext();
    const path = useMemo(() => statPath.split(STAT_STORAGE_JOINED), [statPath]);
    const [root, actualPath] = useMemo(() => {
        const [root, ...actualPath] = path;
        return [root, actualPath];
    }, [path]);
    const rootObject = root === "counts" ? counts : groupActivities;
    const value = get(rootObject, actualPath);

    const statTitle = useMemo(
        () =>
            texts && texts[path[path.length - 1]]
                ? getT({ textKey: path[path.length - 1], texts }).join("")
                : path[path.length - 1],
        [path, texts]
    );

    const statTooltip = useMemo(() => {
        const [activityGroupName, ...restPath] = actualPath;
        // update the title
        restPath[restPath.length - 1] = statTitle;
        return [ACTIVITY_GROUP_NAMES[activityGroupName] || activityGroupName, ...restPath].join(" -> ");
    }, [actualPath, statTitle]);

    return (
        <Tooltip title={statTooltip} placement="top-start">
            <Box>
                <StatRow statTitle={statTitle} value={value} path={path} />
            </Box>
        </Tooltip>
    );
}
