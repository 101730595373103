import { User } from "firebase/auth";
import { collection, deleteDoc, doc, serverTimestamp, setDoc, updateDoc } from "firebase/firestore";
import { isEmpty } from "lodash";

import { getFirebaseFirestoreDb } from "../firebase/firebase";
import { deleteSearch, updateSearch } from "../search/searchUpdates";
import {
    METUPAL_USER_INFO,
    METUPAL_USER_INFO_KEYS,
    SEARCH_USER_INFO,
    SEARCH_USER_INFO_KEYS,
    THERAPIST_USER_INFO,
    THERAPIST_USER_INFO_KEYS,
    USER_INFO,
} from "./userInfo";

export function getUserRef(userUid: string) {
    return doc(getFirebaseFirestoreDb(), "users", userUid);
}
export function getUserCollection() {
    return collection(getFirebaseFirestoreDb(), "users");
}
export function getTherapistUserRef(userUid: string) {
    return doc(getFirebaseFirestoreDb(), "therapists", userUid);
}
export function getTherapistCollection() {
    return collection(getFirebaseFirestoreDb(), "therapists");
}
export function getMetupalUserRef(userUid: string) {
    return doc(getFirebaseFirestoreDb(), "metupalim", userUid);
}
export function getMetupalCollection() {
    return collection(getFirebaseFirestoreDb(), "metupalim");
}

function getDataForTherapistUser(updates: USER_INFO): THERAPIST_USER_INFO {
    let therapistUpdates: THERAPIST_USER_INFO = {};
    for (let key in updates) {
        if (THERAPIST_USER_INFO_KEYS[key]) {
            therapistUpdates[key] = updates[key];
        }
    }
    return therapistUpdates;
}

function getDataForMetupalUser(updates: USER_INFO): METUPAL_USER_INFO {
    let metupalUpdates: METUPAL_USER_INFO = {};
    for (let key in updates) {
        if (METUPAL_USER_INFO_KEYS[key]) {
            metupalUpdates[key] = updates[key];
        }
    }
    return metupalUpdates;
}

function getDataForSearchUser(updates: USER_INFO): SEARCH_USER_INFO {
    let searchUpdates: SEARCH_USER_INFO = {};
    for (let key in updates) {
        if (SEARCH_USER_INFO_KEYS[key]) {
            searchUpdates[key] = updates[key];
        }
    }
    return searchUpdates;
}

export function createUserInfo(user: User, additionalUpdates: USER_INFO = {}) {
    const data: USER_INFO = {
        ...additionalUpdates,
        created: serverTimestamp(),
        uid: user.uid,
        ban: false,
        unsub: false,
    };
    if (user.email) {
        data.email = user.email;
    }
    const promises = [setDoc(getUserRef(user.uid), data)];
    if (data.accountHasTherapist) {
        // this shouldn't happen - therapists are supposed to register before they onboard
        const therapistData = getDataForTherapistUser(data);
        promises.push(setDoc(getTherapistUserRef(user.uid), therapistData));
    }
    if (data.accountHasMetupal) {
        // this might happen if user onboarded and then registered
        const metupalData = getDataForMetupalUser(data);
        promises.push(setDoc(getMetupalUserRef(user.uid), metupalData));
        // it's after onboard so the user finished search.
        promises.push(updateSearch(user.uid));
    }
    return Promise.all(promises);
}

export function updateUserInfo(uid: string, existingUserInfo: USER_INFO | null | undefined, updates: USER_INFO) {
    const promises: Promise<any>[] = [updateDoc(getUserRef(uid), updates)];

    const getTherapistUpdatePromise = () => {
        const therapistUpdates = getDataForTherapistUser(updates);
        if (!isEmpty(therapistUpdates)) {
            return updateDoc(getTherapistUserRef(uid), therapistUpdates);
        }
        return Promise.resolve();
    };

    const getTherapistCreatePromse = () => {
        const therapistData = getDataForTherapistUser({ ...existingUserInfo, ...updates });
        return setDoc(getTherapistUserRef(uid), therapistData);
    };

    if (existingUserInfo?.accountHasTherapist) {
        promises.push(
            getTherapistUpdatePromise().catch(() => getTherapistCreatePromse().then(() => getTherapistUpdatePromise()))
        );
    } else if (updates.accountHasTherapist) {
        // create account therapist - therapists create their account here
        promises.push(getTherapistCreatePromse());
    }

    const getMetupalUpdatePromise = () => {
        const metupalUpdates = getDataForMetupalUser(updates);
        if (!isEmpty(metupalUpdates)) {
            return updateDoc(getMetupalUserRef(uid), metupalUpdates);
        }
        return Promise.resolve();
    };

    const getMetupalCreatePromse = () => {
        const mergedUserInfo: USER_INFO = { ...existingUserInfo, ...updates };
        const metupalData = getDataForMetupalUser(mergedUserInfo);
        return setDoc(getMetupalUserRef(uid), metupalData);
    };

    if (existingUserInfo?.accountHasMetupal) {
        promises.push(
            getMetupalUpdatePromise().catch(() => getMetupalCreatePromse().then(() => getMetupalUpdatePromise()))
        );
        // update search
        const searchUpdates = getDataForSearchUser(updates);
        if (searchUpdates.enableSearch === false) {
            promises.push(deleteSearch());
        } else if (!isEmpty(searchUpdates)) {
            promises.push(updateSearch(uid));
        }
    } else if (updates.accountHasMetupal) {
        // create account metupal - metupalim sometimes create account through here
        promises.push(getMetupalCreatePromse());

        // it's after onboard so the user finished search.
        promises.push(updateSearch(uid));
    }

    return Promise.all(promises);
}

export function deleteUserInfo(user: User) {
    return deleteDoc(getUserRef(user.uid));
}
