import { Box, Button, Slider, Stack } from "@mui/material";
import { GeoPoint } from "firebase/firestore";
import { Dispatch, SetStateAction, useCallback, useMemo, useState } from "react";

import T from "../locallization/T";
import { RTL_LOCALES } from "../locallization/useLocale";
import { useAppContext } from "../root/AppContext";
import GoogleRadiusMap from "./GoogleRadiusMap";

const MAX_DISTANCE_KM = 50;

export default function InputLocationDistance({
    rangeValue,
    setRangeValue,
    geo,
    initialMapOpen = false,
}: {
    rangeValue: number;
    setRangeValue: Dispatch<SetStateAction<number>> | ((value: number) => void);
    geo?: GeoPoint | null;
    initialMapOpen?: boolean;
}) {
    const { locale } = useAppContext();
    const isRtl = RTL_LOCALES.has(locale || "");

    const [mapOpen, setMapOpen] = useState(initialMapOpen);
    const handleChange = useCallback(
        (event: Event, newValue: number | number[]) => {
            setRangeValue(isRtl ? MAX_DISTANCE_KM - (newValue as number) : (newValue as number));
        },
        [setRangeValue, isRtl]
    );

    const marks = useMemo(
        () => [
            {
                value: 0,
                label: isRtl ? "" + MAX_DISTANCE_KM : "1",
            },
            { value: 5 },
            { value: 10 },
            { value: 15 },
            { value: 20 },
            { value: 25 },
            { value: 30 },
            { value: 35 },
            { value: 40 },
            { value: 45 },
            {
                value: MAX_DISTANCE_KM - 1,
                label: isRtl ? "1" : "" + MAX_DISTANCE_KM,
            },
        ],
        [isRtl]
    );

    return (
        <Stack gap={1}>
            <Box sx={{ mx: 2 }}>
                <Slider
                    value={isRtl ? MAX_DISTANCE_KM - rangeValue : rangeValue}
                    onChange={handleChange}
                    marks={marks}
                    min={marks[0].value}
                    max={marks[marks.length - 1].value}
                    valueLabelDisplay="on"
                    valueLabelFormat={(x) => (
                        <T params={{ distance: isRtl ? MAX_DISTANCE_KM - x : x }}>onboarding question location km</T>
                    )}
                    track={isRtl ? "inverted" : "normal"}
                />
            </Box>
            {geo &&
                (mapOpen ? (
                    <GoogleRadiusMap geo={geo} rangeValue={rangeValue} />
                ) : (
                    <Button onClick={() => setMapOpen(true)} variant="text" sx={{ alignSelf: "baseline" }}>
                        <T>search input location map button</T>
                    </Button>
                ))}
        </Stack>
    );
}
