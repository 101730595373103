import { httpsCallable } from "firebase/functions";
import { useSnackbar } from "notistack";
import { Dispatch, SetStateAction, useCallback } from "react";

import { getFirebaseFunctions } from "../firebase/firebase";
import T from "../locallization/T";
import { useResetUnreadCount } from "../notifications/useResetUnreadCount";

export function useDeclineMetupal({
    metupalUid,
    setLoading,
    onDecline,
}: {
    metupalUid: string;
    setLoading: Dispatch<SetStateAction<boolean>>;
    onDecline?: () => void;
}) {
    const { enqueueSnackbar } = useSnackbar();

    const resetUnreadCount = useResetUnreadCount({
        userInfoKey: "matchesCounts",
        targetUid: metupalUid,
    });

    const declineMetupal = useCallback(
        (event) => {
            event.stopPropagation();
            setLoading(true);

            resetUnreadCount();
            const rejectMetupalCallback = httpsCallable<{ metupalUid: string }, {}>(
                getFirebaseFunctions(),
                "onCallRejectMetupal"
            );

            rejectMetupalCallback({ metupalUid })
                .then(() => {
                    setLoading(false);
                    enqueueSnackbar(<T>match metupal decline success</T>, {
                        variant: "success",
                    });
                    onDecline && onDecline();
                })
                .catch((error) => {
                    setLoading(false);
                    console.error(error);
                    enqueueSnackbar(<T params={{ code: 159 }}>general error</T>, { variant: "warning" });
                });
        },
        [enqueueSnackbar, metupalUid, resetUnreadCount, setLoading, onDecline]
    );
    return { declineMetupal };
}
