import { ArrowBack } from "@mui/icons-material";
import { Button, Card, CardContent, CircularProgress, Link, Stack, Typography } from "@mui/material";
import { User } from "firebase/auth";
import { isEmpty } from "lodash";
import { useCallback, useState } from "react";

import T from "../locallization/T";
import { ROUTES } from "../navigation/Router";
import { useAppContext } from "../root/AppContext";
import { useSearchFormValidation } from "../search/basic/useSearchFormValidation";
import SearchInputCost from "../search/inputs/SearchInputCost";
import SearchInputLocationDistance from "../search/inputs/searchInputLocationDistance";
import SearchInputLocationGeo from "../search/inputs/SearchInputLocationGeo";
import SearchInputLocationSelector from "../search/inputs/SearchInputLocationSelector";
import { useSaveSearch } from "../search/useSaveSearch";
import { getSpacedButtonIconProps } from "../theme/buttons";
import WithThemeIcon from "../theme/WithThemeIcon";
import { updateUserInfo } from "../user/userUpdates";

export default function SearchShortened() {
    const { user, userInfo } = useAppContext();

    const { saveData, loading, onData, updatedData, newUserInfo } = useSaveSearch();
    const [hasLocation, setHasLocation] = useState(!!userInfo?.locationGeo);
    const errorMessage = useSearchFormValidation({ newUserInfo });

    const onNavigateToSearch = useCallback(() => {
        updateUserInfo((user as User).uid, userInfo, { noMatchesIndicator: false });
    }, [user, userInfo]);

    return (
        <Card sx={{ borderRadius: 4 }}>
            <CardContent>
                <Stack gap={3}>
                    <SearchInputLocationSelector
                        newUserInfo={newUserInfo}
                        onData={onData}
                        setHasLocation={setHasLocation}
                        expanded
                    />
                    {hasLocation && (
                        <>
                            <SearchInputLocationGeo newUserInfo={newUserInfo} onData={onData} userInfo={userInfo} />
                            <SearchInputLocationDistance newUserInfo={newUserInfo} onData={onData} />
                        </>
                    )}
                    <SearchInputCost newUserInfo={newUserInfo} onData={onData} />

                    <Stack gap={1} mt={2}>
                        {errorMessage && <Typography color="error">{errorMessage}</Typography>}

                        <Button
                            disabled={isEmpty(updatedData) || loading || !!errorMessage}
                            variant="contained"
                            size="huge"
                            onClick={saveData}
                            {...getSpacedButtonIconProps()}
                            endIcon={loading ? <CircularProgress size={24} /> : <WithThemeIcon Icon={ArrowBack} />}>
                            <T>search update button</T>
                        </Button>
                    </Stack>

                    <Link onClick={onNavigateToSearch} href={ROUTES.SEARCH} sx={{ alignSelf: "center", mb: 1 }}>
                        <Typography>
                            <T>search update all button</T>
                        </Typography>
                    </Link>
                </Stack>
            </CardContent>
        </Card>
    );
}
