import { useMemo } from "react";

import { USER_INFO } from "../../user/userInfo";
import FacebookLogoPng from "../png/facebookLogo.png";
import InstagramLogoPng from "../png/instagramLogo.png";
import SiteLogoPng from "../png/siteLogo.png";
import YoutubeLogoPng from "../png/youtubeLogo.png";

export function useSocials({ newUserInfo }: { newUserInfo: USER_INFO }) {
    return useMemo(
        () =>
            [
                { textKey: "social facebook", Image: FacebookLogoPng, size: 36, value: newUserInfo?.socialFacebook },
                { textKey: "social instagram", Image: InstagramLogoPng, size: 32, value: newUserInfo?.socialInstagram },
                { textKey: "social site", Image: SiteLogoPng, size: 40, value: newUserInfo?.socialSite },
                { textKey: "social youtube", Image: YoutubeLogoPng, size: 45, value: newUserInfo?.socialYoutube },
            ].filter((social) => !!social.value),
        [newUserInfo?.socialFacebook, newUserInfo?.socialInstagram, newUserInfo?.socialSite, newUserInfo?.socialYoutube]
    );
}
