import { Button, Stack } from '@mui/material';
import { times } from 'lodash';
import { ReactNode, useCallback, useMemo, useState } from 'react';

import { SEARCH_INPUT_BASIC_PROPS } from './SearchInputBasicProps';
import SearchInputBasicText from './SearchInputBasicText';

export default function SearchInputBasicTextList({
    onData,
    newUserInfo,
    userInfoKey,
    minListItems,
    maxListItems,
    showMoreLabel,
    ...props
}: SEARCH_INPUT_BASIC_PROPS & { minListItems: number; maxListItems: number; showMoreLabel: ReactNode }) {
    const initialList = useMemo(() => {
        const items = [...((newUserInfo[userInfoKey] as string[]) || [])];
        while (items.length < minListItems) {
            items.push("");
        }
        return items;
    }, [minListItems, newUserInfo, userInfoKey]);
    const [listValue, setListValue] = useState(initialList);

    const showMoreInputs = useCallback(() => {
        setListValue((listValue) => [...listValue, ""]);
    }, []);

    const updateListValue = useCallback(
        (index: number, value: string) => {
            const newList = [...listValue];
            newList[index] = value;
            setListValue(newList);

            onData({ [userInfoKey]: newList.filter((item) => item) });
        },
        [listValue, onData, userInfoKey]
    );

    return (
        <Stack gap={2}>
            {times(listValue.length, (index) => (
                <SearchInputBasicText
                    key={index}
                    newUserInfo={{ [userInfoKey]: index < listValue.length ? listValue[index] : "" }}
                    onData={(newData) => updateListValue(index, newData[userInfoKey] as string)}
                    userInfoKey={userInfoKey}
                    {...props}
                />
            ))}
            {listValue.length < maxListItems && (
                <Button onClick={showMoreInputs} variant="text" sx={{ alignSelf: "baseline", mt: -1 }}>
                    {showMoreLabel}
                </Button>
            )}
        </Stack>
    );
}
