import {
    ArrowCircleLeftOutlined,
    ArrowForward,
    CalendarMonthOutlined,
    CancelOutlined,
    ChatOutlined,
    ClearOutlined,
    DeleteForeverOutlined,
} from "@mui/icons-material";
import {
    Button,
    CircularProgress,
    IconButton,
    ListItemIcon,
    ListItemText,
    MenuItem,
    Stack,
    TextField,
    Typography,
} from "@mui/material";
import { httpsCallable } from "firebase/functions";
import { useSnackbar } from "notistack";
import { lazy, Suspense, useCallback, useState } from "react";

import { createChat } from "../chat/chatUpdates";
import { useNavigateToChat } from "../chat/useNavigateToChat";
import ActionButtons, { ActionButtonPrimary, ActionButtonSecondary } from "../common/ActionButtons";
import AppDialog from "../common/dialog/AppDialog";
import MoreButton from "../common/MoreButton";
import { getFirebaseFunctions } from "../firebase/firebase";
import T from "../locallization/T";
import { useResetUnreadCount } from "../notifications/useResetUnreadCount";
import TherapistOutputSpecialty from "../outputs/therapist/TherapistOutputSpecialty";
import TherapistSummaryCost from "../outputs/therapist/TherapistSummaryCost";
import TherapistSummaryShortOutput from "../outputs/therapist/TherapistSummaryShortOutput";
import { useAppContext } from "../root/AppContext";
import { boldStyles } from "../theme/theme";
import WithThemeIcon from "../theme/WithThemeIcon";
import TherapistPageView from "../therapist_page/TherapistPageView";
import { THERAPIST_USER_INFO } from "../user/userInfo";
import DeletedStateCard from "./DeletedStateCard";
import MatchCard from "./MatchCard";

const LazyAdminMatchUsersDialog = lazy(() => import("../secret/users/AdminMatchUsersDialog"));

export default function TherapistMatchCard({
    therapistUserInfo,
    therapistUid,
}: {
    therapistUserInfo?: THERAPIST_USER_INFO;
    therapistUid: string;
}) {
    const { user, userInfo } = useAppContext();
    const [showConsultMatchDialog, setShowConsultMatchDialog] = useState(false);
    const [loading, setLoading] = useState(false);
    const [showDialog, setShowDialog] = useState(false);
    const [showRejectDialog, setShowRejectDialog] = useState(false);
    const [rejectReason, setRejectReason] = useState("");
    const resetUnreadCount = useResetUnreadCount({
        userInfoKey: "matchesCounts",
        targetUid: therapistUid,
    });

    const { enqueueSnackbar } = useSnackbar();
    const alreadyHasChat = (userInfo?.chats || {})[therapistUid];
    const checkMet = (userInfo?.checkMetupalMet || {})[therapistUid];

    const setTrueShowDialog = useCallback(() => {
        resetUnreadCount();
        setShowDialog(true);
    }, [resetUnreadCount]);
    const setFalseShowDialog = useCallback(() => setShowDialog(false), []);

    const setTrueShowRejectDialog = useCallback(() => setShowRejectDialog(true), []);
    const setFalseShowRejectDialog = useCallback(() => setShowRejectDialog(false), []);

    const updateRejectReason = useCallback((event) => setRejectReason(event.target.value), []);

    const navigateToChat = useNavigateToChat(therapistUid, { isTherapist: true });

    const acceptTherapist = useCallback(() => {
        if (!user || !userInfo || !therapistUserInfo) {
            return Promise.reject();
        }

        resetUnreadCount();
        if (userInfo.consult) {
            setShowConsultMatchDialog(true);
            return;
        }
        if (alreadyHasChat) {
            return navigateToChat();
        }
        setLoading(true);

        createChat(userInfo, therapistUserInfo)
            .then(() => {
                navigateToChat();
            })
            .catch((error) => {
                setLoading(false);
                console.error(error);
                enqueueSnackbar(<T params={{ code: 160 }}>general error</T>, { variant: "warning" });
            });
    }, [user, userInfo, enqueueSnackbar, therapistUserInfo, navigateToChat, resetUnreadCount, alreadyHasChat]);

    const rejectTherapist = useCallback(() => {
        if (!user || !userInfo) {
            return Promise.reject();
        }

        resetUnreadCount();
        setLoading(true);

        const rejectTherapistCallback = httpsCallable<{ therapistUid: string; rejectReason: string }, {}>(
            getFirebaseFunctions(),
            "onCallRejectTherapist"
        );

        rejectTherapistCallback({ therapistUid, rejectReason })
            .then(() => {
                // the match should disappear now
                setLoading(false);
                enqueueSnackbar(therapistUserInfo ? <T>match decline success</T> : <T>match delete success</T>, {
                    variant: "success",
                });
            })
            .catch((error) => {
                setLoading(false);
                console.error(error);
                enqueueSnackbar(<T params={{ code: 161 }}>general error</T>, { variant: "warning" });
            });
    }, [user, userInfo, therapistUid, enqueueSnackbar, resetUnreadCount, rejectReason, therapistUserInfo]);

    const updateMetStatus = useCallback(
        (met: boolean) => {
            if (!user || !userInfo) {
                return Promise.reject();
            }

            resetUnreadCount();
            setLoading(true);

            const setMetupalMeetingCallback = httpsCallable<{ therapistUid: string; met: boolean }, {}>(
                getFirebaseFunctions(),
                "onCallSetMetupalMeeting"
            );

            setMetupalMeetingCallback({ therapistUid, met })
                .then(() => {
                    // the match should disappear now
                    setLoading(false);
                    enqueueSnackbar(met ? <T>match meet success</T> : <T>match not meet success</T>, {
                        variant: "success",
                    });
                })
                .catch((error) => {
                    setLoading(false);
                    console.error(error);
                    enqueueSnackbar(<T params={{ code: 192 }}>general error</T>, { variant: "warning" });
                });
        },
        [user, userInfo, therapistUid, enqueueSnackbar, resetUnreadCount]
    );

    const setTrueMetStatus = useCallback(() => {
        updateMetStatus(true);
    }, [updateMetStatus]);

    const setFalseMetStatus = useCallback(
        (event) => {
            event.stopPropagation();
            updateMetStatus(false);
        },
        [updateMetStatus]
    );

    if (!therapistUserInfo) {
        return <DeletedStateCard matchIsTherapist={true} onHide={rejectTherapist} loading={loading} />;
    }

    return (
        <>
            <MatchCard
                basicUserInfo={therapistUserInfo}
                subheader={<TherapistOutputSpecialty newUserInfo={therapistUserInfo} />}
                onClick={checkMet ? setTrueMetStatus : acceptTherapist}
                actionSide={<TherapistSummaryCost newUserInfo={therapistUserInfo} />}
                action={
                    <Button
                        sx={{ ml: "auto" }}
                        startIcon={
                            loading ? (
                                <CircularProgress size={24} />
                            ) : checkMet ? (
                                <CalendarMonthOutlined />
                            ) : (
                                <ChatOutlined />
                            )
                        }
                        onClick={checkMet ? setTrueMetStatus : acceptTherapist}
                        disabled={loading}
                        variant="contained"
                        size="large">
                        {userInfo?.consult ? (
                            "התאם למטופל"
                        ) : checkMet ? (
                            <T>match button check met</T>
                        ) : (
                            <T>match button</T>
                        )}
                    </Button>
                }
                HeaderProps={{
                    onClick: setTrueShowDialog,
                    action: (
                        <Stack flexDirection="row">
                            <IconButton color="primary">
                                <WithThemeIcon Icon={ArrowCircleLeftOutlined} />
                            </IconButton>
                            {checkMet && (
                                <MoreButton sx={{ alignSelf: "baseline" }}>
                                    <MenuItem onClick={setFalseMetStatus} disabled={loading}>
                                        <ListItemIcon>
                                            {loading ? (
                                                <CircularProgress size={20} />
                                            ) : (
                                                <ClearOutlined fontSize="small" color="primary" />
                                            )}
                                        </ListItemIcon>
                                        <ListItemText>
                                            <T>therapist dialog button cancelled</T>
                                        </ListItemText>
                                    </MenuItem>
                                </MoreButton>
                            )}
                        </Stack>
                    ),
                    sx: {
                        cursor: "pointer",
                        ".MuiCardHeader-action": {
                            alignSelf: "center",
                        },
                    },
                }}>
                <TherapistSummaryShortOutput newUserInfo={therapistUserInfo} />
            </MatchCard>
            <AppDialog
                open={showDialog}
                setOpen={setShowDialog}
                fullScreen
                disableGutters
                elevateActions
                actions={
                    <>
                        <ActionButtonPrimary
                            onClick={checkMet ? setTrueMetStatus : acceptTherapist}
                            disabled={loading}
                            startIcon={
                                loading ? (
                                    <CircularProgress size={24} />
                                ) : checkMet ? (
                                    <CalendarMonthOutlined />
                                ) : (
                                    <ChatOutlined />
                                )
                            }>
                            {userInfo?.consult ? (
                                "התאם למטופל"
                            ) : checkMet ? (
                                <T>match button check met</T>
                            ) : (
                                <T>therapist dialog button ok</T>
                            )}
                        </ActionButtonPrimary>
                    </>
                }>
                <TherapistPageView therapistUserInfo={therapistUserInfo} onBack={setFalseShowDialog} />
                <ActionButtons mt={2} justifyContent="center">
                    <Button
                        disabled={loading}
                        variant="text"
                        onClick={checkMet ? setFalseMetStatus : setTrueShowRejectDialog}
                        startIcon={<CancelOutlined />}>
                        {checkMet ? <T>therapist dialog button cancelled</T> : <T>therapist dialog button no</T>}
                    </Button>
                </ActionButtons>
            </AppDialog>
            <AppDialog
                open={showRejectDialog}
                setOpen={setShowRejectDialog}
                actions={
                    <>
                        <ActionButtonSecondary
                            onClick={setFalseShowRejectDialog}
                            disabled={loading}
                            startIcon={<WithThemeIcon Icon={ArrowForward} />}>
                            <T>therapist reject dialog button no</T>
                        </ActionButtonSecondary>
                        <ActionButtonPrimary
                            onClick={rejectTherapist}
                            disabled={loading || !rejectReason}
                            color="error"
                            startIcon={loading ? <CircularProgress size={24} /> : <DeleteForeverOutlined />}>
                            <T>therapist reject dialog button ok</T>
                        </ActionButtonPrimary>
                    </>
                }>
                <Stack gap={1}>
                    <Typography variant="h3" sx={{ ...boldStyles }}>
                        <T>therapist reject dialog title</T>
                    </Typography>
                    <Typography variant="h4">
                        <T>therapist reject dialog subtitle</T>
                    </Typography>
                    <TextField
                        sx={{ mt: 3 }}
                        label={<T>therapist reject dialog input</T>}
                        value={rejectReason}
                        onChange={updateRejectReason}
                        inputProps={{ maxLength: 250 }}
                        fullWidth
                        minRows={2}
                        multiline
                        autoFocus
                    />
                </Stack>
            </AppDialog>
            {showConsultMatchDialog && (
                <Suspense>
                    <LazyAdminMatchUsersDialog
                        open={showConsultMatchDialog}
                        setOpen={setShowConsultMatchDialog}
                        therapistUserInfo={therapistUserInfo}
                    />
                </Suspense>
            )}
        </>
    );
}
