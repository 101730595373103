import { Stack } from "@mui/material";

import PageTitle from "../common/PageTitle";
import T from "../locallization/T";
import PageContainer from "../navigation/PageContainer";
import { ROUTES } from "../navigation/Router";
import ChatsList from "./ChatsList";

export default function Chat() {
    return (
        <PageContainer pageTitleKey="chat title" pagePath={ROUTES.CHAT} hideTopNav backgrounded>
            <Stack gap={2}>
                <PageTitle gap={2}>
                    <T>chat title</T>
                </PageTitle>
                <ChatsList />
            </Stack>
        </PageContainer>
    );
}
