import { AddOutlined, CancelOutlined, ExpandMoreOutlined, SaveOutlined } from "@mui/icons-material";
import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Box,
    Button,
    CircularProgress,
    Stack,
    TextField,
} from "@mui/material";
import { fromPairs, isEmpty, toPairs } from "lodash";
import { useSnackbar } from "notistack";
import { FormEvent, useCallback, useMemo, useRef, useState } from "react";

import { ActionButtonPrimary, ActionButtonSecondary } from "../common/ActionButtons";
import AppDialog from "../common/dialog/AppDialog";

export default function TextsEditor({
    texts,
    onSave,
    showAll,
    accordions,
    numbers,
}: {
    texts: {};
    onSave: (updates: {}) => Promise<void>;
    showAll?: boolean;
    accordions?: boolean;
    numbers?: boolean;
}) {
    const sortedPairs = useMemo(
        () => toPairs<string>(texts).sort((a, b) => a[0].localeCompare(b[0], "en", { numeric: true })),
        [texts]
    );
    const [updatedTexts, setUpdatedTexts] = useState<[string, string][]>(sortedPairs);
    const [saving, setSaving] = useState(false);
    const [showPluralDialog, setShowPluralDialog] = useState(false);
    const [pluralJson, setPluralJson] = useState({});
    const [search, setSearch] = useState("");
    const updateSearch = useCallback((event) => setSearch(event.target.value), []);
    const { enqueueSnackbar } = useSnackbar();
    const bottomContainerRef = useRef<HTMLDivElement>(null);

    const handleKeyChange = useCallback((event: FormEvent, index: number) => {
        setUpdatedTexts((prevUpdates) => {
            const updates = [...prevUpdates];
            (event.target as any).value = (event.target as any).value.trim();
            updates[index][0] = (event.target as any).value;
            return updates;
        });
    }, []);

    const handleValueChange = useCallback((event: FormEvent, index: number) => {
        setUpdatedTexts((prevUpdates) => {
            const updates = [...prevUpdates];
            updates[index][1] = (event.target as any).value;
            return updates;
        });
    }, []);

    const addRow = useCallback(() => {
        setUpdatedTexts((prevUpdates) => [...prevUpdates, ["", ""]]);
        if (bottomContainerRef.current) {
            bottomContainerRef.current.scrollIntoView({ behavior: "smooth" });
        }
    }, []);

    const togglePluralDialog = useCallback(() => {
        setPluralJson({});
        setShowPluralDialog((showPluralDialog) => !showPluralDialog);
    }, []);
    const updatePluralJson = useCallback(
        (event) => {
            try {
                setPluralJson(JSON.parse(event.target.value));
            } catch (error) {
                console.error({ error });
                enqueueSnackbar("Had an error parsing JSON: " + error, { variant: "warning" });
            }
        },
        [enqueueSnackbar]
    );
    const insertPluralJson = useCallback(
        (event) => {
            setUpdatedTexts((prevUpdates) => {
                const newUpdates = [...prevUpdates];
                for (let key in pluralJson) {
                    newUpdates.push([key, pluralJson[key]]);
                }

                return newUpdates;
            });
            togglePluralDialog();
        },
        [pluralJson, togglePluralDialog]
    );

    const save = useCallback(() => {
        const valuesToSave = fromPairs(updatedTexts);
        const updates = {};
        for (let key of Object.keys(valuesToSave)) {
            if (!key) {
                continue;
            }
            if (!texts.hasOwnProperty(key) || texts[key] !== valuesToSave[key]) {
                updates[key] = valuesToSave[key];
            }
            if (!valuesToSave[key]) {
                updates[key] = null;
            }
            if (!updates[key] && !texts.hasOwnProperty(key)) {
                delete updates[key];
            }
        }
        for (let key of Object.keys(texts)) {
            // key was changed, remove old key
            if (!valuesToSave.hasOwnProperty(key)) {
                updates[key] = null;
            }
        }

        if (isEmpty(updates)) {
            // show empty toast
            enqueueSnackbar("No changes detected", { variant: "info" });
        } else {
            setSaving(true);
            onSave(updates)
                .then(() => {
                    setSaving(false);
                    enqueueSnackbar("Data saved successfully", { variant: "success" });
                })
                .catch((error) => {
                    setSaving(false);
                    console.error({ error });
                    enqueueSnackbar("Had an error saving: " + error, { variant: "warning" });
                });
        }
    }, [texts, updatedTexts, onSave, enqueueSnackbar]);

    return (
        <Box component="form" noValidate autoComplete="off">
            {!showAll && (
                <TextField
                    value={search}
                    onChange={updateSearch}
                    color="primary"
                    fullWidth
                    autoFocus
                    label="Search"
                    size="small"
                    sx={{ mb: 2 }}
                />
            )}
            {useMemo(
                () =>
                    updatedTexts.map((pairs, index) => {
                        const hasNewKey = !texts.hasOwnProperty(pairs[0]);
                        const hasNewValue = texts[pairs[0]] !== pairs[1];
                        let showTextBySearch = false;
                        if (search.length >= 3 || pairs[1] === search) {
                            showTextBySearch = pairs[0].includes(search) || pairs[1].includes(search);
                            for (const suffix of ["F", "T", "P"]) {
                                // show female (or other suffixes) counterpart if it is after a matching male
                                if (index > 0 && pairs[0] === `${updatedTexts[index - 1][0]} ${suffix}`) {
                                    showTextBySearch = showTextBySearch || updatedTexts[index - 1][1].includes(search);
                                }
                                // show male counterpart if it is before a matching female (or other suffixes)
                                if (
                                    index < updatedTexts.length - 1 &&
                                    updatedTexts[index + 1][0] === `${pairs[0]} ${suffix}`
                                ) {
                                    showTextBySearch = showTextBySearch || updatedTexts[index + 1][1].includes(search);
                                }
                            }
                        }
                        if (!hasNewKey && !hasNewValue && !showTextBySearch && !showAll) {
                            return null;
                        }
                        const keyField = (
                            <TextField
                                color={hasNewKey ? "success" : "info"}
                                focused
                                fullWidth
                                label="Key"
                                variant="outlined"
                                defaultValue={pairs[0]}
                                onBlur={(event) => handleKeyChange(event, index)}
                                size="small"
                                onClick={(event) => event.stopPropagation()}
                            />
                        );
                        const valueField = (
                            <TextField
                                color={hasNewValue ? "success" : "info"}
                                focused
                                fullWidth={!numbers}
                                label="Value"
                                variant="outlined"
                                type={numbers ? "number" : "text"}
                                defaultValue={pairs[1]}
                                onBlur={(event) => handleValueChange(event, index)}
                                size="small"
                                multiline={!numbers}
                            />
                        );

                        return accordions ? (
                            <Accordion key={index} TransitionProps={{ unmountOnExit: true }}>
                                <AccordionSummary expandIcon={<ExpandMoreOutlined />}>{keyField}</AccordionSummary>
                                <AccordionDetails>{valueField}</AccordionDetails>
                            </Accordion>
                        ) : (
                            <Stack key={index} sx={{ mb: 2 }} gap={1} flexDirection={numbers ? "row" : "column"}>
                                {keyField}
                                {valueField}
                            </Stack>
                        );
                    }),
                [texts, updatedTexts, handleKeyChange, handleValueChange, search, showAll, accordions, numbers]
            )}
            <Box ref={bottomContainerRef} height="40px" />

            <Stack
                direction="row"
                sx={{ mt: 2, pointerEvents: "none" }}
                spacing={2}
                justifyContent="space-between"
                alignItems="flex-start"
                position="sticky"
                bottom={16}
                zIndex={1}>
                <Stack gap={1}>
                    <Button
                        sx={{ pointerEvents: "all" }}
                        variant="contained"
                        onClick={addRow}
                        startIcon={<AddOutlined />}>
                        טקסט חדש
                    </Button>
                    <Button
                        sx={{ pointerEvents: "all" }}
                        variant="contained"
                        onClick={togglePluralDialog}
                        startIcon={<AddOutlined />}>
                        טקסטים ברבים
                    </Button>
                </Stack>
                <Button
                    sx={{ pointerEvents: "all" }}
                    disabled={saving}
                    variant="contained"
                    onClick={save}
                    endIcon={saving ? <CircularProgress size={24} /> : <SaveOutlined />}>
                    שמירה
                </Button>
            </Stack>
            <AppDialog
                open={showPluralDialog}
                setOpen={setShowPluralDialog}
                title="טקסטים ברבים"
                actions={
                    <>
                        <ActionButtonSecondary onClick={togglePluralDialog} endIcon={<CancelOutlined />}>
                            ביטול
                        </ActionButtonSecondary>
                        <ActionButtonPrimary
                            onClick={insertPluralJson}
                            disabled={!pluralJson}
                            endIcon={<AddOutlined />}>
                            הוספה
                        </ActionButtonPrimary>
                    </>
                }>
                <TextField
                    margin="dense"
                    color={pluralJson ? "success" : "info"}
                    focused
                    fullWidth
                    label="ג'ייסון של הטקסטים"
                    variant="outlined"
                    minRows={3}
                    onBlur={updatePluralJson}
                    size="small"
                    multiline
                />
            </AppDialog>
        </Box>
    );
}
