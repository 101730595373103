import { Stack } from "@mui/material";

import { THERAPIST_OUTPUT_PROPS } from "../basic/OutputProps";
import TherapistOutputLicenseYear from "./TherapistOutputLicenseYear";
import TherapistOutputLocation from "./TherapistOutputLocation";
import TherapistOutputReasons from "./TherapistOutputReasons";
import TherapistOutputZoom from "./TherapistOutputZoom";

export default function TherapistSummaryShortOutput({ newUserInfo }: THERAPIST_OUTPUT_PROPS) {
    return (
        <Stack gap={1}>
            <TherapistOutputLicenseYear newUserInfo={newUserInfo} short />
            <TherapistOutputReasons newUserInfo={newUserInfo} short />
            <TherapistOutputLocation newUserInfo={newUserInfo} short />
            <TherapistOutputZoom newUserInfo={newUserInfo} short />
        </Stack>
    );
}
