export function saveStorage(key: string, value: string) {
    try {
        localStorage.setItem(key, value);
    } catch (error) {}
}
export function getStorage(key: string) {
    try {
        return localStorage.getItem(key) || "";
    } catch (error) {
        return "";
    }
}
export function deleteStorage(key: string) {
    localStorage.removeItem(key);
}

export function saveSessionStorage(key: string, value: string) {
    try {
        sessionStorage.setItem(key, value);
    } catch (error) {}
}
export function getSessionStorage(key: string) {
    try {
        return sessionStorage.getItem(key) || "";
    } catch (error) {
        return "";
    }
}
export function deleteSessionStorage(key: string) {
    sessionStorage.removeItem(key);
}

export function saveStorageJSON(key: string, value: {}) {
    try {
        localStorage.setItem(key, JSON.stringify(value));
    } catch (error) {}
}
export function getStorageJSON(key: string): {} {
    try {
        return JSON.parse(localStorage.getItem(key) || "{}");
    } catch (error) {
        return {};
    }
}
