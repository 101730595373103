import { HelpOutlineOutlined } from "@mui/icons-material";
import { Fade, Link, Stack, Typography } from "@mui/material";
import { Box } from "@mui/system";
import { lazy, Suspense } from "react";

import ActionButtons, { ActionButtonPrimary, ActionButtonSecondary } from "../../common/ActionButtons";
import T from "../../locallization/T";
import { LOCALE_EN } from "../../locallization/useLocale";
import AppSpinner from "../../navigation/AppSpinner";
import PageContainer from "../../navigation/PageContainer";
import { ROUTES } from "../../navigation/Router";
import { useAppContext } from "../../root/AppContext";
import { COLOR_APP_PRIMARY } from "../../theme/theme";
import AlreadyHasAccountLink from "../AlreadyHasAccountLink";
import LogoTitlePng from "../png/logoTitle.png";

const LazyLottieBunnySit = lazy(() => import("./LottieBunnySit"));

export default function LoggedOutHome() {
    const { locale } = useAppContext();

    return (
        <PageContainer pageTitleKey="" pagePath={ROUTES.HOME} hideTopNav hideBotNav>
            <Stack alignItems="center" justifyContent="center" height="100%">
                <Stack mt="auto" mb="auto" alignItems="center">
                    <Box mb={3} width={160} height={270}>
                        <Suspense fallback={<AppSpinner />}>
                            <Fade in>
                                <Box>
                                    <LazyLottieBunnySit />
                                </Box>
                            </Fade>
                        </Suspense>
                    </Box>
                    <Box height={45} width={171} component="img" src={LogoTitlePng} alt="Zigmond" />
                    <Typography variant="subtitle1">
                        <T>login subtitle</T>
                    </Typography>
                </Stack>

                <Stack alignItems="center" textAlign="center">
                    <ActionButtons sx={{ p: 0, pb: 2 }} gap={1}>
                        <ActionButtonSecondary href={ROUTES.REGISTER_THRAPIST}>
                            <T>login flow action give therapy</T>
                        </ActionButtonSecondary>
                        <ActionButtonPrimary href={ROUTES.ONBOARDING}>
                            <T>login flow action look therapy</T>
                        </ActionButtonPrimary>
                    </ActionButtons>
                    <AlreadyHasAccountLink />
                    <Link
                        my={3}
                        component="a"
                        href={locale === LOCALE_EN ? "https://www.zigmond.info/en/home-en" : "https://www.zigmond.info"}
                        target="_blank"
                        sx={{ color: COLOR_APP_PRIMARY }}>
                        <Stack flexDirection="row" gap={1}>
                            <HelpOutlineOutlined />
                            <Typography variant="h3">
                                <T>login flow how it works</T>
                            </Typography>
                        </Stack>
                    </Link>
                </Stack>
            </Stack>
        </PageContainer>
    );
}
