import { findIndex, map, sortBy } from "lodash";
import { Fragment, useMemo } from "react";

import { getTalkGender } from "../../common/gender/getTalkGender";
import SeparatedList from "../../common/SeparatedList";
import T from "../../locallization/T";
import {
    therapistQuestionIcons,
    therapistQuestionIconsColorful,
} from "../../onboarding/therapist/OnboardingQuestionsTherapist";
import { useAppContext } from "../../root/AppContext";
import { userInfoTimes } from "../../search/inputs/SearchInputTime";
import { THERAPIST_OUTPUT_PROPS } from "../basic/OutputProps";
import OutputRow from "../basic/OutputRow";

export default function TherapistOutputTimes({ newUserInfo, colorful, short, ...props }: THERAPIST_OUTPUT_PROPS) {
    const { userInfo } = useAppContext();

    const timesByDay = useMemo(() => {
        const daysToTimes: { [day: number]: { day: number; times: string[] } } = {};
        const therapistTimes = newUserInfo?.therapistTimes || {};
        for (let time in therapistTimes) {
            const days: number[] = therapistTimes[time];
            for (const day of days) {
                daysToTimes[day] = daysToTimes[day] || { day, times: [] };
                daysToTimes[day].times.push(time);
            }
        }
        return daysToTimes;
    }, [newUserInfo?.therapistTimes]);

    const sortedTimesByDay = useMemo(() => {
        const sortedDays = sortBy(timesByDay, ["day"]);
        for (let i = 0; i < sortedDays.length; i++) {
            const times = sortedDays[i].times;
            sortedDays[i].times = sortBy(times, [
                (item) => findIndex(userInfoTimes, (orderItem) => orderItem.value === item),
            ]);
        }

        return sortedDays;
    }, [timesByDay]);

    return (
        <OutputRow
            {...props}
            short={short}
            title={<T>therapist output times</T>}
            value={
                (newUserInfo?.therapistEnableSearch ||
                    (userInfo?.uid === newUserInfo?.uid && !userInfo?.timesSearched)) &&
                sortedTimesByDay.length > 0 ? (
                    <SeparatedList separator={short ? ", " : "\n"}>
                        {map(sortedTimesByDay, ({ day, times }) => (
                            <Fragment key={day}>
                                <T children={`day long ${day}`} />
                                {` (`}
                                {times.length === userInfoTimes.length ? (
                                    <T overrideGender={getTalkGender(newUserInfo)}>therapist output time all</T>
                                ) : (
                                    <SeparatedList>
                                        {map(times, (time) => (
                                            <T key={time} children={`search input time short ${time}`} />
                                        ))}
                                    </SeparatedList>
                                )}
                                {`)`}
                            </Fragment>
                        ))}
                    </SeparatedList>
                ) : (
                    <T>therapist output time no available</T>
                )
            }
            Icon={colorful ? therapistQuestionIconsColorful.therapist_times : therapistQuestionIcons.therapist_times}
        />
    );
}
