import { VisibilityOffOutlined, VisibilityOutlined } from '@mui/icons-material';
import { BaseTextFieldProps, IconButton, InputAdornment, TextField } from '@mui/material';
import { Dispatch, SetStateAction, useCallback, useState } from 'react';

import T from '../locallization/T';
import { MAX_PASSWORD_LENGTH } from '../login/sign/RegisterForm';

type PASSWORD_VARIANTS = "normal" | "confirm" | "new" | "confirm new";

interface Props extends BaseTextFieldProps {
    password: string;
    setPassword: Dispatch<SetStateAction<string>>;
    type?: PASSWORD_VARIANTS;
}

export default function PasswordInput({ password, setPassword, type = "normal", ...otherProps }: Props) {
    const [showPassword, setShowPassword] = useState(false);
    const updatePassword = useCallback((event) => setPassword(event.target.value), [setPassword]);
    const toggleShowPassword = useCallback((event) => {
        event.preventDefault();
        setShowPassword((show) => !show);
    }, []);

    let text = <T>login form input password</T>;
    if (type === "confirm") {
        text = <T>login form input password again</T>;
    } else if (type === "new") {
        text = <T>login form input new password</T>;
    } else if (type === "confirm new") {
        text = <T>login form input new password again</T>;
    }

    return (
        <TextField
            label={text}
            variant="outlined"
            type={showPassword ? "text" : "password"}
            inputProps={{ maxLength: MAX_PASSWORD_LENGTH }}
            value={password}
            onChange={updatePassword}
            fullWidth
            InputProps={{
                endAdornment: (
                    <InputAdornment position="end">
                        <IconButton onClick={toggleShowPassword} edge="end">
                            {showPassword ? <VisibilityOffOutlined /> : <VisibilityOutlined />}
                        </IconButton>
                    </InputAdornment>
                ),
            }}
            {...otherProps}
        />
    );
}
