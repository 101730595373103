import { CheckOutlined } from "@mui/icons-material";
import {
    EmailAuthProvider,
    GoogleAuthProvider,
    OAuthProvider,
    reauthenticateWithCredential,
    reauthenticateWithPopup,
    User,
} from "firebase/auth";
import { useCallback, useEffect, useState } from "react";

import { ActionButtonPrimary, ActionButtonSecondary } from "../common/ActionButtons";
import AppDialog from "../common/dialog/AppDialog";
import PasswordInput from "../common/PasswordInput";
import T from "../locallization/T";

export type AUTH_PROVIDERS = "password" | "google.com" | "apple.com";

export const ERROR_MESSAGE_USER_CLOSED_DIALOG = "User closed dialog";

export function useReauthenticate(user: User | null) {
    const [showDialog, setShowDialog] = useState(false);
    const [passwordPromise, setPasswordPromise] = useState<{ resolve; reject }>();

    const hideDialog = useCallback(() => {
        if (passwordPromise) {
            passwordPromise.reject(new Error(ERROR_MESSAGE_USER_CLOSED_DIALOG));
        }
        setShowDialog(false);
    }, [passwordPromise]);

    const reauthenthicate = useCallback(() => {
        if (!user) {
            return Promise.reject();
        }
        const providers = getAuthProviders(user);
        if (providers.has("google.com")) {
            return reauthenticateWithPopup(user, new GoogleAuthProvider());
        }
        if (providers.has("apple.com")) {
            return reauthenticateWithPopup(user, new OAuthProvider("apple.com"));
        }
        const promise = new Promise<string>((resolve, reject) => {
            setShowDialog(true);
            setPasswordPromise({ resolve, reject });
        });
        return promise;
    }, [user]);

    const sendReauth = useCallback(
        (password: string) => {
            if (!user || !passwordPromise) {
                return;
            }
            setShowDialog(false);
            const credentials = EmailAuthProvider.credential(user.email || "", password);
            reauthenticateWithCredential(user, credentials)
                .then(passwordPromise.resolve)
                .catch(passwordPromise.reject)
                .finally(() => setPasswordPromise(undefined));
        },
        [user, passwordPromise]
    );

    return { reauthenthicate, modalProps: { showDialog, hideDialog, sendReauth } };
}

export function getAuthProviders(user: User) {
    const providers = new Set<AUTH_PROVIDERS>();
    user.providerData.forEach(({ providerId }) => providers.add(providerId as AUTH_PROVIDERS));
    return providers;
}

export default function ReauthenticateModal({ showDialog, hideDialog, sendReauth }) {
    const [password, setPassword] = useState("");
    const onConfirm = useCallback(() => {
        sendReauth(password);
    }, [sendReauth, password]);

    useEffect(() => {
        if (showDialog) {
            setPassword("");
        }
    }, [showDialog]);

    return (
        <AppDialog
            open={showDialog}
            setOpen={hideDialog}
            dialogTitle={<T>reauth dialog title</T>}
            actions={
                <>
                    <ActionButtonSecondary onClick={hideDialog} autoFocus>
                        <T>profile delete dialog cancel button</T>
                    </ActionButtonSecondary>
                    <ActionButtonPrimary onClick={onConfirm} disabled={!password} endIcon={<CheckOutlined />}>
                        <T>reauth dialog confirm button</T>{" "}
                    </ActionButtonPrimary>
                </>
            }>
            <PasswordInput password={password} setPassword={setPassword} margin="dense" autoFocus />
        </AppDialog>
    );
}

export function reauthenticationError(error: Error, enqueueSnackbar) {
    console.error(error);
    if (error.message.includes(ERROR_MESSAGE_USER_CLOSED_DIALOG)) {
        // don't show any message
        return;
    }
    let errorMessage = <T params={{ code: 185 }}>general error</T>;
    if (error.message.includes("auth/wrong-password")) {
        errorMessage = <T>login form error no password</T>;
    }
    enqueueSnackbar(errorMessage, { variant: "warning" });
}
