import { Analytics, getAnalytics } from "firebase/analytics";
import { initializeApp } from "firebase/app";
import { browserLocalPersistence, getAuth, setPersistence } from "firebase/auth";
import { getDatabase } from "firebase/database";
import { getFirestore } from "firebase/firestore";
import { getFunctions } from "firebase/functions";
import { getStorage } from "firebase/storage";

import { isLocalhost } from "../common/isLocalhost";
import { isStaging } from "../common/isStaging";

const DOMAIN = "zigmond.me";
export const URL = `https://${DOMAIN}`;

export const useStaging = isLocalhost || isStaging;

export const firebaseConfig = useStaging
    ? // staging env:
      {
          apiKey: "AIzaSyCTctDEH7sYWlxVaVgWKgbi3EynCj6TQLM",
          authDomain: "zigmond-staging.firebaseapp.com",
          projectId: "zigmond-staging",
          storageBucket: "zigmond-staging.appspot.com",
          messagingSenderId: "215714592822",
          appId: "1:215714592822:web:18e8cd2d713016c0f04097",
      }
    : // prod env:
      {
          apiKey: "AIzaSyDnhT5dXtBivKPu2wuuSATnL4nYaKqXR8w",
          authDomain: DOMAIN,
          projectId: "psyder-e8686",
          storageBucket: "psyder-e8686.appspot.com",
          messagingSenderId: "587487206473",
          appId: "1:587487206473:web:b2bb728bb5d63729a9b398",
          measurementId: "G-4HB75HBFKR",
      };

// Initialize Firebase
const app = initializeApp(firebaseConfig);
let analytics: Analytics;
if (!isLocalhost) {
    analytics = getAnalytics(app);
    analytics.app.automaticDataCollectionEnabled = false;
}
const auth = getAuth(app);
const realtimeDb = getDatabase(
    app,
    useStaging
        ? "https://zigmond-staging-default-rtdb.europe-west1.firebasedatabase.app/"
        : "https://psyder-e8686-default-rtdb.europe-west1.firebasedatabase.app/"
);
const firestoreDb = getFirestore(app);
const storage = getStorage(app);
const functions = getFunctions(app, "europe-west1");

setPersistence(auth, browserLocalPersistence);

// Uncomment to work with local server:
// connectFunctionsEmulator(functions, "localhost", 5001);
// Uncomment to work with local database firestore:
// connectFirestoreEmulator(firestoreDb, "localhost", 8080);

export function getFirebase() {
    return app;
}

export function getFirebaseAnalytics() {
    return analytics;
}

export function getFirebaseAuth() {
    return auth;
}

export function getFirebaseRealtimeDb() {
    return realtimeDb;
}

export function getFirebaseFirestoreDb() {
    return firestoreDb;
}

export function getFirebaseStorage() {
    return storage;
}

export function getFirebaseFunctions() {
    return functions;
}
