import T from '../../locallization/T';
import SearchInputBasicTextList from '../basic/SearchInputBasicTextList';
import { SEARCH_INPUT_PROPS } from '../inputs/SearchInputProps';

export default function SearchInputArticlesLinks({ onData, newUserInfo }: SEARCH_INPUT_PROPS) {
    return (
        <SearchInputBasicTextList
            onData={onData}
            newUserInfo={newUserInfo}
            userInfoKey="therapistArticlesLinks"
            label={<T>search input articles link</T>}
            showMoreLabel={<T>search input articles link more button</T>}
            minListItems={2}
            maxListItems={20}
            maxLength={1500}
            dir="ltr"
        />
    );
}
