import { useUnreleasedFeature } from "../flags/useUnreleasedFeature";

export const LOCALE_HE = "he";
export const LOCALE_EN = "en";
const ALL_LOCALES = new Set([LOCALE_HE, LOCALE_EN]);
export const RTL_LOCALES = new Set([LOCALE_HE]);
export const DEFAULT_LOCALE = LOCALE_HE;

export const MAPS_API_LOCALES = {
    [LOCALE_HE]: "iw",
    [LOCALE_EN]: "en",
};

export function useLocale() {
    const forceLocale = useUnreleasedFeature("locale");
    const locale = ALL_LOCALES.has(forceLocale) ? forceLocale : DEFAULT_LOCALE;

    if (RTL_LOCALES.has(locale)) {
        document.dir = "rtl";
    } else {
        document.dir = "ltr";
    }

    return locale;
}
