import { range, without } from "lodash";
import { useCallback } from "react";

import { getTalkGenderRaw } from "../../common/gender/getTalkGender";
import T from "../../locallization/T";
import { USER_INFO, USER_INFO_SPECIALTIES } from "../../user/userInfo";
import SearchInputBasicNumberedSet from "../basic/SearchInputBasicNumeredSet";
import { SEARCH_INPUT_PROPS } from "../inputs/SearchInputProps";

const LAST_SPECIALTY = 11;
export const allSpecialties = without(range(1, USER_INFO_SPECIALTIES + 1), LAST_SPECIALTY).concat([LAST_SPECIALTY]);

export function alreadyHasSpecialty(userInfo: USER_INFO) {
    return !!userInfo.therapistSpecialties?.length;
}

export default function SearchInputSpecialty({ onData, newUserInfo }: SEARCH_INPUT_PROPS) {
    const getValueLabel = useCallback(
        (specialty: number) => (
            <T
                overrideGender={getTalkGenderRaw(newUserInfo?.gender, newUserInfo?.talkGender)}
                children={`specialty ${specialty}`}
            />
        ),
        [newUserInfo?.gender, newUserInfo?.talkGender]
    );

    return (
        <SearchInputBasicNumberedSet
            onData={onData}
            newUserInfo={newUserInfo}
            userInfoKey="therapistSpecialties"
            label={<T>search input specialty</T>}
            allValues={allSpecialties}
            getValueLabel={getValueLabel}
        />
    );
}
