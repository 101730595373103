import { TextField, TextFieldProps } from '@mui/material';
import { isNaN } from 'lodash';
import { useCallback, useState } from 'react';

import { USER_INFO } from '../../user/userInfo';
import { SEARCH_INPUT_PROPS } from '../inputs/SearchInputProps';

const MIN_YEAR = 1900;
const MAX_YEAR = new Date().getFullYear();

export function isValidYear(year: number) {
    return !isNaN(year) && year >= MIN_YEAR && year <= MAX_YEAR;
}

export default function SearchInputBasicYear({
    onData,
    newUserInfo,
    userInfoKey,
    label,
    defaultValue = "",
}: SEARCH_INPUT_PROPS &
    TextFieldProps & {
        userInfoKey: keyof USER_INFO;
    }) {
    const [year, setYear] = useState(newUserInfo[userInfoKey] || defaultValue);

    const updateYear = useCallback(
        (event) => {
            setYear(event.target.value);
            const year = +event.target.value;
            if (isValidYear(year)) {
                onData({
                    [userInfoKey]: year,
                });
            }
        },
        [onData, userInfoKey]
    );

    return (
        <TextField
            label={label}
            variant="outlined"
            type="text"
            inputProps={{
                inputMode: "tel",
            }}
            value={year}
            onChange={updateYear}
        />
    );
}
