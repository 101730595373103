import { Stack, Typography } from "@mui/material";
import { useCallback, useEffect, useMemo, useState } from "react";

import T from "../../locallization/T";
import SearchInputReasons, { REASONS_LIMIT } from "../../search/inputs/SearchInputReasons";
import { USER_INFO } from "../../user/userInfo";
import { ONBOARDING_QUESTION_PROPS } from "./OnboardingQuestionProps";

export const REASONS_TEXT_MIN_WORDS = 1;

export function alreadyHasReasons(userInfo: USER_INFO) {
    return (userInfo.reasonsText || "").trim().length >= REASONS_TEXT_MIN_WORDS;
}

export default function OnboardingQuestionReasons({ onDataProgress, newUserInfo }: ONBOARDING_QUESTION_PROPS) {
    const [reasonsText, setReasonsText] = useState<string>(newUserInfo?.reasonsText || "");
    const [reasonsList, setReasonsList] = useState<number[]>(newUserInfo?.reasonsList || []);

    const updatedUserInfo = useMemo(
        () => ({ ...newUserInfo, reasonsText, reasonsList }),
        [newUserInfo, reasonsText, reasonsList]
    );

    useEffect(() => {
        if (alreadyHasReasons(updatedUserInfo)) {
            onDataProgress({
                reasonsText,
                reasonsList,
            });
        } else {
            onDataProgress({});
        }
    }, [onDataProgress, reasonsText, reasonsList, updatedUserInfo]);

    const onData = useCallback((updates: USER_INFO) => {
        if (updates.reasonsList !== undefined) {
            setReasonsList(updates.reasonsList);
        }
        if (updates.reasonsText !== undefined) {
            setReasonsText(updates.reasonsText);
        }
    }, []);

    return (
        <Stack gap={2}>
            <Typography variant="subtitle1">
                <T params={{ count: REASONS_LIMIT }}>onboarding questions reasons limit</T>
            </Typography>
            <SearchInputReasons onData={onData} newUserInfo={updatedUserInfo} />
        </Stack>
    );
}
