import { VideocamTwoTone } from "@mui/icons-material";

import { getTalkGender } from "../../common/gender/getTalkGender";
import T from "../../locallization/T";
import { userInfoZoom } from "../../onboarding/questions/OnboardingQuestionLocation";
import { THERAPIST_OUTPUT_PROPS } from "../basic/OutputProps";
import OutputRow from "../basic/OutputRow";

export default function TherapistOutputZoom({ newUserInfo, expanded, colorful, ...props }: THERAPIST_OUTPUT_PROPS) {
    if (expanded) {
        return (
            <OutputRow
                {...props}
                expanded={expanded}
                title={<T overrideGender={getTalkGender(newUserInfo)}>therapist output zoom</T>}
                Icon={colorful ? VideocamTwoTone : userInfoZoom[0].Icon}
                value={newUserInfo?.therapistLocationZoom === "Y" ? <T>yes</T> : <T>no</T>}
            />
        );
    }
    return newUserInfo?.therapistLocationZoom === "Y" ? (
        <OutputRow
            {...props}
            title={<T overrideGender={getTalkGender(newUserInfo)}>therapist output zoom</T>}
            Icon={colorful ? VideocamTwoTone : userInfoZoom[0].Icon}
        />
    ) : null;
}
