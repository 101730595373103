import { Checkbox, FormControlLabel, Stack } from "@mui/material";
import { useCallback, useEffect, useState } from "react";

import T from "../../locallization/T";
import SearchInputCost from "../../search/inputs/SearchInputCost";
import SearchInputKupa from "../../search/inputs/SearchInputKupa";
import { USER_INFO, USER_INFO_KUPA } from "../../user/userInfo";
import { ONBOARDING_QUESTION_PROPS } from "./OnboardingQuestionProps";
import { DEFAULT_COST } from "./OnboardingQuestionTherapistCost";

export function alreadyHasCost(userInfo: USER_INFO) {
    return !!userInfo.cost;
}
export function hasKupa(userInfo?: USER_INFO) {
    return (userInfo?.kupa || "none") !== "none";
}

export default function OnboardingQuestionCost({ onDataProgress, newUserInfo }: ONBOARDING_QUESTION_PROPS) {
    const [costValue, setCostValue] = useState<number | undefined>(newUserInfo?.cost || DEFAULT_COST);
    const [showKupa, setShowKupa] = useState(hasKupa(newUserInfo));
    const [kupa, setKupa] = useState<USER_INFO_KUPA | undefined>(newUserInfo?.kupa);

    const onCostData = useCallback((updates: USER_INFO) => {
        setCostValue(updates.cost);
    }, []);
    const onKupaData = useCallback((updates: USER_INFO) => {
        setKupa(updates.kupa);
    }, []);
    const toggleShowKupa = useCallback(() => {
        if (showKupa) {
            // reset saved kupa data
            setKupa(undefined);
        }
        setShowKupa((showKupa) => !showKupa);
    }, [showKupa]);

    useEffect(() => {
        if (costValue) {
            onDataProgress({ cost: costValue, kupa: kupa || "none" });
        } else {
            onDataProgress({});
        }
    }, [costValue, kupa, onDataProgress]);

    return (
        <Stack gap={3}>
            <SearchInputCost onData={onCostData} newUserInfo={newUserInfo} showTextInput showAverageNote />
            <Stack gap={1}>
                <FormControlLabel
                    control={<Checkbox checked={showKupa} onChange={toggleShowKupa} />}
                    label={<T>search input cost show kupa</T>}
                />
                {showKupa && <SearchInputKupa onData={onKupaData} newUserInfo={newUserInfo} expanded />}
            </Stack>
        </Stack>
    );
}
