import {
    CalendarMonthOutlined,
    CancelOutlined,
    ClearOutlined,
    PaymentsOutlined,
    RestoreOutlined,
} from "@mui/icons-material";
import {
    Button,
    CircularProgress,
    Container,
    Link,
    ListItemIcon,
    ListItemText,
    MenuItem,
    Stack,
    Typography,
} from "@mui/material";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

import ActionButtons, { ActionButtonPrimary } from "../../common/ActionButtons";
import AppDialog from "../../common/dialog/AppDialog";
import MoreButton from "../../common/MoreButton";
import T from "../../locallization/T";
import MatchRejectDialog from "../../matches/MatchRejectDialog";
import MatchScheduleDialog from "../../matches/MatchScheduleDialog";
import { useOpenPayment } from "../../matches/useOpenPayment";
import { usePaymentDue } from "../../matches/usePaymentDue";
import { useRestoreMetupal } from "../../matches/useRestoreMetupal";
import { useLoadUserInfos } from "../../matches/userInfosService";
import MetupalPageView from "../../metupalim/MetupalPageView";
import AppSpinner from "../../navigation/AppSpinner";
import PageContainer from "../../navigation/PageContainer";
import { ROUTES } from "../../navigation/Router";
import { usePageSizes } from "../../navigation/usePageSizes";
import { useResetUnreadCount } from "../../notifications/useResetUnreadCount";
import { useProfileName } from "../../outputs/basic/useProfileName";
import ProfileLoginAs from "../../profile/settingButtons/ProfileLoginAs";
import { useAppContext } from "../../root/AppContext";
import TherapistPageView from "../../therapist_page/TherapistPageView";
import MessagesInput from "./MessagesInput";
import MessagesList from "./MessagesList";

export default function Messages() {
    const { userInfo } = useAppContext();
    const [showDialog, setShowDialog] = useState(false);
    const [displayMessagesCount, setDisplayMessagesCount] = useState<number>();
    const [showScheduleDialog, setShowScheduleDialog] = useState(false);
    const [showRejectDialog, setShowRejectDialog] = useState(false);
    const [loadingInAction, setLoadingInAction] = useState(false);
    const { contentSx } = usePageSizes();
    const navigate = useNavigate();
    const onBack = useCallback(() => navigate(ROUTES.CHAT), [navigate]);

    const params = useParams();
    const [metupalUid, therapistUid] = useMemo(() => {
        const chatId = params.chatId || "";
        return [chatId.slice(0, chatId.length / 2), chatId.slice(chatId.length / 2, chatId.length)];
    }, [params.chatId]);
    const uids = useMemo(() => {
        return userInfo?.uid === therapistUid ? [metupalUid] : [therapistUid];
    }, [userInfo?.uid, metupalUid, therapistUid]);
    const { loading: loadingUserInfos, userInfos } = useLoadUserInfos(uids, !userInfo?.isTherapist);

    const matchUserInfo = userInfos[0];

    const matchName = useProfileName({
        targetUserInfo: matchUserInfo,
        isTargetTherapist: !userInfo?.isTherapist,
    });
    const paymentDue = usePaymentDue();
    const futurePaymentDue = usePaymentDue({ prepay: true });
    const { openPayment } = useOpenPayment();
    const { openPayment: openPrepayment } = useOpenPayment({ prepay: true });
    const { restoreMetupal } = useRestoreMetupal({
        metupalUid: metupalUid || "",
        setLoading: setLoadingInAction,
    });

    const loading = loadingUserInfos;

    const isRejected = !!(userInfo?.isTherapist
        ? (userInfo?.rejectedMetupalim || {})[metupalUid || ""] ||
          (userInfo?.rejectedByMetupalim || {})[metupalUid || ""]
        : (userInfo?.rejectedTherapists || {})[therapistUid || ""] ||
          (userInfo?.rejectedByTherapists || {})[therapistUid || ""]);

    const toggleShowDialog = useCallback(() => {
        setShowDialog((show) => !show);
    }, []);

    const toggleShowScheduleDialog = useCallback(
        (event) => {
            event.stopPropagation();
            if (loadingInAction) {
                return;
            }
            setShowScheduleDialog((show) => !show);
        },
        [loadingInAction]
    );
    const toggleShowRejectDialog = useCallback(
        (event) => {
            event.stopPropagation();
            if (loadingInAction) {
                return;
            }
            setShowRejectDialog((show) => !show);
        },
        [loadingInAction]
    );

    const resetUnreadCount = useResetUnreadCount({ userInfoKey: "messagesCounts", targetUid: uids[0] });

    useEffect(() => resetUnreadCount(), [resetUnreadCount]);

    const showActions = userInfo?.isTherapist && matchUserInfo && !matchUserInfo.consult;

    const hasChatRolesSwapped = userInfo?.isTherapist
        ? (userInfo?.chats || {})[therapistUid]
        : (userInfo?.chatsWithMetupalim || {})[metupalUid];

    const declineText = (userInfo?.checkMet || {})[metupalUid] ? (
        <T>metupal dialog button cancelled</T>
    ) : (
        <T>metupal dialog button decline has chat</T>
    );

    return (
        <PageContainer
            pageTitleKey="messages title"
            pageTitleParams={{ name: matchName }}
            pagePath={ROUTES.MESSAGES}
            pageReady={!loadingUserInfos}
            onBack={onBack}
            BotNavComponent={
                loading || displayMessagesCount === undefined ? (
                    <></>
                ) : (
                    <Container>
                        <Stack pb={3} pt={3} sx={contentSx}>
                            <MessagesInput
                                disabled={!matchUserInfo || isRejected}
                                displayMessagesCount={displayMessagesCount}
                                chatId={params.chatId || ""}
                                matchUserInfo={matchUserInfo}
                            />
                        </Stack>
                    </Container>
                )
            }
            title={
                loading ? undefined : matchUserInfo ? (
                    <T
                        params={{
                            name:
                                matchUserInfo.consult || isRejected ? (
                                    matchName
                                ) : (
                                    <Link
                                        component="a"
                                        onClick={toggleShowDialog}
                                        sx={{
                                            color: "inherit",
                                            textDecorationColor: "inherit",
                                            cursor: "pointer",
                                            whiteSpace: "nowrap",
                                        }}>
                                        {matchName}
                                    </Link>
                                ),
                        }}>
                        messages title
                    </T>
                ) : (
                    <T>profile deleted</T>
                )
            }
            actionIcon={
                showActions ? (
                    <MoreButton>
                        {!(userInfo?.met || {})[metupalUid] && (
                            <MenuItem onClick={toggleShowScheduleDialog} disabled={loadingInAction}>
                                <ListItemIcon>
                                    {loadingInAction ? (
                                        <CircularProgress size={20} />
                                    ) : (
                                        <CalendarMonthOutlined fontSize="small" color="primary" />
                                    )}
                                </ListItemIcon>
                                <ListItemText>{<T>match therapist button meet</T>}</ListItemText>
                            </MenuItem>
                        )}
                        {paymentDue[metupalUid] && (
                            <MenuItem onClick={openPayment} disabled={loadingInAction}>
                                <ListItemIcon>
                                    <PaymentsOutlined fontSize="small" color="primary" />
                                </ListItemIcon>
                                <ListItemText>{<T>match therapist button pay</T>}</ListItemText>
                            </MenuItem>
                        )}
                        {!paymentDue[metupalUid] && futurePaymentDue[metupalUid] && (
                            <MenuItem onClick={openPrepayment} disabled={loadingInAction}>
                                <ListItemIcon>
                                    <PaymentsOutlined fontSize="small" color="primary" />
                                </ListItemIcon>
                                <ListItemText>{<T>match therapist button prepay</T>}</ListItemText>
                            </MenuItem>
                        )}
                        {(userInfo?.rejectedMetupalim || {})[metupalUid] && (
                            <MenuItem onClick={restoreMetupal} disabled={loadingInAction}>
                                <ListItemIcon>
                                    {loadingInAction ? (
                                        <CircularProgress size={20} />
                                    ) : (
                                        <RestoreOutlined fontSize="small" color="primary" />
                                    )}
                                </ListItemIcon>
                                <ListItemText>
                                    <T>favorite restore match</T>
                                </ListItemText>
                            </MenuItem>
                        )}
                        {!(userInfo?.rejectedMetupalim || {})[metupalUid] && !paymentDue[metupalUid] && (
                            <MenuItem onClick={toggleShowRejectDialog} disabled={loadingInAction}>
                                <ListItemIcon>
                                    {loadingInAction ? (
                                        <CircularProgress size={20} />
                                    ) : (
                                        <ClearOutlined fontSize="small" color="primary" />
                                    )}
                                </ListItemIcon>
                                <ListItemText>{declineText}</ListItemText>
                            </MenuItem>
                        )}
                    </MoreButton>
                ) : null
            }
            backgrounded>
            {loading ? (
                <AppSpinner />
            ) : (
                <>
                    <MessagesList
                        chatId={params.chatId || ""}
                        matchUserInfo={isRejected ? null : matchUserInfo}
                        setDisplayMessagesCount={setDisplayMessagesCount}
                    />
                    {hasChatRolesSwapped ? (
                        <Stack mt={4} gap={1}>
                            <Typography>
                                <T>chat swapped notice</T>
                            </Typography>
                            <ProfileLoginAs />
                        </Stack>
                    ) : null}
                </>
            )}
            <AppDialog open={showDialog} setOpen={setShowDialog} fullScreen disableGutters>
                {userInfo?.isTherapist ? (
                    <MetupalPageView metupalUserInfo={matchUserInfo} onBack={toggleShowDialog} />
                ) : (
                    <TherapistPageView therapistUserInfo={matchUserInfo} onBack={toggleShowDialog} />
                )}
                {showActions && (
                    <ActionButtons mt={3} flexDirection="column">
                        {(userInfo?.rejectedMetupalim || {})[metupalUid] ? (
                            <Button
                                disabled={loadingInAction}
                                variant="text"
                                onClick={restoreMetupal}
                                sx={{ alignSelf: "center" }}
                                startIcon={<RestoreOutlined />}>
                                <T>favorite restore match</T>
                            </Button>
                        ) : !(userInfo?.rejectedMetupalim || {})[metupalUid] && !paymentDue[metupalUid] ? (
                            <Button
                                disabled={loadingInAction}
                                variant="text"
                                onClick={toggleShowRejectDialog}
                                sx={{ alignSelf: "center" }}
                                startIcon={<CancelOutlined />}>
                                {declineText}
                            </Button>
                        ) : null}
                        {paymentDue[metupalUid] ? (
                            <ActionButtonPrimary
                                onClick={openPayment}
                                disabled={loadingInAction}
                                endIcon={loadingInAction ? <CircularProgress size={24} /> : <PaymentsOutlined />}>
                                <T>match therapist button pay</T>
                            </ActionButtonPrimary>
                        ) : !paymentDue[metupalUid] && futurePaymentDue[metupalUid] ? (
                            <ActionButtonPrimary
                                onClick={openPrepayment}
                                disabled={loadingInAction}
                                endIcon={loadingInAction ? <CircularProgress size={24} /> : <PaymentsOutlined />}>
                                <T>match therapist button prepay</T>
                            </ActionButtonPrimary>
                        ) : !(userInfo?.met || {})[metupalUid] ? (
                            <ActionButtonPrimary
                                onClick={toggleShowScheduleDialog}
                                disabled={loadingInAction}
                                endIcon={loadingInAction ? <CircularProgress size={24} /> : <CalendarMonthOutlined />}>
                                <T>match therapist button meet</T>
                            </ActionButtonPrimary>
                        ) : null}
                    </ActionButtons>
                )}
            </AppDialog>
            {userInfo?.isTherapist && (
                <MatchScheduleDialog
                    metupalUid={metupalUid}
                    showScheduleDialog={showScheduleDialog}
                    setShowScheduleDialog={setShowScheduleDialog}
                    loading={loadingInAction}
                    setLoading={setLoadingInAction}
                />
            )}
            {userInfo?.isTherapist && (
                <MatchRejectDialog
                    metupalUid={metupalUid}
                    showRejectDialog={showRejectDialog}
                    setShowRejectDialog={setShowRejectDialog}
                    loading={loadingInAction}
                    setLoading={setLoadingInAction}
                />
            )}
        </PageContainer>
    );
}
