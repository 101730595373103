import { Stack, Typography } from "@mui/material";
import { useCallback, useState } from "react";

import InputLocationDistance from "../../inputs/InputLocationDistance";
import T from "../../locallization/T";
import { SEARCH_INPUT_PROPS } from "./SearchInputProps";

export default function SearchInputLocationDistance({ onData, newUserInfo }: SEARCH_INPUT_PROPS) {
    const [rangeValue, setRangeValue] = useState(newUserInfo?.locationRange || 0);

    const updateRangeValue = useCallback(
        (value: number) => {
            setRangeValue(value);
            onData({
                locationRange: value,
            });
        },
        [onData]
    );

    if (!newUserInfo?.locationGeo) {
        return null;
    }

    return (
        <Stack gap={4}>
            <Typography>
                <T>search input location radius</T>
            </Typography>
            <InputLocationDistance
                rangeValue={rangeValue}
                setRangeValue={updateRangeValue}
                geo={newUserInfo?.locationGeo}
            />
        </Stack>
    );
}
