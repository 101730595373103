import { PermContactCalendarOutlined } from "@mui/icons-material";

import T from "../../locallization/T";
import SettingsGeneral from "../settings/SettingsGeneral";
import SettingRowBase from "./SettingRowBase";

export default function SettingRowGeneral() {
    return (
        <SettingRowBase
            text={<T>profile setting general</T>}
            Icon={PermContactCalendarOutlined}
            SettingContent={SettingsGeneral}
            fullScreen
        />
    );
}
