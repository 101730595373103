import { LocationOnOutlined, VideocamOffOutlined, VideocamOutlined } from "@mui/icons-material";
import { Stack, SvgIcon, Typography } from "@mui/material";
import { GeoPoint } from "firebase/firestore";
import { useEffect, useState } from "react";

import { PlaceType } from "../../inputs/GoogleAutocomplete";
import InputLocationDistance from "../../inputs/InputLocationDistance";
import InputLocationGeo from "../../inputs/InputLocationGeo";
import T from "../../locallization/T";
import { USER_INFO, USER_INFO_ZOOM } from "../../user/userInfo";
import AppToggleButton from "../buttonGroup/AppToggleButton";
import OnboardingQuestionMultiple from "./helpers/OnboardingQuestionMultiple";
import { ONBOARDING_QUESTION_PROPS } from "./OnboardingQuestionProps";

const DEFAULT_DISTANCE_KM = 8;

export const userInfoZoom: { value: USER_INFO_ZOOM; textKey: string; Icon: typeof SvgIcon }[] = [
    { value: "Y", textKey: "yes", Icon: VideocamOutlined },
    { value: "N", textKey: "no", Icon: VideocamOffOutlined },
];

export type LOCATION_TYPES = "zoom" | "front";

export const userInfoLocationType: { value: LOCATION_TYPES; textKey: string; Icon: typeof SvgIcon }[] = [
    { value: "zoom", textKey: "onboarding location zoom", Icon: VideocamOutlined },
    { value: "front", textKey: "onboarding location front", Icon: LocationOnOutlined },
];

export function alreadyHasLocation(userInfo: USER_INFO) {
    return (!!userInfo.locationGeo && !!userInfo.locationRange) || userInfo.locationZoom === "Y";
}

export default function OnboardingQuestionLocation({ onDataProgress }: ONBOARDING_QUESTION_PROPS) {
    const [placeValue, setPlaceValue] = useState<PlaceType | null>(null);
    const [geo, setGeo] = useState<GeoPoint | null>(null);
    const [rangeValue, setRangeValue] = useState(DEFAULT_DISTANCE_KM);
    const [selectedLocationOptions, setSelectedLocationOptions] = useState(new Set<LOCATION_TYPES>([]));

    useEffect(() => {
        if (
            (selectedLocationOptions.has("zoom") && !selectedLocationOptions.has("front")) ||
            (selectedLocationOptions.has("front") && geo)
        ) {
            onDataProgress({
                locationGeo: geo ?? undefined,
                locationRange: rangeValue,
                locationName: placeValue?.description,
                locationZoom: selectedLocationOptions.has("zoom") ? "Y" : "N",
            });
        } else {
            onDataProgress({});
        }
    }, [onDataProgress, geo, rangeValue, placeValue, selectedLocationOptions]);

    return (
        <Stack gap={2}>
            <OnboardingQuestionMultiple />

            <Stack flexDirection="row" gap={1} mb={1}>
                {userInfoLocationType.map(({ value, textKey, Icon }) => (
                    <AppToggleButton
                        key={value}
                        value={value}
                        selectedOptions={selectedLocationOptions}
                        setSelectedOptions={setSelectedLocationOptions}
                        Icon={<Icon />}>
                        <T>{textKey}</T>
                    </AppToggleButton>
                ))}
            </Stack>
            {selectedLocationOptions.has("front") && (
                <>
                    <InputLocationGeo placeValue={placeValue} setPlaceValue={setPlaceValue} setGeo={setGeo} />
                    {placeValue && (
                        <>
                            <Typography variant="subtitle1" sx={{ mb: 2, mt: 1 }}>
                                <T>onboarding question location radius</T>
                            </Typography>
                            <InputLocationDistance
                                rangeValue={rangeValue}
                                setRangeValue={setRangeValue}
                                geo={geo}
                                initialMapOpen
                            />
                        </>
                    )}
                </>
            )}
        </Stack>
    );
}
