import { isEmpty } from 'lodash';

import { useDbConstant } from '../../constants/useConstant';

export const ACTIVITY_DAILY = "daily";
export const ACTIVITY_WEEKLY = "weekly";
export const ACTIVITY_MONTHLY = "monthly";
export const ACTIVITY_GLOBAL = "global";

const ACTIVITIES_TIMES = [ACTIVITY_DAILY, ACTIVITY_WEEKLY, ACTIVITY_MONTHLY, ACTIVITY_GLOBAL];

const ACTIVITIES_MAPPING = [
    { key: "search_new", title: "חיפושים חדשים" },
    { key: "search_potential_sum", title: "ממוצע פניות מתאימות לחיפוש", avg_base: "search_new" },
    { key: "search_matches_sum", title: "ממוצע פניות בפועל לחיפוש", avg_base: "search_new" },
    { key: "search_first_time", title: "חיפושים ראשונים" },
    { key: "search_no_matches", title: "חיפושים בלי תוצאות מראש" },
    { key: "search_results_0", title: "התאמות - עם 0 תוצאות" },
    { key: "search_results_1", title: "התאמות - עם 1 תוצאות" },
    { key: "search_results_2", title: "התאמות - עם 2 תוצאות" },
    { key: "search_results_3", title: "התאמות - עם 3 תוצאות" },
    { key: "match_reject_as_therapist", title: "פניות שמטפלים דחו" },
    { key: "match_reject_as_metupal", title: "מטפלים שמטופלים דחו" },
    { key: "chat_new", title: "צ'אטים שנפתחו" },
    { key: "chat_phone", title: "צ'אטים שנשלח בהם טלפון" },
    { key: "chat_first_message_phone", title: "צ'אטים שההודעה הראשונה עם טלפון" },
    { key: "message_first", title: "צ'אטים עם הודעות" },
    { key: "message_first_as_metupal", title: "צ'אטים עם הודעה ראשונה ממטופל" },
    { key: "message_first_as_therapist", title: "צ'אטים עם הודעה ראשונה ממטפל" },
    { key: "message_new", title: "הודעות שנשלחו" },
    { key: "message_new_as_metupal", title: "הודעות שנשלחו ממטופל" },
    { key: "message_new_as_therapist", title: "הודעות שנשלחו ממטפל" },
    { key: "match_check_met_email", title: "מטפלים שנשאלו האם נפגשו" },
    { key: "match_set_meeting", title: "פגישות שנקבעו" },
    { key: "match_metupal_set_meeting", title: "פגישות שמטופלים סימנו שנקבעו" },
    { key: "pay_consult", title: "שיחות יעוץ ששילמו" },
    { key: "pay_consult_sum", title: "כסף משיחות יעוץ" },
    { key: "pay_meet", title: "פגישות ששילמו" },
    { key: "pay_meet_sum", title: "כסף מפגישות" },
    { key: "no_response_warning", title: "מטפלים שקיבלו אזערת זמינות" },
    { key: "no_response_punish", title: "מטפלים שקיבלו עונש זמינות" },
    { key: "user_delete", title: "יוזרים שנמחקו" },
    { key: "invalidate_met_metupal_conflict", title: "חריגות שהמטפל אמר לא והמטופל כן" },
    { key: "invalidate_met_answer_delay", title: "חריגות שהמטפל לא ענה" },
    { key: "invalidate_outdated_payment", title: "חריגות שהמטפל לא שילם בזמן" },
    { key: "invalidate_too_many_payments", title: "חריגות שהמטפל צריך לשלם יותר מדי" },
    { key: "invalidate_invalid_email", title: "חריגות שהאימייל לא קיים במערכת" },
    { key: "invalidate_unknown_product", title: "חריגות שהמוצר ששולם לא קיים" },
    { key: "invalidate_invalid_pay_count", title: "חריגות שכמות התשלומים לא תואמת" },
];

export function useGroupActivities() {
    const activities: { [key: string]: any } = useDbConstant<{}>(`activities`, {});
    if (isEmpty(activities)) {
        return null;
    }
    let results = {};

    for (const time of ACTIVITIES_TIMES) {
        results[time] = {};
        const values = activities[time] || {};
        for (const { key, title, avg_base } of ACTIVITIES_MAPPING) {
            const value = values[key];
            if (value) {
                if (avg_base) {
                    const avgCount = values[avg_base] || 0;
                    if (avgCount) {
                        // round to 2 decimal points.
                        results[time][title] = Math.round((value / avgCount) * 100) / 100;
                    }
                } else {
                    results[time][title] = value;
                }
            }
        }
    }
    return results;
}
