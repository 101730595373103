import { CalculateOutlined } from "@mui/icons-material";
import { Accordion, AccordionSummary, Button, CircularProgress, Stack, Typography } from "@mui/material";
import { httpsCallable } from "firebase/functions";
import { useSnackbar } from "notistack";
import { useCallback, useMemo, useState } from "react";

import { getFirebaseFunctions } from "../../firebase/firebase";
import T from "../../locallization/T";
import { USER_INFO } from "../../user/userInfo";

type MEASURE_RESULT = {
    roundedTo2Decimals: number;
    score: number;
    total: number;
    history: { score: number; total: number; handler: string }[];
};

export default function AdminCalculateMeasureButton({
    newUserInfo,
    isTherapist,
}: {
    newUserInfo: USER_INFO;
    isTherapist: boolean;
}) {
    const [loading, setLoading] = useState(false);
    const [measure, setMeasure] = useState<MEASURE_RESULT | null>(null);
    const { enqueueSnackbar } = useSnackbar();

    const calculateMeasureCallback = useMemo(
        () =>
            httpsCallable<{ uid: string; isTherapist: boolean }, MEASURE_RESULT>(
                getFirebaseFunctions(),
                "onCallCalculateMeasure"
            ),
        []
    );

    const calculate = useCallback(() => {
        setLoading(true);
        calculateMeasureCallback({
            uid: newUserInfo.uid || "",
            isTherapist,
        })
            .then((response) => {
                console.info("Measure result:", response);
                setLoading(false);
                enqueueSnackbar("החישוב בוצע בהצלחה", { variant: "success" });
                setMeasure(response.data);
            })
            .catch((error) => {
                setLoading(false);
                console.error(error);
                enqueueSnackbar(<T params={{ code: 176 }}>general error</T>, { variant: "warning" });
            });
    }, [enqueueSnackbar, newUserInfo, calculateMeasureCallback, isTherapist]);

    return (
        <>
            <Button
                onClick={calculate}
                color="info"
                disabled={loading}
                startIcon={loading ? <CircularProgress size={24} /> : <CalculateOutlined />}>
                חישוב מדד זיגמונד
            </Button>
            {measure && (
                <Stack mt={1}>
                    <Accordion>
                        <AccordionSummary>
                            <Typography sx={{ unicodeBidi: "plaintext" }}>
                                {measure.roundedTo2Decimals}% ({Math.round(measure.score * 100) / 100} / {measure.total}
                                )
                            </Typography>
                        </AccordionSummary>
                    </Accordion>
                    {measure.history.map(({ score, total, handler }) => (
                        <Accordion key={handler}>
                            <AccordionSummary>
                                <Typography>
                                    {handler}: {Math.round(score * 100) / 100} / {total}
                                </Typography>
                            </AccordionSummary>
                        </Accordion>
                    ))}
                </Stack>
            )}
        </>
    );
}
