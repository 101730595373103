import { DeleteOutlineOutlined } from "@mui/icons-material";
import {
    Card,
    CardActionArea,
    CardContent,
    CardProps,
    CircularProgress,
    ListItemIcon,
    ListItemText,
    MenuItem,
    Stack,
} from "@mui/material";
import { useMemo } from "react";

import DeletedState from "../chat/DeletedState";
import MoreButton from "../common/MoreButton";
import T from "../locallization/T";

export default function DeletedStateCard({
    matchIsTherapist,
    href,
    onHide,
    loading,
    ...CardProps
}: { matchIsTherapist?: boolean; href?: string; onHide?: (event?: any) => void; loading?: boolean } & CardProps) {
    const content = useMemo(
        () => (
            <CardContent>
                <Stack gap={1} flexDirection="row" alignItems="flex-start">
                    <DeletedState matchIsTherapist={matchIsTherapist} />
                    {onHide ? (
                        <MoreButton>
                            <MenuItem onClick={onHide} disabled={loading}>
                                <ListItemIcon>
                                    {loading ? (
                                        <CircularProgress size={20} />
                                    ) : (
                                        <DeleteOutlineOutlined fontSize="small" color="primary" />
                                    )}
                                </ListItemIcon>
                                <ListItemText>
                                    <T>deleted note action hide</T>
                                </ListItemText>
                            </MenuItem>
                        </MoreButton>
                    ) : null}
                </Stack>
            </CardContent>
        ),
        [matchIsTherapist, onHide, loading]
    );
    return <Card {...CardProps}>{href ? <CardActionArea href={href}>{content}</CardActionArea> : content}</Card>;
}
