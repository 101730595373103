import { isArray } from 'lodash';
import { Fragment } from 'react';

export default function SeparatedList({ children, separator = ", " }: { children?: any[]; separator?: string }) {
    if (!children || !isArray(children)) {
        return children as any;
    }
    return (
        <>
            {children.map((child, index) => (
                <Fragment key={index}>
                    {child}
                    {index < children.length - 1 && separator}
                </Fragment>
            ))}
        </>
    );
}
