import AppVideo from '../../common/AppVideo';
import { useDownloadVideoThumbnailUrl, useDownloadVideoUrl } from '../../uploads/videoUpload';
import { THERAPIST_OUTPUT_PROPS } from '../basic/OutputProps';

export default function TherapistOutputVideo({ newUserInfo }: THERAPIST_OUTPUT_PROPS) {
    const { url: videoUrl, loadingAsset: loadingVideo } = useDownloadVideoUrl(newUserInfo);
    const { url: thumbnailUrl, loadingAsset: loadingThumbnail } = useDownloadVideoThumbnailUrl(newUserInfo);

    if (!newUserInfo.therapistVideoKey) {
        return null;
    }

    const loading = loadingVideo || loadingThumbnail;

    return <AppVideo loading={loading} thumbnailUrl={thumbnailUrl} videoUrl={videoUrl} />;
}
