import T from '../../locallization/T';
import { THERAPIST_OUTPUT_PROPS } from '../basic/OutputProps';
import OutputRow from '../basic/OutputRow';
import { DEGREE_ICON } from './TherapistOutputDegrees';

export default function TherapistOutputAdditionalStudies({ newUserInfo, ...props }: THERAPIST_OUTPUT_PROPS) {
    return (
        <OutputRow
            {...props}
            title={<T>therapist output additional studies</T>}
            value={newUserInfo?.therapistAdditionalStudies}
            Icon={DEGREE_ICON}
            hideOnEmpty
        />
    );
}
