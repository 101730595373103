import { Stack } from "@mui/material";
import { ReactNode } from "react";

import { THERAPIST_OUTPUT_PROPS } from "../basic/OutputProps";
import TherapistOutputAges from "./TherapistOutputAges";
import TherapistOutputCost from "./TherapistOutputCost";
import TherapistOutputLocation from "./TherapistOutputLocation";
import TherapistOutputTimes from "./TherapistOutputTimes";
import TherapistOutputTypes from "./TherapistOutputTypes";
import TherapistOutputZoom from "./TherapistOutputZoom";

export default function TherapistSummaryOutput({
    newUserInfo,
    dividers = null,
}: THERAPIST_OUTPUT_PROPS & { dividers?: ReactNode }) {
    return (
        <Stack gap={2} divider={dividers}>
            <TherapistOutputLocation newUserInfo={newUserInfo} expanded={!!dividers} colorful={!!dividers} />
            <TherapistOutputTimes newUserInfo={newUserInfo} expanded={!!dividers} colorful={!!dividers} />
            <TherapistOutputTypes newUserInfo={newUserInfo} expanded={!!dividers} colorful={!!dividers} />
            <TherapistOutputCost newUserInfo={newUserInfo} expanded={!!dividers} colorful={!!dividers} />
            <TherapistOutputZoom newUserInfo={newUserInfo} expanded={!!dividers} colorful={!!dividers} />
            <TherapistOutputAges newUserInfo={newUserInfo} expanded={!!dividers} colorful={!!dividers} />
        </Stack>
    );
}
