import {
    Checkbox,
    FormControl,
    InputLabel,
    ListItemText,
    ListSubheader,
    MenuItem,
    Select,
    SelectChangeEvent,
} from "@mui/material";
import { some, values } from "lodash";
import { Fragment, useCallback, useEffect, useMemo, useState } from "react";

import SeparatedList from "../../common/SeparatedList";
import T from "../../locallization/T";
import {
    DEFAULT_THERAPIST_TIME,
    SUGGESTED_THERAPY_TIMES,
    THERAPY_TYPES_WITH_TIMES,
} from "../../onboarding/questions/OnboardingQuestionTherapistType";
import { USER_INFO, USER_INFO_MAP, USER_INFO_THERAPY_TYPE } from "../../user/userInfo";
import SelectConfirmButton from "../basic/SelectConfirmButton";
import { SEARCH_INPUT_PROPS } from "../inputs/SearchInputProps";

export function isDifferentMinuteTypes(newUserInfo?: USER_INFO) {
    return some(values(newUserInfo?.therapistMinutes || {}), (minute) => minute !== DEFAULT_THERAPIST_TIME);
}

const KEY_SEPARATOR = "@@";

export default function SearchInputTherapistMinutes({ onData, newUserInfo }: SEARCH_INPUT_PROPS) {
    const [selectedMinutes, setSelectedMinutes] = useState<USER_INFO_MAP<USER_INFO_THERAPY_TYPE, number>>(
        newUserInfo?.therapistMinutes || {}
    );
    const [open, setOpen] = useState(false);
    const handleClose = useCallback(() => setOpen(false), []);
    const handleOpen = useCallback(() => setOpen(true), []);

    const selectMinutesStrings = useMemo(() => {
        let strings: string[] = [];
        for (let type in selectedMinutes) {
            strings.push(`${type}${KEY_SEPARATOR}${selectedMinutes[type]}`);
        }
        return strings;
    }, [selectedMinutes]);

    const therapistTypesWithMinutes = useMemo(
        () => (newUserInfo?.therapistTherapyTypes || []).filter((type) => THERAPY_TYPES_WITH_TIMES.has(type)),
        [newUserInfo?.therapistTherapyTypes]
    );

    useEffect(() => {
        setSelectedMinutes((existingMinutes) => {
            const newMinutes = { ...existingMinutes };
            let hadChange = false;
            for (const type of therapistTypesWithMinutes) {
                newMinutes[type] = newMinutes[type] || DEFAULT_THERAPIST_TIME;
                if (newMinutes[type] !== existingMinutes[type]) {
                    hadChange = true;
                }
            }
            return hadChange ? newMinutes : existingMinutes;
        });
    }, [therapistTypesWithMinutes]);

    const selectMinutes = (event: SelectChangeEvent<typeof selectMinutesStrings>) => {
        const value = event.target.value;
        // On autofill we get a stringified value.
        const arrayValue = typeof value === "string" ? value.split(",") : value;
        setSelectedMinutes((existingMinutes) => {
            const newMinutes = { ...existingMinutes };

            let hadChange = false;
            for (const selectedMinuteString of arrayValue) {
                const [type, minuteStr] = selectedMinuteString.split(KEY_SEPARATOR);
                const minute = +minuteStr;
                if (existingMinutes[type] !== minute) {
                    newMinutes[type] = minute;
                    hadChange = true;
                }
            }
            return hadChange ? newMinutes : existingMinutes;
        });
    };

    useEffect(() => {
        onData({ therapistMinutes: selectedMinutes });
    }, [onData, selectedMinutes]);

    if (therapistTypesWithMinutes.length === 0) {
        return null;
    }

    return (
        <FormControl>
            <InputLabel>
                <T>onboarding question type time</T>
            </InputLabel>
            <Select
                multiple
                value={selectMinutesStrings}
                onChange={selectMinutes}
                label={<T>onboarding question type time</T>}
                open={open}
                onClose={handleClose}
                onOpen={handleOpen}
                renderValue={() => (
                    <SeparatedList separator="; ">
                        {therapistTypesWithMinutes.map((type) => (
                            <Fragment key={type}>
                                <T children={`onboarding question type ${type}`} suffix=" - " />
                                <T params={{ time: selectedMinutes[type] || DEFAULT_THERAPIST_TIME }}>time minutes</T>
                            </Fragment>
                        ))}
                    </SeparatedList>
                )}>
                {therapistTypesWithMinutes.map((type) => [
                    <ListSubheader>
                        <T children={`onboarding question type ${type}`} />
                    </ListSubheader>,
                    [
                        ...SUGGESTED_THERAPY_TIMES.map((time) => (
                            <MenuItem key={time} value={`${type}${KEY_SEPARATOR}${time}`}>
                                <Checkbox checked={selectedMinutes[type] === time} />
                                <ListItemText primary={<T params={{ time }}>time minutes</T>} />
                            </MenuItem>
                        )),
                    ],
                ])}
                <SelectConfirmButton
                    handleClose={handleClose}
                    listSize={therapistTypesWithMinutes.length * SUGGESTED_THERAPY_TIMES.length}
                />
            </Select>
        </FormControl>
    );
}
