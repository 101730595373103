import { Button, Paper } from "@mui/material";
import { common } from "@mui/material/colors";

import T from "../../locallization/T";

const LONG_LIST = 6;

export default function SelectConfirmButton({ handleClose, listSize }) {
    return (
        <Paper
            sx={{
                display: "flex",
                justifyContent: "flex-end",
                position: "sticky",
                mb: -1,
                p: 1,
                bottom: 0,
                background: common.white,
                zIndex: 1,
            }}
            square
            elevation={listSize >= LONG_LIST ? 3 : 0}>
            <Button variant="text" onClick={handleClose}>
                <T>dialog button confirm</T>
            </Button>
        </Paper>
    );
}
