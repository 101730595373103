import { Box, Stack, Typography } from "@mui/material";
import { ReactNode } from "react";

import NonEmptyDivider from "../common/NonEmptyDivider";
import T from "../locallization/T";
import OutputPhoto from "../outputs/basic/OutputPhoto";
import { useNoNumberText } from "../outputs/basic/useNoNumberText";
import TherapistOutputArticlesLinks from "../outputs/therapist/TherapistOutputArticlesLinks";
import TherapistOutputSocials from "../outputs/therapist/TherapistOutputSocials";
import TherapistOutputVideo from "../outputs/therapist/TherapistOutputVideo";
import TherapistSummaryOutput from "../outputs/therapist/TherapistSummaryOutput";
import TherapistTitle from "../outputs/therapist/TherapistTitle";
import { useSocials } from "../outputs/therapist/useSocials";
import { USER_INFO } from "../user/userInfo";
import HighlightTop from "./HighlightTop";
import InfoSection from "./info/InfoSection";
import TherapistInfoProfessional from "./info/TherapistInfoProfessional";

export default function TherapistPageView({
    therapistUserInfo,
    hideBack,
    showLogo,
    onBack,
    children,
}: {
    therapistUserInfo: USER_INFO;
    hideBack?: boolean;
    showLogo?: boolean;
    onBack?: () => any;
    children?: ReactNode;
}) {
    const socials = useSocials({ newUserInfo: therapistUserInfo });
    const otherInfo = useNoNumberText(therapistUserInfo?.uid, therapistUserInfo?.therapistOtherInfo);
    return (
        <Box>
            <HighlightTop onBack={onBack} hideBack={hideBack} showLogo={showLogo}>
                <OutputPhoto newUserInfo={therapistUserInfo} isTargetTherapist={true} pink={false} />
                <TherapistTitle newUserInfo={therapistUserInfo} />
            </HighlightTop>
            <Stack gap={3} pb={3} px={2}>
                <TherapistSummaryOutput
                    newUserInfo={therapistUserInfo}
                    dividers={<NonEmptyDivider sx={{ mx: -2 }} />}
                />
                <NonEmptyDivider sx={{ mx: -2 }} />
                <TherapistInfoProfessional therapistUserInfo={therapistUserInfo} />
                <NonEmptyDivider sx={{ mx: -2 }} />
                <Stack gap={4}>
                    {otherInfo && (
                        <InfoSection bigger title={<T>therapist page view other</T>}>
                            <Typography sx={{ wordBreak: "break-word" }}>{otherInfo}</Typography>
                        </InfoSection>
                    )}
                    <TherapistOutputVideo newUserInfo={therapistUserInfo} />
                    {therapistUserInfo?.therapistArticlesLinks?.length ? (
                        <InfoSection bigger title={<T>therapist page view links</T>}>
                            <TherapistOutputArticlesLinks newUserInfo={therapistUserInfo} />
                        </InfoSection>
                    ) : null}
                    {socials.length ? (
                        <InfoSection bigger title={<T>therapist page view socials</T>}>
                            <TherapistOutputSocials newUserInfo={therapistUserInfo} />
                        </InfoSection>
                    ) : null}
                </Stack>
                {children}
            </Stack>
        </Box>
    );
}
