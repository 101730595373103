import { range } from "lodash";
import { useCallback } from "react";

import { getTalkGenderRaw } from "../../common/gender/getTalkGender";
import T from "../../locallization/T";
import { USER_INFO_METHODS } from "../../user/userInfo";
import SearchInputBasicNumberedSet from "../basic/SearchInputBasicNumeredSet";
import { SEARCH_INPUT_PROPS } from "../inputs/SearchInputProps";

export const allMethods = range(1, USER_INFO_METHODS + 1);

export default function SearchInputMethods({ onData, newUserInfo }: SEARCH_INPUT_PROPS) {
    const getValueLabel = useCallback(
        (method: number) => (
            <T
                overrideGender={getTalkGenderRaw(newUserInfo?.gender, newUserInfo?.talkGender)}
                children={`method ${method}`}
            />
        ),
        [newUserInfo?.gender, newUserInfo?.talkGender]
    );

    return (
        <SearchInputBasicNumberedSet
            onData={onData}
            newUserInfo={newUserInfo}
            userInfoKey="therapistMethods"
            label={<T>search input methods</T>}
            allValues={allMethods}
            getValueLabel={getValueLabel}
        />
    );
}
