import AppSpinner from "../navigation/AppSpinner";
import { useAppContext } from "../root/AppContext";

export default function LoadingContainer({ children }) {
    const { userLoading, texts, user, userInfoLoading } = useAppContext();

    const showSpinner = userLoading || (user && userInfoLoading) || !texts;

    return showSpinner ? <AppSpinner /> : children;
}
