import { Stack } from '@mui/material';

import PageTitle from '../../common/PageTitle';
import T from '../../locallization/T';
import PageContainer from '../../navigation/PageContainer';

export default function SignInFormContainer({
    pageTitleKey,
    pagePath,
    children,
    onBack,
}: {
    pageTitleKey: string;
    pagePath: string;
    children: any;
    onBack?: any;
}) {
    return (
        <PageContainer pageTitleKey={pageTitleKey} pagePath={pagePath} showLogo hideBotNav onBack={onBack}>
            <Stack gap={3}>
                <PageTitle hasTopNav gap={3}>
                    <T>{pageTitleKey}</T>
                </PageTitle>
                {children}
            </Stack>
        </PageContainer>
    );
}
