import { PsychologyOutlined } from "@mui/icons-material";

import SeparatedList from "../../common/SeparatedList";
import T from "../../locallization/T";
import { allLangs } from "../../search/therapistInputs/SearchInputLangs";
import { THERAPIST_OUTPUT_PROPS } from "../basic/OutputProps";
import OutputRow from "../basic/OutputRow";
import { useSortedOutput } from "../basic/useSortedOutput";

export default function TherapistOutputLangs({ newUserInfo, ...props }: THERAPIST_OUTPUT_PROPS) {
    const sortedLangs = useSortedOutput(newUserInfo?.therapistLangs, allLangs);

    return sortedLangs.length ? (
        <OutputRow
            {...props}
            title={<T>search input langs</T>}
            value={
                <SeparatedList>
                    {sortedLangs.map((lang) => (
                        <T key={lang} children={`lang ${lang}`} />
                    ))}
                </SeparatedList>
            }
            Icon={PsychologyOutlined}
        />
    ) : null;
}
