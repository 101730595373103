import { LocationOn } from "@mui/icons-material";
import { Autocomplete, Box, CircularProgress, Grid, TextField, Typography } from "@mui/material";
import { debounce } from "lodash";
import { useEffect, useMemo, useState } from "react";

import T from "../locallization/T";
import { getMapsAutocompleteService, useLoadMaps } from "./useLoadMaps";

interface MainTextMatchedSubstrings {
    offset: number;
    length: number;
}
interface StructuredFormatting {
    main_text: string;
    secondary_text: string;
    main_text_matched_substrings: readonly MainTextMatchedSubstrings[];
}
export type PlaceType = {
    description: string;
    structured_formatting?: StructuredFormatting;
    place_id?: string;
};

export default function GoogleMaps({ value, setValue }) {
    const [inputValue, setInputValue] = useState("");
    const [options, setOptions] = useState<readonly PlaceType[]>([]);
    const [loading, setLoading] = useState(false);
    const { loadingScript } = useLoadMaps();

    const fetchPlaces = useMemo(
        () =>
            debounce((request: { input: string }, callback: (results?: readonly PlaceType[]) => void) => {
                getMapsAutocompleteService().getPlacePredictions(request, callback);
            }, 600),
        []
    );

    useEffect(() => {
        let active = true;

        if (loadingScript) {
            return;
        }

        if (inputValue === "") {
            setOptions(value ? [value] : []);
            return;
        }

        setLoading(true);
        fetchPlaces({ input: inputValue }, (results?: readonly PlaceType[]) => {
            if (active) {
                setLoading(false);
                let newOptions: readonly PlaceType[] = [];

                if (value) {
                    newOptions = [value];
                }

                if (results) {
                    newOptions = [...newOptions, ...results];
                }

                setOptions(newOptions);
            }
        });

        return () => {
            active = false;
        };
    }, [value, inputValue, fetchPlaces, loadingScript]);

    return (
        <Autocomplete
            getOptionLabel={(option) => (typeof option === "string" ? option : option.description)}
            filterOptions={(x) => x}
            options={options}
            autoComplete
            autoHighlight
            blurOnSelect="touch"
            includeInputInList
            filterSelectedOptions
            value={value}
            noOptionsText={
                loading ? <T>onboarding question location loading</T> : <T>onboarding question location empty</T>
            }
            onChange={(event: any, newValue: PlaceType | null) => {
                setOptions(newValue ? [newValue, ...options] : options);
                setValue(newValue);
            }}
            onInputChange={(event, newInputValue) => {
                setInputValue(newInputValue);
            }}
            renderInput={(params) => (
                <TextField
                    {...params}
                    label={<T>onboarding question location input</T>}
                    fullWidth
                    InputProps={{
                        ...params.InputProps,
                        endAdornment: (
                            <>
                                {loading ? <CircularProgress size={20} /> : null}
                                {params.InputProps.endAdornment}
                            </>
                        ),
                    }}
                />
            )}
            renderOption={(props, option) => {
                return option.structured_formatting ? (
                    <li {...props}>
                        <Grid container alignItems="center">
                            <Grid item>
                                <Box component={LocationOn} sx={{ color: "text.secondary", mr: 2 }} />
                            </Grid>
                            <Grid item xs>
                                {option.structured_formatting.main_text}
                                <Typography variant="body2" color="text.secondary">
                                    {option.structured_formatting.secondary_text}
                                </Typography>
                            </Grid>
                        </Grid>
                    </li>
                ) : null;
            }}
        />
    );
}
