import { Stack } from "@mui/material";
import { without } from "lodash";
import { useCallback } from "react";

import T from "../../locallization/T";
import SearchInputBasicChips from "../basic/SearchInputBasicChips";
import SearchInputBasicNumberedSet from "../basic/SearchInputBasicNumeredSet";
import { SEARCH_INPUT_PROPS } from "./SearchInputProps";

// Notice: Update Measure files if changing anything here.
export const OTHER_THERAPY_TYPES = [1, 2];
export const OTHER_TALK = [13, 14];
export const OTHER_AGES = [3, 4];
export const OTHER_GENDER = [5, 6];
export const OTHER_ACCESSIBILITY = [7];
export const OTHER_LANGUAGES = [8, 9, 10, 11, 12, 15];
export const OTHER_RELIGION = [16, 17, 18, 19, 20];
export const OTHER_CULTURE = [21, 22, 23, 24];
export const OTHER_LIFE = [25, 26, 27, 30];
export const OTHER_LGBT = [28, 29];

const ALL_OTHERS = [
    OTHER_THERAPY_TYPES,
    OTHER_TALK,
    OTHER_AGES,
    OTHER_GENDER,
    OTHER_ACCESSIBILITY,
    OTHER_LANGUAGES,
    OTHER_RELIGION,
    OTHER_CULTURE,
    OTHER_LGBT,
    OTHER_LIFE,
];

export const OTHER_TO_TITLE_MAP: { [key: number]: number } = {};

for (const otherList of ALL_OTHERS) {
    for (const other of otherList) {
        OTHER_TO_TITLE_MAP[other] = otherList[0];
    }
}

export const allOthers = [...ALL_OTHERS.flatMap((x) => x)];

export default function SearchInputOthers({ onData, newUserInfo }: SEARCH_INPUT_PROPS) {
    const getValueLabel = useCallback((other: number) => <T children={`other ${other}`} />, []);
    const getTitleLabel = useCallback((other: number) => <T children={`other title ${other}`} />, []);
    const isValueSelected = useCallback(() => true, []);
    const onValueDelete = useCallback(
        (other: number) => {
            onData({ othersList: without(newUserInfo?.othersList, other) });
        },
        [onData, newUserInfo?.othersList]
    );

    return (
        <Stack gap={1}>
            <SearchInputBasicNumberedSet
                onData={onData}
                newUserInfo={newUserInfo}
                userInfoKey="othersList"
                label={<T>metupal output other</T>}
                allValues={ALL_OTHERS}
                getValueLabel={getValueLabel}
                getTitleLabel={getTitleLabel}
                limit={1}
            />
            <SearchInputBasicChips
                allValues={newUserInfo?.othersList || []}
                getValueLabel={getValueLabel}
                isValueSelected={isValueSelected}
                onValueDelete={onValueDelete}
                forceHorizontal={false}
            />
        </Stack>
    );
}
