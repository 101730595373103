import { ref, update } from "firebase/database";

import { useDbConstant } from "../constants/useConstant";
import { getFirebaseRealtimeDb } from "../firebase/firebase";

export const ZIGMOND_TEXT_DB_KEY = `zigmond_measure`;

export function useZigmondTexts() {
    const values: { [key: string]: number } | undefined = useDbConstant<{} | undefined>(ZIGMOND_TEXT_DB_KEY, undefined);
    return { values };
}

export function saveZigmondTexts(updates: {}) {
    const instanceRef = ref(getFirebaseRealtimeDb(), ZIGMOND_TEXT_DB_KEY);

    return update(instanceRef, updates);
}
