import T from '../../locallization/T';
import SearchInputBasicTextarea from '../basic/SearchInputBasicTextarea';
import { SEARCH_INPUT_PROPS } from '../inputs/SearchInputProps';

const MAX_ADDITIONAL_STUDIES_LENGTH = 750;

export default function SearchInputAdditionalStudies({ onData, newUserInfo }: SEARCH_INPUT_PROPS) {
    return (
        <SearchInputBasicTextarea
            onData={onData}
            newUserInfo={newUserInfo}
            userInfoKey="therapistAdditionalStudies"
            label={<T>search input additional studies</T>}
            maxLength={MAX_ADDITIONAL_STUDIES_LENGTH}
        />
    );
}
