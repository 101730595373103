import { ThumbDownOutlined } from "@mui/icons-material";
import { Stack, SvgIcon, ToggleButton, ToggleButtonGroup } from "@mui/material";
import { find } from "lodash";
import { useCallback, useMemo, useState } from "react";

import PageTitle from "../common/PageTitle";
import T from "../locallization/T";
import PageContainer from "../navigation/PageContainer";
import { ROUTES } from "../navigation/Router";
import { useAppContext } from "../root/AppContext";
import { PRIVATE_USER_INFO } from "../user/userInfo";
import FavoritesMatches from "./FavoritesMatches";

export type FAVORITE_VALUE = "confirmed" | "rejected" | "blocked";
type FAVORITE = { value: FAVORITE_VALUE; fieldKey: keyof PRIVATE_USER_INFO; Icon: typeof SvgIcon };

const therapistFavorites: FAVORITE[] = [{ value: "rejected", fieldKey: "rejectedMetupalim", Icon: ThumbDownOutlined }];
const metupalFavorites: FAVORITE[] = [];

export default function History() {
    const { userInfo } = useAppContext();
    const favorites = userInfo?.isTherapist ? therapistFavorites : metupalFavorites;
    const [favoriteValue, setFavoriteValue] = useState(favorites[0].value);

    const updateFavoriteValue = useCallback(
        (event: React.MouseEvent<HTMLElement>, newFavoriteValue: FAVORITE_VALUE) => {
            if (newFavoriteValue) {
                setFavoriteValue(newFavoriteValue);
            }
        },
        []
    );

    const titleKey = `favorites title ${favoriteValue}`;
    const fieldKey = useMemo(
        () => find(favorites, (favorite) => favorite.value === favoriteValue)?.fieldKey as keyof PRIVATE_USER_INFO,
        [favorites, favoriteValue]
    );

    return (
        <PageContainer pageTitleKey={titleKey} pagePath={ROUTES.HISTORY} hideTopNav backgrounded>
            <Stack gap={2}>
                <PageTitle gap={2}>
                    <T>{titleKey}</T>
                </PageTitle>

                {favorites.length > 1 && (
                    <Stack alignItems="center">
                        <ToggleButtonGroup
                            color="primary"
                            value={favoriteValue}
                            exclusive
                            onChange={updateFavoriteValue}>
                            {favorites.map(({ value, Icon }) => (
                                <ToggleButton key={value} value={value}>
                                    <Icon sx={{ mr: 1 }} fontSize="small" />
                                    <T children={`favorites subtitle ${value}`} />
                                </ToggleButton>
                            ))}
                        </ToggleButtonGroup>
                    </Stack>
                )}

                <FavoritesMatches favoriteValue={favoriteValue} fieldKey={fieldKey} key={fieldKey} />
            </Stack>
        </PageContainer>
    );
}
