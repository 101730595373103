import { ArrowBack } from "@mui/icons-material";
import { Button, CircularProgress, Stack, Typography } from "@mui/material";
import { Container } from "@mui/system";
import { User } from "firebase/auth";
import { useSnackbar } from "notistack";
import { useCallback, useMemo, useState } from "react";

import { getTalkGender } from "../../common/gender/getTalkGender";
import PageTitle from "../../common/PageTitle";
import T from "../../locallization/T";
import PageContainer from "../../navigation/PageContainer";
import { ROUTES } from "../../navigation/Router";
import { usePageSizes } from "../../navigation/usePageSizes";
import { getUpdatesForBackOfSignAs } from "../../profile/settingButtons/ProfileLoginAs";
import { useAppContext } from "../../root/AppContext";
import SearchInputAge from "../../search/inputs/SearchInputAge";
import SearchInputGender from "../../search/inputs/SearchInputGender";
import SearchInputTalkGender from "../../search/inputs/SearchInputTalkGender";
import SearchInputFirstName, { alreadyHasFirstName } from "../../search/therapistInputs/SearchInputFirstName";
import SearchInputLastName, { alreadyHasLastName } from "../../search/therapistInputs/SearchInputLastName";
import SearchInputLicense, { alreadyHasLicense } from "../../search/therapistInputs/SearchInputLicense";
import SearchInputSpecialty, { alreadyHasSpecialty } from "../../search/therapistInputs/SearchInputSpecialty";
import { getSpacedButtonIconProps } from "../../theme/buttons";
import WithThemeIcon from "../../theme/WithThemeIcon";
import { ensureLicenseDoesntExist, ERROR_LICENSE_EXISTS } from "../../user/userByLicense";
import { USER_INFO } from "../../user/userInfo";
import { updateUserInfo } from "../../user/userUpdates";
import { alreadyHasGender } from "../questions/OnboardingQuestionGender";

export default function TherapistOnboardingStart() {
    const { user, userInfo, initialDeleteUserInfo } = useAppContext();
    const [newData, setNewData] = useState<USER_INFO>({});
    const [loading, setLoading] = useState(false);
    const { enqueueSnackbar } = useSnackbar();
    const { contentSx } = usePageSizes();

    const newUserInfo = useMemo(() => ({ ...userInfo, ...newData }), [userInfo, newData]);

    const hasAllFields = useMemo(() => {
        return (
            alreadyHasFirstName(newUserInfo) &&
            alreadyHasLastName(newUserInfo) &&
            alreadyHasGender(newUserInfo) &&
            alreadyHasLicense(newUserInfo) &&
            alreadyHasSpecialty(newUserInfo)
        );
    }, [newUserInfo]);

    const onData = useCallback((updates: USER_INFO) => {
        setNewData((data) => ({ ...data, ...updates }));
    }, []);

    const continueClicked = useCallback(() => {
        setLoading(true);
        (newData.license ? ensureLicenseDoesntExist(user as User, newData.license as string) : Promise.resolve())
            .then(() => updateUserInfo((user as User).uid, userInfo, { ...newData, hasTherapistBasicInfo: true }))
            .then(() => {
                setLoading(false);
            })
            .catch((error) => {
                setLoading(false);
                console.error(error);
                let message = <T>onboard therapist start license error</T>;
                if (error.message.includes(ERROR_LICENSE_EXISTS)) {
                    message = <T>onboard therapist start license exists error</T>;
                }
                enqueueSnackbar(message, { variant: "warning" });
            });
    }, [user, userInfo, newData, enqueueSnackbar]);

    const onBack = useCallback(() => {
        if (!user || loading) {
            return;
        }
        setLoading(true);
        let promise;
        if (userInfo?.accountHasMetupal || userInfo?.accountHasTherapist) {
            promise = updateUserInfo(user.uid, userInfo, getUpdatesForBackOfSignAs(userInfo));
        } else {
            promise = initialDeleteUserInfo();
        }

        promise.catch((error) => {
            setLoading(false);
            console.error(error);
            enqueueSnackbar(<T params={{ code: 165 }}>general error</T>, { variant: "warning" });
        });
    }, [user, userInfo, initialDeleteUserInfo, enqueueSnackbar, loading]);

    return (
        <PageContainer
            pageTitleKey="title therapist onboarding start"
            pagePath={ROUTES.THERAPIST_ONBOARDING_START}
            onBack={onBack}
            showLogo
            BotNavComponent={
                <Container>
                    <Stack textAlign="center" gap={3} pb={5} sx={contentSx}>
                        {hasAllFields && (
                            <Typography>
                                <T overrideGender={getTalkGender(newUserInfo)}>onboard therapist start caption</T>
                            </Typography>
                        )}
                        <Button
                            disabled={!hasAllFields || loading}
                            variant="contained"
                            size="huge"
                            onClick={continueClicked}
                            {...getSpacedButtonIconProps()}
                            endIcon={loading ? <CircularProgress size={24} /> : <WithThemeIcon Icon={ArrowBack} />}>
                            <T overrideGender={getTalkGender(newUserInfo)}>onboarding questions continue</T>
                        </Button>
                    </Stack>
                </Container>
            }>
            <Stack gap={2}>
                <PageTitle hasTopNav gap={2}>
                    <T overrideGender={getTalkGender(newUserInfo)}>onboard therapist start title</T>
                </PageTitle>
                <SearchInputFirstName newUserInfo={newUserInfo} onData={onData} />
                <SearchInputLastName newUserInfo={newUserInfo} onData={onData} />
                <SearchInputGender newUserInfo={newUserInfo} onData={onData} />
                <SearchInputTalkGender newUserInfo={newUserInfo} onData={onData} />
                <SearchInputAge newUserInfo={newUserInfo} onData={onData} />
                <SearchInputLicense newUserInfo={newUserInfo} onData={onData} />
                <SearchInputSpecialty newUserInfo={newUserInfo} onData={onData} />
                {/* Update hasAllFields if adding more inputs~! */}
            </Stack>
        </PageContainer>
    );
}
