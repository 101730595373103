import T from "../../locallization/T";
import { metupalQuestionIcons, metupalQuestionIconsColorful } from "../../onboarding/OnboardingQuestionsMetupal";
import { METUPAL_OUTPUT_PROPS } from "../basic/OutputProps";
import OutputRow from "../basic/OutputRow";

export default function MetupalOutputCost({ newUserInfo, colorful, ...props }: METUPAL_OUTPUT_PROPS) {
    return (
        <OutputRow
            {...props}
            title={<T>metupal output cost</T>}
            value={
                <T params={{ cost: newUserInfo?.cost }}>onboarding question cost ils</T> || (
                    <T>output was not mentioned</T>
                )
            }
            Icon={colorful ? metupalQuestionIconsColorful.cost : metupalQuestionIcons.cost}
        />
    );
}
