import { ArrowForward, DeleteForeverOutlined } from "@mui/icons-material";
import { CircularProgress, Stack, Typography } from "@mui/material";
import { Dispatch, SetStateAction, useCallback } from "react";

import { ActionButtonPrimary, ActionButtonSecondary } from "../common/ActionButtons";
import AppDialog from "../common/dialog/AppDialog";
import T from "../locallization/T";
import { boldStyles } from "../theme/theme";
import WithThemeIcon from "../theme/WithThemeIcon";
import { useDeclineMetupal } from "./useDeclineMetupal";

export default function MatchRejectDialog({
    metupalUid,
    showRejectDialog,
    setShowRejectDialog,
    loading,
    setLoading,
}: {
    metupalUid: string;
    showRejectDialog: boolean;
    setShowRejectDialog: Dispatch<SetStateAction<boolean>>;
    loading: boolean;
    setLoading: Dispatch<SetStateAction<boolean>>;
}) {
    const hideRejectDialog = useCallback(() => {
        if (loading) {
            return;
        }
        setShowRejectDialog(false);
    }, [loading, setShowRejectDialog]);

    const onDecline = useCallback(() => {
        setShowRejectDialog(false);
    }, [setShowRejectDialog]);

    const { declineMetupal } = useDeclineMetupal({ metupalUid, setLoading, onDecline });

    return (
        <AppDialog
            open={showRejectDialog}
            setOpen={setShowRejectDialog}
            actions={
                <>
                    <ActionButtonSecondary
                        onClick={hideRejectDialog}
                        disabled={loading}
                        startIcon={<WithThemeIcon Icon={ArrowForward} />}>
                        <T>metupal reject dialog button no</T>
                    </ActionButtonSecondary>
                    <ActionButtonPrimary
                        onClick={declineMetupal}
                        disabled={loading}
                        color="error"
                        startIcon={loading ? <CircularProgress size={24} /> : <DeleteForeverOutlined />}>
                        <T>metupal reject dialog button ok</T>
                    </ActionButtonPrimary>
                </>
            }>
            <Stack gap={1}>
                <Typography variant="h3" sx={{ ...boldStyles }}>
                    <T>metupal reject dialog title</T>
                </Typography>
                <Typography variant="h4">
                    <T>metupal reject dialog subtitle</T>
                </Typography>
            </Stack>
        </AppDialog>
    );
}
