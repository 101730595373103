import { useEffect, useState } from "react";

import { useSmallScreen } from "./useSmallScreen";

export function useHideHeaderOnInput() {
    const isSmallScreen = useSmallScreen();

    const [inputFocused, setInputFocused] = useState(false);
    useEffect(() => {
        if (!isSmallScreen) {
            setInputFocused(false);
            return;
        }
        function listener() {
            const isInputFocused =
                !!document.activeElement &&
                ((document.activeElement instanceof HTMLInputElement &&
                    ["email", "number", "password", "search", "tel", "text"].includes(document.activeElement.type)) ||
                    document.activeElement instanceof HTMLTextAreaElement);
            setInputFocused(isInputFocused);
        }

        document.addEventListener("focusin", listener);
        document.addEventListener("focusout", listener);

        return () => {
            document.removeEventListener("focusin", listener);
            document.removeEventListener("focusout", listener);
        };
    }, [isSmallScreen]);

    return inputFocused;
}
