import { isEqual } from 'lodash';
import { SetStateAction, useCallback } from 'react';

import { useAppContext } from '../root/AppContext';
import { USER_INFO } from '../user/userInfo';

export default function useUpdateUserInfo(setUpdatedData: (value: SetStateAction<USER_INFO>) => void) {
    const { userInfo } = useAppContext();
    return useCallback(
        (updates: USER_INFO) => {
            setUpdatedData((updatedData) => {
                const mergedUpdates = { ...updatedData, ...updates };
                for (let key in updates) {
                    if (
                        userInfo &&
                        (isEqual(userInfo[key], updates[key]) ||
                            (!userInfo[key] && !updates[key] && updates[key] !== false))
                    ) {
                        delete mergedUpdates[key];
                    }
                }
                return mergedUpdates;
            });
        },
        [userInfo, setUpdatedData]
    );
}
