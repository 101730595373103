import { FavoriteBorderOutlined } from "@mui/icons-material";
import { Pagination, Stack, Typography } from "@mui/material";
import { useCallback, useMemo, useState } from "react";

import T from "../locallization/T";
import { useLoadUserInfos } from "../matches/userInfosService";
import AppSpinner from "../navigation/AppSpinner";
import { useAppContext } from "../root/AppContext";
import { PRIVATE_USER_INFO } from "../user/userInfo";
import FavoriteCard from "./FavoriteCard";
import { FAVORITE_VALUE } from "./History";
import { useSortedFavorite } from "./useSortedFavorite";

const MATCHES_PER_PAGE = 5;

export default function FavoritesMatches({
    favoriteValue,
    fieldKey,
}: {
    favoriteValue: FAVORITE_VALUE;
    fieldKey: keyof PRIVATE_USER_INFO;
}) {
    const { userInfo } = useAppContext();

    const [page, setPage] = useState(1);
    const updatePage = useCallback((event: React.ChangeEvent<unknown>, value: number) => {
        setPage(value);
    }, []);

    const favoritesObject = (userInfo || {})[fieldKey];
    const sortedUserIds = useSortedFavorite(favoritesObject as {});
    const userIdsToUse = useMemo(() => {
        const start = (page - 1) * MATCHES_PER_PAGE;
        const end = start + MATCHES_PER_PAGE;
        return sortedUserIds.slice(start, end);
    }, [sortedUserIds, page]);

    const { loading, userInfos } = useLoadUserInfos(userIdsToUse, !userInfo?.isTherapist);

    const totalPages = Math.ceil(sortedUserIds.length / MATCHES_PER_PAGE);

    return (
        <Stack gap={3}>
            {loading ? (
                <AppSpinner />
            ) : userInfos.length ? (
                <>
                    {userInfos.map((targetUserInfo, index) => (
                        <FavoriteCard
                            favoriteValue={favoriteValue}
                            targetUserInfo={targetUserInfo}
                            date={(favoritesObject || {})[targetUserInfo?.uid || ""]}
                            key={(targetUserInfo?.uid || "") + index}
                        />
                    ))}
                    {totalPages > 1 && (
                        <Pagination count={totalPages} page={page} onChange={updatePage} sx={{ alignSelf: "center" }} />
                    )}
                </>
            ) : (
                <Typography>
                    <T
                        params={{ icon: <FavoriteBorderOutlined fontSize="inherit" /> }}
                        children={`favorites empty ${favoriteValue}`}
                    />
                </Typography>
            )}
        </Stack>
    );
}
