import { CasesOutlined } from "@mui/icons-material";
import { Stack } from "@mui/material";
import { useCallback, useMemo } from "react";

import SeparatedList from "../../common/SeparatedList";
import T from "../../locallization/T";
import { useAppContext } from "../../root/AppContext";
import SearchInputBasicChips from "../../search/basic/SearchInputBasicChips";
import { useSortedReasons } from "../../search/inputs/useSortedReasons";
import { THERAPIST_OUTPUT_PROPS } from "../basic/OutputProps";
import OutputRow from "../basic/OutputRow";

export default function TherapistOutputReasons({ newUserInfo, ...props }: THERAPIST_OUTPUT_PROPS) {
    const { userInfo } = useAppContext();
    const { sortedReasons } = useSortedReasons(newUserInfo?.therapistReasonsList || []);

    const outputReasons = useMemo(() => {
        if (!props.short || !userInfo?.uid || userInfo?.uid === newUserInfo?.uid) {
            return sortedReasons;
        }
        const myReasonsSet = new Set(userInfo?.reasonsList || []);
        const mergedReasons: number[] = [];
        for (const reason of sortedReasons) {
            if (myReasonsSet.has(reason)) {
                mergedReasons.push(reason);
            }
        }
        return mergedReasons;
    }, [userInfo?.reasonsList, sortedReasons, userInfo?.uid, newUserInfo?.uid, props.short]);
    const getValueLabel = useCallback((reason: number) => <T children={`reason ${reason}`} />, []);

    return outputReasons.length ? (
        <Stack gap={1}>
            <OutputRow
                {...props}
                // not sure if this is ideal, but it's fine for now
                short
                title={<T>therapist output reasons</T>}
                value={
                    props.short ? (
                        <SeparatedList>
                            {outputReasons.map((reason) => (
                                <T key={reason} children={`reason ${reason}`} />
                            ))}
                        </SeparatedList>
                    ) : undefined
                }
                Icon={CasesOutlined}
            />
            {props.short ? null : (
                <SearchInputBasicChips
                    allValues={outputReasons}
                    getValueLabel={getValueLabel}
                    forceHorizontal={false}
                />
            )}
        </Stack>
    ) : null;
}
