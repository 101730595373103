import { ArrowForward } from "@mui/icons-material";
import { Box, Container, IconButton, Stack, Typography } from "@mui/material";
import { useCallback } from "react";
import { useNavigate } from "react-router-dom";

import LogoTitlePng from "../login/png/logoTitle.png";
import WithThemeIcon from "../theme/WithThemeIcon";

export type TopNavProps = {
    hideBack?: boolean;
    showLogo?: boolean;
    title?: React.ReactNode;
    onBack?: () => void;
    actionIcon?: React.ReactNode;
};

export default function TopNav({
    hideBack = false,
    showLogo = false,
    title = null,
    onBack = undefined,
    actionIcon = null,
}: TopNavProps) {
    const navigate = useNavigate();

    const goBack = useCallback(() => {
        onBack ? onBack() : navigate(-1);
    }, [onBack, navigate]);
    return (
        <Container sx={{ textAlign: "start" }}>
            <Stack flexDirection="row" alignItems="center" justifyContent="space-between" height={40}>
                {!hideBack ? (
                    <IconButton onClick={goBack} edge="start" color="inherit">
                        <WithThemeIcon Icon={ArrowForward} />
                    </IconButton>
                ) : (
                    <Box width={28} />
                )}
                {showLogo ? (
                    <img src={LogoTitlePng} alt="Zigmond" style={{ height: 24 }} />
                ) : title ? (
                    <Typography
                        variant="h3"
                        sx={{
                            whiteSpace: "nowrap",
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                            px: 1,
                        }}>
                        {title}
                    </Typography>
                ) : (
                    <div />
                )}
                {actionIcon || <Box width={28} />}
            </Stack>
        </Container>
    );
}
