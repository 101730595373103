import { Stack, Typography } from "@mui/material";

import { boldStyles } from "../../theme/theme";
import OutputPhoto from "../basic/OutputPhoto";
import { PRIVATE_OUTPUT_PROPS } from "../basic/OutputProps";
import { useProfileEmail } from "../basic/useProfileEmail";
import { useProfileName } from "../basic/useProfileName";

export default function OutputGeneral({ newUserInfo }: PRIVATE_OUTPUT_PROPS) {
    const name = useProfileName({
        targetUserInfo: newUserInfo,
        isTargetTherapist: newUserInfo?.isTherapist,
    });

    const email = useProfileEmail({ newUserInfo });

    return (
        <Stack flexDirection="row" gap={2}>
            <OutputPhoto newUserInfo={newUserInfo} isTargetTherapist={newUserInfo?.isTherapist} size={64} />
            <Stack mt={1} mb={1} justifyContent="space-around">
                <Typography variant="h3" sx={{ ...boldStyles }}>
                    {name}
                </Typography>
                {email && <Typography variant="h4">{email}</Typography>}
            </Stack>
        </Stack>
    );
}
