import { useDbConstant } from "../constants/useConstant";
import { DEFAULT_LOCALE } from "./useLocale";

export const PAGES_TEXT_DB_KEY = `pages_locale`;

export function usePageText(page: string, { skip = false } = {}) {
    const text: string | undefined = useDbConstant<string | undefined>(
        `${PAGES_TEXT_DB_KEY}/${DEFAULT_LOCALE}/${page}`,
        undefined,
        { skip }
    );

    return { text, locale: DEFAULT_LOCALE };
}

export function usePages() {
    const pages: { [key: string]: string } | undefined = useDbConstant<{} | undefined>(
        `${PAGES_TEXT_DB_KEY}/${DEFAULT_LOCALE}`,
        undefined
    );
    return { pages, locale: DEFAULT_LOCALE };
}
