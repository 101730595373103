import { Card, CardContent, CardProps, Stack, Typography } from "@mui/material";
import { ReactNode } from "react";

import { boldStyles } from "../theme/theme";

export default function StatCard({
    prompt,
    value = 0,
    sx,
    ...props
}: { prompt: ReactNode; value?: number } & CardProps) {
    return (
        <Card sx={{ borderRadius: 4, ...sx }} {...props}>
            <CardContent
                component={Stack}
                gap={2}
                alignItems="center"
                textAlign="center"
                sx={{ px: 1 }}
                height="100%"
                justifyContent="space-between">
                <Typography variant="h4">{prompt}</Typography>
                <Typography variant="h2" sx={{ ...boldStyles }}>
                    {value}
                </Typography>
            </CardContent>
        </Card>
    );
}
