import { MessageOutlined } from "@mui/icons-material";
import { Alert, Stack } from "@mui/material";
import { SxProps, Theme } from "@mui/material/styles";

import T from "../../locallization/T";

export default function NewMessageIntro({
    hasMessages = false,
    sx = {},
}: {
    hasMessages?: boolean;
    sx?: SxProps<Theme>;
}) {
    return (
        <Stack sx={sx}>
            <Alert
                severity="info"
                variant="outlined"
                icon={<MessageOutlined color="primary" />}
                sx={{ whiteSpace: "pre-line" }}>
                {hasMessages ? <T>messages intro</T> : <T>messages intro empty</T>}
            </Alert>
        </Stack>
    );
}
