import {
    ChatBubbleOutlineOutlined,
    HomeOutlined,
    ManageAccountsOutlined,
    PsychologyOutlined,
    RoomPreferencesOutlined,
    SearchOutlined,
} from "@mui/icons-material";
import { Badge, BottomNavigation, BottomNavigationAction, Paper } from "@mui/material";
import { SetStateAction, useCallback, useState } from "react";

import T from "../locallization/T";
import { useUnreadCounts } from "../notifications/useUnreadCounts";
import { useAppContext } from "../root/AppContext";
import { ROUTES } from "./Router";

export default function BottomNav() {
    const { adminProps, userInfo } = useAppContext();
    const [navValue, setNavValue] = useState(window.location.pathname);
    const unreadChatMessages = useUnreadCounts(userInfo?.messagesCounts);
    let unreadMatches = useUnreadCounts(userInfo?.matchesCounts);
    if (userInfo?.isTherapist && !userInfo.therapistEnableSearch) {
        unreadMatches = 0;
    } else if (!userInfo?.isTherapist && userInfo?.noMatchesIndicator) {
        unreadMatches = 1;
    }

    const onChange = useCallback((event: any, newValue: SetStateAction<string>) => {
        setNavValue(newValue);
    }, []);

    if (!userInfo) {
        return null;
    }

    return (
        <Paper elevation={3} square sx={{ zIndex: 1 }}>
            <BottomNavigation value={navValue} onChange={onChange} sx={{ maxWidth: "100%" }} showLabels>
                <BottomNavigationAction
                    href={ROUTES.PROFILE}
                    value={ROUTES.PROFILE}
                    label={<T>footer profile</T>}
                    icon={<ManageAccountsOutlined />}
                />
                <BottomNavigationAction
                    href={ROUTES.CHAT}
                    value={ROUTES.CHAT}
                    label={<T>footer chat</T>}
                    icon={
                        <Badge badgeContent={unreadChatMessages} color="primary">
                            <ChatBubbleOutlineOutlined />
                        </Badge>
                    }
                />
                {userInfo.isTherapist ? (
                    <BottomNavigationAction
                        href={ROUTES.THERAPIST_PAGE}
                        value={ROUTES.THERAPIST_PAGE}
                        label={<T>footer therapist page</T>}
                        icon={<PsychologyOutlined />}
                    />
                ) : (
                    <BottomNavigationAction
                        href={ROUTES.SEARCH}
                        value={ROUTES.SEARCH}
                        label={<T>footer search</T>}
                        icon={<SearchOutlined />}
                    />
                )}
                <BottomNavigationAction
                    href={ROUTES.HOME}
                    value={ROUTES.HOME}
                    label={<T>footer home</T>}
                    icon={
                        <Badge badgeContent={unreadMatches} color="primary">
                            <HomeOutlined />
                        </Badge>
                    }
                />
                {adminProps?.admin ? (
                    <BottomNavigationAction
                        href={ROUTES.SECRET}
                        value={ROUTES.SECRET}
                        label={"~_~"}
                        icon={<RoomPreferencesOutlined color="error" />}
                    />
                ) : null}
            </BottomNavigation>
        </Paper>
    );
}
