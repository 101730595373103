import { Stack } from "@mui/material";
import { useEffect } from "react";

import { useSortedFavorite } from "../history/useSortedFavorite";
import T from "../locallization/T";
import MatchedList from "./MatchedList";
import { useCheckMetupalMet } from "./useCheckMetupalMet";
import { useReadyMatches } from "./useReadyMatches";
import { useSortedMatches } from "./useSortedMatches";

export default function Matches({ setMatchesCount }: { setMatchesCount: (count: number) => void }) {
    const readyMatches = useReadyMatches();
    const checkMet = useCheckMetupalMet();
    const sortedMatchIds = useSortedMatches(readyMatches);
    const sortedCheckMet = useSortedFavorite(checkMet);

    useEffect(() => {
        setMatchesCount(sortedMatchIds.length);
    }, [setMatchesCount, sortedMatchIds.length]);

    return (
        <Stack gap={3}>
            <MatchedList
                initialMatchesCount={1}
                matchesPerPage={3}
                sortedUserIds={sortedCheckMet}
                isTargetTherapist
                title={<T>home metupal title check met</T>}
            />
            <MatchedList
                initialMatchesCount={5}
                matchesPerPage={5}
                sortedUserIds={sortedMatchIds}
                isTargetTherapist
                title={sortedCheckMet.length > 0 ? <T>home metupal title matches</T> : undefined}
            />
        </Stack>
    );
}
