import { ClearOutlined, HearingOutlined, RecordVoiceOverOutlined } from "@mui/icons-material";
import { SvgIcon } from "@mui/material";

import T from "../../locallization/T";
import { USER_INFO_TALK } from "../../user/userInfo";
import SearchInputBasicSelect from "../basic/SearchInputBasicSelect";
import { SEARCH_INPUT_PROPS } from "../inputs/SearchInputProps";

export const userInfoTalks: { value: USER_INFO_TALK; textKey: string; Icon: typeof SvgIcon }[] = [
    { value: "hard", textKey: "talk value hard", Icon: ClearOutlined },
    { value: "talk", textKey: "other therapist 13", Icon: RecordVoiceOverOutlined },
    { value: "listen", textKey: "other therapist 14", Icon: HearingOutlined },
];

export default function SearchInputTherapistTalk({ onData, newUserInfo }: SEARCH_INPUT_PROPS) {
    return (
        <SearchInputBasicSelect
            onData={onData}
            newUserInfo={newUserInfo}
            userInfoKey="therapistTalk"
            label={<T>other title therapist 13</T>}
            allValues={userInfoTalks}
            helperText={<T>search input therapist talk subtext</T>}
        />
    );
}
