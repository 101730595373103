import { Fragment, useMemo } from "react";

import { getTalkGenderRaw } from "../common/gender/getTalkGender";
import { UNRELEASED_FEATURE_TEXT_KEYS, useUnreleasedFeature } from "../flags/useUnreleasedFeature";
import { getStorageGender, getStorageIsTherapist } from "../onboarding/onboardingStorage";
import { useAppContext } from "../root/AppContext";
import { USER_INFO_GENDER } from "../user/userInfo";

type BASE_T_PROPS = {
    params?: {};
    prefix?: string;
    suffix?: string;
    overrideGender?: USER_INFO_GENDER;
    overrideTherapist?: boolean;
};

export default function T({ children, ...props }: BASE_T_PROPS & { children?: string }) {
    const textParts = useT({ textKey: children, ...props });

    return (
        <>
            {textParts.map((text, index) => (
                <Fragment key={index}>{text}</Fragment>
            ))}
        </>
    );
}

export function useT({
    textKey,
    params = {},
    prefix,
    suffix,
    overrideGender,
    overrideTherapist,
}: BASE_T_PROPS & { textKey?: string }) {
    const { texts = {}, userInfo } = useAppContext();
    const showKeys = !!useUnreleasedFeature(UNRELEASED_FEATURE_TEXT_KEYS);

    return useMemo(
        () =>
            getT({
                textKey,
                params,
                prefix,
                suffix,
                overrideGender,
                overrideTherapist,
                texts,
                gender: getTalkGenderRaw(userInfo?.gender, userInfo?.talkGender),
                isTherapist: userInfo?.isTherapist,
                showKeys,
            }),
        [
            textKey,
            params,
            prefix,
            suffix,
            overrideGender,
            overrideTherapist,
            texts,
            userInfo?.gender,
            userInfo?.talkGender,
            userInfo?.isTherapist,
            showKeys,
        ]
    );
}

export function useTs({
    textKeys,
    params = {},
    prefix,
    suffix,
    overrideGender,
    overrideTherapist,
}: BASE_T_PROPS & { textKeys: string[] }) {
    const { texts = {}, userInfo } = useAppContext();
    const showKeys = !!useUnreleasedFeature(UNRELEASED_FEATURE_TEXT_KEYS);

    return useMemo(
        () =>
            textKeys.map((textKey) =>
                getT({
                    textKey,
                    params,
                    prefix,
                    suffix,
                    overrideGender,
                    overrideTherapist,
                    texts,
                    gender: getTalkGenderRaw(userInfo?.gender, userInfo?.talkGender),
                    isTherapist: userInfo?.isTherapist,
                    showKeys,
                })
            ),
        [
            textKeys,
            params,
            prefix,
            suffix,
            overrideGender,
            overrideTherapist,
            texts,
            userInfo?.gender,
            userInfo?.talkGender,
            userInfo?.isTherapist,
            showKeys,
        ]
    );
}

export function getT({
    textKey = "",
    params = {},
    prefix,
    suffix,
    overrideGender,
    overrideTherapist,
    texts,
    gender,
    isTherapist,
    showKeys,
}: BASE_T_PROPS & {
    textKey?: string;
    texts: {};
    gender?: USER_INFO_GENDER;
    isTherapist?: boolean;
    showKeys?: boolean;
}): string[] {
    if (!textKey) {
        return [];
    }
    const genderToUse = overrideGender || gender || getStorageGender();
    const isFemale = genderToUse === "F";
    const therapistToUse = overrideTherapist || isTherapist || getStorageIsTherapist();

    if (params["count"] > 1 && texts[`${textKey} P`]) {
        textKey = `${textKey} P`;
    }
    if (isFemale && texts[`${textKey} F`]) {
        textKey = `${textKey} F`;
    }
    if (therapistToUse && texts[`${textKey} T`]) {
        textKey = `${textKey} T`;
    }

    let textValue = showKeys ? textKey : texts[textKey] || `MISSING TEXT: ${textKey}`;
    const textParts = [textValue];

    for (let paramKey in params) {
        for (let i = 0; i < textParts.length; i++) {
            const textPart = textParts[i];
            const param = `{${paramKey}}`;
            if (typeof textPart === "string" && textPart.includes(param)) {
                const splitParts = textPart.split(param);
                const jointParts: any[] = [];
                for (let j = 0; j < splitParts.length; j++) {
                    jointParts.push(splitParts[j]);
                    if (j < splitParts.length - 1) {
                        jointParts.push(params[paramKey]);
                    }
                }
                textParts.splice(i, 1, ...jointParts);
            }
        }
    }
    if (prefix) {
        textParts.unshift(prefix);
    }
    if (suffix) {
        textParts.push(suffix);
    }

    return textParts;
}
