import { useEffect, useState } from "react";

import { useStaging } from "../firebase/firebase";
import { MAPS_API_LOCALES } from "../locallization/useLocale";
import { useTexts } from "../locallization/useTexts";

const GOOGLE_MAPS_API_KEY = useStaging
    ? "AIzaSyCYlnFccRwwpQEZqIoai0hO-OgfWIe6zJs"
    : "AIzaSyCnGahN2WyqjfUfMfbRTIEptzf7JX8anRI";

// using globals is sometimes ok :]
let loadingGlobal = false;

let autocompleteService = null;
let geocodeService = null;

export function useLoadMaps() {
    const [loadingScript, setLoadingScript] = useState(true);
    const [tryAgainIndex, setTryAgainIndex] = useState(0);

    const { locale } = useTexts();

    useEffect(() => {
        let timer: number;

        if ((window as any).google) {
            setLoadingScript(false);
        } else {
            if (!loadingGlobal) {
                loadingGlobal = true;
                loadScript(
                    `https://maps.googleapis.com/maps/api/js?key=${GOOGLE_MAPS_API_KEY}&libraries=places&language=${MAPS_API_LOCALES[locale]}&callback=_.noop`,
                    document.querySelector("head"),
                    "google-maps"
                );
            }
            timer = setTimeout(() => setTryAgainIndex((index) => index + 1), 500) as unknown as number;
            return () => {
                clearTimeout(timer);
            };
        }
    }, [locale, tryAgainIndex]);

    return { loadingScript };
}

function loadScript(src: string, position: HTMLElement | null, id: string) {
    if (!position) {
        return;
    }

    const script = document.createElement("script");
    script.setAttribute("async", "");
    script.setAttribute("id", id);
    script.src = src;
    position.appendChild(script);
}

export function getMapsAutocompleteService() {
    if (!autocompleteService) {
        autocompleteService = new (window as any).google.maps.places.AutocompleteService();
    }
    return autocompleteService as any;
}
export function getMapsGeocodeService() {
    if (!geocodeService) {
        geocodeService = new (window as any).google.maps.Geocoder();
    }
    return geocodeService as any;
}
