import { ref, update } from "firebase/database";

import { getFirebaseRealtimeDb } from "../firebase/firebase";
import { PAGES_TEXT_DB_KEY } from "./usePageTexts";

export function saveLocalization(updates: {}, locale: string) {
    const instanceRef = ref(getFirebaseRealtimeDb(), `locale/${locale}`);

    return update(instanceRef, updates);
}

export function savePagesLocalization(updates: {}, locale: string) {
    const instanceRef = ref(getFirebaseRealtimeDb(), `${PAGES_TEXT_DB_KEY}/${locale}`);

    return update(instanceRef, updates);
}
