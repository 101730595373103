import T from "../../locallization/T";
import { userInfoGenders } from "../../onboarding/questions/OnboardingQuestionGender";
import SearchInputBasicSelect from "../basic/SearchInputBasicSelect";
import { SEARCH_INPUT_PROPS } from "./SearchInputProps";

export default function SearchInputGender({ onData, newUserInfo }: SEARCH_INPUT_PROPS) {
    return (
        <SearchInputBasicSelect
            onData={onData}
            newUserInfo={newUserInfo}
            userInfoKey="gender"
            label={<T>search input gender sex</T>}
            allValues={userInfoGenders}
        />
    );
}
