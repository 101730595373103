import { useMemo } from "react";

import { useAppContext } from "../root/AppContext";
import { USER_INFO } from "../user/userInfo";
import { useIsAppWebview } from "../webview/useIsWebviewApp";

export function usePaymentDue({
    targetUserInfo,
    prepay = false,
}: { targetUserInfo?: USER_INFO | null; prepay?: boolean } = {}) {
    const { userInfo } = useAppContext();
    targetUserInfo = targetUserInfo || userInfo;
    const isAppWebview = useIsAppWebview();

    return useMemo(() => {
        const paymentDue = {};
        if (isAppWebview) {
            return paymentDue;
        }
        const currentDate = Date.now();
        for (let uid in targetUserInfo?.paymentDue || {}) {
            const paymentDate = (targetUserInfo?.paymentDue || {})[uid] || 0;
            // only show payments of today and behind.
            if (paymentDate <= currentDate || prepay) {
                paymentDue[uid] = paymentDate;
            }
        }
        return paymentDue;
    }, [targetUserInfo?.paymentDue, isAppWebview, prepay]);
}
