import { MoreVertOutlined } from "@mui/icons-material";
import { Box, BoxProps, IconButton, Menu } from "@mui/material";
import { MouseEvent, ReactNode, useCallback, useState } from "react";

export default function MoreButton({ children, ...props }: { children: ReactNode } & BoxProps) {
    const [actionsAnchorEl, setActionsAnchorEl] = useState<null | HTMLElement>(null);

    const handleShowActions = useCallback((event: MouseEvent<HTMLElement>) => {
        event.stopPropagation();
        setActionsAnchorEl(event.currentTarget);
    }, []);
    const handleHideActions = useCallback((event: any) => {
        event.stopPropagation();
        setActionsAnchorEl(null);
    }, []);
    return (
        <Box {...props}>
            <IconButton color="primary" onClick={handleShowActions}>
                <MoreVertOutlined />
            </IconButton>
            <Menu anchorEl={actionsAnchorEl} open={Boolean(actionsAnchorEl)} onClose={handleHideActions}>
                {children}
            </Menu>
        </Box>
    );
}
