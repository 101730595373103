import { useCallback } from "react";

import T from "../../locallization/T";
import { USER_INFO } from "../../user/userInfo";
import SearchInputBasicText from "../basic/SearchInputBasicText";
import { SEARCH_INPUT_PROPS } from "../inputs/SearchInputProps";

const MAX_LICENSE_LENGTH = 16;
const DEFAULT_LICENSE_VALUE = "27-";

export function alreadyHasLicense(userInfo: USER_INFO) {
    return !!userInfo.license;
}

export default function SearchInputLicense({ onData, newUserInfo, isDisabled }: SEARCH_INPUT_PROPS) {
    const prependDefaultIfMissing = useCallback(() => {
        if (newUserInfo?.license && !newUserInfo.license.startsWith(DEFAULT_LICENSE_VALUE)) {
            onData({
                license: `${DEFAULT_LICENSE_VALUE}${newUserInfo.license.trim()}`,
            });
        }
    }, [onData, newUserInfo?.license]);

    return (
        <SearchInputBasicText
            label={<T>search input license</T>}
            maxLength={MAX_LICENSE_LENGTH}
            userInfoKey="license"
            defaultValue={DEFAULT_LICENSE_VALUE}
            onData={onData}
            newUserInfo={newUserInfo}
            disabled={isDisabled}
            onBlur={prependDefaultIfMissing}
        />
    );
}
