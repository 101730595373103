import { onDisconnect, onValue, ref, set } from "firebase/database";
import IdleTracker from "idle-tracker";
import { useEffect } from "react";

import { getFirebaseRealtimeDb } from "../firebase/firebase";
import { USER_INFO } from "./userInfo";

const AFK_DURATION = 60000 * 0.5; // half a minute

export function useOnline({ userInfo }: { userInfo?: USER_INFO | null }) {
    useEffect(() => {
        if (userInfo?.uid) {
            const userConnectionRef = ref(getFirebaseRealtimeDb(), `connected/${userInfo.uid}`);

            const isConnectedRef = ref(getFirebaseRealtimeDb(), ".info/connected");
            let connected = false;

            let isAfk = document.hidden;

            const toggleAfk = (afk: boolean, fromTimer?: boolean) => {
                isAfk = afk;
                if (connected) {
                    set(userConnectionRef, !isAfk);
                    if (!isAfk && !fromTimer) {
                        // we might have just come from a different tab and that one marks us as not connected in a bit.
                        setTimeout(() => {
                            // still here after 2 seconds, make sure server knows it.
                            if (connected && !isAfk) {
                                toggleAfk(isAfk, true);
                            }
                        }, 2000);
                    }
                }
            };

            const idleTracker = new IdleTracker({
                timeout: AFK_DURATION,
                onIdleCallback: ({ idle }) => {
                    toggleAfk(idle);
                },
            });

            const onVisibilityChange = () => {
                toggleAfk(document.hidden);
            };

            document.addEventListener("visibilitychange", onVisibilityChange);

            onValue(isConnectedRef, async (snap) => {
                if (snap.val() === true) {
                    idleTracker.start();
                    connected = true;
                    await onDisconnect(userConnectionRef).remove();
                    if (connected) {
                        set(userConnectionRef, !isAfk);
                    }
                } else {
                    // make sure it's empty so being afk doesn't re-set it
                    connected = false;
                }
            });

            return () => {
                idleTracker.end();
                document.removeEventListener("visibilitychange", onVisibilityChange);
                connected = false;
            };
        }
    }, [userInfo?.uid]);
}
