import { Stack, Typography } from "@mui/material";
import { useCallback, useEffect, useMemo, useState } from "react";

import T from "../../locallization/T";
import AppToggleButton from "../../onboarding/buttonGroup/AppToggleButton";
import { LOCATION_TYPES, userInfoLocationType } from "../../onboarding/questions/OnboardingQuestionLocation";
import { useAppContext } from "../../root/AppContext";
import { USER_INFO } from "../../user/userInfo";
import SearchInputBasicSelect from "../basic/SearchInputBasicSelect";
import { SEARCH_INPUT_PROPS } from "./SearchInputProps";

export default function SearchInputLocationSelector({
    onData,
    newUserInfo,
    setHasLocation,
    expanded,
}: SEARCH_INPUT_PROPS & { setHasLocation: (hasLocation: boolean) => void; expanded?: boolean }) {
    const { userInfo } = useAppContext();

    const initialValue = useMemo(() => {
        const values: LOCATION_TYPES[] = [];
        if (userInfo?.locationZoom === "Y") {
            values.push("zoom");
        }
        if (userInfo?.locationGeo) {
            values.push("front");
        }
        return values;
    }, [userInfo?.locationZoom, userInfo?.locationGeo]);

    const [selectedLocationOptions, setSelectedLocationOptions] = useState(new Set<LOCATION_TYPES>(initialValue));

    const updateData = useCallback(
        (options: Set<LOCATION_TYPES>) => {
            const dataUpdates: USER_INFO = {
                locationZoom: options.has("zoom") ? "Y" : "N",
            };
            if (!options.has("front")) {
                dataUpdates["locationName"] = "";
                dataUpdates["locationGeo"] = null;
            }
            onData(dataUpdates);
            setHasLocation(options.has("front"));
        },
        [onData, setHasLocation]
    );

    const onDataInner = useCallback(
        (data: USER_INFO) => {
            const options = new Set(data["temp"] as LOCATION_TYPES[]);
            updateData(options);
        },
        [updateData]
    );

    useEffect(() => {
        if (expanded) {
            updateData(selectedLocationOptions);
        }
    }, [selectedLocationOptions, expanded, updateData]);

    if (expanded) {
        return (
            <Stack gap={2}>
                <Typography variant="subtitle1">
                    <T>search input location type expanded</T>
                </Typography>
                <Stack flexDirection="row" gap={1} mb={1}>
                    {userInfoLocationType.map(({ value, textKey, Icon }) => (
                        <AppToggleButton
                            key={value}
                            value={value}
                            selectedOptions={selectedLocationOptions}
                            setSelectedOptions={setSelectedLocationOptions}
                            Icon={<Icon />}>
                            <T>{textKey}</T>
                        </AppToggleButton>
                    ))}
                </Stack>
            </Stack>
        );
    }

    return (
        <SearchInputBasicSelect
            onData={onDataInner}
            newUserInfo={newUserInfo}
            userInfoKey="temp"
            label={<T>search input location type</T>}
            allValues={userInfoLocationType}
            multiple
            defaultValue={initialValue}
        />
    );
}
