import { serverTimestamp } from "firebase/firestore";
import { useEffect, useRef } from "react";

import { USER_INFO } from "./userInfo";
import { updateUserInfo } from "./userUpdates";

export function useLastLogin({ userInfo }: { userInfo?: USER_INFO | null }) {
    const userInfoRef = useRef(userInfo);

    // We want to update last login only when you enter and not whenever user info changes, so use ref for it.
    useEffect(() => {
        userInfoRef.current = userInfo;
    }, [userInfo]);

    useEffect(() => {
        if (userInfo?.uid) {
            updateUserInfo(userInfo.uid, userInfoRef.current, { lastLogin: serverTimestamp() });
        }
    }, [userInfo?.uid]);
}
