import { LogoutOutlined } from '@mui/icons-material';
import { signOut } from 'firebase/auth';
import { noop } from 'lodash';
import { useSnackbar } from 'notistack';
import { useCallback, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { getFirebaseAuth } from '../../firebase/firebase';
import T from '../../locallization/T';
import { ROUTES } from '../../navigation/Router';
import SettingRowBase from '../settingRows/SettingRowBase';

export default function ProfileSignOutButton() {
    const [signOutLoading, setSignOutLoading] = useState(false);
    const navigate = useNavigate();
    const { enqueueSnackbar } = useSnackbar();

    const signOutHandler = useCallback(() => {
        setSignOutLoading(true);
        signOut(getFirebaseAuth())
            .then(() => {
                navigate(ROUTES.HOME);
                setSignOutLoading(false);
                enqueueSnackbar(<T>logout form success</T>, { variant: "success" });
            })
            .catch((error) => {
                setSignOutLoading(false);
                console.error(error);
                enqueueSnackbar(<T>logout form error</T>, { variant: "warning" });
            });
    }, [navigate, enqueueSnackbar]);

    return (
        <SettingRowBase
            text={<T>profile sign out button</T>}
            Icon={LogoutOutlined}
            onClick={signOutLoading ? noop : signOutHandler}
        />
    );
}
