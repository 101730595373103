import T from '../../locallization/T';
import SearchInputBasicYear from '../basic/SearchInputBasicYear';
import { SEARCH_INPUT_PROPS } from '../inputs/SearchInputProps';

export default function SearchInputLicenseYear({ onData, newUserInfo }: SEARCH_INPUT_PROPS) {
    // TODO allow deleting this...
    return (
        <SearchInputBasicYear
            onData={onData}
            newUserInfo={newUserInfo}
            userInfoKey="therapistLicenseYear"
            label={<T>search input license year</T>}
        />
    );
}
