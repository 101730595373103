import { Stack, Typography } from "@mui/material";

import PageTitle from "../common/PageTitle";
import T from "../locallization/T";
import { usePageText } from "../locallization/usePageTexts";
import AppSpinner from "../navigation/AppSpinner";
import PageContainer from "../navigation/PageContainer";
import { ROUTES } from "../navigation/Router";

export default function AccessibilityAcknowledgePage() {
    const { text } = usePageText("accessibility_acknowledge");

    return (
        <PageContainer
            pageTitleKey="contact action accessibility"
            pagePath={ROUTES.ACCESSIBILITY_ACKNOWLEDGE}
            hideBotNav
            backgrounded>
            <Stack gap={2}>
                <PageTitle hasTopNav gap={2}>
                    <T>contact action accessibility</T>
                </PageTitle>
                {text ? <Typography>{text}</Typography> : <AppSpinner />}
            </Stack>
        </PageContainer>
    );
}
