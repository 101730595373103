import T from '../../locallization/T';
import SearchInputBasicText from '../basic/SearchInputBasicText';
import { SEARCH_INPUT_PROPS } from '../inputs/SearchInputProps';

export const MAX_DEGREE_LENGTH = 48;

export default function SearchInputDegree({ onData, newUserInfo }: SEARCH_INPUT_PROPS) {
    return (
        <SearchInputBasicText
            label={<T>search input degree</T>}
            maxLength={MAX_DEGREE_LENGTH}
            userInfoKey="therapistDegree"
            onData={onData}
            newUserInfo={newUserInfo}
        />
    );
}
