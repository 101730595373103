import { useMemo } from "react";

import { useAppContext } from "../root/AppContext";

export function useMetMetupalim() {
    const { userInfo } = useAppContext();

    return useMemo(() => {
        return userInfo?.met || {};
    }, [userInfo?.met]);
}
