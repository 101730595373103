import { WorkOutlineOutlined } from '@mui/icons-material';

import T from '../../locallization/T';
import { THERAPIST_OUTPUT_PROPS } from '../basic/OutputProps';
import OutputRow from '../basic/OutputRow';

export const EXP_ICON = WorkOutlineOutlined;

export default function TherapistOutputExp({ newUserInfo, ...props }: THERAPIST_OUTPUT_PROPS) {
    return (
        <OutputRow
            {...props}
            title={<T>therapist output exp</T>}
            value={newUserInfo?.therapistExp}
            Icon={EXP_ICON}
            hideOnEmpty
        />
    );
}
