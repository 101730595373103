import { omit } from "lodash";
import { useCallback } from "react";

import { useAppContext } from "../root/AppContext";
import { USER_INFO, USER_INFO_MAP } from "../user/userInfo";
import { updateUserInfo } from "../user/userUpdates";

export function useResetUnreadCount({ userInfoKey, targetUid }: { userInfoKey: keyof USER_INFO; targetUid: string }) {
    const { user, userInfo } = useAppContext();

    const resetUnreadCount = useCallback(() => {
        if (!userInfo || !user) {
            return;
        }
        const counts = (userInfo[userInfoKey] as USER_INFO_MAP<string, number>) || {};
        if (counts[targetUid]) {
            const countsWithoutUid = omit(counts, [targetUid]);
            // this might get called more than once because we need all userInfo here, but it's okay
            updateUserInfo(user.uid, userInfo, { [userInfoKey]: countsWithoutUid });
        }
    }, [user, userInfo, targetUid, userInfoKey]);

    return resetUnreadCount;
}
