import {
    AccountCircleOutlined,
    AdminPanelSettingsOutlined,
    AttachEmailOutlined,
    DocumentScannerOutlined,
    Filter1Outlined,
    PsychologyAltOutlined,
    ReportProblemOutlined,
    ScaleOutlined,
    TranslateOutlined,
} from "@mui/icons-material";
import { Box, Button, ListItemText, Menu, MenuItem, Stack, Tab, Tabs, Typography } from "@mui/material";
import { MouseEvent, useCallback, useMemo, useState } from "react";
import { useSearchParams } from "react-router-dom";

import { LOCALE_EN, LOCALE_HE } from "../locallization/useLocale";
import PageContainer from "../navigation/PageContainer";
import { ROUTES } from "../navigation/Router";
import { useAppContext } from "../root/AppContext";
import { getSpacedButtonIconProps } from "../theme/buttons";
import EmailSenderSecret from "./emails/EmailSenderSecret";
import InvalidationsListSecret from "./invalidations/InvalidationsListSecret";
import PagesTextEditorSecret from "./PagesTextEditorSecret";
import AdminStats from "./stats/AdminStats";
import TextsEditorSecret from "./TextsEditorSecret";
import UsersTableSecret from "./users/UsersTableSecret";
import ZigmondTextEditorSecret from "./ZigmondTextEditorSecret";

export const ADMIN_HIDDEN_STORAGE_KEY = "hide-admin";

const TAB_NAMES = [
    { title: "~_~", Icon: AdminPanelSettingsOutlined },
    { title: "טקסטים", Icon: TranslateOutlined },
    { title: "משתמשים", Icon: AccountCircleOutlined },
    { title: "מספרים", Icon: Filter1Outlined },
    { title: "דפים", Icon: DocumentScannerOutlined },
    { title: "אימיילים", Icon: AttachEmailOutlined },
    { title: "מדד זיגמונד", Icon: ScaleOutlined },
    { title: "חריגות", Icon: ReportProblemOutlined },
    { title: "סודי ביותר", Icon: PsychologyAltOutlined },

];

export default function Secret() {

    const { adminProps } = useAppContext();
    const [value, setValue] = useState(0);
    const [, setSearchParams] = useSearchParams();
    const [langAnchorEl, setLangAnchorEl] = useState<null | HTMLElement>(null);

    const handleShowLang = useCallback((event: MouseEvent<HTMLElement>) => {
        setLangAnchorEl(event.currentTarget);
    }, []);
    const handleHideLang = useCallback(() => {
        setLangAnchorEl(null);
    }, []);
    const updateLang = useCallback(
        (locale: string) => {
            setSearchParams({ locale });
            handleHideLang();
        },
        [handleHideLang, setSearchParams]
    );
    const updateKeys = useCallback(() => {
        setSearchParams({ keys: "1" });
        handleHideLang();
    }, [handleHideLang, setSearchParams]);
    const resetLang = useCallback(() => {
        setSearchParams({ keys: "", locale: "" });
        handleHideLang();
    }, [handleHideLang, setSearchParams]);

    const handleChange = useCallback((event, newValue: number) => {
        setValue(newValue);
    }, []);

    const hideAdminSession = useCallback(() => {
        try {
            sessionStorage.setItem(ADMIN_HIDDEN_STORAGE_KEY, "1");
        } catch {}
        window.location.reload();
    }, []);

    const NoPermissions = useMemo(
        () => (
            <Stack textAlign="center">
                <Typography>אין לך הרשאות לצפייה בטאב זה</Typography>
            </Stack>
        ),
        []
    );

    return (
        <PageContainer pageTitleKey="title secret" pagePath={ROUTES.SECRET}>
            <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                <Tabs value={value} onChange={handleChange} scrollButtons variant="scrollable">
                    {TAB_NAMES.map(({ title, Icon }) => (
                        <Tab key={title} label={title} icon={<Icon />} iconPosition="start" />
                    ))}
                </Tabs>
            </Box>
            <Box>
                <TabPanel value={value} index={0}>
                    <Stack textAlign="center" alignItems="center" gap={2}>
                        <Typography>
                            ברוכים הבאים לבקאופיס של זיגמונד.
                            <br />
                            אפשר לעשות כאן המון דברים!
                        </Typography>
                        <Stack gap={1}>
                            <Typography>מעבר לדף פנימי:</Typography>
                            {TAB_NAMES.map(({ title, Icon }, index) => (
                                <Button
                                    key={title}
                                    variant="contained"
                                    onClick={() => setValue(index)}
                                    {...getSpacedButtonIconProps()}
                                    startIcon={<Icon />}>
                                    {title}
                                </Button>
                            ))}
                        </Stack>
                        <Button
                            variant="contained"
                            color="info"
                            onClick={handleShowLang}
                            startIcon={<TranslateOutlined />}>
                            החלף שפה
                        </Button>
                        <Menu anchorEl={langAnchorEl} open={Boolean(langAnchorEl)} onClose={handleHideLang}>
                            <MenuItem onClick={() => updateLang(LOCALE_EN)}>
                                <ListItemText>אנגלית</ListItemText>
                            </MenuItem>
                            <MenuItem onClick={() => updateLang(LOCALE_HE)}>
                                <ListItemText>עברית</ListItemText>
                            </MenuItem>
                            <MenuItem onClick={() => updateKeys()}>
                                <ListItemText>בלי טקסט</ListItemText>
                            </MenuItem>
                            <MenuItem onClick={() => resetLang()}>
                                <ListItemText>איפוס</ListItemText>
                            </MenuItem>
                        </Menu>
                        <Button variant="contained" color="error" onClick={hideAdminSession}>
                            הסתר מהפוטר לסשן
                        </Button>
                    </Stack>
                </TabPanel>
                <TabPanel value={value} index={1}>
                    {adminProps?.adminLangView ? <TextsEditorSecret /> : NoPermissions}
                </TabPanel>
                <TabPanel value={value} index={2}>
                    {adminProps?.adminUsersView ? <UsersTableSecret /> : NoPermissions}
                </TabPanel>
                <TabPanel value={value} index={3}>
                    {adminProps?.adminUsersView ? <AdminStats /> : NoPermissions}
                </TabPanel>
                <TabPanel value={value} index={4}>
                    {adminProps?.adminLangView ? <PagesTextEditorSecret /> : NoPermissions}
                </TabPanel>
                <TabPanel value={value} index={5}>
                    {adminProps?.adminEmails ? <EmailSenderSecret /> : NoPermissions}
                </TabPanel>
                <TabPanel value={value} index={6}>
                    {adminProps?.adminZigmondMeasureEdit ? <ZigmondTextEditorSecret /> : NoPermissions}
                </TabPanel>
                <TabPanel value={value} index={7}>
                    {adminProps?.adminInvalidations ? <InvalidationsListSecret /> : NoPermissions}
                </TabPanel>
                <TabPanel value={value} index={8}>
                    {adminProps?.adminChill ? (
                        <Typography textAlign="center">
                            באמת אין פה כלום, ואם מישהו שואל אז להגיד שאין פה כלום
                        </Typography>
                    ) : (
                        NoPermissions
                    )}
                </TabPanel>
            </Box>
        </PageContainer>
    );
}

interface TabPanelProps {
    children?: React.ReactNode;
    index: number;
    value: number;
}

function TabPanel(props: TabPanelProps) {
    const { children, value, index } = props;

    return (
        <Box role="tabpanel" hidden={value !== index}>
            {value === index && <Box sx={{ pt: 3 }}>{children}</Box>}
        </Box>
    );
}
