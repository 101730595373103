import { NotificationsNoneOutlined, NotificationsOffOutlined } from "@mui/icons-material";
import { SvgIcon } from "@mui/material";
import { useCallback } from "react";

import T from "../../locallization/T";
import { USER_INFO_NOTIFICATION_MATCH } from "../../user/userInfo";
import SearchInputBasicSelect from "../basic/SearchInputBasicSelect";
import { SEARCH_INPUT_PROPS } from "./SearchInputProps";

const notificationMatchOptions: { value: USER_INFO_NOTIFICATION_MATCH; Icon: typeof SvgIcon }[] = [
    { value: "daily", Icon: NotificationsNoneOutlined },
    { value: "none", Icon: NotificationsOffOutlined },
];

export default function SearchInputNotificationsMatch({ onData, newUserInfo }: SEARCH_INPUT_PROPS) {
    const getValueLabel = useCallback(
        (value: string) => <T children={`search input notification match ${value}`} />,
        []
    );

    return (
        <SearchInputBasicSelect
            onData={onData}
            newUserInfo={newUserInfo}
            userInfoKey="notificationsMatch"
            label={<T>search input notifications match</T>}
            allValues={notificationMatchOptions}
            defaultValue={notificationMatchOptions[0].value}
            getValueLabel={getValueLabel}
        />
    );
}
