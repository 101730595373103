import { CloseOutlined, LockOpenOutlined, LockOutlined } from "@mui/icons-material";
import { Button, CircularProgress, Stack, TextField, Typography } from "@mui/material";
import { httpsCallable } from "firebase/functions";
import { useSnackbar } from "notistack";
import { useCallback, useMemo, useState } from "react";

import { ActionButtonPrimary, ActionButtonSecondary } from "../../common/ActionButtons";
import AppDialog from "../../common/dialog/AppDialog";
import { getFirebaseFunctions } from "../../firebase/firebase";
import T from "../../locallization/T";
import OutputRow from "../../outputs/basic/OutputRow";
import { USER_INFO } from "../../user/userInfo";

export default function AdminBanPreviewButton({ newUserInfo }: { newUserInfo: USER_INFO }) {
    const [showWarning, setShowWarning] = useState(false);
    const [saving, setSaving] = useState(false);
    const [banToggled, setBanToggled] = useState(false);
    const { enqueueSnackbar } = useSnackbar();
    const [reason, setReason] = useState("");
    const updateReason = useCallback((event) => setReason(event.target.value), []);

    const isBanned = useMemo(() => (banToggled ? !newUserInfo.ban : newUserInfo.ban), [banToggled, newUserInfo.ban]);

    const banCallback = useMemo(
        () =>
            httpsCallable<
                { uid: string; banReason: string; ban: boolean; asMetupal?: boolean; asTherapist?: boolean },
                null
            >(getFirebaseFunctions(), "onCallBan"),
        []
    );

    const setTrueShowWarning = useCallback(() => {
        setShowWarning(true);
    }, []);

    const setFalseShowWarning = useCallback(() => {
        setShowWarning(false);
    }, []);

    const toggleBan = useCallback(() => {
        setSaving(true);
        banCallback({
            uid: newUserInfo.uid || "",
            banReason: reason,
            ban: !isBanned,
            asMetupal: newUserInfo.accountHasMetupal,
            asTherapist: newUserInfo.accountHasTherapist,
        })
            .then((response) => {
                setSaving(false);
                enqueueSnackbar(isBanned ? "חסימת החשבון שוחררה בהצלחה" : "החשבון נחסם בהצלחה", { variant: "success" });
                setShowWarning(false);
                setTimeout(() => {
                    setBanToggled((toggled) => !toggled);
                    setReason("");
                }, 500);
            })
            .catch((error) => {
                setSaving(false);
                console.error(error);
                enqueueSnackbar(<T params={{ code: 175 }}>general error</T>, { variant: "warning" });
            });
    }, [enqueueSnackbar, newUserInfo, isBanned, reason, banCallback]);

    return (
        <>
            <Button
                onClick={setTrueShowWarning}
                color="error"
                startIcon={isBanned ? <LockOpenOutlined /> : <LockOutlined />}>
                {isBanned ? "שחרור חסימת חשבון" : "חסימת חשבון"}
            </Button>
            <AppDialog
                open={showWarning}
                setOpen={setShowWarning}
                fullScreen
                actions={
                    <>
                        <ActionButtonSecondary onClick={setFalseShowWarning} endIcon={<CloseOutlined />}>
                            <T>profile delete dialog cancel button</T>
                        </ActionButtonSecondary>
                        <ActionButtonPrimary
                            onClick={toggleBan}
                            color="error"
                            disabled={saving || !reason}
                            endIcon={saving ? <CircularProgress size={24} /> : null}>
                            {isBanned ? "לשחרר חסימה" : "לחסום"}
                        </ActionButtonPrimary>
                    </>
                }>
                <Stack gap={3}>
                    {newUserInfo.banHistory?.length ? (
                        <>
                            <Typography>היסטוריית חסימות:</Typography>
                            {newUserInfo.banHistory.map(({ ban, by, date, reason }, index) => (
                                <Stack gap={1} key={index}>
                                    <OutputRow title={ban ? "חסימה" : "שחרור"} short />
                                    <OutputRow title={"על ידי"} value={by} short />
                                    <OutputRow
                                        title={"תאריך"}
                                        value={(date as any).toDate().toLocaleString("he")}
                                        short
                                    />
                                    <OutputRow title={"סיבה"} value={reason} short />
                                </Stack>
                            ))}
                        </>
                    ) : null}

                    <Typography>
                        {isBanned ? "מה הסיבה להסרת החסימה של החשבון?" : "מה הסיבה לחסימה של החשבון?"}
                    </Typography>
                    <TextField value={reason} onChange={updateReason} autoFocus label="סיבה" />
                </Stack>
            </AppDialog>
        </>
    );
}
