import { useMemo } from "react";

import { useAppContext } from "../root/AppContext";

export function useCheckMet() {
    const { userInfo } = useAppContext();

    return useMemo(() => {
        return userInfo?.checkMet || {};
    }, [userInfo?.checkMet]);
}
