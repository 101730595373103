import { ArrowBack, CancelOutlined } from "@mui/icons-material";
import { Box, Button, CircularProgress, debounce, Stack, Typography } from "@mui/material";
import { isEmpty } from "lodash";
import { useSnackbar } from "notistack";
import { Fragment, useCallback, useEffect, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";

import CollapsibleSection from "../common/CollapsibleSection";
import T from "../locallization/T";
import BottomNav from "../navigation/BottomNav";
import PageContainer from "../navigation/PageContainer";
import { RESULTS_LOADING_STATE, ROUTES } from "../navigation/Router";
import {
    deleteTherapistFromStorage,
    getTherapistFromStorage,
    saveTherapistToStorage,
} from "../onboarding/therapistStorage";
import TherapistTitle from "../outputs/therapist/TherapistTitle";
import { useAppContext } from "../root/AppContext";
import { useSearchFormValidation } from "../search/basic/useSearchFormValidation";
import SearchInputAge from "../search/inputs/SearchInputAge";
import SearchInputGender from "../search/inputs/SearchInputGender";
import SearchInputPhoto from "../search/inputs/SearchInputPhoto";
import SearchInputTalkGender from "../search/inputs/SearchInputTalkGender";
import SearchInputZoom from "../search/inputs/SearchInputZoom";
import SearchInputAdditionalStudies from "../search/therapistInputs/SearchInputAdditionalStudies";
import SearchInputAges from "../search/therapistInputs/SearchInputAges";
import SearchInputArticlesLinks from "../search/therapistInputs/SearchInputArticlesLinks";
import SearchInputCharity from "../search/therapistInputs/SearchInputCharity";
import SearchInputDegree from "../search/therapistInputs/SearchInputDegree";
import SearchInputDegree2 from "../search/therapistInputs/SearchInputDegree2";
import SearchInputDegree3 from "../search/therapistInputs/SearchInputDegree3";
import SearchInputExp from "../search/therapistInputs/SearchInputExp";
import SearchInputFirstName from "../search/therapistInputs/SearchInputFirstName";
import SearchInputKupot from "../search/therapistInputs/SearchInputKupot";
import SearchInputLangs from "../search/therapistInputs/SearchInputLangs";
import SearchInputLastName from "../search/therapistInputs/SearchInputLastName";
import SearchInputLicense from "../search/therapistInputs/SearchInputLicense";
import SearchInputLicenseYear from "../search/therapistInputs/SearchInputLicenseYear";
import SearchInputMethods from "../search/therapistInputs/SearchInputMethods";
import SearchInputPageVisibility from "../search/therapistInputs/SearchInputPageVisibility";
import SearchInputSocialFacebook from "../search/therapistInputs/SearchInputSocialFacebook";
import SearchInputSocialInstagram from "../search/therapistInputs/SearchInputSocialInstagram";
import SearchInputSocialSite from "../search/therapistInputs/SearchInputSocialSite";
import SearchInputSocialYoutube from "../search/therapistInputs/SearchInputSocialYoutube";
import SearchInputSpecialty from "../search/therapistInputs/SearchInputSpecialty";
import SearchInputTherapistAgesRange from "../search/therapistInputs/SearchInputTherapistAgesRange";
import SearchInputTherapistCost from "../search/therapistInputs/SearchInputTherapistCost";
import SearchInputTherapistLocations from "../search/therapistInputs/SearchInputTherapistLocations";
import SearchInputTherapistMinutes from "../search/therapistInputs/SearchInputTherapistMinutes";
import SearchInputTherapistOthers from "../search/therapistInputs/SearchInputTherapistOthers";
import SearchInputTherapistOthersList from "../search/therapistInputs/SearchInputTherapistOthersList";
import SearchInputTherapistReasons from "../search/therapistInputs/SearchInputTherapistReasons";
import SearchInputTherapistReasonsNot from "../search/therapistInputs/SearchInputTherapistReasonsNot";
import SearchInputTherapistTalk from "../search/therapistInputs/SearchInputTherapistTalk";
import SearchInputTherapistTimes from "../search/therapistInputs/SearchInputTherapistTimes";
import SearchInputTherapistTypes from "../search/therapistInputs/SearchInputTherapistTypes";
import SearchInputTherapistVideo from "../search/therapistInputs/SearchInputTherapistVideo";
import useUpdateUserInfo from "../search/useUpdateUserInfo";
import { getSpacedButtonIconProps } from "../theme/buttons";
import WithThemeIcon from "../theme/WithThemeIcon";
import { USER_INFO } from "../user/userInfo";
import { updateUserInfo } from "../user/userUpdates";
import HighlightTop from "./HighlightTop";
import InfoSection from "./info/InfoSection";
import TherapistPagePreviewButton from "./TherapistPagePreview";
import TherapistPageShareButton from "./TherapistPageShareButton";

const debounceSaveTherapistToStorage = debounce(saveTherapistToStorage, 1000);

export default function TherapistPageEdit() {
    const [newData, setNewData] = useState<USER_INFO>(getTherapistFromStorage());
    const { user, userInfo } = useAppContext();
    const onData = useUpdateUserInfo(setNewData);
    const [loading, setLoading] = useState(false);
    const [formKey, setFormKey] = useState(0);
    const [showMoreDegree, setShowMoreDegree] = useState(!!userInfo?.therapistDegree3);
    const { enqueueSnackbar } = useSnackbar();
    const navigate = useNavigate();

    const isInitialSave = !userInfo?.timesSearched;

    const newUserInfo = useMemo(() => ({ ...userInfo, ...newData }), [userInfo, newData]);

    useEffect(() => {
        debounceSaveTherapistToStorage(newData, { override: true });
    }, [newData]);

    const setTrueShowMoreDegree = useCallback(() => {
        setShowMoreDegree(true);
    }, []);

    const saveTherapist = useCallback(() => {
        if (!user) {
            return;
        }

        setLoading(true);
        updateUserInfo(user.uid, userInfo, newData)
            .then(() => {
                deleteTherapistFromStorage();
                if (isInitialSave) {
                    navigate(ROUTES.RESULTS_LOADER_EDIT, RESULTS_LOADING_STATE);
                } else {
                    enqueueSnackbar(<T>therapist page update success</T>, { variant: "success" });
                    setLoading(false);
                    setNewData({});
                }
            })
            .catch((error) => {
                setLoading(false);
                console.error(error);
                enqueueSnackbar(<T params={{ code: 182 }}>general error</T>, { variant: "warning" });
            });
    }, [user, userInfo, navigate, enqueueSnackbar, newData, isInitialSave]);

    const resetTherapist = useCallback(() => {
        setNewData({});
        // hard reset the form, because all the components have internal states
        setFormKey((key) => key + 1);
    }, []);

    const errorMessage = useSearchFormValidation({ newUserInfo });

    const submitButton = (
        <Button
            disabled={loading || (!isInitialSave && isEmpty(newData)) || !!errorMessage}
            variant="contained"
            size="huge"
            onClick={saveTherapist}
            {...getSpacedButtonIconProps()}
            endIcon={loading ? <CircularProgress size={24} /> : <WithThemeIcon Icon={ArrowBack} />}>
            {isInitialSave ? <T>therapist page done button</T> : <T>therapist page update button</T>}
        </Button>
    );

    return (
        <PageContainer
            pageTitleKey="title therapist page edit"
            pagePath={ROUTES.THERAPIST_PAGE}
            hideBotNav={isInitialSave}
            BotNavComponent={
                isInitialSave ? null : (
                    <>
                        {!isEmpty(newData) && (
                            <Stack gap={1} p={1}>
                                {errorMessage && <Typography color="error">{errorMessage}</Typography>}
                                {submitButton}
                            </Stack>
                        )}
                        <BottomNav />
                    </>
                )
            }
            hideTopNav
            disableGutters>
            <Fragment key={formKey}>
                <HighlightTop
                    hideBack={!isInitialSave}
                    actionIcon={
                        <TherapistPageShareButton
                            newUserInfo={newUserInfo}
                            disabled={userInfo?.pageVisibility !== "public"}
                            asIcon
                        />
                    }>
                    <SearchInputPhoto newUserInfo={newUserInfo} onData={onData} />
                    <TherapistTitle newUserInfo={newUserInfo} />
                </HighlightTop>
                <Stack gap={3} px={2}>
                    <CollapsibleSection summary={<T>therapist page title setting</T>}>
                        <Stack gap={2}>
                            <SearchInputFirstName newUserInfo={newUserInfo} onData={onData} />
                            <SearchInputLastName newUserInfo={newUserInfo} onData={onData} />
                            <SearchInputLicense newUserInfo={newUserInfo} onData={onData} isDisabled />
                            <SearchInputGender newUserInfo={newUserInfo} onData={onData} isTherapist />
                            <SearchInputTalkGender newUserInfo={newUserInfo} onData={onData} />
                            <SearchInputAge newUserInfo={newUserInfo} onData={onData} />
                            <SearchInputSpecialty newUserInfo={newUserInfo} onData={onData} />
                            <SearchInputTherapistLocations
                                onData={onData}
                                userInfo={userInfo}
                                newUserInfo={newUserInfo}
                            />
                            <SearchInputZoom onData={onData} newUserInfo={newUserInfo} isTherapist />
                            <SearchInputTherapistTimes onData={onData} newUserInfo={newUserInfo} />
                            <SearchInputTherapistTypes onData={onData} newUserInfo={newUserInfo} />
                            <SearchInputTherapistMinutes onData={onData} newUserInfo={newUserInfo} />
                            <SearchInputTherapistCost onData={onData} newUserInfo={newUserInfo} />
                            <SearchInputKupot onData={onData} newUserInfo={newUserInfo} />
                            <SearchInputAges onData={onData} newUserInfo={newUserInfo} />
                            <SearchInputCharity onData={onData} newUserInfo={newUserInfo} />
                        </Stack>
                    </CollapsibleSection>

                    <CollapsibleSection summary={<T>therapist page title exp</T>} defaultExpanded>
                        <Stack gap={2}>
                            <SearchInputLicenseYear onData={onData} newUserInfo={newUserInfo} />
                            <SearchInputDegree onData={onData} newUserInfo={newUserInfo} />
                            <SearchInputDegree2 onData={onData} newUserInfo={newUserInfo} />
                            {showMoreDegree ? (
                                <SearchInputDegree3 onData={onData} newUserInfo={newUserInfo} />
                            ) : (
                                <Button
                                    onClick={setTrueShowMoreDegree}
                                    variant="text"
                                    sx={{ alignSelf: "baseline", mt: -1 }}>
                                    <T>degree more button</T>
                                </Button>
                            )}
                            <SearchInputAdditionalStudies onData={onData} newUserInfo={newUserInfo} />
                        </Stack>
                    </CollapsibleSection>

                    <CollapsibleSection summary={<T>therapist page title special</T>} defaultExpanded>
                        <Stack gap={2}>
                            <SearchInputExp onData={onData} newUserInfo={newUserInfo} />
                            <SearchInputMethods onData={onData} newUserInfo={newUserInfo} />
                            <SearchInputLangs onData={onData} newUserInfo={newUserInfo} />
                        </Stack>
                    </CollapsibleSection>

                    <CollapsibleSection summary={<T>therapist page title general</T>}>
                        <Stack gap={2}>
                            <InfoSection
                                title={<T>therapist page section title video</T>}
                                chipTitle={<T>therapist page section chip video</T>}>
                                <SearchInputTherapistVideo onData={onData} newUserInfo={newUserInfo} />
                            </InfoSection>
                            <SearchInputTherapistOthers onData={onData} newUserInfo={newUserInfo} />
                        </Stack>
                    </CollapsibleSection>
                    <CollapsibleSection summary={<T>therapist page title links</T>}>
                        <Stack gap={3}>
                            <InfoSection title={<T>therapist page section title articles links</T>}>
                                <SearchInputArticlesLinks onData={onData} newUserInfo={newUserInfo} />
                            </InfoSection>
                            <InfoSection title={<T>therapist page section title socials</T>}>
                                <Stack gap={2}>
                                    <SearchInputSocialFacebook onData={onData} newUserInfo={newUserInfo} />
                                    <SearchInputSocialInstagram onData={onData} newUserInfo={newUserInfo} />
                                    <SearchInputSocialYoutube onData={onData} newUserInfo={newUserInfo} />
                                    <SearchInputSocialSite onData={onData} newUserInfo={newUserInfo} />
                                </Stack>
                            </InfoSection>
                        </Stack>
                    </CollapsibleSection>
                    <CollapsibleSection summary={<T>therapist page title params</T>} defaultExpanded>
                        <Stack gap={3}>
                            <SearchInputTherapistReasons onData={onData} newUserInfo={newUserInfo} />
                            <SearchInputTherapistReasonsNot onData={onData} newUserInfo={newUserInfo} />
                            <SearchInputTherapistTalk onData={onData} newUserInfo={newUserInfo} />
                            <SearchInputTherapistOthersList onData={onData} newUserInfo={newUserInfo} />
                            <SearchInputTherapistAgesRange onData={onData} newUserInfo={newUserInfo} />
                            <SearchInputPageVisibility onData={onData} newUserInfo={newUserInfo} />
                        </Stack>
                    </CollapsibleSection>

                    <Stack gap={2} mt={1}>
                        <Box>
                            <TherapistPagePreviewButton newUserInfo={newUserInfo} />
                        </Box>
                        <Box>
                            <TherapistPageShareButton
                                newUserInfo={newUserInfo}
                                disabled={userInfo?.pageVisibility !== "public"}
                            />
                        </Box>
                    </Stack>
                    <Stack mt={2} mb={1} gap={3}>
                        {isInitialSave ? submitButton : null}
                        {isInitialSave && errorMessage && (
                            <Typography sx={{ mt: -2 }} color="error">
                                {errorMessage}
                            </Typography>
                        )}
                        {isInitialSave ? null : (
                            <Button
                                disabled={loading || isEmpty(newData)}
                                variant="text"
                                onClick={resetTherapist}
                                sx={{ alignSelf: "center" }}
                                startIcon={<CancelOutlined />}>
                                <T>therapist page cancel button</T>
                            </Button>
                        )}
                    </Stack>
                </Stack>
            </Fragment>
        </PageContainer>
    );
}
