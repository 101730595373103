import { TextField } from "@mui/material";
import { useCallback, useEffect, useMemo, useState } from "react";

import { SEARCH_INPUT_BASIC_PROPS } from "./SearchInputBasicProps";

export default function SearchInputBasicText({
    onData,
    newUserInfo,
    userInfoKey,
    therapistUserInfoKey,
    maxLength,
    label = "",
    isTherapist,
    defaultValue = "",
    onBlur,
    ...props
}: SEARCH_INPUT_BASIC_PROPS) {
    const actualUserInfoKey = useMemo(
        () => (isTherapist && therapistUserInfoKey ? therapistUserInfoKey : userInfoKey),
        [isTherapist, therapistUserInfoKey, userInfoKey]
    );

    const textValue = newUserInfo[actualUserInfoKey];
    const [initialTextValue] = useState(textValue);

    useEffect(() => {
        if (defaultValue && !initialTextValue) {
            onData({ [actualUserInfoKey]: defaultValue });
        }
    }, [onData, defaultValue, initialTextValue, actualUserInfoKey]);

    const updateText = useCallback(
        (event) => {
            onData({ [actualUserInfoKey]: event.target.value });
        },
        [onData, actualUserInfoKey]
    );

    const trimText = useCallback(
        (event) => {
            onData({ [actualUserInfoKey]: ((textValue as string) || "").trim() });
            onBlur && onBlur(event);
        },
        [onData, actualUserInfoKey, onBlur, textValue]
    );

    return (
        <TextField
            label={label}
            value={newUserInfo[actualUserInfoKey]}
            onChange={updateText}
            inputProps={{ maxLength }}
            fullWidth
            onBlur={trimText}
            {...props}
        />
    );
}
