import { Link, Typography } from '@mui/material';
import { SxProps, Theme } from '@mui/material/styles';

import T from '../locallization/T';
import { ROUTES } from '../navigation/Router';

export default function AlreadyHasAccountLink({ sx = [] }: { sx?: SxProps<Theme> }) {
    return (
        <Typography sx={sx} variant="h3">
            <T>login flow action has account</T>{" "}
            <Link href={ROUTES.LOGIN}>
                <T>login flow action enter here</T>
            </Link>
        </Typography>
    );
}
