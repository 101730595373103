import { Stack, StackProps } from "@mui/material";
import { useEffect, useState } from "react";

import { useSortedFavorite } from "../history/useSortedFavorite";
import T from "../locallization/T";
import AppSpinner from "../navigation/AppSpinner";
import { useAppContext } from "../root/AppContext";
import TherapistStats from "../stats/TherapistStats";
import LinkToDeletedMatches from "./LinkToDeletedMatches";
import MatchedList from "./MatchedList";
import { useChatsWithMetupalim } from "./useChatsWithMetupalim";
import { useCheckMet } from "./useCheckMet";
import { useMatchedMetupalim } from "./useMatchedMetupalim";
import { useMetMetupalim } from "./useMetMetupalim";
import { usePaymentDue } from "./usePaymentDue";

export default function MatchedMetupalim({
    setMatchesCount,
    ...props
}: { setMatchesCount: (count: number) => void } & StackProps) {
    const { userInfo } = useAppContext();
    const checkMetMetupalim = useCheckMet();
    const matchedMetupalim = useMatchedMetupalim();
    const chatsWithMetupalim = useChatsWithMetupalim();
    const metMetupalim = useMetMetupalim();
    const paymentDue = usePaymentDue();
    const sortedCheckMet = useSortedFavorite(checkMetMetupalim);
    const sortedMatches = useSortedFavorite(matchedMetupalim);
    const sortedChats = useSortedFavorite(chatsWithMetupalim);
    const sortedMets = useSortedFavorite(metMetupalim);
    const sortedPayments = useSortedFavorite(paymentDue);
    const [showStats, setShowStats] = useState(false);

    useEffect(() => {
        setMatchesCount(sortedMatches.length + sortedChats.length + sortedMets.length + sortedCheckMet.length);
    }, [setMatchesCount, sortedMatches.length, sortedChats.length, sortedMets.length, sortedCheckMet.length]);

    // Make it look like the stats are also loading, so it doesn't load before the other lists and seem weird.
    useEffect(() => {
        const timerId = setTimeout(() => setShowStats(true), 1000);
        return () => clearTimeout(timerId);
    }, []);

    return (
        <Stack gap={4} {...props}>
            <MatchedList
                initialMatchesCount={3}
                matchesPerPage={3}
                sortedUserIds={sortedPayments}
                isTargetTherapist={false}
                title={<T>home therapist title payment</T>}
            />
            <MatchedList
                initialMatchesCount={1}
                matchesPerPage={1}
                sortedUserIds={sortedCheckMet}
                isTargetTherapist={false}
                title={<T>home therapist title check met</T>}
            />
            {userInfo?.therapistEnableSearch && (
                <MatchedList
                    initialMatchesCount={3}
                    matchesPerPage={3}
                    sortedUserIds={sortedMatches}
                    isTargetTherapist={false}
                    title={<T>home therapist title matches</T>}
                />
            )}
            <MatchedList
                initialMatchesCount={1}
                matchesPerPage={3}
                sortedUserIds={sortedChats}
                isTargetTherapist={false}
                title={<T>home therapist title chats</T>}
            />
            <MatchedList
                initialMatchesCount={1}
                matchesPerPage={3}
                sortedUserIds={sortedMets}
                isTargetTherapist={false}
                title={<T>home therapist title meets</T>}
            />
            <LinkToDeletedMatches />
            {userInfo?.therapistEnableSearch ? showStats ? <TherapistStats /> : <AppSpinner /> : null}
        </Stack>
    );
}
