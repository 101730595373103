import T from "../../locallization/T";
import SearchInputBasicTextarea from "../basic/SearchInputBasicTextarea";
import { SEARCH_INPUT_PROPS } from "../inputs/SearchInputProps";

const MAX_THERAPIST_OTHER_INFO_LENGTH = 750;

export default function SearchInputTherapistOthers({ onData, newUserInfo }: SEARCH_INPUT_PROPS) {
    return (
        <SearchInputBasicTextarea
            onData={onData}
            newUserInfo={newUserInfo}
            userInfoKey="therapistOtherInfo"
            label={<T>therapist page section title general</T>}
            maxLength={MAX_THERAPIST_OTHER_INFO_LENGTH}
        />
    );
}
