import {
    Checkbox,
    FormControl,
    FormHelperText,
    InputLabel,
    ListItemText,
    ListSubheader,
    MenuItem,
    Select,
} from "@mui/material";
import { isArray } from "lodash";
import { Fragment, useCallback, useMemo, useState } from "react";

import SeparatedList from "../../common/SeparatedList";
import { USER_INFO } from "../../user/userInfo";
import { SEARCH_INPUT_PROPS } from "../inputs/SearchInputProps";
import SelectConfirmButton from "./SelectConfirmButton";

export default function SearchInputBasicNumberedSet({
    onData,
    newUserInfo,
    userInfoKey,
    label,
    allValues,
    getValueLabel,
    getTitleLabel,
    helperText,
    limit = Infinity,
}: SEARCH_INPUT_PROPS & {
    userInfoKey: keyof USER_INFO;
    label: React.ReactNode;
    allValues: number[] | number[][];
    getValueLabel: (value: number) => React.ReactNode;
    getTitleLabel?: (value: number) => React.ReactNode | null;
    helperText?: React.ReactNode;
    limit?: number;
}) {
    const [open, setOpen] = useState(false);
    const handleClose = useCallback(() => setOpen(false), []);
    const handleOpen = useCallback(() => setOpen(true), []);

    const isGroups = isArray(allValues[0]);
    const groupedValues = useMemo(
        () => (isGroups ? (allValues as number[][]) : [allValues as number[]]),
        [isGroups, allValues]
    );

    const valuesSet = useMemo(() => new Set((newUserInfo[userInfoKey] as number[]) || []), [newUserInfo, userInfoKey]);

    const updateValues = useCallback(
        (event) => {
            onData({ [userInfoKey]: event.target.value });
        },
        [userInfoKey, onData]
    );

    return (
        <FormControl fullWidth>
            <InputLabel>{label}</InputLabel>
            <Select
                multiple
                value={(newUserInfo[userInfoKey] as number[]) || []}
                onChange={updateValues}
                label={label}
                open={open}
                onClose={handleClose}
                onOpen={handleOpen}
                renderValue={(selected) => (
                    <SeparatedList separator="; ">
                        {selected.map((numberValue) => (
                            <Fragment key={numberValue}>{getValueLabel(numberValue)}</Fragment>
                        ))}
                    </SeparatedList>
                )}>
                {groupedValues.map((groupValues) =>
                    groupValues.map((numberValue, groupIndex) => {
                        const title = getTitleLabel && groupIndex === 0 && getTitleLabel(numberValue);

                        const groupSelectedSize = isGroups
                            ? groupValues.filter((value) => valuesSet.has(value)).length
                            : valuesSet.size;
                        return [
                            title ? <ListSubheader>{title}</ListSubheader> : null,
                            <MenuItem
                                value={numberValue}
                                disabled={!valuesSet.has(numberValue) && groupSelectedSize >= limit}>
                                <Checkbox checked={valuesSet.has(numberValue)} />
                                <ListItemText primary={getValueLabel(numberValue)} />
                            </MenuItem>,
                        ];
                    })
                )}
                <SelectConfirmButton handleClose={handleClose} listSize={allValues.length} />
            </Select>
            {helperText && <FormHelperText>{helperText}</FormHelperText>}
        </FormControl>
    );
}
