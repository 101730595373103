import { Stack } from "@mui/material";
import { without } from "lodash";
import { useCallback } from "react";

import T from "../../locallization/T";
import SearchInputBasicChips from "../basic/SearchInputBasicChips";
import SearchInputBasicNumberedSet from "../basic/SearchInputBasicNumeredSet";
import { OTHER_CULTURE, OTHER_LGBT, OTHER_LIFE, OTHER_RELIGION } from "../inputs/SearchInputOthers";
import { SEARCH_INPUT_PROPS } from "../inputs/SearchInputProps";

const ALL_THERAPIST_OTHERS = [OTHER_RELIGION, OTHER_CULTURE, OTHER_LGBT, OTHER_LIFE];

export default function SearchInputTherapistOthersList({ onData, newUserInfo }: SEARCH_INPUT_PROPS) {
    const getValueLabel = useCallback((other: number) => <T children={`other therapist ${other}`} />, []);
    const getTitleLabel = useCallback((other: number) => <T children={`other title therapist ${other}`} />, []);
    const onValueDelete = useCallback(
        (other: number) => {
            onData({ therapistOthersList: without(newUserInfo?.therapistOthersList, other) });
        },
        [onData, newUserInfo?.therapistOthersList]
    );

    return (
        <Stack gap={1}>
            <SearchInputBasicNumberedSet
                onData={onData}
                newUserInfo={newUserInfo}
                userInfoKey="therapistOthersList"
                label={<T>search input therapist others</T>}
                allValues={ALL_THERAPIST_OTHERS}
                getValueLabel={getValueLabel}
                getTitleLabel={getTitleLabel}
                helperText={<T>search input therapist others subtext</T>}
            />
            <SearchInputBasicChips
                allValues={newUserInfo?.therapistOthersList || []}
                getValueLabel={getValueLabel}
                onValueDelete={onValueDelete}
                forceHorizontal={false}
            />
        </Stack>
    );
}
