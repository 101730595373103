import T from '../../locallization/T';
import { USER_INFO } from '../../user/userInfo';
import SearchInputBasicText from '../basic/SearchInputBasicText';
import { SEARCH_INPUT_PROPS } from '../inputs/SearchInputProps';

const MAX_FIRST_NAME_LENGTH = 24;

export function alreadyHasFirstName(userInfo: USER_INFO) {
    return !!userInfo.firstName;
}

export default function SearchInputFirstName({ onData, newUserInfo }: SEARCH_INPUT_PROPS) {
    return (
        <SearchInputBasicText
            label={<T>search input first name</T>}
            maxLength={MAX_FIRST_NAME_LENGTH}
            userInfoKey="firstName"
            onData={onData}
            newUserInfo={newUserInfo}
        />
    );
}
