import T from '../../locallization/T';
import { USER_INFO } from '../../user/userInfo';
import SearchInputBasicText from '../basic/SearchInputBasicText';
import { SEARCH_INPUT_PROPS } from '../inputs/SearchInputProps';

const MAX_LAST_NAME_LENGTH = 24;

export function alreadyHasLastName(userInfo: USER_INFO) {
    return !!userInfo.lastName;
}

export default function SearchInputLastName({ onData, newUserInfo }: SEARCH_INPUT_PROPS) {
    return (
        <SearchInputBasicText
            label={<T>search input last name</T>}
            maxLength={MAX_LAST_NAME_LENGTH}
            userInfoKey="lastName"
            onData={onData}
            newUserInfo={newUserInfo}
        />
    );
}
