import { doc, setDoc } from "firebase/firestore";
import { httpsCallable } from "firebase/functions";

import { getFirebaseFirestoreDb, getFirebaseFunctions } from "../firebase/firebase";

type SEARCH_INFO = {
    uid: string;
};

export function getSearchRef(userUid: string) {
    return doc(getFirebaseFirestoreDb(), "searches", userUid);
}

export function updateSearch(userUid: string) {
    const data: SEARCH_INFO = {
        uid: userUid,
    };

    return setDoc(getSearchRef(userUid), data);
}

export function deleteSearch() {
    const deleteSearchCallback = httpsCallable(getFirebaseFunctions(), "onCallDeleteSearch");
    return deleteSearchCallback();
}
