import { ThumbDownOutlined } from "@mui/icons-material";
import { Button, CircularProgress, Stack, Typography } from "@mui/material";
import { isPlainObject } from "lodash";
import { useCallback, useMemo, useState } from "react";

import AppDialog from "../../common/dialog/AppDialog";
import NonEmptyDivider from "../../common/NonEmptyDivider";
import { useSortedFavorite } from "../../history/useSortedFavorite";
import { useLoadUserInfos } from "../../matches/userInfosService";
import MetupalPagePreviewButton from "../../metupalim/MetupalPagePreviewButton";
import TopNav from "../../navigation/TopNav";
import OutputRow from "../../outputs/basic/OutputRow";
import TherapistPagePreviewButton from "../../therapist_page/TherapistPagePreview";
import { USER_INFO } from "../../user/userInfo";

export default function AdminRejectsButton({
    newUserInfo,
    isTherapist,
}: {
    newUserInfo: USER_INFO;
    isTherapist: boolean;
}) {
    const [showDialog, setShowDialog] = useState(false);

    const rejectsObject = isTherapist ? newUserInfo.rejectedMetupalim : newUserInfo.rejectedTherapists;
    const sortedUserIds = useSortedFavorite(rejectsObject as {});
    const options = useMemo(() => ({ skip: !showDialog }), [showDialog]);
    const { loading, userInfos } = useLoadUserInfos(sortedUserIds, !isTherapist, options);

    const setTrueShowDialog = useCallback(() => {
        setShowDialog(true);
    }, []);

    const setFalseShowDialog = useCallback(() => {
        setShowDialog(false);
    }, []);

    return (
        <>
            <Button onClick={setTrueShowDialog} color="info" startIcon={<ThumbDownOutlined />}>
                דחיות ({sortedUserIds.length})
            </Button>
            <AppDialog
                open={showDialog}
                setOpen={setShowDialog}
                fullScreen
                topNav={<TopNav onBack={setFalseShowDialog} title="דחיות" />}>
                <Stack gap={3} divider={<NonEmptyDivider sx={{ mx: -2 }} />}>
                    {sortedUserIds.length ? (
                        sortedUserIds.map((uid, index) => {
                            const rejectObject = (rejectsObject || {})[uid] as any;
                            let date = rejectObject;
                            let reason = "";
                            if (isPlainObject(rejectObject) && rejectObject.date) {
                                date = rejectObject.date;
                                reason = rejectObject.reason;
                            }
                            return (
                                <Stack gap={1} key={uid}>
                                    <OutputRow
                                        title={"תאריך"}
                                        value={(date as any).toDate().toLocaleString("he")}
                                        short
                                    />
                                    {reason ? <OutputRow title={"סיבת הדחייה"} value={reason} short /> : null}
                                    {loading ? (
                                        <CircularProgress />
                                    ) : userInfos[index] ? (
                                        isTherapist ? (
                                            <MetupalPagePreviewButton
                                                newUserInfo={userInfos[index]}
                                                sx={{ alignSelf: "baseline" }}
                                            />
                                        ) : (
                                            <TherapistPagePreviewButton
                                                newUserInfo={userInfos[index]}
                                                sx={{ alignSelf: "baseline" }}
                                            />
                                        )
                                    ) : null}
                                </Stack>
                            );
                        })
                    ) : (
                        <Typography>אין עדיין דחיות</Typography>
                    )}
                </Stack>
            </AppDialog>
        </>
    );
}
