import { AddAPhotoOutlined, CancelOutlined } from "@mui/icons-material";
import { Avatar, CircularProgress, IconButton, Stack } from "@mui/material";
import { grey } from "@mui/material/colors";
import imageCompression from "browser-image-compression";
import { uploadBytes } from "firebase/storage";
import { useSnackbar } from "notistack";
import { useCallback, useMemo, useState } from "react";

import { FLAG_PROFILE_PICTURE } from "../../flags/flags";
import T from "../../locallization/T";
import OutputPhoto from "../../outputs/basic/OutputPhoto";
import { useAppContext } from "../../root/AppContext";
import { getProfileImageRef, PHOTO_KEY_1, PHOTO_KEY_2, useDownloadProfilePhotoUrl } from "../../uploads/imageUpload";
import { SEARCH_INPUT_PROPS } from "./SearchInputProps";

export const PROFILE_IMAGE_SIZE = 128;
export const DEFAULT_MEDIA_CACHE = "private, max-age=2592000, immutable"; // 1 month

export default function SearchInputPhoto({ onData, newUserInfo }: SEARCH_INPUT_PROPS) {
    const { flags = {}, userInfo } = useAppContext();
    const [loading, setLoading] = useState(false);
    const { url, loadingAsset: loadingPhoto } = useDownloadProfilePhotoUrl(newUserInfo);
    const { enqueueSnackbar } = useSnackbar();

    const uploadPhoto = useCallback(
        ({ target }) => {
            if (!userInfo) {
                return;
            }
            if (target?.files?.length !== 1) {
                return;
            }

            const oldPhotoKey = userInfo.photoKey;

            const newPhotoKey = oldPhotoKey === PHOTO_KEY_1 ? PHOTO_KEY_2 : PHOTO_KEY_1;
            const profileImagesRef = getProfileImageRef(userInfo.uid || "temp", newPhotoKey);

            setLoading(true);
            onData({ photoKey: "" });

            imageCompression(target.files[0], {
                maxSizeMB: 1,
                maxWidthOrHeight: PROFILE_IMAGE_SIZE * 2,
                useWebWorker: true,
            })
                .then((file) =>
                    uploadBytes(profileImagesRef, file, {
                        cacheControl: DEFAULT_MEDIA_CACHE,
                    })
                )
                .then(() => {
                    setLoading(false);
                    onData({ photoKey: newPhotoKey });
                })
                .catch((error) => {
                    setLoading(false);
                    console.error(error);
                    onData({ photoKey: oldPhotoKey });
                    enqueueSnackbar(<T params={{ code: 172 }}>general error</T>, { variant: "warning" });
                });
        },
        [enqueueSnackbar, userInfo, onData]
    );

    const cancelPhoto = useCallback(() => {
        onData({ photoKey: "" });
    }, [onData]);

    const uploadIcon = useMemo(
        () => (
            <IconButton color="inherit" component="label" disabled={loading}>
                {loading ? <CircularProgress size={24} /> : <AddAPhotoOutlined />}
                <input hidden accept="image/*" type="file" onChange={uploadPhoto} />
            </IconButton>
        ),
        [loading, uploadPhoto]
    );
    if (!flags[FLAG_PROFILE_PICTURE]) {
        return null;
    }

    return (
        <Stack alignItems="center" position="relative">
            {url || loadingPhoto ? (
                <>
                    <OutputPhoto
                        newUserInfo={newUserInfo}
                        isTargetTherapist={newUserInfo?.isTherapist}
                        size={PROFILE_IMAGE_SIZE}
                    />
                    <Stack flexDirection="row">
                        {uploadIcon}
                        <IconButton color="inherit" disabled={loading} onClick={cancelPhoto}>
                            <CancelOutlined />
                        </IconButton>
                    </Stack>
                </>
            ) : (
                <Avatar sx={{ width: PROFILE_IMAGE_SIZE, height: PROFILE_IMAGE_SIZE, backgroundColor: grey[600] }}>
                    {uploadIcon}
                </Avatar>
            )}
        </Stack>
    );
}
