import { useAppContext } from '../../root/AppContext';
import OnboardingQuestionsTherapist from './OnboardingQuestionsTherapist';
import TherapistOnboardingStart from './TherapistOnboardingStart';

export default function TherapistOnboarding() {
    const { userInfo } = useAppContext();
    if (!userInfo?.hasTherapistBasicInfo) {
        return <TherapistOnboardingStart />;
    }
    return <OnboardingQuestionsTherapist />;
}
