import { first, isEmpty, keys, map } from "lodash";
import { Fragment, useMemo } from "react";

import SeparatedList from "../../common/SeparatedList";
import T from "../../locallization/T";
import { userInfoCosts } from "../../onboarding/questions/OnboardingQuestionTherapistCost";
import {
    therapistQuestionIcons,
    therapistQuestionIconsColorful,
} from "../../onboarding/therapist/OnboardingQuestionsTherapist";
import { useAppContext } from "../../root/AppContext";
import { userInfoKupot } from "../../search/inputs/SearchInputKupa";
import { isDifferentCostTypes } from "../../search/therapistInputs/SearchInputTherapistCost";
import { isDifferentMinuteTypes } from "../../search/therapistInputs/SearchInputTherapistMinutes";
import { THERAPIST_OUTPUT_PROPS } from "../basic/OutputProps";
import OutputRow from "../basic/OutputRow";
import { useSortedOutput } from "../basic/useSortedOutput";

export default function TherapistOutputCost({ newUserInfo, colorful, ...props }: THERAPIST_OUTPUT_PROPS) {
    const { userInfo } = useAppContext();
    const hasKupot =
        newUserInfo.therapistKupot && !isEmpty(newUserInfo.therapistKupot) && !newUserInfo.therapistKupot["none"];

    const showAllTypes = useMemo(
        () => isDifferentCostTypes(newUserInfo) || isDifferentMinuteTypes(newUserInfo),
        [newUserInfo]
    );
    const specificType = useMemo(() => {
        const therapyType =
            userInfo?.therapyType === "child" || !userInfo?.therapyType ? "personal" : userInfo?.therapyType;
        if (
            userInfo &&
            userInfo?.uid !== newUserInfo?.uid &&
            therapyType &&
            newUserInfo?.therapistCosts &&
            newUserInfo?.therapistCosts[therapyType]
        ) {
            return therapyType;
        }
        if (!showAllTypes) {
            return first(keys(newUserInfo?.therapistCosts)) || "";
        }
        return "";
    }, [userInfo, newUserInfo, showAllTypes]);

    const sortedCosts = useSortedOutput(newUserInfo?.therapistCosts, userInfoCosts, "therapyType");
    const sortedKupot = useSortedOutput(newUserInfo.therapistKupot, userInfoKupot, "value");

    return (
        <OutputRow
            {...props}
            title={<T>therapist output cost</T>}
            value={
                <>
                    {specificType ? (
                        <>
                            <T params={{ cost: (newUserInfo?.therapistCosts || {})[specificType] }}>
                                onboarding question cost range ils
                            </T>
                            {(newUserInfo?.therapistMinutes || {})[specificType] && (
                                <T
                                    params={{
                                        time: (newUserInfo?.therapistMinutes || {})[specificType],
                                    }}
                                    prefix=" ("
                                    suffix=")">
                                    time minutes
                                </T>
                            )}
                        </>
                    ) : (
                        <SeparatedList separator={"\n"}>
                            {map(sortedCosts, (therapyType) => (
                                <Fragment key={therapyType}>
                                    <T
                                        params={{
                                            cost: (newUserInfo.therapistCosts || {})[therapyType],
                                            type: <T children={`onboarding question type ${therapyType}`} />,
                                        }}>
                                        therapist output cost type
                                    </T>
                                    {(newUserInfo?.therapistMinutes || {})[therapyType] && (
                                        <T
                                            params={{
                                                time: (newUserInfo?.therapistMinutes || {})[therapyType],
                                            }}
                                            prefix=" ("
                                            suffix=")">
                                            time minutes
                                        </T>
                                    )}
                                </Fragment>
                            ))}
                        </SeparatedList>
                    )}
                    {hasKupot && (
                        <>
                            {"\n"}
                            <T suffix=": ">therapist output cost kupot</T>
                            <SeparatedList>
                                {map(sortedKupot, (kupa) => (
                                    <T key={kupa} children={`onboarding question kupa ${kupa}`} />
                                ))}
                            </SeparatedList>
                        </>
                    )}
                </>
            }
            Icon={colorful ? therapistQuestionIconsColorful.therapist_cost : therapistQuestionIcons.therapist_cost}
        />
    );
}
