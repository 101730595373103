import { CheckOutlined, ClearOutlined } from "@mui/icons-material";
import { CircularProgress, Stack, TextField, Typography } from "@mui/material";
import { httpsCallable } from "firebase/functions";
import { useSnackbar } from "notistack";
import { Dispatch, lazy, SetStateAction, Suspense, useCallback, useEffect, useState } from "react";

import { ActionButtonPrimary, ActionButtonSecondary } from "../common/ActionButtons";
import AppDialog from "../common/dialog/AppDialog";
import { getFirebaseFunctions } from "../firebase/firebase";
import T from "../locallization/T";
import AppSpinner from "../navigation/AppSpinner";
import { useResetUnreadCount } from "../notifications/useResetUnreadCount";
import { boldStyles } from "../theme/theme";

const LazyAppDateCalendar = lazy(() => import("../common/date/AppDateCalendar"));

const MAX_SCHEDULE_DATE = Date.now() + 2 * 30 * 24 * 60 * 60 * 1000;

export default function MatchScheduleDialog({
    metupalUid,
    showScheduleDialog,
    setShowScheduleDialog,
    loading,
    setLoading,
}: {
    metupalUid: string;
    showScheduleDialog: boolean;
    setShowScheduleDialog: Dispatch<SetStateAction<boolean>>;
    loading: boolean;
    setLoading: Dispatch<SetStateAction<boolean>>;
}) {
    const [scheduleDate, setScheduleDate] = useState();
    const [renderedScheduleDate, setRenderedScheduleDate] = useState(false);

    useEffect(() => {
        if (showScheduleDialog) {
            setRenderedScheduleDate(true);
        }
    }, [showScheduleDialog]);

    const { enqueueSnackbar } = useSnackbar();

    const resetUnreadCount = useResetUnreadCount({
        userInfoKey: "matchesCounts",
        targetUid: metupalUid,
    });

    const scheduledMeeting = useCallback(() => {
        setLoading(true);

        resetUnreadCount();
        const setMeetingCallback = httpsCallable<{ metupalUid: string; timestamp: number }, {}>(
            getFirebaseFunctions(),
            "onCallSetMeeting"
        );

        setMeetingCallback({ metupalUid, timestamp: +(scheduleDate || Date.now()) })
            .then(() => {
                enqueueSnackbar(<T>match metupal meet success</T>, {
                    variant: "success",
                });
                setShowScheduleDialog(false);
            })
            .catch((error) => {
                setLoading(false);
                console.error(error);
                enqueueSnackbar(<T params={{ code: 187 }}>general error</T>, { variant: "warning" });
            });
    }, [enqueueSnackbar, setLoading, setShowScheduleDialog, metupalUid, resetUnreadCount, scheduleDate]);

    const hideScheduleDialog = useCallback(() => {
        if (loading) {
            return;
        }
        setShowScheduleDialog(false);
    }, [loading, setShowScheduleDialog]);

    return (
        <AppDialog
            open={showScheduleDialog}
            setOpen={setShowScheduleDialog}
            actions={
                <>
                    <ActionButtonSecondary onClick={hideScheduleDialog} disabled={loading} endIcon={<ClearOutlined />}>
                        <T>dialog button cancel</T>
                    </ActionButtonSecondary>
                    <ActionButtonPrimary
                        onClick={scheduledMeeting}
                        disabled={loading}
                        endIcon={loading ? <CircularProgress size={24} /> : <CheckOutlined />}>
                        <T>dialog button confirm</T>
                    </ActionButtonPrimary>
                </>
            }>
            <Stack gap={2}>
                <Typography variant="h3" sx={{ ...boldStyles }}>
                    <T>metupal dialog schedule title</T>
                </Typography>

                {renderedScheduleDate && (
                    <Suspense fallback={<AppSpinner />}>
                        <LazyAppDateCalendar
                            value={scheduleDate}
                            onChange={(newValue) => setScheduleDate(newValue)}
                            renderInput={(params) => <TextField {...params} />}
                            displayStaticWrapperAs="desktop"
                            maxDate={MAX_SCHEDULE_DATE}
                        />
                    </Suspense>
                )}
            </Stack>
        </AppDialog>
    );
}
