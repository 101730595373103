import { Box } from "@mui/material";
import { GeoPoint } from "firebase/firestore";
import { useEffect, useRef, useState } from "react";

import { useLoadMaps } from "./useLoadMaps";

export default function GoogleRadiusMap({ rangeValue, geo }: { rangeValue: number; geo: GeoPoint }) {
    const [map, setMap] = useState<any>();
    const circleRef = useRef<any>();
    const mapRef = useRef<HTMLElement>();
    const { loadingScript } = useLoadMaps();

    useEffect(() => {
        if (loadingScript) {
            return;
        }

        setMap(
            new (window as any).google.maps.Map(mapRef.current, {
                zoom: 11,
                center: { lat: geo.latitude, lng: geo.longitude },
                disableDefaultUI: true,
            })
        );
    }, [geo, loadingScript]);

    useEffect(() => {
        if (!map) {
            return;
        }

        if (!circleRef.current) {
            circleRef.current = new (window as any).google.maps.Circle({
                strokeColor: "#FF0000",
                strokeOpacity: 0.8,
                strokeWeight: 2,
                fillColor: "#FF0000",
                fillOpacity: 0.35,
                map,
                center: { lat: geo.latitude, lng: geo.longitude },
                radius: rangeValue * 1000,
            });
        }
        circleRef.current.setMap(map);
        circleRef.current.setCenter({ lat: geo.latitude, lng: geo.longitude });
        circleRef.current.setRadius(rangeValue * 1000);
    }, [rangeValue, geo, map]);

    return <Box ref={mapRef} height={374} width="100%" />;
}
