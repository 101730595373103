import { BlockOutlined, HistoryOutlined } from "@mui/icons-material";

import T from "../../locallization/T";
import { ROUTES } from "../../navigation/Router";
import { useAppContext } from "../../root/AppContext";
import SettingRowBase from "./SettingRowBase";

export default function SettingRowHistory() {
    const { userInfo } = useAppContext();

    return (
        <SettingRowBase
            text={<T>profile setting history</T>}
            Icon={userInfo?.isTherapist ? HistoryOutlined : BlockOutlined}
            href={ROUTES.HISTORY}
        />
    );
}
