import { Chip, Stack, Typography } from "@mui/material";
import { Box } from "@mui/system";
import { ReactNode } from "react";

import { boldStyles } from "../../theme/theme";

export default function InfoSection({
    title,
    children,
    bigger = false,
    chipTitle,
}: {
    title: ReactNode;
    children: ReactNode;
    bigger?: boolean;
    chipTitle?: ReactNode;
}) {
    return (
        <Box>
            <Stack flexDirection="row" justifyContent="space-between" mb={2}>
                <Typography variant={bigger ? "h2" : "h3"} color="primary" sx={{ ...boldStyles }}>
                    {title}
                </Typography>
                {chipTitle && <Chip label={chipTitle} color="light" variant="filled" size="small" />}
            </Stack>
            {children}
        </Box>
    );
}
