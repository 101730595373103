import {
    CalculateOutlined,
    CalculateTwoTone,
    CalendarMonthOutlined,
    EditOutlined,
    EditTwoTone,
    LocalHospitalOutlined,
    LocalHospitalTwoTone,
    LocationOnOutlined,
    LocationOnTwoTone,
    PermContactCalendarTwoTone,
    PsychologyOutlined,
    PsychologyTwoTone,
    TranslateOutlined,
    TranslateTwoTone,
    TuneOutlined,
    TuneTwoTone,
} from "@mui/icons-material";
import { useCallback } from "react";
import { useNavigate } from "react-router-dom";

import { RESULTS_LOADING_STATE, ROUTES } from "../navigation/Router";
import { useAppContext } from "../root/AppContext";
import { USER_INFO } from "../user/userInfo";
import OnboardingQuestions from "./OnboardingQuestions";
import { getOnboardingFromStorage } from "./onboardingStorage";
import OnboardingQuestionAge, { alreadyHasAge } from "./questions/OnboardingQuestionAge";
import OnboardingQuestionCost, { alreadyHasCost } from "./questions/OnboardingQuestionCost";
import OnboardingQuestionGender, { alreadyHasGender } from "./questions/OnboardingQuestionGender";
import OnboardingQuestionLocation, { alreadyHasLocation } from "./questions/OnboardingQuestionLocation";
import OnboardingQuestionOthers, { alreadyHasOthers } from "./questions/OnboardingQuestionOthers";
import { ONBOARDING_QUESTION_PROPS } from "./questions/OnboardingQuestionProps";
import OnboardingQuestionReasons, { alreadyHasReasons } from "./questions/OnboardingQuestionReasons";
import OnboardingQuestionType, { alreadyHasType } from "./questions/OnboardingQuestionType";

export const metupalQuestionIcons = {
    gender: TranslateOutlined,
    location: LocationOnOutlined,
    type: EditOutlined,
    age: CalendarMonthOutlined,
    cost: CalculateOutlined,
    kupa: LocalHospitalOutlined,
    reasons: PsychologyOutlined,
    others: TuneOutlined,
};

export const metupalQuestionIconsColorful = {
    gender: TranslateTwoTone,
    location: LocationOnTwoTone,
    type: EditTwoTone,
    // Ages uses a different icon because the two tone looks bad on the og one
    age: PermContactCalendarTwoTone,
    cost: CalculateTwoTone,
    kupa: LocalHospitalTwoTone,
    reasons: PsychologyTwoTone,
    others: TuneTwoTone,
};

const existingDataCheckers: { [key: string]: (userInfo: USER_INFO) => boolean } = {
    gender: alreadyHasGender,
    location: alreadyHasLocation,
    type: alreadyHasType,
    age: alreadyHasAge,
    cost: alreadyHasCost,
    reasons: alreadyHasReasons,
    others: alreadyHasOthers,
};

const questionComponents: { [key: string]: (props: ONBOARDING_QUESTION_PROPS) => JSX.Element } = {
    gender: OnboardingQuestionGender,
    location: OnboardingQuestionLocation,
    type: OnboardingQuestionType,
    age: OnboardingQuestionAge,
    cost: OnboardingQuestionCost,
    reasons: OnboardingQuestionReasons,
    others: OnboardingQuestionOthers,
};

const pageTitleKeys: { [key: string]: string } = {
    gender: "output gender",
    location: "metupal output location",
    type: "metupal output types",
    age: "metupal output age",
    cost: "metupal output cost",
    reasons: "metupal output reasons",
    others: "metupal output other",
};

const questionsOrder = ["gender", "location", "type", "age", "cost", "reasons", "others"];

export function shouldShowAge(userInfo: USER_INFO | null) {
    return (
        userInfo?.therapyType === "personal" || userInfo?.therapyType === "child" || userInfo?.therapyType === "group"
    );
}

function getScreens(screens: string[], userInfo?: USER_INFO | null) {
    if (!userInfo) {
        userInfo = getOnboardingFromStorage();
    }

    if (!shouldShowAge(userInfo)) {
        screens = screens.filter((screen) => screen !== "age");
    }
    return screens;
}

export default function OnboardingQuestionsMetupal() {
    const { user } = useAppContext();
    const navigate = useNavigate();

    const getOrderedScreens = useCallback((userInfo: USER_INFO) => {
        return getScreens(questionsOrder, userInfo);
    }, []);

    const endFlow = useCallback(() => {
        if (user) {
            navigate(ROUTES.RESULTS_LOADER_INITIAL, RESULTS_LOADING_STATE);
        } else {
            navigate(ROUTES.REGISTER);
        }
    }, [user, navigate]);

    return (
        <OnboardingQuestions
            questionIcons={metupalQuestionIcons}
            existingDataCheckers={existingDataCheckers}
            questionComponents={questionComponents}
            pageTitleKeys={pageTitleKeys}
            getOrderedScreens={getOrderedScreens}
            onEndFlow={endFlow}
        />
    );
}
