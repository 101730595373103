import { ArrowBack } from "@mui/icons-material";
import { Box, Button, Container, Stack } from "@mui/material";
import { isEmpty, isNil, omitBy } from "lodash";
import { useSnackbar } from "notistack";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";

import T, { useT } from "../locallization/T";
import AppSpinner from "../navigation/AppSpinner";
import PageContainer from "../navigation/PageContainer";
import { ROUTES } from "../navigation/Router";
import TopUnion from "../navigation/TopUnion";
import { usePageSizes } from "../navigation/usePageSizes";
import { getUpdatesForBackOfSignAs } from "../profile/settingButtons/ProfileLoginAs";
import { useAppContext } from "../root/AppContext";
import { getSpacedButtonIconProps } from "../theme/buttons";
import WithThemeIcon from "../theme/WithThemeIcon";
import { USER_INFO } from "../user/userInfo";
import { updateUserInfo } from "../user/userUpdates";
import { deleteOnboardingFromStorage, getOnboardingFromStorage, saveOnboardingToStorage } from "./onboardingStorage";

function getInitialScreen(screens: string[], existingDataCheckers: {}, userInfo?: USER_INFO | null): any {
    if (!userInfo) {
        userInfo = getOnboardingFromStorage();
    }
    if (!userInfo) {
        return 0;
    }
    for (let i = 0; i < screens.length; i++) {
        const screenName = screens[i];
        const checker = existingDataCheckers[screenName];
        if (!checker(userInfo)) {
            return i;
        }
    }
    return screens.length - 1;
}

export default function OnboardingQuestions({
    questionIcons,
    existingDataCheckers,
    questionComponents,
    pageTitleKeys,
    getOrderedScreens,
    onEndFlow,
}) {
    const { user, userInfo, initialDeleteUserInfo } = useAppContext();
    const [loading, setLoading] = useState(false);

    const screens = useMemo(() => {
        if (loading) {
            // Keep loading here so it refreshes when value changes, because
            // storage value doesn't update useMemo.
        }
        return getOrderedScreens(userInfo);
    }, [getOrderedScreens, userInfo, loading]);

    const [screenIndex, setScreenIndex] = useState(getInitialScreen(screens, existingDataCheckers, userInfo));
    const [currentDataProgress, setCurrentDataProgress] = useState<USER_INFO>({});
    const screenName = useMemo(() => screens[screenIndex], [screens, screenIndex]);
    const { enqueueSnackbar } = useSnackbar();
    const navigate = useNavigate();
    const location = useLocation();
    const { contentSx } = usePageSizes();

    const newUserInfo = useMemo(
        () => ({ ...(userInfo || getOnboardingFromStorage()), ...currentDataProgress }),
        [userInfo, currentDataProgress]
    );

    const renderedRef = useRef(false);
    useEffect(() => {
        if (location.state) {
            setScreenIndex((location.state as any).step);
        } else {
            if (renderedRef.current) {
                setScreenIndex((screenIndex) => screenIndex - 1);
            }
        }
        renderedRef.current = true;
    }, [location.state]);

    const Icon = questionIcons[screenName];
    const Question = questionComponents[screenName];
    const showContinue = !loading;

    const isLastScreen = screenIndex === screens.length - 1;
    const isFirstScreen = screenIndex === 0;

    const moveScreenForward = useCallback(() => {
        if (isLastScreen) {
            onEndFlow();
        } else {
            navigate(location.pathname, { state: { step: screenIndex + 1 } });
        }
    }, [navigate, isLastScreen, location.pathname, screenIndex, onEndFlow]);

    const moveScreenBack = useCallback(() => {
        if (isFirstScreen) {
            setLoading(true);
            deleteOnboardingFromStorage();
            if (user) {
                let promise;
                if (userInfo?.hasTherapistBasicInfo) {
                    promise = updateUserInfo(user.uid, userInfo, { hasTherapistBasicInfo: false });
                } else if (userInfo?.accountHasMetupal || userInfo?.accountHasTherapist) {
                    promise = updateUserInfo(user.uid, userInfo, getUpdatesForBackOfSignAs(userInfo));
                } else {
                    promise = initialDeleteUserInfo();
                }
                promise.catch((error) => {
                    setLoading(false);
                    console.error(error);
                    enqueueSnackbar(<T params={{ code: 163 }}>general error</T>, { variant: "warning" });
                });
            } else {
                navigate(ROUTES.HOME);
            }
        } else if (location.state) {
            navigate(-1);
        } else {
            setScreenIndex((screenIndex) => screenIndex - 1);
        }
    }, [user, userInfo, initialDeleteUserInfo, enqueueSnackbar, location.state, navigate, isFirstScreen]);

    const dataProgress = useCallback((updates: USER_INFO) => {
        setCurrentDataProgress(updates);
    }, []);
    const completeStep = useCallback(
        (updates: USER_INFO) => {
            setLoading(true);
            if (isLastScreen) {
                const additions: USER_INFO = { onboarded: true };
                if (userInfo?.isTherapist) {
                    additions.accountHasTherapist = true;
                    additions.therapistEnableSearch = true;
                } else {
                    additions.accountHasMetupal = true;
                    additions.enableSearch = true;
                }
                updates = { ...updates, ...additions };
            }
            // remove undefined / null values.
            updates = omitBy(updates, isNil);
            const promise = user ? updateUserInfo(user.uid, userInfo, updates) : saveOnboardingToStorage(updates);
            promise
                .then(() => {
                    setCurrentDataProgress({});
                    moveScreenForward();
                })
                .catch((error) => {
                    setLoading(false);
                    console.error(error);
                    enqueueSnackbar(<T params={{ code: 164 }}>general error</T>, { variant: "warning" });
                });
        },
        [enqueueSnackbar, user, userInfo, moveScreenForward, isLastScreen]
    );
    const continueClicked = useCallback(() => {
        completeStep(currentDataProgress);
    }, [completeStep, currentDataProgress]);

    useEffect(() => {
        setLoading(false);
    }, [screenIndex]);

    const fieldTitle = useT({ textKey: pageTitleKeys[screenName] }).join("");

    return (
        <PageContainer
            pageTitleKey="title onboarding"
            pageTitleParams={{ field: fieldTitle }}
            pagePath={`${ROUTES.ONBOARDING}/${screenName}`}
            TopNavComponent={
                <TopUnion
                    title={<T children={`onboarding questions title ${screenName}`} />}
                    caption={
                        <Box
                            component="bdi"
                            sx={{
                                unicodeBidi: "plaintext",
                            }}>{`${screenIndex + 1} / ${screens.length}`}</Box>
                    }
                    Icon={Icon}
                    onBackClick={moveScreenBack}
                />
            }
            hideBotNav={!showContinue}
            BotNavComponent={
                showContinue ? (
                    <Container>
                        <Stack sx={{ pt: 3, pb: 3, ...contentSx }}>
                            <Button
                                disabled={isEmpty(currentDataProgress)}
                                variant="contained"
                                size="huge"
                                onClick={continueClicked}
                                {...getSpacedButtonIconProps()}
                                endIcon={<WithThemeIcon Icon={ArrowBack} />}>
                                <T>onboarding questions continue</T>
                            </Button>
                        </Stack>
                    </Container>
                ) : null
            }>
            <Box sx={{ pt: 3, pb: 1 }}>
                {loading ? <AppSpinner /> : <Question onDataProgress={dataProgress} newUserInfo={newUserInfo} />}
            </Box>
        </PageContainer>
    );
}
