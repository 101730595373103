import { Stack, Typography } from "@mui/material";
import { useCallback, useMemo, useState } from "react";

import T from "../../locallization/T";
import AppToggleButton from "../../onboarding/buttonGroup/AppToggleButton";
import { userInfoTalkGenders } from "../../onboarding/questions/OnboardingQuestionGender";
import { USER_INFO_GENDER } from "../../user/userInfo";
import SearchInputBasicSelect from "../basic/SearchInputBasicSelect";
import { SEARCH_INPUT_PROPS } from "./SearchInputProps";

export default function SearchInputTalkGender({
    onData,
    newUserInfo,
    expanded,
}: SEARCH_INPUT_PROPS & { expanded?: boolean }) {
    const [talkGender, setTalkGender] = useState<USER_INFO_GENDER | undefined>(newUserInfo?.talkGender);

    const selectedOptions = useMemo(() => new Set<USER_INFO_GENDER>(talkGender ? [talkGender] : []), [talkGender]);

    const updateSelectedGender = useCallback(
        (newSelectedOptionsHandler: (existing: Set<USER_INFO_GENDER>) => Set<USER_INFO_GENDER>) => {
            const newSelectedOptions = newSelectedOptionsHandler(selectedOptions);
            const options = Array.from(newSelectedOptions);
            setTalkGender(options[0]);
            onData({ talkGender: options[0] });
        },
        [selectedOptions, onData]
    );

    if (newUserInfo.gender !== "O") {
        return null;
    }

    if (expanded) {
        return (
            <Stack gap={2}>
                <Typography variant="subtitle1">
                    <T>search input gender expanded</T>
                </Typography>
                <Stack flexDirection="row" gap={1}>
                    {userInfoTalkGenders.map(({ value, textKey, Icon }) => (
                        <AppToggleButton
                            key={value}
                            value={value}
                            limitToOne
                            selectedOptions={selectedOptions}
                            setSelectedOptions={updateSelectedGender}
                            preventEmpty
                            Icon={<Icon />}>
                            <T>{textKey}</T>
                        </AppToggleButton>
                    ))}
                </Stack>
            </Stack>
        );
    }

    return (
        <SearchInputBasicSelect
            onData={onData}
            newUserInfo={newUserInfo}
            userInfoKey="talkGender"
            label={<T>search input gender</T>}
            allValues={userInfoTalkGenders}
        />
    );
}
