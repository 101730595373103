import { useCallback } from "react";

import T from "../../locallization/T";
import { userInfoTypes } from "../../onboarding/questions/OnboardingQuestionType";
import SearchInputBasicSelect from "../basic/SearchInputBasicSelect";
import { SEARCH_INPUT_PROPS } from "../inputs/SearchInputProps";

export const userInfoTherapistTypes = userInfoTypes.filter(({ value }) => value !== "child");

export default function SearchInputTherapistTypes({ onData, newUserInfo }: SEARCH_INPUT_PROPS) {
    const getValueLabel = useCallback((value: string) => <T children={`onboarding question type ${value}`} />, []);

    return (
        <SearchInputBasicSelect
            onData={onData}
            newUserInfo={newUserInfo}
            userInfoKey="therapistTherapyTypes"
            label={<T>search input type</T>}
            allValues={userInfoTherapistTypes}
            getValueLabel={getValueLabel}
            multiple
            preventEmpty
        />
    );
}
