import { Box, Stack } from '@mui/material';
import { common } from '@mui/material/colors';
import { ReactNode } from 'react';

import TopNav from '../navigation/TopNav';
import { CARD_DRAWER_BOX_SHADOW, COLOR_APP_PRIMARY_DARK, COLOR_APP_PRIMARY_LIGHT_2 } from '../theme/theme';

export default function HighlightTop({
    children,
    hideBack,
    onBack,
    showLogo,
    actionIcon,
}: {
    children: any;
    hideBack?: boolean;
    onBack?: () => any;
    showLogo?: boolean;
    actionIcon?: ReactNode;
}) {
    return (
        <Stack
            gap={1}
            position="relative"
            sx={{
                background: COLOR_APP_PRIMARY_LIGHT_2,
                color: COLOR_APP_PRIMARY_DARK,
                overflow: "hidden",
            }}>
            <Box
                width={217}
                height={217}
                borderRadius="100%"
                position="absolute"
                bottom={24}
                right={0}
                sx={{ background: common.white, opacity: 0.3, transform: "translate(50%, 50%)" }}
            />

            <TopNav hideBack={hideBack} onBack={onBack} showLogo={showLogo} actionIcon={actionIcon} />

            <Stack gap={1} px={2} position="relative">
                {children}
            </Stack>
            <Box
                pt={3}
                sx={{
                    background: common.white,
                    borderTopLeftRadius: 24,
                    borderTopRightRadius: 24,
                    boxShadow: CARD_DRAWER_BOX_SHADOW,
                }}
            />
        </Stack>
    );
}
