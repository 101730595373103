import { Link, Typography } from "@mui/material";
import { size } from "lodash";

import T from "../locallization/T";
import { ROUTES } from "../navigation/Router";
import { useAppContext } from "../root/AppContext";

export default function LinkToDeletedMatches() {
    const { userInfo } = useAppContext();

    return size(userInfo?.rejectedMetupalim) > 0 ? (
        <Link href={ROUTES.HISTORY}>
            <Typography>
                <T>home therapist show deleted</T>
            </Typography>
        </Link>
    ) : null;
}
