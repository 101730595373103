import {
    ArrowCircleLeftOutlined,
    CalendarMonthOutlined,
    CancelOutlined,
    ChatOutlined,
    CheckOutlined,
    ClearOutlined,
    PaymentsOutlined,
} from "@mui/icons-material";
import {
    Button,
    CircularProgress,
    IconButton,
    ListItemIcon,
    ListItemText,
    MenuItem,
    Stack,
    Typography,
    useMediaQuery,
} from "@mui/material";
import { isEmpty } from "lodash";
import { useSnackbar } from "notistack";
import { useCallback, useMemo, useState } from "react";

import { createChat } from "../chat/chatUpdates";
import { useNavigateToChat } from "../chat/useNavigateToChat";
import ActionButtons, { ActionButtonPrimary, ActionButtonSecondary } from "../common/ActionButtons";
import AppDialog from "../common/dialog/AppDialog";
import MoreButton from "../common/MoreButton";
import T from "../locallization/T";
import MetupalPageView from "../metupalim/MetupalPageView";
import { useResetUnreadCount } from "../notifications/useResetUnreadCount";
import MetupalSummaryCost from "../outputs/metupal/MetupalSummaryCost";
import MetupalSummaryOutput from "../outputs/metupal/MetupalSummaryOutput";
import { useAppContext } from "../root/AppContext";
import SearchInputTherapistTimes from "../search/therapistInputs/SearchInputTherapistTimes";
import useUpdateUserInfo from "../search/useUpdateUserInfo";
import { boldStyles } from "../theme/theme";
import WithThemeIcon from "../theme/WithThemeIcon";
import { METUPAL_USER_INFO, USER_INFO } from "../user/userInfo";
import { updateUserInfo } from "../user/userUpdates";
import DeletedStateCard from "./DeletedStateCard";
import MatchCard from "./MatchCard";
import MatchRejectDialog from "./MatchRejectDialog";
import MatchScheduleDialog from "./MatchScheduleDialog";
import { useDeclineMetupal } from "./useDeclineMetupal";
import { useOpenPayment } from "./useOpenPayment";
import { usePaymentDue } from "./usePaymentDue";

const SESSION_KEY_UPDATED_TIMES = "updated-times";

export default function MetupalMatchCard({
    metupalUserInfo,
    metupalUid,
}: {
    metupalUserInfo?: METUPAL_USER_INFO;
    metupalUid: string;
}) {
    const { user, userInfo, getSessionValue, setSessionValue } = useAppContext();
    const [loading, setLoading] = useState(false);
    const [showDialog, setShowDialog] = useState(false);
    const [showTimeDialog, setShowTimeDialog] = useState(false);
    const [showRejectDialog, setShowRejectDialog] = useState(false);
    const [showScheduleDialog, setShowScheduleDialog] = useState(false);
    const [newData, setNewData] = useState<USER_INFO>({});
    const { enqueueSnackbar } = useSnackbar();
    const resetUnreadCount = useResetUnreadCount({
        userInfoKey: "matchesCounts",
        targetUid: metupalUid,
    });
    const resetUnreadChatCount = useResetUnreadCount({ userInfoKey: "messagesCounts", targetUid: metupalUid });
    const paymentDue = usePaymentDue();
    const futurePaymentDue = usePaymentDue({ prepay: true });
    const hideButtonsIcons = useMediaQuery("(max-width: 370px)");

    const alreadyHasChat = (userInfo?.chatsWithMetupalim || {})[metupalUid];
    const alreadyMet = (userInfo?.met || {})[metupalUid];
    const checkMet = (userInfo?.checkMet || {})[metupalUid];
    const needPay = paymentDue[metupalUid];
    const canPay = !paymentDue[metupalUid] && futurePaymentDue[metupalUid];

    const onData = useUpdateUserInfo(setNewData);
    const navigateToChat = useNavigateToChat(metupalUid, { isTherapist: false });

    const timeSessionValue = useMemo(
        () => alreadyHasChat || getSessionValue(SESSION_KEY_UPDATED_TIMES),
        [getSessionValue, alreadyHasChat]
    );

    const toggleShowDialog = useCallback(() => {
        if (loading) {
            return;
        }
        setShowDialog((show) => !show);
    }, [loading]);
    const setTrueShowDialog = useCallback(() => {
        resetUnreadCount();
        setShowDialog(true);
    }, [resetUnreadCount]);
    const toggleShowTimeDialog = useCallback(
        (event) => {
            event.stopPropagation();
            if (loading) {
                return;
            }
            setShowTimeDialog((show) => !show);
        },
        [loading]
    );
    const toggleShowScheduleDialog = useCallback(
        (event) => {
            event.stopPropagation();
            if (loading) {
                return;
            }
            setShowScheduleDialog((show) => !show);
        },
        [loading]
    );
    const setTrueShowRejectDialog = useCallback((event) => {
        event.stopPropagation();
        setShowRejectDialog(true);
    }, []);

    const acceptMetupal = useCallback(
        (event) => {
            event.stopPropagation();
            if (!userInfo || !metupalUserInfo) {
                return Promise.reject();
            }

            if (alreadyHasChat) {
                resetUnreadChatCount();
                return navigateToChat();
            }
            resetUnreadCount();

            setLoading(true);

            createChat(userInfo, metupalUserInfo)
                .then(() => {
                    enqueueSnackbar(<T>match metupal accept success</T>, { variant: "success" });
                    navigateToChat();
                })
                .catch((error) => {
                    setLoading(false);
                    console.error(error);
                    enqueueSnackbar(<T params={{ code: 157 }}>general error</T>, { variant: "warning" });
                });
        },
        [
            userInfo,
            enqueueSnackbar,
            metupalUserInfo,
            navigateToChat,
            alreadyHasChat,
            resetUnreadCount,
            resetUnreadChatCount,
        ]
    );

    const saveTimeAndAcceptMetupal = useCallback(
        (event) => {
            event.stopPropagation();
            if (!user || !userInfo) {
                return Promise.reject();
            }

            setSessionValue(SESSION_KEY_UPDATED_TIMES, "1");
            setLoading(true);
            if (isEmpty(newData)) {
                acceptMetupal(event);
            } else {
                updateUserInfo(user.uid, userInfo, newData)
                    .then(() => acceptMetupal(event))
                    .catch((error) => {
                        setLoading(false);
                        console.error(error);
                        enqueueSnackbar(<T params={{ code: 158 }}>general error</T>, { variant: "warning" });
                    });
            }
        },
        [user, userInfo, newData, setSessionValue, acceptMetupal, enqueueSnackbar]
    );

    const { declineMetupal } = useDeclineMetupal({ metupalUid, setLoading });

    const { openPayment } = useOpenPayment();
    const { openPayment: openPrepayment } = useOpenPayment({ prepay: true });

    if (!metupalUserInfo || !userInfo) {
        // This shouldn't really appear anymore, but it might on bugs or errors.
        return <DeletedStateCard matchIsTherapist={false} onHide={declineMetupal} loading={loading} />;
    }

    const subheader = checkMet ? (
        <T>match therapist subtitle check met</T>
    ) : alreadyMet ? (
        <T>match therapist subtitle met</T>
    ) : (userInfo?.messagesCounts || {})[metupalUid] ? (
        <T>match therapist subtitle await chat</T>
    ) : (
        <T>match therapist subtitle can chat</T>
    );

    const buttonText = needPay ? (
        <T>match therapist button pay</T>
    ) : canPay ? (
        <T>match therapist button prepay</T>
    ) : checkMet ? (
        <T>match therapist button meet</T>
    ) : alreadyHasChat ? (
        <T>match therapist button has chat</T>
    ) : (
        <T>match therapist button no chat</T>
    );

    const declineText = checkMet ? (
        <T>metupal dialog button cancelled</T>
    ) : alreadyHasChat ? (
        <T>metupal dialog button decline has chat</T>
    ) : (
        <T>metupal dialog button no</T>
    );

    return (
        <>
            <MatchCard
                basicUserInfo={metupalUserInfo}
                subheader={subheader}
                actionSide={alreadyHasChat ? null : <MetupalSummaryCost newUserInfo={metupalUserInfo} />}
                action={
                    <Stack
                        flexDirection="row"
                        gap={hideButtonsIcons ? 0 : 1}
                        justifyContent="flex-end"
                        sx={{ marginLeft: "auto", whiteSpace: "nowrap" }}>
                        {alreadyHasChat && (
                            <Button
                                disabled={loading}
                                variant="text"
                                onClick={setTrueShowRejectDialog}
                                startIcon={hideButtonsIcons ? null : <CancelOutlined />}>
                                {declineText}
                            </Button>
                        )}
                        <Button
                            sx={{ ml: "auto" }}
                            startIcon={
                                hideButtonsIcons ? null : loading ? (
                                    <CircularProgress size={24} />
                                ) : needPay || canPay ? (
                                    <PaymentsOutlined />
                                ) : checkMet ? (
                                    <CalendarMonthOutlined />
                                ) : (
                                    <ChatOutlined />
                                )
                            }
                            onClick={
                                needPay
                                    ? openPayment
                                    : canPay
                                    ? openPrepayment
                                    : checkMet
                                    ? toggleShowScheduleDialog
                                    : timeSessionValue
                                    ? acceptMetupal
                                    : toggleShowTimeDialog
                            }
                            variant="contained"
                            disabled={loading}
                            size="large">
                            {buttonText}
                        </Button>
                    </Stack>
                }
                HeaderProps={{
                    onClick: setTrueShowDialog,
                    action: (
                        <Stack flexDirection="row">
                            <IconButton color="primary" onClick={setTrueShowDialog}>
                                <WithThemeIcon Icon={ArrowCircleLeftOutlined} />
                            </IconButton>
                            <MoreButton sx={{ alignSelf: "baseline" }}>
                                <MenuItem
                                    onClick={timeSessionValue ? acceptMetupal : toggleShowTimeDialog}
                                    disabled={loading}>
                                    <ListItemIcon>
                                        {loading ? (
                                            <CircularProgress size={20} />
                                        ) : (
                                            <ChatOutlined fontSize="small" color="primary" />
                                        )}
                                    </ListItemIcon>
                                    <ListItemText>
                                        {alreadyHasChat ? (
                                            <T>match therapist button has chat</T>
                                        ) : (
                                            <T>match therapist button no chat</T>
                                        )}
                                    </ListItemText>
                                </MenuItem>
                                {alreadyHasChat && !alreadyMet && (
                                    <MenuItem onClick={toggleShowScheduleDialog} disabled={loading}>
                                        <ListItemIcon>
                                            {loading ? (
                                                <CircularProgress size={20} />
                                            ) : (
                                                <CalendarMonthOutlined fontSize="small" color="primary" />
                                            )}
                                        </ListItemIcon>
                                        <ListItemText>{<T>match therapist button meet</T>}</ListItemText>
                                    </MenuItem>
                                )}
                                {!needPay && (
                                    <MenuItem onClick={setTrueShowRejectDialog} disabled={loading}>
                                        <ListItemIcon>
                                            {loading ? (
                                                <CircularProgress size={20} />
                                            ) : (
                                                <ClearOutlined fontSize="small" color="primary" />
                                            )}
                                        </ListItemIcon>
                                        <ListItemText>{declineText}</ListItemText>
                                    </MenuItem>
                                )}
                            </MoreButton>
                        </Stack>
                    ),
                    sx: {
                        cursor: "pointer",
                    },
                }}>
                <MetupalSummaryOutput newUserInfo={metupalUserInfo} short />
            </MatchCard>
            <AppDialog open={showDialog} setOpen={setShowDialog} fullScreen disableGutters>
                <MetupalPageView metupalUserInfo={metupalUserInfo} onBack={toggleShowDialog} subtitle={subheader} />
                <ActionButtons mt={3} flexDirection="column">
                    {!needPay && (
                        <Button
                            disabled={loading}
                            variant="text"
                            onClick={setTrueShowRejectDialog}
                            sx={{ alignSelf: "center" }}
                            startIcon={<CancelOutlined />}>
                            {declineText}
                        </Button>
                    )}
                    <ActionButtonPrimary
                        onClick={
                            needPay
                                ? openPayment
                                : canPay
                                ? openPrepayment
                                : checkMet
                                ? toggleShowScheduleDialog
                                : timeSessionValue
                                ? acceptMetupal
                                : toggleShowTimeDialog
                        }
                        disabled={loading}
                        endIcon={
                            loading ? (
                                <CircularProgress size={24} />
                            ) : needPay || canPay ? (
                                <PaymentsOutlined />
                            ) : checkMet ? (
                                <CalendarMonthOutlined />
                            ) : (
                                <ChatOutlined />
                            )
                        }>
                        {buttonText}
                    </ActionButtonPrimary>
                </ActionButtons>
            </AppDialog>

            <AppDialog
                open={showTimeDialog}
                setOpen={setShowTimeDialog}
                actions={
                    <>
                        <ActionButtonSecondary
                            onClick={toggleShowTimeDialog}
                            disabled={loading}
                            endIcon={<ClearOutlined />}>
                            <T>dialog button cancel</T>
                        </ActionButtonSecondary>
                        <ActionButtonPrimary
                            onClick={saveTimeAndAcceptMetupal}
                            disabled={loading}
                            endIcon={loading ? <CircularProgress size={24} /> : <CheckOutlined />}>
                            <T>dialog button confirm</T>
                        </ActionButtonPrimary>
                    </>
                }>
                <Stack gap={2}>
                    <Typography variant="h3" sx={{ ...boldStyles }}>
                        <T>metupal dialog time title</T>
                    </Typography>
                    <Typography variant="h4">
                        <T>metupal dialog time subtitle</T>
                    </Typography>
                    <Typography variant="h4" sx={{ mt: 2 }}>
                        <T suffix=":">metupal dialog time prompt</T>
                    </Typography>
                    <SearchInputTherapistTimes onData={onData} newUserInfo={userInfo} expanded preventEmpty />
                </Stack>
            </AppDialog>

            <MatchScheduleDialog
                metupalUid={metupalUid}
                showScheduleDialog={showScheduleDialog}
                setShowScheduleDialog={setShowScheduleDialog}
                loading={loading}
                setLoading={setLoading}
            />

            <MatchRejectDialog
                metupalUid={metupalUid}
                showRejectDialog={showRejectDialog}
                setShowRejectDialog={setShowRejectDialog}
                loading={loading}
                setLoading={setLoading}
            />
        </>
    );
}
