import { getTalkGender } from "../../common/gender/getTalkGender";
import T from "../../locallization/T";
import {
    metupalQuestionIcons,
    metupalQuestionIconsColorful,
    shouldShowAge,
} from "../../onboarding/OnboardingQuestionsMetupal";
import { METUPAL_OUTPUT_PROPS } from "../basic/OutputProps";
import OutputRow from "../basic/OutputRow";
import { useYearsToAge } from "../basic/useYearsToAge";

export default function MetupalOutputAge({ newUserInfo, colorful, ...props }: METUPAL_OUTPUT_PROPS) {
    const age = useYearsToAge(newUserInfo?.year);

    return (
        <OutputRow
            {...props}
            title={<T overrideGender={getTalkGender(newUserInfo)}>metupal output age view</T>}
            value={
                (shouldShowAge(newUserInfo) && (
                    <T params={{ age }} overrideGender={getTalkGender(newUserInfo)}>
                        metupal output age value
                    </T>
                )) || <T>output was not mentioned</T>
            }
            Icon={colorful ? metupalQuestionIconsColorful.age : metupalQuestionIcons.age}
        />
    );
}
