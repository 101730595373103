import { Stack } from "@mui/material";
import { without } from "lodash";
import { useCallback } from "react";

import T from "../../locallization/T";
import SearchInputBasicChips from "../basic/SearchInputBasicChips";
import SearchInputBasicNumberedSet from "../basic/SearchInputBasicNumeredSet";
import SearchInputBasicTextarea from "../basic/SearchInputBasicTextarea";
import { SEARCH_INPUT_PROPS } from "./SearchInputProps";
import { useSortedReasons } from "./useSortedReasons";

const MAX_REASONS_LENGTH = 750;

export const allReasons: number[] = [
    1, 5, 11, 12, 13, 14, 15, 16, 17, 18, 20, 21, 22, 23, 24, 25, 26, 27, 29, 30, 32, 33, 35, 36, 37, 40, 42, 44, 45,
    46, 47, 49, 50, 56, 59,
];

export const REASONS_LIMIT = 5;

export default function SearchInputReasons({ onData, newUserInfo }: SEARCH_INPUT_PROPS) {
    const { sortedReasons } = useSortedReasons(allReasons);

    const getValueLabel = useCallback((reason: number) => <T children={`reason ${reason}`} />, []);

    const isValueSelected = useCallback(() => true, []);
    const onValueDelete = useCallback(
        (reason: number) => {
            onData({ reasonsList: without(newUserInfo?.reasonsList, reason) });
        },
        [onData, newUserInfo?.reasonsList]
    );

    return (
        <Stack gap={1}>
            <SearchInputBasicNumberedSet
                onData={onData}
                newUserInfo={newUserInfo}
                userInfoKey="reasonsList"
                label={<T>search input reasons list</T>}
                allValues={sortedReasons}
                getValueLabel={getValueLabel}
                limit={REASONS_LIMIT}
            />
            <SearchInputBasicChips
                allValues={newUserInfo?.reasonsList || []}
                getValueLabel={getValueLabel}
                isValueSelected={isValueSelected}
                onValueDelete={onValueDelete}
                forceHorizontal={false}
            />
            <SearchInputBasicTextarea
                sx={{ mt: 2 }}
                onData={onData}
                newUserInfo={newUserInfo}
                userInfoKey="reasonsText"
                label={<T>search input reasons text</T>}
                helperText={<T>search input reasons subtext limit</T>}
                maxLength={MAX_REASONS_LENGTH}
            />
        </Stack>
    );
}
