import { HistoryOutlined, SearchOutlined } from "@mui/icons-material";
import { Button, CircularProgress, Stack, Typography } from "@mui/material";
import { useCallback, useMemo, useState } from "react";

import AppDialog from "../../common/dialog/AppDialog";
import NonEmptyDivider from "../../common/NonEmptyDivider";
import { LOAD_OPTIONS, useLoadUserInfos } from "../../matches/userInfosService";
import { useSortedMatches } from "../../matches/useSortedMatches";
import TopNav from "../../navigation/TopNav";
import OutputRow from "../../outputs/basic/OutputRow";
import TherapistPagePreviewButton from "../../therapist_page/TherapistPagePreview";
import { USER_INFO } from "../../user/userInfo";

export default function AdminSearchHistoryButton({ newUserInfo }: { newUserInfo: USER_INFO }) {
    const [showDialog, setShowDialog] = useState(false);
    const matchIds = useSortedMatches(newUserInfo.readyMatches);
    const options: LOAD_OPTIONS = useMemo(() => ({ skip: !showDialog, filterNulls: true }), [showDialog]);
    const { loading: loadingMatched, userInfos: userInfosMatched } = useLoadUserInfos(matchIds, true, options);

    const setTrueShowDialog = useCallback(() => {
        setShowDialog(true);
    }, []);

    const setFalseShowDialog = useCallback(() => {
        setShowDialog(false);
    }, []);

    return (
        <>
            <Button onClick={setTrueShowDialog} color="info" startIcon={<SearchOutlined />}>
                חיפושים אחרונים ({newUserInfo.searchHistory?.length || 0})
            </Button>
            <AppDialog
                open={showDialog}
                setOpen={setShowDialog}
                fullScreen
                topNav={<TopNav onBack={setFalseShowDialog} title="היסטוריית חיפושים" />}>
                <Stack gap={3} flexDirection="column-reverse" divider={<NonEmptyDivider sx={{ mx: -2 }} />} pb={3}>
                    {newUserInfo.searchHistory?.length ? (
                        newUserInfo.searchHistory.map(({ date, sentTo, total }, index) => {
                            const isLastSearch = index + 1 === newUserInfo.searchHistory?.length;
                            return (
                                <Stack gap={1} key={index}>
                                    <OutputRow
                                        title={"תאריך"}
                                        value={(date as any).toDate().toLocaleString("he")}
                                        short
                                    />
                                    <OutputRow title={"לכמה מטפלים הגיעה הפנייה"} value={sentTo} short />
                                    <OutputRow title={"כמה מטפלים בכללי רלוונטים"} value={total} short />

                                    {isLastSearch && sentTo ? (
                                        <OutputRow
                                            title={"המטפלים שהותאמו"}
                                            value={
                                                loadingMatched ? (
                                                    <CircularProgress />
                                                ) : (
                                                    userInfosMatched.map((therapistUserInfo) => (
                                                        <Stack
                                                            key={therapistUserInfo?.uid}
                                                            flexDirection="row"
                                                            alignItems="center">
                                                            <TherapistPagePreviewButton
                                                                sx={{ flexShrink: 0 }}
                                                                newUserInfo={therapistUserInfo}
                                                            />{" "}
                                                            <Typography variant="caption">
                                                                (
                                                                {(newUserInfo.rejectedByTherapists || {})[
                                                                    therapistUserInfo?.uid || ""
                                                                ]
                                                                    ? "המטפל דחה"
                                                                    : (newUserInfo.rejectedTherapists || {})[
                                                                          therapistUserInfo?.uid || ""
                                                                      ]
                                                                    ? `המטופל דחה - הסיבה: "${
                                                                          (newUserInfo.rejectedTherapists || {})[
                                                                              therapistUserInfo?.uid || ""
                                                                          ]?.reason
                                                                      }"`
                                                                    : (newUserInfo.chats || {})[
                                                                          therapistUserInfo?.uid || ""
                                                                      ]
                                                                    ? "צ'אט נפתח"
                                                                    : "ממתין"}
                                                                )
                                                            </Typography>
                                                        </Stack>
                                                    ))
                                                )
                                            }
                                            Icon={HistoryOutlined}
                                        />
                                    ) : null}
                                </Stack>
                            );
                        })
                    ) : (
                        <Typography>אין עדיין חיפושים</Typography>
                    )}
                </Stack>
            </AppDialog>
        </>
    );
}
