import T from "../../locallization/T";
import { therapistQuestionIcons } from "../../onboarding/therapist/OnboardingQuestionsTherapist";
import { THERAPIST_OUTPUT_PROPS } from "../basic/OutputProps";
import OutputRow from "../basic/OutputRow";
import { useYearsToAge } from "../basic/useYearsToAge";

export default function TherapistOutputLicenseYear({ newUserInfo, ...props }: THERAPIST_OUTPUT_PROPS) {
    const licenseAge = useYearsToAge(newUserInfo?.therapistLicenseYear);
    if (!licenseAge) {
        return null;
    }
    return (
        <OutputRow
            {...props}
            title={<T>therapist output license age</T>}
            value={<T params={{ count: licenseAge }}>basic output age</T>}
            Icon={therapistQuestionIcons.therapist_ages}
            hideOnEmpty
        />
    );
}
