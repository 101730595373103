import { map } from "lodash";

import SeparatedList from "../../common/SeparatedList";
import T from "../../locallization/T";
import { userInfoAges } from "../../onboarding/questions/OnboardingQuestionTherapistAges";
import {
    therapistQuestionIcons,
    therapistQuestionIconsColorful,
} from "../../onboarding/therapist/OnboardingQuestionsTherapist";
import { THERAPIST_OUTPUT_PROPS } from "../basic/OutputProps";
import OutputRow from "../basic/OutputRow";
import { useSortedOutput } from "../basic/useSortedOutput";

export default function TherapistOutputAges({ newUserInfo, colorful, ...props }: THERAPIST_OUTPUT_PROPS) {
    const sortedAges = useSortedOutput(newUserInfo?.therapistAges, userInfoAges, "value");

    return (
        <OutputRow
            {...props}
            title={<T>therapist output ages</T>}
            value={
                <SeparatedList>
                    {map(sortedAges, (age) => (
                        <T key={age} children={`onboarding question therapist ages ${age}`} />
                    ))}
                </SeparatedList>
            }
            Icon={colorful ? therapistQuestionIconsColorful.therapist_ages : therapistQuestionIcons.therapist_ages}
        />
    );
}
