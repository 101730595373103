import {
    AccessTimeOutlined,
    AccessTimeTwoTone,
    CalculateOutlined,
    CalculateTwoTone,
    CalendarMonthOutlined,
    EditOutlined,
    EditTwoTone,
    LocalHospitalOutlined,
    LocalHospitalTwoTone,
    LocationOnOutlined,
    LocationOnTwoTone,
    PermContactCalendarTwoTone,
} from "@mui/icons-material";
import { useCallback } from "react";

import { USER_INFO } from "../../user/userInfo";
import OnboardingQuestions from "../OnboardingQuestions";
import { ONBOARDING_QUESTION_PROPS } from "../questions/OnboardingQuestionProps";
import OnboardingQuestionTherapistAges, { alreadyHasTherapistAges } from "../questions/OnboardingQuestionTherapistAges";
import OnboardingQuestionTherapistCost, { alreadyHasTherapistCost } from "../questions/OnboardingQuestionTherapistCost";
import OnboardingQuestionTherapistKupot, {
    alreadyHasTherapistKupot,
} from "../questions/OnboardingQuestionTherapistKupot";
import OnboardingQuestionTherapistLocation, {
    alreadyHasTherapistLocation,
} from "../questions/OnboardingQuestionTherapistLocation";
import OnboardingQuestionTherapistTimes, {
    alreadyHasTherapistTimes,
} from "../questions/OnboardingQuestionTherapistTimes";
import OnboardingQuestionTherapistType, { alreadyHasTherapistType } from "../questions/OnboardingQuestionTherapistType";

export const therapistQuestionIcons = {
    therapist_location: LocationOnOutlined,
    therapist_type: EditOutlined,
    therapist_ages: CalendarMonthOutlined,
    therapist_times: AccessTimeOutlined,
    therapist_cost: CalculateOutlined,
    therapist_kupot: LocalHospitalOutlined,
};
export const therapistQuestionIconsColorful = {
    therapist_location: LocationOnTwoTone,
    therapist_type: EditTwoTone,
    // Ages uses a different icon because the two tone looks bad on the og one
    therapist_ages: PermContactCalendarTwoTone,
    therapist_times: AccessTimeTwoTone,
    therapist_cost: CalculateTwoTone,
    therapist_kupot: LocalHospitalTwoTone,
};

const existingDataCheckers: { [key: string]: (userInfo: USER_INFO) => boolean } = {
    therapist_location: alreadyHasTherapistLocation,
    therapist_type: alreadyHasTherapistType,
    therapist_ages: alreadyHasTherapistAges,
    therapist_times: alreadyHasTherapistTimes,
    therapist_cost: alreadyHasTherapistCost,
    therapist_kupot: alreadyHasTherapistKupot,
};

const questionComponents: { [key: string]: (props: ONBOARDING_QUESTION_PROPS) => JSX.Element } = {
    therapist_location: OnboardingQuestionTherapistLocation,
    therapist_type: OnboardingQuestionTherapistType,
    therapist_ages: OnboardingQuestionTherapistAges,
    therapist_times: OnboardingQuestionTherapistTimes,
    therapist_cost: OnboardingQuestionTherapistCost,
    therapist_kupot: OnboardingQuestionTherapistKupot,
};
const pageTitleKeys: { [key: string]: string } = {
    therapist_location: "therapist output location",
    therapist_type: "therapist output types",
    therapist_ages: "therapist output ages",
    therapist_times: "therapist output times",
    therapist_cost: "therapist output cost",
    therapist_kupot: "therapist output cost kupot",
};

const questionsOrder = [
    "therapist_location",
    "therapist_type",
    "therapist_ages",
    "therapist_times",
    "therapist_cost",
    "therapist_kupot",
];

export default function OnboardingQuestionsTherapist() {
    const getOrderedScreens = useCallback(() => questionsOrder, []);

    const endFlow = useCallback(() => {
        // do nothing, router should handle what to show next
    }, []);

    return (
        <OnboardingQuestions
            questionIcons={therapistQuestionIcons}
            existingDataCheckers={existingDataCheckers}
            questionComponents={questionComponents}
            getOrderedScreens={getOrderedScreens}
            pageTitleKeys={pageTitleKeys}
            onEndFlow={endFlow}
        />
    );
}
