import { User } from "firebase/auth";
import { useSnackbar } from "notistack";
import { useCallback, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";

import T from "../locallization/T";
import { RESULTS_LOADING_STATE, ROUTES } from "../navigation/Router";
import { useAppContext } from "../root/AppContext";
import { USER_INFO } from "../user/userInfo";
import { updateUserInfo } from "../user/userUpdates";
import useUpdateUserInfo from "./useUpdateUserInfo";

export function useSaveSearch() {
    const { user, userInfo } = useAppContext();
    const [updatedData, setUpdatedData] = useState<USER_INFO>({});
    const [loading, setLoading] = useState(false);
    const { enqueueSnackbar } = useSnackbar();
    const navigate = useNavigate();
    const onData = useUpdateUserInfo(setUpdatedData);

    const newUserInfo = useMemo(() => ({ ...userInfo, ...updatedData }), [userInfo, updatedData]);

    const saveData = useCallback(() => {
        setLoading(true);
        updateUserInfo(
            (user as User).uid,
            userInfo,
            userInfo?.isTherapist ? updatedData : { ...updatedData, enableSearch: true, noMatchesIndicator: false }
        )
            .then(() => {
                if (userInfo?.isTherapist && !userInfo?.therapistEnableSearch) {
                    // search is disabled, so only show a save snackbar
                    enqueueSnackbar(<T>profile setting save notice success</T>, { variant: "success" });
                    setLoading(false);
                    setUpdatedData({});
                } else {
                    navigate(ROUTES.RESULTS_LOADER_EDIT, RESULTS_LOADING_STATE);
                }
            })
            .catch((error) => {
                setLoading(false);
                console.error(error);
                enqueueSnackbar(<T params={{ code: 171 }}>general error</T>, { variant: "warning" });
            });
    }, [enqueueSnackbar, navigate, user, userInfo, updatedData]);

    return { saveData, loading, onData, updatedData, newUserInfo };
}
