import { ArrowBack } from "@mui/icons-material";
import { Button, CircularProgress, Stack, Typography } from "@mui/material";
import { applyActionCode, confirmPasswordReset, signOut, User } from "firebase/auth";
import { useSnackbar } from "notistack";
import { ReactNode, useCallback, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import PasswordInput from "../common/PasswordInput";
import { getFirebaseAuth } from "../firebase/firebase";
import T from "../locallization/T";
import { removeLastEmailInStorage } from "../login/sign/EmailVerifiedHolder";
import SignInFormContainer from "../login/sign/SignInFormContainer";
import AppSpinner from "../navigation/AppSpinner";
import PageContainer from "../navigation/PageContainer";
import { LOGIN_STATE_CONTINUE_URL, ROUTES } from "../navigation/Router";
import { useAppContext } from "../root/AppContext";
import { getSpacedButtonIconProps } from "../theme/buttons";
import WithThemeIcon from "../theme/WithThemeIcon";
import { updateUserInfo } from "../user/userUpdates";

export default function ActionsResponder() {
    const navigate = useNavigate();
    const { enqueueSnackbar } = useSnackbar();
    const { user, userInfo } = useAppContext();

    const [resetPasswordCode, setResetPasswordCode] = useState("");
    const [newPassword, setNewPassword] = useState("");
    const [newPasswordConfirm, setNewPasswordConfirm] = useState("");
    const [unsub, setUnsub] = useState(false);
    const [showCloseTabButtonTitle, setShowCloseTabButtonTitle] = useState<ReactNode>(undefined);

    const [loading, setLoading] = useState(false);

    const verifyEmail = useCallback(
        (emailCode: string) => {
            setLoading(true);
            applyActionCode(getFirebaseAuth(), emailCode)
                .then(() => {
                    enqueueSnackbar(<T>email verify form success</T>, { variant: "success" });
                    removeLastEmailInStorage();
                    setShowCloseTabButtonTitle(<T>email verify form success</T>);
                })
                .catch((error) => {
                    if (!getFirebaseAuth().currentUser) {
                        // the user is probably verified already, so go to the login page and come back after logged in.
                        navigate(ROUTES.LOGIN, {
                            state: {
                                [LOGIN_STATE_CONTINUE_URL]: `${window.location.pathname}${window.location.search}`,
                            },
                            replace: true,
                        });
                    } else {
                        console.error("verifying account error", error);
                        enqueueSnackbar(<T params={{ code: 166 }}>general error</T>, { variant: "warning" });
                        setLoading(false);
                    }
                });
        },
        [navigate, enqueueSnackbar]
    );

    const resetPasswordHandler = useCallback(() => {
        if (newPassword !== newPasswordConfirm) {
            return enqueueSnackbar(<T>login form forgot error mismatch</T>, { variant: "warning" });
        }
        setLoading(true);
        confirmPasswordReset(getFirebaseAuth(), resetPasswordCode, newPassword)
            .then(() => {
                enqueueSnackbar(<T>login form reset confirm</T>, { variant: "success" });
                navigate(ROUTES.LOGIN, {
                    replace: true,
                });
            })
            .catch((error) => {
                console.error("verifying account error", error);
                let errorMessage = <T params={{ code: 167 }}>general error</T>;
                if (
                    error.message.includes("auth/expired-action-code") ||
                    error.message.includes("auth/invalid-action-code")
                ) {
                    errorMessage = <T>login form forgot outdated</T>;
                }

                enqueueSnackbar(errorMessage, { variant: "warning" });
                setLoading(false);
            });
    }, [newPassword, newPasswordConfirm, resetPasswordCode, navigate, enqueueSnackbar]);

    useEffect(() => {
        if (!unsub) {
            return;
        }
        setLoading(true);
        updateUserInfo((user as User).uid, userInfo, { unsub: true })
            .then(() => {
                enqueueSnackbar(<T>unsub success</T>, { variant: "success" });
                navigate(ROUTES.HOME, {
                    replace: true,
                });
            })
            .catch((error) => {
                console.error("unsubscribing error", error);
                enqueueSnackbar(<T params={{ code: 168 }}>general error</T>, { variant: "warning" });
                setLoading(false);
            });
    }, [navigate, enqueueSnackbar, unsub, user, userInfo]);

    useEffect(() => {
        const searchParams = new URLSearchParams(window.location.search);
        const code = searchParams.get("oobCode") || "";
        const mode = searchParams.get("mode");
        if (code && mode === "verifyEmail") {
            if (getFirebaseAuth().currentUser?.emailVerified) {
                // already verified, go home.
                navigate(ROUTES.HOME, {
                    replace: true,
                });
            } else {
                verifyEmail(code);
            }
        } else if (code && mode === "resetPassword") {
            // ensure you are signed out when you reset password, so you can login with it afterwards
            signOut(getFirebaseAuth());
            setResetPasswordCode(code);
        } else if (mode === "unsub") {
            if (!getFirebaseAuth().currentUser) {
                navigate(ROUTES.LOGIN, {
                    state: { [LOGIN_STATE_CONTINUE_URL]: `${window.location.pathname}${window.location.search}` },
                    replace: true,
                });
            } else {
                setUnsub(true);
            }
        } else {
            navigate(ROUTES.HOME, {
                replace: true,
            });
        }
    }, [navigate, verifyEmail]);

    if (resetPasswordCode) {
        return (
            <SignInFormContainer pageTitleKey="login form new password title" pagePath={ROUTES.ACTIONS}>
                <PasswordInput type="new" disabled={loading} password={newPassword} setPassword={setNewPassword} />
                <PasswordInput
                    type="confirm new"
                    disabled={loading}
                    password={newPasswordConfirm}
                    setPassword={setNewPasswordConfirm}
                />
                <Button
                    size="huge"
                    color="primary"
                    variant="contained"
                    {...getSpacedButtonIconProps()}
                    disabled={loading || newPassword.length < 6 || newPasswordConfirm.length < 6}
                    endIcon={loading ? <CircularProgress size={24} /> : <WithThemeIcon Icon={ArrowBack} />}
                    onClick={resetPasswordHandler}>
                    <T>login details button continue</T>
                </Button>
            </SignInFormContainer>
        );
    }

    if (showCloseTabButtonTitle) {
        return (
            <PageContainer showLogo hideBotNav hideBack pageReady={false}>
                <Stack alignItems="center" justifyContent="center" height="100%">
                    <Stack gap={1} textAlign="center">
                        <Typography variant="h2">{showCloseTabButtonTitle}</Typography>
                        <Typography variant="h3">
                            <T>action responder close tab prompt</T>
                        </Typography>
                    </Stack>
                </Stack>
            </PageContainer>
        );
    }

    return <AppSpinner />;
}
