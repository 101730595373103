import { Box, FormHelperText, Slider, Stack, Typography } from "@mui/material";
import { useCallback, useState } from "react";

import T from "../../locallization/T";
import { RTL_LOCALES } from "../../locallization/useLocale";
import { useAppContext } from "../../root/AppContext";
import { SEARCH_INPUT_PROPS } from "../inputs/SearchInputProps";

const MIN_AGE = 0;
const MAX_AGE = 120;

const marks = [
    {
        value: 0,
    },
    { value: 10 },
    { value: 20 },
    { value: 30 },
    { value: 40 },
    { value: 50 },
    { value: 60 },
    { value: 70 },
    { value: 80 },
    { value: 90 },
    { value: 100 },
    { value: 110 },
    {
        value: MAX_AGE,
    },
];

export default function SearchInputTherapistAgesRange({ onData, newUserInfo }: SEARCH_INPUT_PROPS) {
    const { locale } = useAppContext();
    const isRtl = RTL_LOCALES.has(locale || "");

    const initialAges = isRtl ? flipAges(newUserInfo?.therapistAgesRange) : newUserInfo?.therapistAgesRange || [];
    const [agesRangeValue, setAgesRangeValue] = useState<number[]>(
        initialAges.length ? initialAges : isRtl ? [MAX_AGE, MIN_AGE] : [MIN_AGE, MAX_AGE]
    );

    const handleChange = useCallback((event: Event, newValue: number | number[]) => {
        setAgesRangeValue(newValue as number[]);
    }, []);

    const finishedChange = useCallback(() => {
        onData({
            therapistAgesRange: isRtl ? flipAges(agesRangeValue) : agesRangeValue,
        });
    }, [onData, agesRangeValue, isRtl]);

    return (
        <Stack gap={5}>
            <Typography>
                <T>search input therapist ages range</T>
            </Typography>

            <Box mx={2}>
                <Slider
                    onChangeCommitted={finishedChange}
                    value={agesRangeValue}
                    onChange={handleChange}
                    marks={marks}
                    min={marks[0].value}
                    max={marks[marks.length - 1].value}
                    valueLabelDisplay="on"
                    valueLabelFormat={(x) => (isRtl ? MAX_AGE - x : x)}
                />
                <FormHelperText sx={{ mt: -0.5 }}>
                    <T>search input therapist ages helper</T>
                </FormHelperText>
            </Box>
        </Stack>
    );
}

function flipAges(ages?: number[]) {
    if (ages?.length) {
        return [MAX_AGE - ages[1], MAX_AGE - ages[0]];
    }
    return [];
}
