import { Stack, TextField, Typography } from "@mui/material";
import { isNaN } from "lodash";
import { useCallback, useMemo, useState } from "react";

import InputCostSlider from "../../inputs/InputCostSlider";
import T from "../../locallization/T";
import { DEFAULT_COST, userInfoCosts } from "../../onboarding/questions/OnboardingQuestionTherapistCost";
import { SEARCH_INPUT_PROPS } from "./SearchInputProps";

export default function SearchInputCost({
    onData,
    newUserInfo,
    showTextInput = false,
    showAverageNote = false,
}: SEARCH_INPUT_PROPS & {
    showTextInput?: boolean;
    showAverageNote?: boolean;
}) {
    const { base, min, max } = useMemo(() => {
        const therapyType =
            newUserInfo?.therapyType === "child" || !newUserInfo?.therapyType ? "personal" : newUserInfo?.therapyType;
        return userInfoCosts.find((cost) => therapyType === cost.therapyType) || userInfoCosts[0];
    }, [newUserInfo]);
    const [costValue, setCostValue] = useState<number>(newUserInfo?.cost || base);

    const updateCostValue = useCallback((value: number) => {
        setCostValue(value);
    }, []);
    const finishedChange = useCallback(() => {
        const inBoundries = costValue >= min && costValue <= max;
        onData({
            cost: inBoundries ? costValue : undefined,
        });
    }, [onData, costValue, min, max]);

    return (
        <Stack gap={4}>
            {showTextInput ? (
                <TextField
                    label={<T>search input cost</T>}
                    fullWidth
                    variant="outlined"
                    type="text"
                    inputProps={{
                        inputMode: "tel",
                    }}
                    value={isNaN(costValue) ? "" : costValue}
                    onChange={(event) => updateCostValue(+event.target.value)}
                    onBlur={finishedChange}
                    helperText={showAverageNote && <T params={{ cost: DEFAULT_COST }}>search input cost avg note</T>}
                />
            ) : (
                <Typography>
                    <T>search input cost</T>
                </Typography>
            )}
            <InputCostSlider
                finishedChange={finishedChange}
                costValue={costValue}
                setCostValue={updateCostValue}
                maxCost={max}
                minCost={min}
            />
        </Stack>
    );
}
