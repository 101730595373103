import { Stack, Typography } from "@mui/material";
import { useEffect, useMemo, useState } from "react";

import T from "../../locallization/T";
import { useAppContext } from "../../root/AppContext";
import SearchInputTherapistTimes from "../../search/therapistInputs/SearchInputTherapistTimes";
import { boldStyles } from "../../theme/theme";
import { SETTINGS_PROPS } from "./SettingsProps";

export default function SettingSearch({ onData, newUserInfo }: SETTINGS_PROPS) {
    const { userInfo } = useAppContext();
    const toggleSearchKey = useMemo(
        () => (userInfo?.isTherapist ? "therapistEnableSearch" : "enableSearch"),
        [userInfo?.isTherapist]
    );

    // using state so it doesn't change right after saving before the dialog closes
    const [currentSearchValue] = useState((userInfo || {})[toggleSearchKey]);

    // on load set the value as opposite, then show warning.
    useEffect(() => {
        onData({
            [toggleSearchKey]: !currentSearchValue,
        });
    }, [onData, toggleSearchKey, currentSearchValue]);

    const title = useMemo(() => {
        if (currentSearchValue && userInfo?.isTherapist) {
            return <T>search dialog stop title therapist</T>;
        } else if (currentSearchValue) {
            return <T>search dialog stop title</T>;
        } else if (!currentSearchValue && userInfo?.isTherapist) {
            return <T>search dialog start title therapist</T>;
        } else {
            return <T>search dialog start title</T>;
        }
    }, [currentSearchValue, userInfo?.isTherapist]);
    const subtitle = useMemo(() => {
        if (currentSearchValue && userInfo?.isTherapist) {
            return <T>search dialog stop subtitle therapist</T>;
        } else if (currentSearchValue) {
            return <T>search dialog stop subtitle</T>;
        } else if (!currentSearchValue && userInfo?.isTherapist) {
            return <T>search dialog start subtitle therapist</T>;
        } else {
            return <T>search dialog start subtitle</T>;
        }
    }, [currentSearchValue, userInfo?.isTherapist]);

    return (
        <Stack gap={2}>
            <Typography variant="h3" sx={{ ...boldStyles }}>
                {title}
            </Typography>
            <Typography variant="h4">{subtitle}</Typography>

            {!currentSearchValue && userInfo?.isTherapist && (
                <SearchInputTherapistTimes onData={onData} newUserInfo={newUserInfo} expanded preventEmpty />
            )}
        </Stack>
    );
}
