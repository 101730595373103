import { SvgIcon, SvgIconProps } from "@mui/material";

import { RTL_LOCALES } from "../locallization/useLocale";
import { useAppContext } from "../root/AppContext";

export default function WithThemeIcon({
    Icon,
    reverse,
    sx = {},
    ...props
}: { Icon: typeof SvgIcon; reverse?: boolean } & SvgIconProps) {
    const { locale } = useAppContext();

    let flip = RTL_LOCALES.has(locale || "");
    if (reverse) {
        flip = !flip;
    }

    return <Icon sx={{ ...sx, transform: flip ? undefined : "scaleX(-1)" }} {...props} />;
}
