import { Stack } from "@mui/material";
import { without } from "lodash";
import { useCallback } from "react";

import T from "../../locallization/T";
import SearchInputBasicChips from "../basic/SearchInputBasicChips";
import SearchInputBasicNumberedSet from "../basic/SearchInputBasicNumeredSet";
import { SEARCH_INPUT_PROPS } from "../inputs/SearchInputProps";
import { allReasons } from "../inputs/SearchInputReasons";
import { useSortedReasons } from "../inputs/useSortedReasons";

export default function SearchInputTherapistReasonsNot({ onData, newUserInfo }: SEARCH_INPUT_PROPS) {
    const { sortedReasons } = useSortedReasons(allReasons);

    const getValueLabel = useCallback((reason: number) => <T children={`reason ${reason}`} />, []);
    const onValueDelete = useCallback(
        (reason: number) => {
            onData({ therapistReasonsNotList: without(newUserInfo?.therapistReasonsNotList, reason) });
        },
        [onData, newUserInfo?.therapistReasonsNotList]
    );

    return (
        <Stack gap={1}>
            <SearchInputBasicNumberedSet
                onData={onData}
                newUserInfo={newUserInfo}
                userInfoKey="therapistReasonsNotList"
                label={<T>search input therapist reasons not</T>}
                allValues={sortedReasons}
                getValueLabel={getValueLabel}
            />
            <SearchInputBasicChips
                allValues={newUserInfo?.therapistReasonsNotList || []}
                getValueLabel={getValueLabel}
                onValueDelete={onValueDelete}
                forceHorizontal={false}
            />
        </Stack>
    );
}
