import {
    CancelOutlined,
    ChevronLeftOutlined,
    EditOutlined,
    OpenInNewOutlined,
    PanToolAltOutlined,
    SaveOutlined,
} from "@mui/icons-material";
import { Box, Button, CircularProgress, styled, SvgIcon, SxProps, Theme, Typography } from "@mui/material";
import { isEmpty } from "lodash";
import { useSnackbar } from "notistack";
import { ReactElement, useCallback, useMemo, useState } from "react";

import { ActionButtonPrimary, ActionButtonSecondary } from "../../common/ActionButtons";
import AppDialog from "../../common/dialog/AppDialog";
import T from "../../locallization/T";
import TopNav from "../../navigation/TopNav";
import { useAppContext } from "../../root/AppContext";
import useUpdateUserInfo from "../../search/useUpdateUserInfo";
import { COLOR_APP_PRIMARY_DARK, COLOR_APP_PRIMARY_LIGHT, COLOR_APP_PRIMARY_LIGHT_2 } from "../../theme/theme";
import WithThemeIcon from "../../theme/WithThemeIcon";
import { USER_INFO } from "../../user/userInfo";
import { updateUserInfo } from "../../user/userUpdates";
import { INLINE_SETTINGS_PROPS } from "../inlineSettings/InlineSettingsProps";
import { SETTINGS_PROPS } from "../settings/SettingsProps";

const SettingButton = styled(Button)({
    backgroundColor: COLOR_APP_PRIMARY_LIGHT_2,
    color: COLOR_APP_PRIMARY_DARK,
    border: "none",
    "&:hover": {
        backgroundColor: COLOR_APP_PRIMARY_LIGHT,
    },
}) as any; // We set it as any because it yells about 'target'

export default function SettingRowBase({
    text,
    Icon,
    SettingContent,
    InlineSettingContent,
    href,
    onClick,
    sx = [],
    fullScreen = false,
    startOpen = false,
    hideTopNav = false,
}: {
    text: ReactElement;
    Icon?: typeof SvgIcon;
    SettingContent?: (props: SETTINGS_PROPS) => JSX.Element;
    InlineSettingContent?: (props: INLINE_SETTINGS_PROPS) => JSX.Element;
    href?: string;
    onClick?: () => void;
    sx?: SxProps<Theme>;
    fullScreen?: boolean;
    startOpen?: boolean;
    hideTopNav?: boolean;
}) {
    const { user, userInfo } = useAppContext();
    const [showDialog, setShowDialog] = useState(startOpen);
    const [saving, setSaving] = useState(false);
    const [newData, setNewData] = useState<USER_INFO>({});

    const onData = useUpdateUserInfo(setNewData);
    const { enqueueSnackbar } = useSnackbar();

    const newUserInfo = useMemo(() => ({ ...userInfo, ...newData }), [userInfo, newData]);

    const toggleDialogOn = useCallback(() => {
        setNewData({});
        setShowDialog(true);
    }, []);
    const hideDialog = useCallback(() => {
        if (!saving) {
            setShowDialog(false);
        }
    }, [saving]);

    const saveData = useCallback(() => {
        if (!user) {
            return;
        }
        setSaving(true);

        updateUserInfo(user.uid, userInfo, newData)
            .then(() => {
                setSaving(false);
                enqueueSnackbar(<T>profile setting save notice success</T>, { variant: "success" });
                hideDialog();
            })
            .catch((error) => {
                setSaving(false);
                console.error(error);
                enqueueSnackbar(<T params={{ code: 170 }}>general error</T>, { variant: "warning" });
            });
    }, [user, userInfo, newData, hideDialog, enqueueSnackbar]);

    const isExternalLink = href && !href.startsWith("/");

    return (
        <>
            <SettingButton
                href={href}
                onClick={onClick || toggleDialogOn}
                variant="outlined"
                size="huge"
                sx={sx}
                LinkComponent={isExternalLink ? "a" : undefined}
                target={isExternalLink ? "_blank" : undefined}
                color="inherit">
                {Icon && <Icon sx={{ mr: 1 }} />}
                <Typography>{text}</Typography>
                <Box ml="auto">
                    {InlineSettingContent ? (
                        <InlineSettingContent newUserInfo={userInfo as USER_INFO} />
                    ) : isExternalLink ? (
                        <WithThemeIcon Icon={OpenInNewOutlined} reverse />
                    ) : href ? (
                        <WithThemeIcon Icon={ChevronLeftOutlined} />
                    ) : onClick ? (
                        <PanToolAltOutlined />
                    ) : (
                        <EditOutlined />
                    )}
                </Box>
            </SettingButton>
            {SettingContent && (
                <AppDialog
                    open={showDialog}
                    setOpen={setShowDialog}
                    fullScreen={fullScreen}
                    actions={
                        <>
                            <ActionButtonSecondary onClick={hideDialog} endIcon={<CancelOutlined />} disabled={saving}>
                                <T>profile delete dialog cancel button</T>
                            </ActionButtonSecondary>
                            <ActionButtonPrimary
                                onClick={saveData}
                                disabled={isEmpty(newData) || saving}
                                endIcon={saving ? <CircularProgress size={24} /> : <SaveOutlined />}>
                                <T>profile setting button save</T>
                            </ActionButtonPrimary>
                        </>
                    }
                    topNav={hideTopNav ? null : <TopNav hideBack={!fullScreen} onBack={hideDialog} title={text} />}>
                    <SettingContent onData={onData} newUserInfo={newUserInfo} />
                </AppDialog>
            )}
        </>
    );
}
