import {
    browserLocalPersistence,
    createUserWithEmailAndPassword,
    FacebookAuthProvider,
    GoogleAuthProvider,
    OAuthProvider,
    setPersistence,
    signInWithCredential,
    signInWithEmailAndPassword,
    signInWithPopup,
    signInWithRedirect,
} from "firebase/auth";

import { getFirebaseAuth } from "../firebase/firebase";

export function signInWithGoogle() {
    return withPersist().then(() => {
        return signInWithPopup(getFirebaseAuth(), new GoogleAuthProvider());
    });
}
export function signInWithGoogleToken(idToken: string) {
    return withPersist().then(() => {
        const credential = GoogleAuthProvider.credential(idToken);
        return signInWithCredential(getFirebaseAuth(), credential);
    });
}
export function signInWithApple(redirect: boolean) {
    return withPersist().then(() => {
        return redirect
            ? signInWithRedirect(getFirebaseAuth(), new OAuthProvider("apple.com"))
            : signInWithPopup(getFirebaseAuth(), new OAuthProvider("apple.com"));
    });
}
export function signInWithAppleToken(idToken: string) {
    return withPersist().then(() => {
        const provider = new OAuthProvider("apple.com");
        const credential = provider.credential({ idToken });
        return signInWithCredential(getFirebaseAuth(), credential);
    });
}

export function signInWithFacebook() {
    return withPersist().then(() => {
        return signInWithRedirect(getFirebaseAuth(), new FacebookAuthProvider());
    });
}

function withPersist() {
    return setPersistence(getFirebaseAuth(), browserLocalPersistence);
}

export function signUpWithUsername(email: string, password: string) {
    return withPersist().then(() => {
        return createUserWithEmailAndPassword(getFirebaseAuth(), email, password);
    });
}
export function signInWithUsername(email: string, password: string) {
    return withPersist().then(() => {
        return signInWithEmailAndPassword(getFirebaseAuth(), email, password);
    });
}
