import { Box, CircularProgress, Paper, Stack, Typography } from "@mui/material";
import { FieldValue } from "firebase/firestore";
import { useEffect, useMemo, useRef } from "react";

import T from "../../locallization/T";
import AppSpinner from "../../navigation/AppSpinner";
import { useProfileName } from "../../outputs/basic/useProfileName";
import { useAppContext } from "../../root/AppContext";
import { COLOR_APP_PRIMARY_DARK, COLOR_APP_PRIMARY_LIGHT, COLOR_APP_PRIMARY_LIGHT_3 } from "../../theme/theme";
import { METUPAL_USER_INFO, THERAPIST_USER_INFO } from "../../user/userInfo";
import { MESSAGE } from "../chatUpdates";
import DeletedState from "../DeletedState";
import NewMessageIntro from "./NewMessageIntro";
import { useMessagesService } from "./useMessagesService";

export default function MessagesList({
    chatId,
    matchUserInfo,
    setDisplayMessagesCount,
}: {
    chatId: string;
    matchUserInfo: THERAPIST_USER_INFO | METUPAL_USER_INFO | null;
    setDisplayMessagesCount: (count: number) => void;
}) {
    const { loading, messages } = useMessagesService({ chatId });
    const { user, userInfo } = useAppContext();
    const messagesContainerRef = useRef<HTMLDivElement>(null);
    const name = useProfileName({
        targetUserInfo: matchUserInfo,
        isTargetTherapist: !userInfo?.isTherapist,
    });
    const displayMessages = useMemo(() => {
        if (matchUserInfo?.consult) {
            return messages.concat([
                {
                    sender: matchUserInfo.uid,
                    text: (<T params={{ name }}>consult first message</T>) as unknown as string,
                    sentDate: { toDate: () => new Date(user?.metadata.creationTime || "") } as unknown as FieldValue,
                } as MESSAGE,
            ]);
        }
        return messages;
    }, [matchUserInfo, messages, name, user?.metadata.creationTime]);

    useEffect(() => {
        if (!loading) {
            setDisplayMessagesCount(displayMessages.length);
        }
    }, [displayMessages.length, setDisplayMessagesCount, loading]);

    useEffect(() => {
        if (!loading) {
            messagesContainerRef.current?.scrollIntoView({ block: "end", behavior: "smooth" });
        }
    }, [loading, messages]);

    if (loading) {
        return <AppSpinner />;
    }

    let lastSender = "";
    return (
        <Stack flexDirection="column-reverse" gap={1} ref={messagesContainerRef}>
            {!matchUserInfo && (
                <Box mt={1}>
                    <DeletedState />
                </Box>
            )}
            {displayMessages.map(({ sender, text, sentDate }, index) => {
                const isSelf = sender === userInfo?.uid;
                const isGroupFirst = sender !== lastSender;
                lastSender = sender;

                return (
                    <Paper
                        key={index}
                        elevation={0}
                        sx={{
                            alignSelf: isSelf ? "flex-start" : "flex-end",
                            pt: 2,
                            px: 2,
                            pb: 1,
                            whiteSpace: "pre-line",
                            wordBreak: "break-word",
                            mb: isGroupFirst && index > 0 ? 1 : 0,
                            mr: isSelf ? 2 : 0,
                            ml: isSelf ? 0 : 2,
                            borderTopRightRadius: isSelf ? 0 : null,
                            borderTopLeftRadius: isSelf ? null : 0,
                            background: isSelf ? COLOR_APP_PRIMARY_LIGHT_3 : COLOR_APP_PRIMARY_LIGHT,
                            color: COLOR_APP_PRIMARY_DARK,
                        }}>
                        <Typography>{text}</Typography>
                        <Typography variant="caption" sx={{ alignSelf: "flex-end" }}>
                            {sentDate ? <bdi>{formatMessageDate(sentDate)}</bdi> : <CircularProgress size={16} />}
                        </Typography>
                    </Paper>
                );
            })}
            {!matchUserInfo?.consult && <NewMessageIntro hasMessages={displayMessages.length > 0} sx={{ mb: 1 }} />}
        </Stack>
    );
}

export function formatMessageDate(date: any, { short = false } = {}) {
    const today = new Date();
    const dateObj: Date = typeof date === "number" ? new Date(date) : date.toDate();
    const timeText = `${padX(dateObj.getHours())}:${padX(dateObj.getMinutes())}`;
    let dateText = timeText;
    if (dateObj.toDateString() !== today.toDateString()) {
        // Different day - add the date.
        dateText = `${padX(dateObj.getDate())}/${padX(dateObj.getMonth() + 1)}`;
        if (!short) {
            dateText = `${dateText}, ${timeText}`;
        }
    }
    return dateText;
}

function padX(num: number, count = 2) {
    return ("" + num).padStart(count, "0");
}
