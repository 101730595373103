import { BaseTextFieldProps, TextField } from "@mui/material";
import { Dispatch, SetStateAction, useCallback, useState } from "react";

import T from "../locallization/T";

export default function EmailInput({
    email,
    setEmail,
    type = "normal",
    ...props
}: {
    email: string;
    setEmail: Dispatch<SetStateAction<string>>;
} & BaseTextFieldProps) {
    const [showWarning, setShowWarning] = useState(false);
    const updateEmail = useCallback((event) => setEmail(event.target.value), [setEmail]);
    const checkIfShouldShowWarning = useCallback(() => {
        const trimmedEmail = email.trim();
        if (trimmedEmail && !trimmedEmail.endsWith("@gmail.com")) {
            setShowWarning(true);
        }
    }, [email]);
    const setFalseShowWarning = useCallback(() => setShowWarning(false), []);

    return (
        <TextField
            label={<T>login form input email</T>}
            variant="outlined"
            type={"email"}
            value={email}
            onChange={updateEmail}
            onBlur={checkIfShouldShowWarning}
            onFocus={setFalseShowWarning}
            helperText={showWarning ? <T>login form input email error</T> : undefined}
            {...props}
        />
    );
}
