import { Stack } from "@mui/material";

import TherapistOutputAdditionalStudies from "../../outputs/therapist/TherapistOutputAdditionalStudies";
import TherapistOutputDegrees from "../../outputs/therapist/TherapistOutputDegrees";
import TherapistOutputExp from "../../outputs/therapist/TherapistOutputExp";
import TherapistOutputLangs from "../../outputs/therapist/TherapistOutputLangs";
import TherapistOutputLicenseYear from "../../outputs/therapist/TherapistOutputLicenseYear";
import TherapistOutputMethods from "../../outputs/therapist/TherapistOutputMethods";
import TherapistOutputReasons from "../../outputs/therapist/TherapistOutputReasons";
import { USER_INFO } from "../../user/userInfo";

export default function TherapistInfoProfessional({ therapistUserInfo }: { therapistUserInfo: USER_INFO }) {
    return (
        <Stack>
            <TherapistOutputLicenseYear newUserInfo={therapistUserInfo} short />
            <TherapistOutputDegrees newUserInfo={therapistUserInfo} short />
            <TherapistOutputAdditionalStudies newUserInfo={therapistUserInfo} short />
            <TherapistOutputExp newUserInfo={therapistUserInfo} short />
            <TherapistOutputMethods newUserInfo={therapistUserInfo} short />
            <TherapistOutputLangs newUserInfo={therapistUserInfo} short />
            <TherapistOutputReasons newUserInfo={therapistUserInfo} />
        </Stack>
    );
}
