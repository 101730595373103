import { Link, Stack, Typography } from '@mui/material';

import { THERAPIST_OUTPUT_PROPS } from '../basic/OutputProps';

export function getLink(rawLink: string) {
    let link = rawLink;
    if (!link.startsWith("http://") && !link.startsWith("https://")) {
        link = `https://${link}`;
    }
    return link;
}

export default function TherapistOutputArticlesLinks({ newUserInfo }: THERAPIST_OUTPUT_PROPS) {
    if (!newUserInfo?.therapistArticlesLinks?.length) {
        return null;
    }
    return (
        <Stack gap={2} sx={{ wordBreak: "break-word" }}>
            {newUserInfo?.therapistArticlesLinks.map((link, index) => (
                <Link
                    key={index}
                    component="a"
                    href={getLink(link)}
                    target="_blank"
                    rel="noreferrer"
                    alignSelf="flex-start">
                    <Typography sx={{ unicodeBidi: "plaintext" }}>{decodeURIComponent(link)}</Typography>
                </Link>
            ))}
        </Stack>
    );
}
