import { ArrowBack } from "@mui/icons-material";
import { Box, Button, Stack, Typography } from "@mui/material";
import { Container } from "@mui/system";
import { useSnackbar } from "notistack";
import { useCallback, useState } from "react";

import T from "../locallization/T";
import PageContainer from "../navigation/PageContainer";
import { ROUTES } from "../navigation/Router";
import { usePageSizes } from "../navigation/usePageSizes";
import { useAppContext } from "../root/AppContext";
import { getSpacedButtonIconProps } from "../theme/buttons";
import { boldStyles } from "../theme/theme";
import WithThemeIcon from "../theme/WithThemeIcon";
import { updateUserInfo } from "../user/userUpdates";
import BunnyWritingPng from "./png/bunnyWriting.png";

export default function TherapistPageIntro() {
    const { user, userInfo } = useAppContext();
    const [loading, setLoading] = useState(false);
    const { enqueueSnackbar } = useSnackbar();
    const { contentSx } = usePageSizes();

    const goBackToOnboarding = useCallback(() => {
        if (user && !loading) {
            setLoading(true);

            updateUserInfo(user.uid, userInfo, { onboarded: false }).catch((error) => {
                setLoading(false);
                console.error(error);
                enqueueSnackbar(<T params={{ code: 183 }}>general error</T>, { variant: "warning" });
            });
        }
    }, [loading, user, userInfo, enqueueSnackbar]);

    return (
        <PageContainer
            pageTitleKey="title therapist page intro"
            pagePath={ROUTES.THERAPIST_PAGE_INTRO}
            onBack={goBackToOnboarding}
            showLogo
            BotNavComponent={
                <Container>
                    <Stack pb={3} sx={contentSx}>
                        <Button
                            disabled={loading}
                            variant="contained"
                            size="huge"
                            href={ROUTES.THERAPIST_PAGE}
                            {...getSpacedButtonIconProps()}
                            endIcon={<WithThemeIcon Icon={ArrowBack} />}>
                            <T>therapist page intro button</T>
                        </Button>
                    </Stack>
                </Container>
            }>
            <Stack alignItems="center" justifyContent="center" textAlign="center" height="100%" gap={1}>
                <Box width={148} height={267} mb={1} component="img" src={BunnyWritingPng} alt="Logo" />
                <Typography variant="h2" sx={{ ...boldStyles }} mb={1}>
                    <T>therapist page intro title</T>
                </Typography>
                <Typography>
                    <T>therapist page intro text</T>
                </Typography>
                <Typography sx={{ ...boldStyles }}>
                    <T>therapist page intro subtext</T>
                </Typography>
            </Stack>
        </PageContainer>
    );
}
