import { GeoPoint } from "firebase/firestore";
import { useCallback, useState } from "react";

import { PlaceType } from "../../inputs/GoogleAutocomplete";
import InputLocationGeo from "../../inputs/InputLocationGeo";
import { SEARCH_INPUT_PROPS } from "./SearchInputProps";

export default function SearchInputLocationGeo({ onData, newUserInfo }: SEARCH_INPUT_PROPS) {
    const [placeValue, setPlaceValue] = useState<PlaceType | null>(
        newUserInfo.locationName ? { description: newUserInfo.locationName || "" } : null
    );

    const updateGeo = useCallback(
        (geo: GeoPoint | null) => {
            if (geo) {
                onData({
                    locationGeo: geo,
                    locationName: placeValue?.description,
                });
            } else {
                onData({
                    locationGeo: null,
                    locationName: "",
                });
            }
        },
        [placeValue, onData]
    );

    return <InputLocationGeo placeValue={placeValue} setPlaceValue={setPlaceValue} setGeo={updateGeo} />;
}
