import { deleteUser, onIdTokenChanged, signOut, User } from "firebase/auth";
import { onSnapshot } from "firebase/firestore";
import { isEqual } from "lodash";
import { useCallback, useEffect, useState } from "react";

import { getFirebaseAuth } from "../firebase/firebase";
import { deleteOnboardingFromStorage } from "../onboarding/onboardingStorage";
import { deleteTherapistFromStorage } from "../onboarding/therapistStorage";
import { USER_INFO } from "./userInfo";
import { deleteUserInfo, getUserRef } from "./userUpdates";

export function useUser() {
    const [user, setUser] = useState<User | null>(null);
    const [userInfo, setUserInfo] = useState<USER_INFO | null>(null);
    const [loading, setLoading] = useState(true);
    const [userInfoLoading, setUserInfoLoading] = useState(true);

    useEffect(() => {
        const unsubscribe = onIdTokenChanged(getFirebaseAuth(), (user) => {
            setLoading(false);
            setUser((prevUser) => {
                if (prevUser && !user) {
                    // clean storage on disconnect to prevent pollution between accounts.
                    deleteOnboardingFromStorage();
                    deleteTherapistFromStorage();
                }
                if (user && prevUser?.uid !== user?.uid) {
                    setUserInfoLoading(true);
                }
                return user;
            });
        });

        return () => unsubscribe();
    }, []);

    useEffect(() => {
        if (user?.uid) {
            const ref = getUserRef(user.uid);
            const unsubscribe = onSnapshot(ref, { includeMetadataChanges: true }, (userInfoData) => {
                if (userInfoData.metadata.hasPendingWrites) {
                    // ignore local changes! only take what server returns.
                    return;
                }
                setUserInfoLoading(false);
                const newUserInfo = (userInfoData.data() as USER_INFO) || null;
                setUserInfo((existingUserInfo) => {
                    if (newUserInfo && existingUserInfo) {
                        for (let key in newUserInfo) {
                            // If the old user info had the same objects, use them so the app won't refresh for no reason.
                            if (isEqual(newUserInfo[key], existingUserInfo[key])) {
                                newUserInfo[key] = existingUserInfo[key];
                            }
                        }
                    }
                    return newUserInfo;
                });
            });
            return () => unsubscribe();
        } else {
            setUserInfo(null);
        }
    }, [user?.uid]);

    const initiateDeleteUser = useCallback(
        (reauthenthicate) => {
            if (!user) {
                return Promise.reject();
            }
            return reauthenthicate(user)
                .then(() => {
                    setUserInfoLoading(true);
                    setLoading(true);
                    return deleteUserInfo(user);
                })
                .then(() => {
                    return deleteUser(user);
                })
                .catch((error) => {
                    setUserInfoLoading(false);

                    throw error;
                });
        },
        [user]
    );

    const initialDeleteUserInfo = useCallback(() => {
        if (!user) {
            return Promise.reject();
        }
        setLoading(true);
        return deleteUserInfo(user)
            .then(() => {
                return signOut(getFirebaseAuth());
            })
            .finally(() => setLoading(false));
    }, [user]);

    const reloadUser = useCallback(() => {
        if (!user) {
            return Promise.resolve();
        }
        setLoading(true);
        return user
            .reload()
            .then(() => {
                setUser(getFirebaseAuth().currentUser);
            })
            .finally(() => setLoading(false));
    }, [user]);

    return { user, loading, userInfo, userInfoLoading, initiateDeleteUser, initialDeleteUserInfo, reloadUser };
}
