import {
    Checkbox,
    FormControl,
    FormHelperText,
    InputLabel,
    ListItemIcon,
    ListItemText,
    MenuItem,
    Select,
    SvgIcon,
} from "@mui/material";
import { isArray } from "lodash";
import { Fragment, ReactNode, useCallback, useState } from "react";

import SeparatedList from "../../common/SeparatedList";
import T from "../../locallization/T";
import { convertFromUserInfoMap, convertToUserInfoMap, USER_INFO } from "../../user/userInfo";
import { SEARCH_INPUT_PROPS } from "../inputs/SearchInputProps";
import SelectConfirmButton from "./SelectConfirmButton";

export default function SearchInputBasicSelect<
    T extends { value: string; ImagePng?: string; textKey?: string; params?: {}; Icon?: typeof SvgIcon }
>({
    onData,
    newUserInfo,
    userInfoKey,
    label,
    allValues,
    defaultValue,
    getSelectedLabel,
    getValueLabel,
    multiple,
    convertToMap,
    preventEmpty,
    helperText,
}: SEARCH_INPUT_PROPS & {
    userInfoKey: keyof USER_INFO;
    label: ReactNode;
    allValues: T[];
    defaultValue?: string | string[];
    getSelectedLabel?: (value: string) => ReactNode;
    getValueLabel?: (value: string) => ReactNode;
    multiple?: boolean;
    convertToMap?: boolean;
    preventEmpty?: boolean;
    helperText?: React.ReactNode;
}) {
    const [open, setOpen] = useState(false);
    const handleClose = useCallback(() => setOpen(false), []);
    const handleOpen = useCallback(() => setOpen(true), []);

    defaultValue = defaultValue || (multiple ? [] : "");
    const initialValue = convertToMap
        ? convertFromUserInfoMap(newUserInfo[userInfoKey] as {})
        : (newUserInfo[userInfoKey] as string | string[]);
    const [userInfoValue, setUserInfoValue] = useState<string | string[]>(initialValue || defaultValue);

    const updateValue = useCallback(
        (event) => {
            if (preventEmpty && event.target.value.length === 0) {
                return;
            }
            setUserInfoValue(event.target.value);
            onData({ [userInfoKey]: convertToMap ? convertToUserInfoMap(event.target.value) : event.target.value });
        },
        [onData, userInfoKey, convertToMap, preventEmpty]
    );

    return (
        <FormControl>
            <InputLabel>{label}</InputLabel>
            <Select
                multiple={multiple}
                value={userInfoValue}
                onChange={updateValue}
                label={label}
                open={open}
                onClose={handleClose}
                onOpen={handleOpen}
                renderValue={(selected) => {
                    const getLabel = getSelectedLabel || getValueLabel;
                    return isArray(selected) ? (
                        <SeparatedList separator="; ">
                            {allValues
                                .map(
                                    ({ value, textKey, params }) =>
                                        selected.includes(value) && (
                                            <Fragment key={value}>
                                                {getLabel ? getLabel(value) : <T params={params}>{textKey}</T>}
                                            </Fragment>
                                        )
                                )
                                .filter((a) => a)}
                        </SeparatedList>
                    ) : (
                        allValues.map(
                            ({ value, textKey, params }) =>
                                value === selected && (
                                    <Fragment key={value}>
                                        {getLabel ? getLabel(value) : <T params={params}>{textKey}</T>}
                                    </Fragment>
                                )
                        )
                    );
                }}>
                {allValues.map(({ value, ImagePng, textKey, params, Icon }) => (
                    <MenuItem key={value} value={value}>
                        {Icon && !multiple && (
                            <ListItemIcon>
                                <Icon fontSize="small" />
                            </ListItemIcon>
                        )}
                        {isArray(userInfoValue) && <Checkbox checked={userInfoValue.indexOf(value) > -1} />}
                        {ImagePng ? (
                            <ListItemIcon>
                                <img src={ImagePng} alt={value} />
                            </ListItemIcon>
                        ) : (
                            <ListItemText
                                primary={getValueLabel ? getValueLabel(value) : <T params={params}>{textKey}</T>}
                            />
                        )}
                        {Icon && multiple && (
                            <ListItemIcon>
                                <Icon fontSize="small" />
                            </ListItemIcon>
                        )}
                    </MenuItem>
                ))}
                {multiple && <SelectConfirmButton handleClose={handleClose} listSize={allValues.length} />}
            </Select>
            {helperText && <FormHelperText>{helperText}</FormHelperText>}
        </FormControl>
    );
}
