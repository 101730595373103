import { Stack, Typography } from "@mui/material";

import T from "../locallization/T";
import { useAppContext } from "../root/AppContext";
import { boldStyles } from "../theme/theme";
import StatCard from "./StatsCard";

export default function TherapistStats() {
    const { userInfo } = useAppContext();

    return (
        <Stack gap={2}>
            <Typography variant="h3" sx={{ ...boldStyles }}>
                <T>home therapist title stats</T>
            </Typography>
            <Stack gap={1} flexDirection="row" flexWrap="wrap">
                <StatCard sx={{ width: "calc(50% - 4px)" }} prompt={<T>stat views</T>} value={userInfo?.stats?.views} />
                <StatCard
                    sx={{ width: "calc(50% - 4px)" }}
                    prompt={<T>stat matches</T>}
                    value={userInfo?.stats?.matches}
                />
                <StatCard
                    sx={{ width: "calc(50% - 4px)" }}
                    prompt={<T>stat messages</T>}
                    value={userInfo?.stats?.messages}
                />
                <StatCard sx={{ width: "calc(50% - 4px)" }} prompt={<T>stat meets</T>} value={userInfo?.stats?.meets} />
            </Stack>
        </Stack>
    );
}
