import { FieldValue, GeoPoint } from "firebase/firestore";

export type USER_INFO_GENDER = "M" | "F" | "O";
export type USER_INFO_THERAPY_TYPE = "couple" | "family" | "group" | "parents" | "personal" | "child";
export type USER_INFO_TIME = "morning" | "noon" | "afternoon" | "evening";
export type USER_INFO_KUPA = "clalit" | "macabi" | "meuhedet" | "leumit" | "none";
export type USER_INFO_AGE = "babies" | "children" | "teenagers" | "adults" | "elderly";
export type USER_INFO_PAGE_VISIBILITY = "private" | "public";
export type USER_INFO_NOTIFICATION_MATCH = "daily" | "none";
export type USER_INFO_NOTIFICATION_CHAT = "every" | "new" | "none";
export type USER_INFO_MESSAGE_PREF = "self" | "zigmond";
export type USER_INFO_PREF = "must" | "prefer";
export type USER_INFO_TALK = "talk" | "listen" | "hard";
export const USER_INFO_SPECIALTIES = 13;
export const USER_INFO_METHODS = 17;
export const USER_INFO_LANGS = 6;
type USER_INFO_BOOL = "Y" | "N";
export type USER_INFO_ZOOM = USER_INFO_BOOL;
export type USER_INFO_ALLOW_CHARITY = USER_INFO_BOOL;
export type USER_INFO_MAP<T extends string, B = true> = { [P in T]?: B };
export type USER_BAN_HISTORY = { ban: boolean; by: string; date: FieldValue; reason: string };
type USER_INFO_STATS = "messages" | "matches" | "views" | "meets";

export type SEARCH_HISTORY = {
    date: FieldValue;
    sentTo: number;
    total: number;
    matches?: number;
};

export type BASIC_USER_INFO = {
    uid?: string;

    // Basic info everyone:
    gender?: USER_INFO_GENDER;
    talkGender?: USER_INFO_GENDER;
    firstName?: string;
    lastName?: string;
    year?: number;
    photoKey?: string;
    ban?: boolean;
    consult?: boolean;
};

// anything in this list will trigger new search, and shown on metupal page
type PUBLIC_SEARCH_USER_INFO = {
    // Search details - these trigger a new search
    locationRange?: number;
    locationGeo?: GeoPoint | null;
    locationName?: string;
    locationZoom?: USER_INFO_ZOOM;
    therapyType?: USER_INFO_THERAPY_TYPE;
    times?: USER_INFO_MAP<USER_INFO_TIME>;
    cost?: number;
    kupa?: USER_INFO_KUPA;
    othersList?: number[];
    reasonsList?: number[];
    reasonsText?: string;
};
// anything in this list will trigger new search, and stay private
type PRIVATE_SEARCH_USER_INFO = {
    enableSearch?: boolean;
    messagePref?: USER_INFO_MESSAGE_PREF;
};
export type SEARCH_USER_INFO = PRIVATE_SEARCH_USER_INFO & PUBLIC_SEARCH_USER_INFO;

export type METUPAL_USER_INFO = BASIC_USER_INFO & PUBLIC_SEARCH_USER_INFO;

export type THERAPIST_USER_INFO = BASIC_USER_INFO & {
    // Therapist info
    license?: string;
    therapistSpecialties?: number[];
    therapistLocationGeos?: GeoPoint[];
    therapistLocationNames?: string[];
    therapistLocationAccessibles?: boolean[];

    therapistLocationZoom?: USER_INFO_ZOOM;
    therapistTherapyTypes?: USER_INFO_THERAPY_TYPE[];
    therapistMinutes?: USER_INFO_MAP<USER_INFO_THERAPY_TYPE, number>;
    therapistTimes?: USER_INFO_MAP<USER_INFO_TIME, number[]>;
    therapistCosts?: USER_INFO_MAP<USER_INFO_THERAPY_TYPE, number>;
    therapistAllowCharity?: USER_INFO_ALLOW_CHARITY;
    therapistKupot?: USER_INFO_MAP<USER_INFO_KUPA>;
    therapistAges?: USER_INFO_MAP<USER_INFO_AGE>;

    // Therapist basic info
    therapistOtherInfo?: string;
    therapistDegree?: string;
    therapistDegree2?: string;
    therapistDegree3?: string;
    therapistMethods?: number[];
    therapistReasonsList?: number[];
    therapistOthersList?: number[];
    therapistLangs?: number[];
    therapistLicenseYear?: number;
    therapistAdditionalStudies?: string;
    therapistExp?: string;
    therapistArticlesLinks?: string[];
    socialFacebook?: string;
    socialInstagram?: string;
    socialYoutube?: string;
    socialSite?: string;

    // Therapist video
    therapistVideoKey?: string;
    therapistVideoThumbKey?: string;

    // Preferences
    pageVisibility?: USER_INFO_PAGE_VISIBILITY;
    therapistEnableSearch?: boolean;
};

export type PRIVATE_USER_INFO = PRIVATE_SEARCH_USER_INFO & {
    // New accounts:
    created?: FieldValue;
    email?: string;
    accountHasTherapist?: boolean;
    accountHasMetupal?: boolean;
    banHistory?: USER_BAN_HISTORY[];
    unsub?: boolean;
    lastLogin?: FieldValue;

    // Search:
    timesSearched?: boolean;
    onboarded?: boolean;
    noMatchesIndicator?: boolean;
    searchHistory?: SEARCH_HISTORY[];

    // Therapist search:
    hasTherapistBasicInfo?: boolean;
    isTherapist?: boolean;
    therapistTermsAck?: FieldValue;
    therapistReasonsNotList?: number[];
    therapistAgesRange?: number[];
    therapistTalk?: USER_INFO_TALK;

    stats?: USER_INFO_MAP<USER_INFO_STATS, number>;

    // Matching
    matchedMetupalim?: USER_INFO_MAP<string, number>;
    readyMatches?: USER_INFO_MAP<string, number>;
    matches?: USER_INFO_MAP<string, any>;
    chats?: USER_INFO_MAP<string>;
    chatsWithMetupalim?: USER_INFO_MAP<string, number | true>;
    messagesCounts?: USER_INFO_MAP<string, number>;
    matchesCounts?: USER_INFO_MAP<string, number>;
    met?: USER_INFO_MAP<string, number>;
    checkMet?: USER_INFO_MAP<string, number>;
    checkMetupalMet?: USER_INFO_MAP<string, number>;
    paymentDue?: USER_INFO_MAP<string, number>;
    paid?: USER_INFO_MAP<string, number>;
    payHistory?: PAY_HISTORY[];

    // History
    rejectedMetupalim?: USER_INFO_MAP<string, FieldValue>;
    rejectedTherapists?: USER_INFO_MAP<string, { date: FieldValue; reason: string }>;
    rejectedByMetupalim?: USER_INFO_MAP<string, FieldValue>;
    rejectedByTherapists?: USER_INFO_MAP<string, FieldValue>;
    blocked?: USER_INFO_MAP<string, FieldValue>;

    // Notifications
    notificationsMatch?: USER_INFO_NOTIFICATION_MATCH;
    notificationsChat?: USER_INFO_NOTIFICATION_CHAT;
};

export type USER_INFO = THERAPIST_USER_INFO & METUPAL_USER_INFO & PRIVATE_USER_INFO & { temp?: any };

type KeysEnum<T> = {
    [P in keyof Required<T>]: true;
};

// idk why but we have to duplicate this for the validations.
export const SEARCH_USER_INFO_KEYS: KeysEnum<SEARCH_USER_INFO> = {
    // Search filters
    locationRange: true,
    locationGeo: true,
    locationName: true,
    locationZoom: true,
    therapyType: true,
    times: true,
    cost: true,
    kupa: true,
    reasonsList: true,
    othersList: true,
    reasonsText: true,
    enableSearch: true,
    messagePref: true,
};

// idk why but we have to duplicate this for the validations.
export const METUPAL_USER_INFO_KEYS: KeysEnum<METUPAL_USER_INFO> = {
    // Basic info everyone:
    uid: true,
    gender: true,
    talkGender: true,
    firstName: true,
    lastName: true,
    year: true,
    photoKey: true,
    ban: true,
    consult: true,

    // Search filters
    locationRange: true,
    locationGeo: true,
    locationName: true,
    locationZoom: true,
    therapyType: true,
    times: true,
    cost: true,
    kupa: true,
    reasonsList: true,
    othersList: true,
    reasonsText: true,
};

// idk why but we have to duplicate this for the validations.
export const THERAPIST_USER_INFO_KEYS: KeysEnum<THERAPIST_USER_INFO> = {
    // Basic info everyone:
    uid: true,
    gender: true,
    talkGender: true,
    firstName: true,
    lastName: true,
    year: true,
    photoKey: true,
    ban: true,
    consult: true,

    // Therapist info
    license: true,
    therapistSpecialties: true,
    therapistLocationGeos: true,
    therapistLocationNames: true,
    therapistLocationAccessibles: true,
    therapistLocationZoom: true,
    therapistTherapyTypes: true,
    therapistMinutes: true,
    therapistTimes: true,
    therapistCosts: true,
    therapistAllowCharity: true,
    therapistKupot: true,
    therapistAges: true,

    // Therapist basic info
    therapistOtherInfo: true,
    therapistDegree: true,
    therapistDegree2: true,
    therapistDegree3: true,
    therapistMethods: true,
    therapistReasonsList: true,
    therapistOthersList: true,
    therapistLangs: true,
    therapistLicenseYear: true,
    therapistAdditionalStudies: true,
    therapistExp: true,
    therapistArticlesLinks: true,
    socialFacebook: true,
    socialInstagram: true,
    socialYoutube: true,
    socialSite: true,

    therapistVideoKey: true,
    therapistVideoThumbKey: true,

    pageVisibility: true,
    therapistEnableSearch: true,
};

export function convertToUserInfoMap<T extends string>(values?: T[]): USER_INFO_MAP<T> {
    const result: USER_INFO_MAP<T> = {};
    for (const value of values || []) {
        result[value] = true;
    }
    return result;
}

export function convertFromUserInfoMap<T extends string>(values?: USER_INFO_MAP<T>): T[] {
    const result: T[] = [];
    for (let key in values || {}) {
        result.push(key as T);
    }
    return result;
}

type PAY_HISTORY = {
    date: number;
    type: "consult" | "meet" | "unknown";
    amount: number;
};
