import { useCallback } from "react";
import { NavigateOptions, useNavigate } from "react-router-dom";

import { ROUTE_ID_CHAT_ID, ROUTES } from "../navigation/Router";
import { useAppContext } from "../root/AppContext";
import { getChatUid } from "./chatUpdates";

export function useNavigateToChat(targetUid?: string, { isTherapist }: { isTherapist?: boolean } = {}) {
    const { userInfo } = useAppContext();
    const navigate = useNavigate();

    const isTargetTherapist = typeof isTherapist === "boolean" ? isTherapist : !userInfo?.isTherapist;

    return useCallback(
        ({ navigateOptions }: { navigateOptions?: NavigateOptions } = {}) => {
            const metupalUid = isTargetTherapist ? userInfo?.uid : targetUid;
            const therapistUid = isTargetTherapist ? targetUid : userInfo?.uid;
            const route = ROUTES.MESSAGES.replace(ROUTE_ID_CHAT_ID, getChatUid(metupalUid || "", therapistUid || ""));
            navigate(route, navigateOptions);
        },
        [navigate, userInfo?.uid, isTargetTherapist, targetUid]
    );
}
