import { isEmpty } from "lodash";
import { useCallback, useMemo } from "react";

import { saveZigmondTexts, useZigmondTexts } from "../locallization/useZigmondTexts";
import TextsEditor from "./TextsEditor";

export default function ZigmondTextEditorSecret() {
    const { values = {} } = useZigmondTexts();

    const onSave = useCallback((updates: {}) => {
        const numberedUpdates = {};
        for (let key in updates) {
            // we want to save as numbers
            numberedUpdates[key] = +updates[key];
        }
        return saveZigmondTexts(numberedUpdates);
    }, []);

    // convert the numbers to strings, so the editor functions properly
    const stringValues = useMemo(() => {
        const stringValuesInner = {};
        for (let key in values) {
            // we want to save as numbers
            stringValuesInner[key] = "" + values[key];
        }
        return stringValuesInner;
    }, [values]);

    if (isEmpty(values)) {
        // loading =p
        return null;
    }

    return <TextsEditor texts={stringValues} onSave={onSave} showAll numbers />;
}
