import { ShareOutlined } from "@mui/icons-material";
import { Button, IconButton, SxProps, TextField, Theme } from "@mui/material";
import { useSnackbar } from "notistack";
import { useCallback, useState } from "react";

import { ActionButtonPrimary } from "../common/ActionButtons";
import AppDialog from "../common/dialog/AppDialog";
import { DEFAULT_HOME_URL, useShare } from "../common/useShare";
import T from "../locallization/T";
import { ROUTE_ID_THERAPIST_ID, ROUTES } from "../navigation/Router";
import { USER_INFO } from "../user/userInfo";
import { useIsInWebview } from "../webview/useIsWebviewApp";

export default function TherapistPageShareButton({
    newUserInfo,
    sx = [],
    disabled = false,
    asIcon = false,
}: {
    newUserInfo: USER_INFO;
    sx?: SxProps<Theme>;
    disabled?: boolean;
    asIcon?: boolean;
}) {
    const { enqueueSnackbar } = useSnackbar();
    const [showDialog, setShowDialog] = useState(false);
    const isWebview = useIsInWebview();

    const toggleDialog = useCallback(() => setShowDialog((showDialog) => !showDialog), []);
    const getUrl = useCallback(() => {
        return `${DEFAULT_HOME_URL}${ROUTES.THERAPIST_PAGE_PUBLIC.replace(
            ROUTE_ID_THERAPIST_ID,
            newUserInfo?.uid || ""
        )}`;
    }, [newUserInfo?.uid]);
    const shareLink = useShare({ getUrl });

    const onShareClick = useCallback(() => {
        if (isWebview) {
            toggleDialog();
        } else {
            try {
                shareLink();
            } catch {
                // in case you can't copy for some reason
                toggleDialog();
            }
        }
    }, [toggleDialog, shareLink, isWebview]);

    const disabledOnClick = useCallback(() => {
        enqueueSnackbar(
            <T
                params={{
                    field: <T>search input page visibility</T>,
                    value: <T>page visibility public</T>,
                }}>
                therapist page link disable tooltip
            </T>,
            { variant: "warning" }
        );
    }, [enqueueSnackbar]);

    return (
        <>
            {asIcon ? (
                <IconButton onClick={disabled ? disabledOnClick : onShareClick} edge="end" color="inherit">
                    <ShareOutlined />
                </IconButton>
            ) : (
                <Button
                    onClick={disabled ? disabledOnClick : onShareClick}
                    sx={sx}
                    variant="text"
                    color="inherit"
                    startIcon={<ShareOutlined />}>
                    <T>therapist page link button</T>
                </Button>
            )}

            <AppDialog
                open={showDialog}
                setOpen={setShowDialog}
                actions={
                    <ActionButtonPrimary onClick={toggleDialog}>
                        <T>dialog button close</T>
                    </ActionButtonPrimary>
                }>
                <TextField
                    variant="outlined"
                    type={"text"}
                    value={getUrl()}
                    fullWidth
                    autoFocus
                    onFocus={(e) => {
                        e.target.select();
                    }}
                />
            </AppDialog>
        </>
    );
}
