import { CircularProgress, Stack } from "@mui/material";
import { SxProps, Theme } from "@mui/material/styles";

export default function AppSpinner({ sx = [] }: { sx?: SxProps<Theme> }) {
    return (
        <Stack alignItems="center" justifyContent="center" height="100%" sx={sx}>
            <CircularProgress />
        </Stack>
    );
}
