import { MessageOutlined } from "@mui/icons-material";
import { Button, CircularProgress } from "@mui/material";
import { httpsCallable } from "firebase/functions";
import { useSnackbar } from "notistack";
import { useCallback, useMemo, useState } from "react";

import { useNavigateToChat } from "../../chat/useNavigateToChat";
import { getFirebaseFunctions } from "../../firebase/firebase";
import T from "../../locallization/T";
import { USER_INFO } from "../../user/userInfo";

export default function AdminCreateChatButton({
    newUserInfo,
    isTherapist,
}: {
    newUserInfo: USER_INFO;
    isTherapist: boolean;
}) {
    const [saving, setSaving] = useState(false);
    const { enqueueSnackbar } = useSnackbar();

    const createChatCallback = useMemo(
        () => httpsCallable<{ uid: string; isTherapist: boolean }, null>(getFirebaseFunctions(), "onCallCreateChat"),
        []
    );

    const navigateToChat = useNavigateToChat(newUserInfo.uid, { isTherapist });

    const createChat = useCallback(() => {
        setSaving(true);
        createChatCallback({
            uid: newUserInfo.uid || "",
            isTherapist,
        })
            .then((response) => {
                setSaving(false);
                enqueueSnackbar("הצ'אט נוצר בהצלחה", { variant: "success" });
                navigateToChat();
            })
            .catch((error) => {
                setSaving(false);
                console.error(error);
                enqueueSnackbar(<T params={{ code: 177 }}>general error</T>, { variant: "warning" });
            });
    }, [enqueueSnackbar, newUserInfo, createChatCallback, isTherapist, navigateToChat]);

    return (
        <>
            <Button
                onClick={createChat}
                color="info"
                disabled={saving}
                startIcon={saving ? <CircularProgress size={24} /> : <MessageOutlined />}>
                פתיחת צ'אט ({isTherapist ? `כמטופל` : `כמטפל`})
            </Button>
        </>
    );
}
