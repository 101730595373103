import { ArrowForward, CloseOutlined } from "@mui/icons-material";
import { Button, IconButton, Stack, Step, StepContent, StepLabel, Stepper, Typography } from "@mui/material";
import { useCallback, useMemo, useState } from "react";

import ActionButtons, { ActionButtonPrimary, ActionButtonSecondary } from "../../common/ActionButtons";
import AppVideo from "../../common/AppVideo";
import CardedPage from "../../common/CardedPage";
import AppDialog from "../../common/dialog/AppDialog";
import T from "../../locallization/T";
import { usePageText } from "../../locallization/usePageTexts";
import { COLOR_APP_PRIMARY_LIGHT } from "../../theme/theme";
import WithThemeIcon from "../../theme/WithThemeIcon";
import { VIEW_URL } from "../../uploads/videoUpload";

export default function HowToUploadVideosButton() {
    const [showDialog, setShowDialog] = useState(false);
    const { text } = usePageText("how_to_video");
    const [activeStep, setActiveStep] = useState(0);

    const textParts = useMemo(
        () =>
            (text || "").split("\n\n").map((textPart) => ({
                label: textPart.slice(0, textPart.indexOf("\n")),
                description: textPart.slice(textPart.indexOf("\n") + 1),
            })),
        [text]
    );

    const setTrueShowDialog = useCallback(() => {
        setActiveStep(0);
        setShowDialog(true);
    }, []);
    const setFalseShowDialog = useCallback(() => {
        setShowDialog(false);
    }, []);

    const handleNext = useCallback(() => {
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
    }, []);

    const handleBack = useCallback(() => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    }, []);

    return (
        <>
            <Button onClick={setTrueShowDialog} sx={{ alignSelf: "baseline" }}>
                <T>video guide prompt</T>
            </Button>
            <AppDialog
                open={showDialog}
                setOpen={setShowDialog}
                fullScreen
                ContentProps={{ sx: { background: COLOR_APP_PRIMARY_LIGHT } }}>
                <CardedPage>
                    <Stack>
                        <IconButton
                            onClick={setFalseShowDialog}
                            edge="start"
                            color="inherit"
                            sx={{ alignSelf: "baseline" }}>
                            <WithThemeIcon Icon={ArrowForward} />
                        </IconButton>
                        <Stepper activeStep={activeStep} orientation="vertical" sx={{ textAlign: "initial", mb: 2 }}>
                            {textParts.map((step, index) => (
                                <Step key={step.label}>
                                    <StepLabel>
                                        <Typography>{step.label}</Typography>
                                    </StepLabel>
                                    <StepContent>
                                        <Typography>{step.description}</Typography>
                                        <ActionButtons>
                                            <ActionButtonSecondary disabled={index === 0} onClick={handleBack}>
                                                <T>how to video button prev</T>
                                            </ActionButtonSecondary>
                                            <ActionButtonPrimary
                                                disabled={index === textParts.length - 1}
                                                onClick={handleNext}>
                                                <T>how to video button next</T>
                                            </ActionButtonPrimary>
                                        </ActionButtons>
                                    </StepContent>
                                </Step>
                            ))}
                        </Stepper>
                        <Typography variant="h2" sx={{ mb: 2 }}>
                            <T>how to video example</T>
                        </Typography>
                        <AppVideo videoUrl={`${VIEW_URL}/v1672303540/zigmond/how_to_example_xksueg6.mp4`} />
                        <ActionButtons mt={2}>
                            <ActionButtonSecondary onClick={setFalseShowDialog} endIcon={<CloseOutlined />}>
                                <T>dialog button close</T>
                            </ActionButtonSecondary>
                        </ActionButtons>
                    </Stack>
                </CardedPage>
            </AppDialog>
        </>
    );
}
