import { InsertEmoticonOutlined, SendOutlined } from "@mui/icons-material";
import { Fab, IconButton, InputAdornment, Popover, Stack, TextField } from "@mui/material";
import EmojiPicker, { Categories, EmojiStyle } from "emoji-picker-react";
import { useSnackbar } from "notistack";
import { KeyboardEvent, useCallback, useMemo, useRef, useState } from "react";
import { useLocation } from "react-router-dom";

import T, { useT } from "../../locallization/T";
import { useAppContext } from "../../root/AppContext";
import WithThemeIcon from "../../theme/WithThemeIcon";
import { CONSULT_UID } from "../../user/consult";
import { METUPAL_USER_INFO, THERAPIST_USER_INFO } from "../../user/userInfo";
import { createChat, createMessage } from "../chatUpdates";

export const CHAT_PREFILL_STATE = "chat_prefill";
export const CHAT_PREFILL_CONSULT = "consult";

const MAX_MESSAGE_LENGTH = 500;

const EMOJI_CATEGORIES_CONFIG = [
    {
        category: Categories.SUGGESTED,
        name: "",
    },
    {
        category: Categories.SMILEYS_PEOPLE,
        name: "",
    },
    {
        category: Categories.ANIMALS_NATURE,
        name: "",
    },
    {
        category: Categories.FOOD_DRINK,
        name: "",
    },
    {
        category: Categories.TRAVEL_PLACES,
        name: "",
    },
    {
        category: Categories.ACTIVITIES,
        name: "",
    },
    {
        category: Categories.OBJECTS,
        name: "",
    },
    {
        category: Categories.SYMBOLS,
        name: "",
    },
    {
        category: Categories.FLAGS,
        name: "",
    },
];
const EMOJI_PREVIEW_CONFIG = { showPreview: false };

export default function MessagesInput({
    chatId,
    disabled,
    matchUserInfo,
    displayMessagesCount,
}: {
    chatId: string;
    disabled?: boolean;
    matchUserInfo: THERAPIST_USER_INFO | METUPAL_USER_INFO;
    displayMessagesCount: number;
}) {
    const location = useLocation();
    const chatPrefillState = ((location.state as {}) || {})[CHAT_PREFILL_STATE];

    const emptyChatInitialText = useT({
        textKey:
            chatPrefillState === CHAT_PREFILL_CONSULT ? "messages input prefill consult" : "messages input prefill",
    });
    const [textValue, setTextValue] = useState(
        displayMessagesCount === 0 || chatPrefillState ? emptyChatInitialText.join("") : ""
    );
    const [showEmojis, setShowEmojis] = useState(false);
    const emojiRef = useRef(null);
    const inputRef = useRef<HTMLInputElement>();
    const { enqueueSnackbar } = useSnackbar();
    const { userInfo } = useAppContext();
    const textPlaceholder = useT({ textKey: "messages input placeholder" });

    const updateText = useCallback((event) => {
        setTextValue(event.target.value);
    }, []);
    const toggleEmojis = useCallback(() => {
        inputRef.current?.blur();
        // wait for the keyboard to close before showing the picker, so it doesn't flicker
        setTimeout(() => {
            setShowEmojis(true);
        }, 100);
    }, []);
    const hideEmojis = useCallback(() => {
        setShowEmojis(false);
        // focus the keyboard back only after the picker closed
        setTimeout(() => {
            inputRef.current?.focus();
            // put the selection at the end
            inputRef.current?.setSelectionRange(
                inputRef.current?.value.length || 0,
                inputRef.current?.value.length || 0
            );
        }, 100);
    }, []);
    const onEmojiClick = useCallback(({ emoji }) => {
        const cursorPosition = inputRef.current?.selectionStart || 0;
        setTextValue((textValue) => {
            if (emoji.length + textValue.length > MAX_MESSAGE_LENGTH) {
                // can't add more emojis - it will be too long
                return textValue;
            }
            return textValue.slice(0, cursorPosition) + emoji + textValue.slice(cursorPosition);
        });

        // has to be in a timeout because we are changing the value so the selection resets
        setTimeout(() => {
            inputRef.current?.setSelectionRange(cursorPosition + emoji.length, cursorPosition + emoji.length);
        });
    }, []);

    const sendMessage = useCallback(async () => {
        if (!userInfo) {
            return;
        }
        setTextValue("");
        try {
            if (matchUserInfo.consult) {
                const chatObject = userInfo?.isTherapist ? userInfo?.chatsWithMetupalim : userInfo?.chats;
                const addConsult = !(chatObject || {})[CONSULT_UID];
                if (addConsult) {
                    await createChat(userInfo, matchUserInfo);
                }
            }
            await createMessage(chatId, userInfo?.uid || "", textValue.trim());
        } catch (error) {
            console.error(error);
            enqueueSnackbar(<T params={{ code: 150 }}>general error</T>, { variant: "warning" });
        }
    }, [enqueueSnackbar, chatId, textValue, userInfo, matchUserInfo]);

    const submitIfCtrlEnter = useCallback(
        (event: KeyboardEvent) => {
            if (event.key === "Enter") {
                if (event.ctrlKey || event.metaKey || event.shiftKey) {
                    event.preventDefault();
                    if (textValue.trim()) {
                        sendMessage();
                    }
                }
            }
        },
        [textValue, sendMessage]
    );

    const InputProps = useMemo(
        () => ({
            endAdornment: (
                <InputAdornment position="end">
                    <IconButton onClick={toggleEmojis} edge="end" ref={emojiRef}>
                        <InsertEmoticonOutlined />
                    </IconButton>
                    <Popover
                        open={showEmojis}
                        onClose={hideEmojis}
                        anchorEl={emojiRef.current}
                        anchorOrigin={{
                            vertical: "top",
                            horizontal: "left",
                        }}
                        transformOrigin={{
                            vertical: "bottom",
                            horizontal: "left",
                        }}>
                        <EmojiPicker
                            searchDisabled
                            skinTonesDisabled
                            autoFocusSearch={false}
                            previewConfig={EMOJI_PREVIEW_CONFIG}
                            emojiStyle={EmojiStyle.GOOGLE}
                            lazyLoadEmojis
                            emojiVersion="4.0"
                            categories={EMOJI_CATEGORIES_CONFIG}
                            onEmojiClick={onEmojiClick}
                        />
                    </Popover>
                </InputAdornment>
            ),
        }),
        [toggleEmojis, showEmojis, hideEmojis, onEmojiClick]
    );

    return (
        <Stack gap={1} flexDirection="row" alignItems="flex-end">
            <TextField
                placeholder={textPlaceholder.join()}
                value={textValue}
                onChange={updateText}
                inputProps={{ maxLength: MAX_MESSAGE_LENGTH }}
                multiline
                maxRows={5}
                fullWidth
                onKeyDown={submitIfCtrlEnter}
                inputRef={inputRef}
                InputProps={disabled ? {} : InputProps}
                autoFocus
                disabled={disabled}
            />
            <Fab color="primary" onClick={sendMessage} disabled={!textValue.trim()} sx={{ flexShrink: 0 }}>
                <WithThemeIcon Icon={SendOutlined} reverse />
            </Fab>
        </Stack>
    );
}
