import {
    DocumentData,
    getDocs,
    limit,
    onSnapshot,
    orderBy,
    query,
    QuerySnapshot,
    startAfter,
    where,
} from "firebase/firestore";
import { isEmpty } from "lodash";
import { useSnackbar } from "notistack";
import { useCallback, useEffect, useRef, useState } from "react";

import T from "../locallization/T";
import { useAppContext } from "../root/AppContext";
import { CHAT, getChatCollection } from "./chatUpdates";

const CHATS_PER_PAGE = 10;

export function useChatsService(isTherapists: boolean) {
    const { userInfo } = useAppContext();
    const [loading, setLoading] = useState(true);
    const [loadMoreIndex, setLoadMoreIndex] = useState(0);
    const [chats, setChats] = useState<CHAT[]>([]);
    const lastChatRef = useRef({});
    const [hasMoreChats, setHasMoreChats] = useState(false);
    const { enqueueSnackbar } = useSnackbar();

    useEffect(() => {
        setLoading(true);

        const filters = [
            isTherapists
                ? where("metupalUids", "array-contains", userInfo?.uid)
                : where("therapistUid", "==", userInfo?.uid),
            limit(CHATS_PER_PAGE),
            orderBy("lastMessageTime", "desc"),
        ];
        if (!isEmpty(lastChatRef.current)) {
            filters.push(startAfter(lastChatRef.current));
        }
        const q = query(getChatCollection(), ...filters);

        // the first batch of chats, keep them live. If the user loads more, just get them once and stop watching.
        const isShowingLiveBatch = loadMoreIndex === 0;

        const onData = (querySnapshot: QuerySnapshot<DocumentData>) => {
            if (querySnapshot.docs.length) {
                lastChatRef.current = querySnapshot.docs[querySnapshot.docs.length - 1];
                // if all chats requested were loaded, there could be more
                setHasMoreChats(querySnapshot.docs.length === CHATS_PER_PAGE);
            } else {
                lastChatRef.current = {};
                // if loaded 0 docs, no more chats.
                setHasMoreChats(false);
            }
            const newChatsToAdd = querySnapshot.docs.map((doc) => doc.data() as CHAT);
            if (isShowingLiveBatch) {
                setChats(newChatsToAdd);
            } else {
                setChats((chats) => chats.concat(newChatsToAdd));
            }
            setLoading(false);
        };

        const onError = (error) => {
            console.error(error);
            setLoading(false);
            enqueueSnackbar(<T params={{ code: 152 }}>general error</T>, { variant: "warning" });
        };

        if (isShowingLiveBatch) {
            const unsubscribe = onSnapshot(q, onData, onError);
            return () => unsubscribe();
        } else {
            getDocs(q).then(onData).catch(onError);
        }
    }, [isTherapists, userInfo?.uid, loadMoreIndex, enqueueSnackbar]);

    const loadMoreChats = useCallback(() => setLoadMoreIndex((loadMoreIndex) => loadMoreIndex + 1), []);

    return { loading, chats, hasMoreChats, loadMoreChats };
}
