import { Stack } from "@mui/material";
import { useLocation } from "react-router-dom";

import PageTitle from "../common/PageTitle";
import T from "../locallization/T";
import PageContainer from "../navigation/PageContainer";
import { PROFILE_STATE_OPEN_SETTING, PROFILE_STATE_OPEN_SETTING_NOTIFICATIONS, ROUTES } from "../navigation/Router";
import OutputGeneral from "../outputs/private/OutputGeneral";
import { useAppContext } from "../root/AppContext";
import ProfileLoginAs from "./settingButtons/ProfileLoginAs";
import SettingRowAccount from "./settingRows/SettingRowAccount";
import SettingRowContact from "./settingRows/SettingRowContact";
import SettingRowGeneral from "./settingRows/SettingRowGeneral";
import SettingRowNotifications from "./settingRows/SettingRowNotifications";
import SettingRowSearch from "./settingRows/SettingRowSearch";

export default function Profile() {
    const { user, userInfo } = useAppContext();

    const location = useLocation();
    const intiialDialogOpen = ((location.state as {}) || {})[PROFILE_STATE_OPEN_SETTING];

    if (!user || !userInfo) {
        return <div>No user</div>;
    }

    return (
        <PageContainer pageTitleKey="title settings" pagePath={ROUTES.PROFILE} hideTopNav backgrounded>
            <Stack gap={2}>
                <PageTitle gap={2}>
                    <T>profile page title</T>
                </PageTitle>
                <OutputGeneral newUserInfo={userInfo} />
                <SettingRowGeneral />
                {userInfo.isTherapist && <SettingRowSearch />}
                <SettingRowNotifications startOpen={intiialDialogOpen === PROFILE_STATE_OPEN_SETTING_NOTIFICATIONS} />
                <SettingRowAccount />
                <SettingRowContact />
                <ProfileLoginAs />
            </Stack>
        </PageContainer>
    );
}
