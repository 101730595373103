import { Box, Link, Stack, Typography } from "@mui/material";
import { lazy, Suspense, useCallback, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import T from "../locallization/T";
import PageContainer from "../navigation/PageContainer";
import { PROFILE_STATE_OPEN_SETTING, PROFILE_STATE_OPEN_SETTING_NOTIFICATIONS, ROUTES } from "../navigation/Router";
import { deleteOnboardingFromStorage } from "../onboarding/onboardingStorage";
import { useAppContext } from "../root/AppContext";
import { boldStyles } from "../theme/theme";
import { updateUserInfo } from "../user/userUpdates";
import FacesBackgroundPng from "./png/facesBackground.png";
import ResultsBackgroundPng from "./png/resultsBackground.png";

const LazyLottieSpinner = lazy(() => import("./LottieSpinner"));

export default function ResultsLoader({ initial = false }: { initial?: boolean }) {
    const { user, userInfo } = useAppContext();
    const navigate = useNavigate();
    const [minWaitReady, setMinWaitReady] = useState(false);
    const [isWaitingFOrResults] = useState(!userInfo?.isTherapist && userInfo?.enableSearch);

    useEffect(() => {
        // if you made it here, there shouldn't be any user info saved on storage.
        deleteOnboardingFromStorage();
    }, []);

    useEffect(() => {
        if (user && !userInfo?.timesSearched) {
            updateUserInfo(user.uid, userInfo, { timesSearched: true });
        }
    }, [user, userInfo]);

    useEffect(() => {
        if (!userInfo?.isTherapist && !userInfo?.enableSearch && minWaitReady) {
            // As soon as search is disabled, go home to show results
            navigate(ROUTES.HOME, { replace: true });
        }
    }, [navigate, userInfo?.isTherapist, userInfo?.enableSearch, minWaitReady]);

    useEffect(() => {
        const timerId = setTimeout(() => setMinWaitReady(true), 5000);

        return () => clearTimeout(timerId);
    }, [navigate, userInfo?.isTherapist, userInfo?.enableSearch]);

    const navigateToNotificationPreferences = useCallback(
        (event) => {
            event.preventDefault();
            navigate(ROUTES.PROFILE, {
                state: { [PROFILE_STATE_OPEN_SETTING]: PROFILE_STATE_OPEN_SETTING_NOTIFICATIONS },
            });
        },
        [navigate]
    );

    return (
        <PageContainer
            pageTitleKey="title finish onboarding"
            pagePath={initial ? ROUTES.RESULTS_LOADER_INITIAL : ROUTES.RESULTS_LOADER_EDIT}
            hideBack
            hideBotNav={isWaitingFOrResults}
            showLogo>
            <Stack alignItems="center" justifyContent="center" height="100%" textAlign="center" position="relative">
                <Box
                    width={375}
                    maxWidth="100%"
                    component="img"
                    src={ResultsBackgroundPng}
                    alt=""
                    sx={{
                        position: "absolute",
                        left: 0,
                        right: 0,
                        margin: "auto",
                        zIndex: -1,
                        top: -40,
                    }}
                />
                <Box height={404} zIndex={-1} overflow="hidden" position="relative">
                    <Suspense>
                        <LazyLottieSpinner />
                    </Suspense>
                    <Box
                        height="100%"
                        component="img"
                        src={FacesBackgroundPng}
                        alt=""
                        sx={{
                            position: "absolute",
                            left: 0,
                            right: 0,
                            top: 0,
                            margin: "auto",
                            objectFit: "contain",
                            maxWidth: "100%",
                        }}
                    />
                </Box>

                <>
                    <Typography variant="h2" sx={{ mt: userInfo?.isTherapist ? 4 : 5, ...boldStyles }}>
                        {userInfo?.isTherapist ? (
                            <T>results loader title therapist</T>
                        ) : (
                            <T>results loader title new</T>
                        )}
                    </Typography>
                    <Typography variant="h4" sx={{ mt: 1 }}>
                        {userInfo?.isTherapist ? (
                            <T>results loader subtitle therapist</T>
                        ) : (
                            <T>results loader subtitle</T>
                        )}
                    </Typography>
                    {userInfo?.isTherapist && (
                        <Link sx={{ mt: 2, mb: 3 }} href={ROUTES.PROFILE} onClick={navigateToNotificationPreferences}>
                            <Typography>
                                <T>results loader notifications link</T>
                            </Typography>
                        </Link>
                    )}
                </>
            </Stack>
        </PageContainer>
    );
}
