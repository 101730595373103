import { ExpandMoreOutlined } from "@mui/icons-material";
import {
    Accordion,
    AccordionDetails,
    AccordionProps,
    AccordionSummary,
    ButtonBase,
    styled,
    Typography,
} from "@mui/material";
import { ReactNode } from "react";

import { boldStyles, COLOR_APP_PRIMARY_DARK, COLOR_APP_PRIMARY_LIGHT, COLOR_APP_PRIMARY_LIGHT_2 } from "../theme/theme";

const StyledAccordion = styled(Accordion)({
    "&:before": {
        display: "none",
    },
});
const StyledAccordionSummary = styled(AccordionSummary)({
    background: COLOR_APP_PRIMARY_LIGHT_2,
    color: COLOR_APP_PRIMARY_DARK,
    borderRadius: 8,
    width: "100%",
    "&:hover": {
        background: COLOR_APP_PRIMARY_LIGHT,
    },
    "& .MuiAccordionSummary-content": {
        marginTop: 20,
        marginBottom: 20,
    },
});
const StyledAccordionDetails = styled(AccordionDetails)(() => ({
    paddingTop: 24,
    paddingRight: 0,
    paddingLeft: 0,
}));

export default function CollapsibleSection({
    summary,
    children,
    ...props
}: { summary: ReactNode; children: ReactNode } & AccordionProps) {
    return (
        <StyledAccordion disableGutters elevation={0} square={false} {...props}>
            <ButtonBase sx={{ width: "100%", borderRadius: 2 }}>
                <StyledAccordionSummary expandIcon={<ExpandMoreOutlined />}>
                    <Typography sx={{ ...boldStyles }}>{summary}</Typography>
                </StyledAccordionSummary>
            </ButtonBase>
            <StyledAccordionDetails>{children}</StyledAccordionDetails>
        </StyledAccordion>
    );
}
