import T from '../../locallization/T';
import SearchInputBasicText from '../basic/SearchInputBasicText';
import { SEARCH_INPUT_PROPS } from '../inputs/SearchInputProps';
import { MAX_DEGREE_LENGTH } from './SearchInputDegree';

export default function SearchInputDegree3({ onData, newUserInfo }: SEARCH_INPUT_PROPS) {
    return (
        <SearchInputBasicText
            label={<T>search input degree 3</T>}
            maxLength={MAX_DEGREE_LENGTH}
            userInfoKey="therapistDegree3"
            onData={onData}
            newUserInfo={newUserInfo}
        />
    );
}
