import { Checkbox, FormControl, InputLabel, ListItemIcon, ListItemText, MenuItem, Select } from "@mui/material";
import { useCallback, useMemo, useState } from "react";

import SeparatedList from "../../common/SeparatedList";
import T from "../../locallization/T";
import { useUpdateTherapistKupotOptions } from "../../onboarding/questions/OnboardingQuestionTherapistKupot";
import { convertFromUserInfoMap, convertToUserInfoMap, USER_INFO_KUPA } from "../../user/userInfo";
import SelectConfirmButton from "../basic/SelectConfirmButton";
import { userInfoKupotWithNone } from "../inputs/SearchInputKupa";
import { SEARCH_INPUT_PROPS } from "../inputs/SearchInputProps";

export default function SearchInputKupot({ onData, newUserInfo }: SEARCH_INPUT_PROPS) {
    const [open, setOpen] = useState(false);
    const handleClose = useCallback(() => setOpen(false), []);
    const handleOpen = useCallback(() => setOpen(true), []);
    const [kupotSet, setKupotSet] = useState<Set<USER_INFO_KUPA>>(
        new Set(convertFromUserInfoMap(newUserInfo?.therapistKupot || {}))
    );
    const kupot = useMemo(() => Array.from(kupotSet), [kupotSet]);

    const updateSelectedOptions = useUpdateTherapistKupotOptions(setKupotSet);

    const updateKupot = useCallback(
        (event) => {
            if (event.target.value.length > 0) {
                updateSelectedOptions(
                    () => new Set(event.target.value),
                    (newData) => onData({ therapistKupot: convertToUserInfoMap(Array.from(newData)) })
                );
            }
        },
        [onData, updateSelectedOptions]
    );

    return (
        <FormControl>
            <InputLabel>
                <T>search input kupot</T>
            </InputLabel>
            <Select
                multiple
                value={kupot}
                onChange={updateKupot}
                label={<T>search input kupot</T>}
                open={open}
                onClose={handleClose}
                onOpen={handleOpen}
                renderValue={(selected) => (
                    <SeparatedList separator="; ">
                        {selected.map((kupa) => (
                            <T key={kupa} children={`onboarding question kupa ${kupa}`} />
                        ))}
                    </SeparatedList>
                )}>
                {userInfoKupotWithNone.map(({ value: kupa, ImagePng, textKey }) => (
                    <MenuItem key={kupa} value={kupa}>
                        <Checkbox checked={kupot.indexOf(kupa) > -1} />
                        {ImagePng ? (
                            <ListItemIcon>
                                <img src={ImagePng} alt={kupa} />
                            </ListItemIcon>
                        ) : (
                            <ListItemText primary={<T>{textKey}</T>} />
                        )}
                    </MenuItem>
                ))}
                <SelectConfirmButton handleClose={handleClose} listSize={userInfoKupotWithNone.length} />
            </Select>
        </FormControl>
    );
}
