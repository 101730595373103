import { USER_INFO } from "../user/userInfo";

const THERAPIST_STORAGE_KEY = "therapist-status";

let therapistUpdatesSoFar: USER_INFO | null = null;

export function saveTherapistToStorage(updates: USER_INFO, { override = false } = {}) {
    try {
        const existingTherapist = getTherapistFromStorage();
        let newTherapist: USER_INFO = override ? { ...updates } : { ...existingTherapist, ...updates };
        therapistUpdatesSoFar = newTherapist;
        const therapistString = JSON.stringify(newTherapist);
        localStorage.setItem(THERAPIST_STORAGE_KEY, therapistString);
    } catch (error) {
        return Promise.reject(error);
    }
    return Promise.resolve();
}

export function getTherapistFromStorage() {
    if (therapistUpdatesSoFar) {
        return therapistUpdatesSoFar;
    }
    try {
        const therapistString = localStorage.getItem(THERAPIST_STORAGE_KEY) || "";
        const therapistJson: USER_INFO = JSON.parse(therapistString);
        therapistUpdatesSoFar = therapistJson;
        return therapistJson;
    } catch (error) {
        return {};
    }
}

export function deleteTherapistFromStorage() {
    localStorage.removeItem(THERAPIST_STORAGE_KEY);
    therapistUpdatesSoFar = null;
}
