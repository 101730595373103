import { Button, ButtonProps, Stack, StackProps, SxProps, Theme } from '@mui/material';
import { ReactNode } from 'react';

export default function ActionButtons({
    children,
    sx = [],
    ...props
}: { children: ReactNode; sx?: SxProps<Theme> } & StackProps) {
    return (
        <Stack flexDirection="row" sx={{ p: 2, whiteSpace: "nowrap", ...sx }} width="100%" gap={2} {...props}>
            {children}
        </Stack>
    );
}

export function ActionButtonPrimary(props: ButtonProps) {
    return <Button sx={{ width: "100%" }} variant="contained" size="huge" {...props} />;
}

export function ActionButtonSecondary(props: ButtonProps) {
    return <Button sx={{ width: "100%" }} variant="outlined" size="huge" {...props} />;
}
