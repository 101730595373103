import { map } from "lodash";

import SeparatedList from "../../common/SeparatedList";
import T from "../../locallization/T";
import { userInfoTypes } from "../../onboarding/questions/OnboardingQuestionType";
import {
    therapistQuestionIcons,
    therapistQuestionIconsColorful,
} from "../../onboarding/therapist/OnboardingQuestionsTherapist";
import { THERAPIST_OUTPUT_PROPS } from "../basic/OutputProps";
import OutputRow from "../basic/OutputRow";
import { useSortedOutput } from "../basic/useSortedOutput";

export default function TherapistOutputTypes({ newUserInfo, colorful, ...props }: THERAPIST_OUTPUT_PROPS) {
    const sortedTypes = useSortedOutput(newUserInfo?.therapistTherapyTypes, userInfoTypes, "value");

    return (
        <OutputRow
            {...props}
            title={<T>therapist output types</T>}
            value={
                <SeparatedList>
                    {map(sortedTypes, (type) => (
                        <T key={type} children={`onboarding question type ${type}`} />
                    ))}
                </SeparatedList>
            }
            Icon={colorful ? therapistQuestionIconsColorful.therapist_type : therapistQuestionIcons.therapist_type}
        />
    );
}
