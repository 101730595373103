const cacheMap = new Map<string, {}>();

// idk it's kind of like use deep memo i guess
export function useCached(obj = {}) {
    const key = JSON.stringify(obj);
    let value = cacheMap.get(key);
    if (!value) {
        value = obj;
        cacheMap.set(key, value);
    }
    return value;
}
