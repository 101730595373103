import { GeoPoint } from "firebase/firestore";

import { getTalkGender } from "../common/gender/getTalkGender";
import { USER_INFO } from "../user/userInfo";

const ONBOARDING_STORAGE_KEY = "onboarding-status";

let onboardingUpdatesSoFar: USER_INFO | null = null;

export function saveOnboardingToStorage(updates: USER_INFO, { override = false } = {}) {
    try {
        const existingOnboarding = getOnboardingFromStorage();
        let newOnboarding: USER_INFO = override ? { ...updates } : { ...existingOnboarding, ...updates };
        onboardingUpdatesSoFar = newOnboarding;
        if (newOnboarding.locationGeo) {
            newOnboarding = { ...newOnboarding };
            (newOnboarding.locationGeo as any) = newOnboarding.locationGeo?.toJSON();
        }
        const onboardingString = JSON.stringify(newOnboarding);
        localStorage.setItem(ONBOARDING_STORAGE_KEY, onboardingString);
    } catch (error) {
        return Promise.reject(error);
    }
    return Promise.resolve();
}

export function getOnboardingFromStorage() {
    if (onboardingUpdatesSoFar) {
        return onboardingUpdatesSoFar;
    }
    try {
        const onboardingString = localStorage.getItem(ONBOARDING_STORAGE_KEY) || "";
        const onboardingJson: USER_INFO = JSON.parse(onboardingString);
        if (onboardingJson.locationGeo) {
            onboardingJson.locationGeo = new GeoPoint(
                onboardingJson.locationGeo.latitude,
                onboardingJson.locationGeo.longitude
            );
        }
        onboardingUpdatesSoFar = onboardingJson;
        return onboardingJson;
    } catch (error) {
        return {};
    }
}

export function deleteOnboardingFromStorage() {
    localStorage.removeItem(ONBOARDING_STORAGE_KEY);
    onboardingUpdatesSoFar = null;
}
export function getStorageGender() {
    const existingOnboarding = getOnboardingFromStorage();
    return getTalkGender(existingOnboarding);
}
export function getStorageIsTherapist() {
    const existingOnboarding = getOnboardingFromStorage();
    return existingOnboarding.isTherapist;
}
