import T from "../../locallization/T";
import { metupalQuestionIcons, metupalQuestionIconsColorful } from "../../onboarding/OnboardingQuestionsMetupal";
import { hasKupa } from "../../onboarding/questions/OnboardingQuestionCost";
import { METUPAL_OUTPUT_PROPS } from "../basic/OutputProps";
import OutputRow from "../basic/OutputRow";

export default function MetupalOutputKupa({ newUserInfo, colorful, ...props }: METUPAL_OUTPUT_PROPS) {
    if (!hasKupa(newUserInfo)) {
        return null;
    }
    return (
        <OutputRow
            {...props}
            title={<T>metupal output kupa</T>}
            value={<T children={`onboarding question kupa ${newUserInfo?.kupa}`} /> || <T>output was not mentioned</T>}
            Icon={colorful ? metupalQuestionIconsColorful.kupa : metupalQuestionIcons.kupa}
        />
    );
}
