import { Stack, Typography } from "@mui/material";
import { useEffect, useState } from "react";

import T from "../../locallization/T";
import AppToggleButton from "../../onboarding/buttonGroup/AppToggleButton";
import { USER_INFO_MESSAGE_PREF } from "../../user/userInfo";
import SearchInputBasicSelect from "../basic/SearchInputBasicSelect";
import { SEARCH_INPUT_PROPS } from "./SearchInputProps";

export const userInfoMessagePref: { value: USER_INFO_MESSAGE_PREF; textKey: string }[] = [
    { value: "zigmond", textKey: "search input message pref zigmond" },
    { value: "self", textKey: "search input message pref self" },
];

export default function SearchInputMessagePref({
    onData,
    newUserInfo,
    expanded,
}: SEARCH_INPUT_PROPS & { expanded?: boolean }) {
    const [selectedOptions, setSelectedOptions] = useState(
        new Set<USER_INFO_MESSAGE_PREF>(newUserInfo?.messagePref ? [newUserInfo?.messagePref] : [])
    );

    useEffect(() => {
        if (expanded) {
            const arr = Array.from(selectedOptions);
            const messagePref = arr.length ? arr[0] : undefined;
            onData({
                messagePref,
            });
        }
    }, [selectedOptions, onData, expanded]);

    if (expanded) {
        return (
            <Stack gap={2}>
                <Typography variant="subtitle1">
                    <T>onboarding messages pref</T>
                </Typography>
                <Stack flexDirection="row" gap={1}>
                    {userInfoMessagePref.map(({ value, textKey }) => (
                        <AppToggleButton
                            key={value}
                            value={value}
                            limitToOne
                            preventEmpty
                            selectedOptions={selectedOptions}
                            setSelectedOptions={setSelectedOptions}>
                            <T>{textKey}</T>
                        </AppToggleButton>
                    ))}
                </Stack>
            </Stack>
        );
    }

    return (
        <SearchInputBasicSelect
            onData={onData}
            newUserInfo={newUserInfo}
            userInfoKey="messagePref"
            label={<T>search input messages pref</T>}
            allValues={userInfoMessagePref}
        />
    );
}
