import { Stack } from "@mui/material";

import AppSpinner from "../navigation/AppSpinner";
import { APP_WIDTH } from "../navigation/usePageSizes";

export default function AppVideo({
    loading,
    thumbnailUrl,
    videoUrl,
}: {
    loading?: boolean;
    thumbnailUrl?: string;
    videoUrl: string;
}) {
    return (
        <Stack
            maxWidth="100%"
            width={APP_WIDTH}
            // Set a height when loading so the space is occupied.
            height={loading ? (APP_WIDTH * 9) / 16 : "auto"}
            borderRadius={1}
            overflow="hidden">
            {loading ? (
                <AppSpinner />
            ) : (
                <video width="100%" poster={thumbnailUrl} controls controlsList="nodownload" playsInline>
                    <source src={videoUrl} type="video/mp4" />
                </video>
            )}
        </Stack>
    );
}
