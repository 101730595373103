import { Stack } from "@mui/material";
import { useCallback, useEffect, useMemo, useState } from "react";

import SearchInputMessagePref from "../../search/inputs/SearchInputMessagePref";
import SearchInputOthers from "../../search/inputs/SearchInputOthers";
import { USER_INFO, USER_INFO_MESSAGE_PREF } from "../../user/userInfo";
import { ONBOARDING_QUESTION_PROPS } from "./OnboardingQuestionProps";

export function alreadyHasOthers(userInfo: USER_INFO) {
    return userInfo.messagePref !== undefined;
}

export default function OnboardingQuestionOthers({ onDataProgress, newUserInfo }: ONBOARDING_QUESTION_PROPS) {
    const [othersList, setOthersList] = useState<number[]>(newUserInfo?.othersList || []);
    const [messagePref, setMessagePref] = useState<USER_INFO_MESSAGE_PREF | undefined>(newUserInfo?.messagePref);

    const updatedUserInfo = useMemo(
        () => ({ ...newUserInfo, othersList, messagePref }),
        [newUserInfo, othersList, messagePref]
    );

    useEffect(() => {
        if (messagePref) {
            onDataProgress({
                othersList,
                messagePref,
            });
        } else {
            onDataProgress({});
        }
    }, [onDataProgress, othersList, messagePref]);

    const onData = useCallback((updates: USER_INFO) => {
        if (updates.othersList !== undefined) {
            setOthersList(updates.othersList);
        }
        if (updates.messagePref !== undefined) {
            setMessagePref(updates.messagePref);
        }
    }, []);

    return (
        <Stack gap={3}>
            <SearchInputOthers onData={onData} newUserInfo={updatedUserInfo} />
            <SearchInputMessagePref onData={onData} newUserInfo={updatedUserInfo} expanded />
        </Stack>
    );
}
