import { Checkbox, FormControl, InputLabel, ListItemIcon, ListItemText, MenuItem, Select, Stack } from "@mui/material";
import { isArray, keys, sortedIndex } from "lodash";
import { Fragment, useCallback, useMemo, useState } from "react";

import SeparatedList from "../../common/SeparatedList";
import T from "../../locallization/T";
import AppToggleButton from "../../onboarding/buttonGroup/AppToggleButton";
import { USER_INFO_MAP, USER_INFO_TIME } from "../../user/userInfo";
import SearchInputBasicChips from "../basic/SearchInputBasicChips";
import SelectConfirmButton from "../basic/SelectConfirmButton";
import { SEARCH_INPUT_PROPS } from "../inputs/SearchInputProps";
import { userInfoTimes } from "../inputs/SearchInputTime";

const TIME_DAYS = [1, 2, 3, 4, 5, 6, 7];
export const DEFAULT_TIME_DAYS_SELECTED = [1, 2, 3, 4, 5];

export default function SearchInputTherapistTimes({
    onData,
    newUserInfo,
    expanded,
    preventEmpty,
}: SEARCH_INPUT_PROPS & { expanded?: boolean; preventEmpty?: boolean }) {
    const [times, setTimes] = useState<USER_INFO_MAP<USER_INFO_TIME, number[]>>(newUserInfo?.therapistTimes || {});
    const timesList = useMemo(() => keys(times) as USER_INFO_TIME[], [times]);
    const timesSet = useMemo(() => new Set<USER_INFO_TIME>(timesList), [timesList]);
    const [open, setOpen] = useState(false);
    const handleClose = useCallback(() => setOpen(false), []);
    const handleOpen = useCallback(() => setOpen(true), []);

    const updateTimes = useCallback(
        (newTimesSet: Set<USER_INFO_TIME>) => {
            const newTimes = { ...times };
            // remove existing times
            for (let time in newTimes) {
                if (!newTimesSet.has(time as USER_INFO_TIME)) {
                    delete newTimes[time];
                }
            }
            // add missing times
            for (const time of Array.from(newTimesSet)) {
                if (!newTimes[time]) {
                    newTimes[time] = [...DEFAULT_TIME_DAYS_SELECTED];
                }
            }
            setTimes(newTimes);
            onData({ therapistTimes: newTimes });
        },
        [onData, times]
    );

    const updateTimesSet = useCallback(
        (getNewTimesSet: (selectedOptions: Set<USER_INFO_TIME>) => Set<USER_INFO_TIME>) => {
            const newTimesSet = getNewTimesSet(timesSet);
            updateTimes(newTimesSet);
        },
        [updateTimes, timesSet]
    );

    const updateTimesList = useCallback(
        (event) => {
            const list = event.target.value;
            if (preventEmpty && list.length === 0) {
                return;
            }
            updateTimes(new Set(list));
        },
        [updateTimes, preventEmpty]
    );

    const toggleDay = useCallback(
        (time: string, day: number) => {
            const timeDays = times[time] || [];

            const newTimes = { ...times };
            const index = sortedIndex(timeDays, day);
            const newTimesDay = [...timeDays];
            if (timeDays[index] === day) {
                newTimesDay.splice(index, 1);
            } else {
                newTimesDay.splice(index, 0, day);
            }
            newTimes[time] = newTimesDay;

            setTimes(newTimes);
            onData({ therapistTimes: newTimes });
        },
        [onData, times]
    );

    const isTimeDaySelected = useCallback(
        (time: string, day: number) => {
            const timeDays = times[time] || [];
            if (isArray(timeDays)) {
                return timeDays.includes(day);
            }
            return false;
        },
        [times]
    );

    const getDayValueLabel = useCallback((value: number) => <T children={`day ${value}`} />, []);
    const getValueLabel = useCallback((value: string) => <T children={`search input time ${value}`} />, []);
    const getSelectedLabel = useCallback((value: string) => <T children={`search input time short ${value}`} />, []);

    if (expanded) {
        return (
            <Stack gap={2}>
                {userInfoTimes.map(({ value: time, Icon }) => (
                    <Fragment key={time}>
                        <AppToggleButton
                            value={time}
                            selectedOptions={timesSet}
                            setSelectedOptions={updateTimesSet}
                            preventEmpty={preventEmpty}
                            Icon={<Icon />}>
                            <T children={`search input time ${time}`} />
                        </AppToggleButton>
                        {timesSet.has(time) && (
                            <SearchInputBasicChips
                                allValues={TIME_DAYS}
                                getValueLabel={getDayValueLabel}
                                isValueSelected={(day) => isTimeDaySelected(time, day)}
                                onValueClick={(day) => toggleDay(time, day)}
                            />
                        )}
                    </Fragment>
                ))}
            </Stack>
        );
    }

    return (
        <FormControl>
            <InputLabel>
                <T>search input time</T>
            </InputLabel>
            <Select
                multiple
                value={timesList}
                onChange={updateTimesList}
                label={<T>search input time</T>}
                open={open}
                onClose={handleClose}
                onOpen={handleOpen}
                renderValue={(selected) => (
                    <SeparatedList separator="; ">
                        {userInfoTimes
                            .map(
                                ({ value: time }) =>
                                    selected.includes(time) && <Fragment key={time}>{getSelectedLabel(time)}</Fragment>
                            )
                            .filter((a) => a)}
                    </SeparatedList>
                )}>
                {userInfoTimes.map(({ value: time, Icon }) => [
                    <MenuItem key={time} value={time}>
                        <Checkbox checked={times.hasOwnProperty(time)} />
                        <ListItemText primary={getValueLabel(time)} />
                        <ListItemIcon>
                            <Icon fontSize="small" />
                        </ListItemIcon>
                    </MenuItem>,

                    timesSet.has(time) ? (
                        <MenuItem sx={{ pl: 3 }}>
                            <SearchInputBasicChips
                                allValues={TIME_DAYS}
                                getValueLabel={getDayValueLabel}
                                isValueSelected={(day) => isTimeDaySelected(time, day)}
                                onValueClick={(day) => toggleDay(time, day)}
                            />
                        </MenuItem>
                    ) : null,
                ])}
                <SelectConfirmButton handleClose={handleClose} listSize={userInfoTimes.length} />
            </Select>
        </FormControl>
    );
}
