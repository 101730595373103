import { keys } from "lodash";
import { useMemo } from "react";

import { useAppContext } from "../root/AppContext";
import { CONSULT_UID } from "../user/consult";
import { USER_INFO } from "../user/userInfo";

export function useChatsUids({ newUserInfo, isTherapist }: { newUserInfo?: USER_INFO; isTherapist?: boolean } = {}) {
    const { userInfo } = useAppContext();
    const targetUserInfo = newUserInfo ?? userInfo;

    const chatObject =
        isTherapist ?? targetUserInfo?.isTherapist ? targetUserInfo?.chatsWithMetupalim : targetUserInfo?.chats;
    const addConsult = !(chatObject || {})[CONSULT_UID];
    return useMemo(() => {
        const list = keys(chatObject);
        if (addConsult) {
            list.push(CONSULT_UID);
        }
        return list;
    }, [chatObject, addConsult]);
}
