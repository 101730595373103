import { Stack, StackProps, Typography } from "@mui/material";
import { useCallback } from "react";

import { CHAT_PREFILL_CONSULT, CHAT_PREFILL_STATE } from "../chat/messages/MessagesInput";
import { useNavigateToChat } from "../chat/useNavigateToChat";
import T from "../locallization/T";
import MatchCard from "../matches/MatchCard";
import { useLoadUserInfos } from "../matches/userInfosService";
import { useProfileName } from "../outputs/basic/useProfileName";
import { boldStyles } from "../theme/theme";
import { CONSULT_UID } from "../user/consult";

const CONSULT_UIDS = [CONSULT_UID];

export function NeedConsultCard({ ...stackProps }: {} & StackProps) {
    const { loading, userInfos } = useLoadUserInfos(CONSULT_UIDS, true);

    const navigateToChat = useNavigateToChat(CONSULT_UID);

    const onConsultCardClick = useCallback(() => {
        navigateToChat({ navigateOptions: { state: { [CHAT_PREFILL_STATE]: CHAT_PREFILL_CONSULT } } });
    }, [navigateToChat]);

    const name = useProfileName({
        targetUserInfo: userInfos[0],
        isTargetTherapist: true,
    });

    if (loading || !userInfos.length) {
        return null;
    }
    return (
        <Stack gap={2} {...stackProps}>
            <Typography variant="h3" sx={{ ...boldStyles }}>
                <T>consult card title</T>
            </Typography>
            <MatchCard
                basicUserInfo={userInfos[0]}
                onClick={onConsultCardClick}
                title={
                    <Typography variant="h3" sx={{ ...boldStyles }}>
                        <T>consult card header title</T>
                    </Typography>
                }
                subheader={name}
                actionSide={
                    <Stack>
                        <Typography>
                            <T>consult card cost title</T>
                        </Typography>
                        <Typography variant="h3" sx={{ ...boldStyles }}>
                            <T>consult card cost value</T>
                        </Typography>
                    </Stack>
                }>
                <Typography>
                    <T>consult card content</T>
                </Typography>
            </MatchCard>
        </Stack>
    );
}
