import { getStorage, ref } from "firebase/storage";
import { useCallback } from "react";

import { BASIC_USER_INFO } from "../user/userInfo";
import { STORAGE_CACHE_MAP, useDownloadUrl } from "./imageUpload";

const CLOUD_NAME = `dpa7mwpxr`;
const PRESET = `opbumou9`;
const UPLOAD_URL = `https://api.cloudinary.com/v1_1/${CLOUD_NAME}/video/upload`;
const DELETE_URL = `https://api.cloudinary.com/v1_1/${CLOUD_NAME}/delete_by_token`;
export const VIEW_URL = `https://res.cloudinary.com/${CLOUD_NAME}/video/upload`;

export const VIDEO_KEY_1 = "v1";
export const VIDEO_KEY_2 = "v2";

const videosCacheMap: STORAGE_CACHE_MAP = new Map();
const videoThumbnailsCacheMap: STORAGE_CACHE_MAP = new Map();

export function getThreapistVideoThumbnailRef(uid: string, photoKey: string) {
    return ref(getStorage(), `therapist-video-thumbnails/${uid}/${photoKey}.jpg`);
}

export function getTherapistVideoRef(uid: string, videoKey: string) {
    return ref(getStorage(), `therapist-videos/${uid}/${videoKey}.mp4`);
}

export function useDownloadVideoUrl(userInfo: BASIC_USER_INFO) {
    return useDownloadUrl(userInfo, "therapistVideoKey", getTherapistVideoRef, videosCacheMap);
}
export function useDownloadVideoThumbnailUrl(userInfo: BASIC_USER_INFO) {
    return useDownloadUrl(userInfo, "therapistVideoThumbKey", getThreapistVideoThumbnailRef, videoThumbnailsCacheMap);
}

export function useUploadVideo() {
    const uploadVideoToCloudinary = useCallback((file: File) => {
        const formData = new FormData();
        formData.append("file", file);
        formData.append("upload_preset", PRESET);
        return fetch(UPLOAD_URL, {
            method: "POST",
            body: formData,
        }).then((response) => response.json());
    }, []);

    const downloadAssetsFromCloudinary = useCallback((secureUrl: string) => {
        return Promise.all([
            fetch(secureUrl).then((response) => response.blob()),
            fetch(secureUrl.replace(".mp4", ".jpg")).then((response) => response.blob()),
        ]);
    }, []);

    const deleteVideoFromCloudinary = useCallback((deleteToken: string) => {
        const formData = new FormData();
        formData.append("token", deleteToken);
        return fetch(DELETE_URL, {
            method: "POST",
            body: formData,
        });
    }, []);

    return { uploadVideoToCloudinary, downloadAssetsFromCloudinary, deleteVideoFromCloudinary };
}
