import { User } from 'firebase/auth';
import { getDocs, query, where } from 'firebase/firestore';

import { USER_INFO } from './userInfo';
import { getTherapistCollection } from './userUpdates';

export const ERROR_LICENSE_EXISTS = "License already exists";

export async function ensureLicenseDoesntExist(user: User, license: string) {
    const therapistCollection = getTherapistCollection();
    const q = query(therapistCollection, where("license", "==", license));

    const querySnapshot = await getDocs<USER_INFO>(q);

    querySnapshot.forEach((doc) => {
        if (doc.data().uid !== user.uid) {
            throw new Error(ERROR_LICENSE_EXISTS);
        }
    });
}
