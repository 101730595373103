import { NotificationsActiveOutlined, NotificationsNoneOutlined, NotificationsOffOutlined } from "@mui/icons-material";
import { SvgIcon } from "@mui/material";
import { without } from "lodash";
import { useCallback, useMemo } from "react";

import T from "../../locallization/T";
import { USER_INFO_NOTIFICATION_CHAT } from "../../user/userInfo";
import SearchInputBasicSelect from "../basic/SearchInputBasicSelect";
import { SEARCH_INPUT_PROPS } from "./SearchInputProps";

const notificationChatOptions: { value: USER_INFO_NOTIFICATION_CHAT; Icon: typeof SvgIcon }[] = [
    { value: "every", Icon: NotificationsActiveOutlined },
    { value: "new", Icon: NotificationsNoneOutlined },
    { value: "none", Icon: NotificationsOffOutlined },
];

export default function SearchInputNotificationsChat({ onData, newUserInfo }: SEARCH_INPUT_PROPS) {
    const getValueLabel = useCallback(
        (value: string) => <T children={`search input notification chat ${value}`} />,
        []
    );

    const options = useMemo(() => {
        // one of the rare cases that we need to check whether ur account has therapist at all.
        // because we use the same option for both variants, so if u have therapist, show to metupal too.
        if (newUserInfo?.accountHasTherapist) {
            return notificationChatOptions;
        } else {
            return without(notificationChatOptions, notificationChatOptions[1]);
        }
    }, [newUserInfo?.accountHasTherapist]);

    return (
        <SearchInputBasicSelect
            onData={onData}
            newUserInfo={newUserInfo}
            userInfoKey="notificationsChat"
            label={<T>search input notifications chat</T>}
            allValues={options}
            defaultValue={options[0].value}
            getValueLabel={getValueLabel}
        />
    );
}
