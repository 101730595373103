import { useCallback, useState } from "react";

export function useSessionObject() {
    const [sessionObject, setSessionObject] = useState({});

    const getSessionValue = useCallback(
        (key: string) => {
            if (!sessionObject[key]) {
                try {
                    const value = sessionStorage.getItem(key) || "";
                    if (value) {
                        sessionObject[key] = value;
                    }
                } catch {}
            }
            return sessionObject[key] || "";
        },
        [sessionObject]
    );

    const setSessionValue = useCallback((key: string, value: string) => {
        setSessionObject((sessionObject) => ({ ...sessionObject, [key]: value }));
        try {
            sessionStorage.setItem(key, value);
        } catch {}
    }, []);

    return {
        getSessionValue,
        setSessionValue,
    };
}
