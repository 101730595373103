import { useMemo } from "react";

import { useAppContext } from "../root/AppContext";

export function useCheckMetupalMet() {
    const { userInfo } = useAppContext();

    return useMemo(() => {
        return userInfo?.checkMetupalMet || {};
    }, [userInfo?.checkMetupalMet]);
}
