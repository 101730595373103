import { ArrowForward } from "@mui/icons-material";
import { Box, IconButton, Paper, Stack, Typography } from "@mui/material";
import { noop } from "lodash";

import { COLOR_APP_PRIMARY_DARK, COLOR_APP_PRIMARY_LIGHT } from "../theme/theme";
import WithThemeIcon from "../theme/WithThemeIcon";
import { useHideHeaderOnInput } from "./useHideHeaderOnInput";
import { APP_WIDTH } from "./usePageSizes";

export default function TopUnion({ title, Icon, showBack = true, caption, onBackClick = noop }) {
    const hideHeader = useHideHeaderOnInput();

    return hideHeader ? (
        <div />
    ) : (
        <Paper
            elevation={2}
            sx={{
                background: COLOR_APP_PRIMARY_LIGHT,
                p: 4,
                mt: 2,
                width: "calc(100% - 32px)",
                maxWidth: APP_WIDTH,
                borderRadius: 4,
                color: COLOR_APP_PRIMARY_DARK,
                alignSelf: "center",
            }}>
            <Stack gap={0.5} justifyContent="center" textAlign="center">
                <Stack flexDirection="row" alignItems="center" justifyContent="space-between">
                    {showBack ? (
                        <IconButton onClick={onBackClick} color="inherit" sx={{ alignSelf: "flex-start" }} edge="start">
                            <WithThemeIcon Icon={ArrowForward} />
                        </IconButton>
                    ) : (
                        <div />
                    )}
                    <IconButton color="inherit" sx={{ pointerEvents: "none", mt: 2 }} size="medium">
                        <Icon fontSize="inherit" />
                    </IconButton>
                    {showBack ? <Box width={28} /> : <div />}
                </Stack>
                <Typography
                    variant="h2"
                    sx={{
                        // the line height for h2 is 2 pixels above the font size, so we need the min height to have a few more pixels
                        minHeight: "calc(2em + 4px)",
                    }}>
                    {title}
                </Typography>
                {caption && <Typography>{caption}</Typography>}
            </Stack>
        </Paper>
    );
}
