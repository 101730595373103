import T from "../../locallization/T";
import { alreadyHasLocation } from "../../onboarding/questions/OnboardingQuestionLocation";
import { alreadyHasReasons } from "../../onboarding/questions/OnboardingQuestionReasons";
import { alreadyHasTherapistLocation } from "../../onboarding/questions/OnboardingQuestionTherapistLocation";
import { USER_INFO } from "../../user/userInfo";
import { alreadyHasSpecialty } from "../therapistInputs/SearchInputSpecialty";

export function useSearchFormValidation({ newUserInfo }: { newUserInfo: USER_INFO }) {
    if (newUserInfo.isTherapist) {
        if (!alreadyHasTherapistLocation(newUserInfo)) {
            return <T prefix="* ">form error invalid therapist location</T>;
        }
        if (!alreadyHasSpecialty(newUserInfo)) {
            return <T prefix="* ">form error invalid specialty</T>;
        }
    } else {
        if (!alreadyHasLocation(newUserInfo)) {
            return <T prefix="* ">form error invalid location</T>;
        }
        if (!alreadyHasReasons(newUserInfo)) {
            return <T prefix="* ">form error invalid reasons limit</T>;
        }
    }
    return null;
}
