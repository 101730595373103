import { useMemo } from "react";

import { useAppContext } from "../root/AppContext";

export function useMatchedMetupalim() {
    const { userInfo } = useAppContext();
    return useMemo(() => {
        return userInfo?.matchedMetupalim || {};
    }, [userInfo?.matchedMetupalim]);
}
