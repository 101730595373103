import { useEffect } from "react";

import { useIsAppWebview } from "./useIsWebviewApp";

type APP_EVENT_HANDLER = (params: { data?: {}; errorCode?: any }) => void;
const appEventsListener = new Map<string, Set<APP_EVENT_HANDLER>>();

export function sendAppEvent(event: string) {
    (window as any).ReactNativeWebView?.postMessage(event);
}
export function useRegisterAppAdaptor() {
    const isInAppWebview = useIsAppWebview();

    useEffect(() => {
        if (isInAppWebview) {
            (window as any).OnWebViewEvent = ({ event, data, errorCode }) => {
                const listeners = appEventsListener.get(event) || new Set();
                for (const listener of Array.from(listeners)) {
                    listener({ data, errorCode });
                }
            };
            return () => {
                delete (window as any).OnWebViewEvent;
            };
        }
    }, [isInAppWebview]);
}
export function registerToAppEvent(event: string, handler: APP_EVENT_HANDLER) {
    let handlers = appEventsListener.get(event);
    if (!handlers) {
        handlers = new Set();
        appEventsListener.set(event, handlers);
    }
    handlers.add(handler);

    return () => {
        handlers?.delete(handler);
        if (handlers?.size === 0) {
            appEventsListener.delete(event);
        }
    };
}
