import { AddOutlined, RemoveOutlined } from "@mui/icons-material";
import { Button, Stack, TextField, Typography } from "@mui/material";
import { find, size, uniq, values } from "lodash";
import { useCallback, useEffect, useMemo, useState } from "react";

import InputCostSlider from "../../inputs/InputCostSlider";
import T from "../../locallization/T";
import { DEFAULT_COST, userInfoCosts } from "../../onboarding/questions/OnboardingQuestionTherapistCost";
import { USER_INFO, USER_INFO_MAP, USER_INFO_THERAPY_TYPE } from "../../user/userInfo";
import { SEARCH_INPUT_PROPS } from "../inputs/SearchInputProps";

export function isDifferentCostTypes(newUserInfo?: USER_INFO) {
    return uniq(values(newUserInfo?.therapistCosts || {})).length > 1;
}

export default function SearchInputTherapistCost({
    onData,
    newUserInfo,
    showTextInputs = false,
}: SEARCH_INPUT_PROPS & { showTextInputs?: boolean }) {
    const initialShowAllTypes = useMemo(() => isDifferentCostTypes(newUserInfo), [newUserInfo]);
    const [showAllTypes, setShowAllTypes] = useState(initialShowAllTypes);
    const [costValues, setCostValues] = useState<USER_INFO_MAP<USER_INFO_THERAPY_TYPE, number>>(
        newUserInfo?.therapistCosts || {}
    );

    const firstType = useMemo(
        () => (newUserInfo?.therapistTherapyTypes || [])[0] || "personal",
        [newUserInfo?.therapistTherapyTypes]
    );

    const updateCostValue = useCallback((therapyType: USER_INFO_THERAPY_TYPE, value: number) => {
        setCostValues((costValues) => ({ ...costValues, [therapyType]: value }));
    }, []);
    const finishedChange = useCallback(
        (therapyType: USER_INFO_THERAPY_TYPE) => {
            const costDefaults = find(userInfoCosts, (cost) => cost.therapyType === therapyType);
            const costsClone = { ...costValues };
            if (costDefaults && costsClone.hasOwnProperty(therapyType)) {
                if ((costsClone[therapyType] as number) < costDefaults.min) {
                    costsClone[therapyType] = costDefaults.min;
                    setCostValues(costsClone);
                }
                if ((costsClone[therapyType] as number) > costDefaults.max) {
                    costsClone[therapyType] = costDefaults.max;
                    setCostValues(costsClone);
                }
            }
            if (!showAllTypes) {
                for (const type of newUserInfo?.therapistTherapyTypes || []) {
                    costsClone[type] = costsClone[therapyType];
                }
            }
            onData({
                therapistCosts: costsClone,
            });
        },
        [onData, costValues, newUserInfo?.therapistTherapyTypes, showAllTypes]
    );

    const resetCostValues = useCallback(
        ({ toDefault = false, onlyIfNewValue = false } = {}) => {
            setCostValues((costValues) => {
                const newValues = {};
                // new values to add
                for (const type of newUserInfo?.therapistTherapyTypes || []) {
                    const shouldSetValue = onlyIfNewValue ? !costValues[type] : true;
                    if (shouldSetValue) {
                        newValues[type] = toDefault
                            ? DEFAULT_COST
                            : userInfoCosts.find(({ therapyType }) => type === therapyType)?.base;
                    }
                }
                const resultValues = { ...costValues, ...newValues };
                // values to remove
                for (let type in costValues) {
                    if (!(newUserInfo?.therapistTherapyTypes || []).includes(type as USER_INFO_THERAPY_TYPE)) {
                        delete resultValues[type];
                    }
                }
                if ((newUserInfo?.therapistTherapyTypes || []).length < 2) {
                    setShowAllTypes(false);
                }
                // call a timeout so the
                setTimeout(
                    () =>
                        onData({
                            therapistCosts: resultValues,
                        }),
                    0
                );
                return resultValues;
            });
        },
        [newUserInfo?.therapistTherapyTypes, onData]
    );

    const toggleAllTypes = useCallback(() => {
        setShowAllTypes((showAllTypes) => !showAllTypes);
        resetCostValues({ toDefault: showAllTypes });
    }, [resetCostValues, showAllTypes]);

    // set default values
    useEffect(() => {
        resetCostValues({
            toDefault: true,
            onlyIfNewValue: true,
        });
    }, [resetCostValues, onData]);

    return (
        <Stack gap={2}>
            {userInfoCosts.map(({ therapyType, min, max, base }) =>
                (
                    showAllTypes ? newUserInfo?.therapistTherapyTypes?.includes(therapyType) : therapyType === firstType
                ) ? (
                    <Stack key={therapyType} gap={4}>
                        {showTextInputs ? (
                            <TextField
                                label={
                                    showAllTypes ? (
                                        <T
                                            params={{
                                                type: <T children={`onboarding question type ${therapyType}`} />,
                                            }}>
                                            search input therapist cost
                                        </T>
                                    ) : (
                                        <T>search input cost</T>
                                    )
                                }
                                type="text"
                                variant="outlined"
                                inputProps={{
                                    inputMode: "tel",
                                }}
                                value={costValues[therapyType] ?? base}
                                onChange={(event) => updateCostValue(therapyType, +event.target.value)}
                                onBlur={() => finishedChange(therapyType)}
                            />
                        ) : (
                            <Typography>
                                {showAllTypes ? (
                                    <T params={{ type: <T children={`onboarding question type ${therapyType}`} /> }}>
                                        search input therapist cost
                                    </T>
                                ) : (
                                    <T>search input cost</T>
                                )}
                            </Typography>
                        )}
                        <InputCostSlider
                            finishedChange={() => finishedChange(therapyType)}
                            costValue={costValues[therapyType] ?? base}
                            setCostValue={(value: number) => updateCostValue(therapyType, value)}
                            maxCost={max}
                            minCost={min}
                        />
                    </Stack>
                ) : null
            )}
            {size(newUserInfo?.therapistTherapyTypes) > 1 && (
                <Button
                    variant="text"
                    onClick={toggleAllTypes}
                    sx={{ alignSelf: "flex-start" }}
                    startIcon={showAllTypes ? <RemoveOutlined /> : <AddOutlined />}>
                    {showAllTypes ? (
                        <T>search input therapist cost button one type</T>
                    ) : (
                        <T>search input therapist cost button all types</T>
                    )}
                </Button>
            )}
        </Stack>
    );
}
