import { Slider } from "@mui/material";
import { Box } from "@mui/system";
import { range } from "lodash";
import { useCallback, useMemo } from "react";

import T from "../locallization/T";
import { RTL_LOCALES } from "../locallization/useLocale";
import { useAppContext } from "../root/AppContext";

export default function InputCostSlider({ costValue, setCostValue, maxCost, minCost, finishedChange }) {
    const { locale } = useAppContext();
    const isRtl = RTL_LOCALES.has(locale || "");

    const numberCostValue = useMemo(() => (isNaN(costValue) ? minCost : Math.floor(costValue)), [costValue, minCost]);
    const marks = useMemo(() => {
        const marks: { label?: string; value: number }[] = range(0, 1.1, 0.1).map((index) => ({
            value: minCost + Math.round((maxCost - minCost) * index),
        }));
        marks[0].label = "" + (isRtl ? maxCost : minCost);
        marks[marks.length - 1].label = "" + (isRtl ? minCost : maxCost);
        return marks;
    }, [minCost, maxCost, isRtl]);

    const handleChange = useCallback(
        (event: Event, newValue: number | number[]) => {
            setCostValue(isRtl ? minCost + maxCost - (newValue as number) : (newValue as number));
        },
        [setCostValue, minCost, maxCost, isRtl]
    );

    return (
        <Box sx={{ mr: 3, ml: 2 }}>
            <Slider
                onChangeCommitted={finishedChange}
                value={isRtl ? minCost + maxCost - numberCostValue : numberCostValue}
                onChange={handleChange}
                marks={marks}
                step={10}
                min={minCost}
                max={maxCost}
                valueLabelDisplay="on"
                valueLabelFormat={(x) => (
                    <T params={{ cost: isRtl ? minCost + maxCost - x : x }}>onboarding question cost range ils</T>
                )}
                track={isRtl ? "inverted" : "normal"}
            />
        </Box>
    );
}
