import { SchoolOutlined } from '@mui/icons-material';
import { useMemo } from 'react';

import SeparatedList from '../../common/SeparatedList';
import T from '../../locallization/T';
import { THERAPIST_OUTPUT_PROPS } from '../basic/OutputProps';
import OutputRow from '../basic/OutputRow';

export const DEGREE_ICON = SchoolOutlined;

export default function TherapistOutputDegrees({ newUserInfo, ...props }: THERAPIST_OUTPUT_PROPS) {
    const degrees = useMemo(
        () =>
            [newUserInfo?.therapistDegree, newUserInfo?.therapistDegree2, newUserInfo?.therapistDegree3]
                .map((degree, index) => ({ degree: (degree || "").trim(), index }))
                .filter(({ degree }) => !!degree)
                .map(({ degree, index }) => (
                    <T key={index} params={{ degree }} children={`degree output ${index + 1}`} />
                )),
        [newUserInfo?.therapistDegree, newUserInfo?.therapistDegree2, newUserInfo?.therapistDegree3]
    );

    if (!degrees.length) {
        return null;
    }
    return (
        <OutputRow
            {...props}
            title={<T>therapist output degree</T>}
            value={<SeparatedList>{degrees}</SeparatedList>}
            Icon={DEGREE_ICON}
        />
    );
}
