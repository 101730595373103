import { Stack } from '@mui/material';

import SearchInputNotificationsChat from '../../search/inputs/SearchInputNotificationsChat';
import SearchInputNotificationsMatch from '../../search/inputs/SearchInputNotificationsMatch';
import { SETTINGS_PROPS } from './SettingsProps';

export default function SettingNotifications({ onData, newUserInfo }: SETTINGS_PROPS) {
    return (
        <Stack gap={2}>
            {newUserInfo.isTherapist && <SearchInputNotificationsMatch newUserInfo={newUserInfo} onData={onData} />}
            <SearchInputNotificationsChat newUserInfo={newUserInfo} onData={onData} />
        </Stack>
    );
}
