import { Box, styled, SxProps, Theme, ToggleButton } from "@mui/material";
import { common } from "@mui/material/colors";
import { useCallback } from "react";

import {
    BUTTON_BOX_SHADOW,
    COLOR_APP_PRIMARY,
    COLOR_APP_PRIMARY_LIGHT,
    COLOR_APP_PRIMARY_LIGHT_2,
} from "../../theme/theme";

const ToggleButtonOverride = styled(ToggleButton)({
    color: COLOR_APP_PRIMARY,
    border: "none",
    background: common.white,
    boxShadow: BUTTON_BOX_SHADOW,
    borderRadius: 8,
    transition: "all .25s cubic-bezier(0.4, 0, 0.2, 1)",
    "&.Mui-selected": {
        background: COLOR_APP_PRIMARY_LIGHT,
        "&:hover": {
            background: COLOR_APP_PRIMARY_LIGHT_2,
        },
    },
});

export default function AppToggleButton({
    value,
    children,
    Icon = null,
    selectedOptions,
    setSelectedOptions,
    limitToOne = false,
    preventEmpty = false,
    sx = [],
}: {
    value: any;
    children: any;
    Icon?: any;
    selectedOptions: any;
    setSelectedOptions: any;
    limitToOne?: boolean;
    preventEmpty?: boolean;
    sx?: SxProps<Theme>;
}) {
    const toggleButton = useCallback(() => {
        setSelectedOptions((selectedOptions: Set<string>) => {
            const newSet = new Set(selectedOptions);
            if (newSet.has(value)) {
                if (newSet.size > 1 || !preventEmpty) {
                    newSet.delete(value);
                }
            } else {
                if (limitToOne) {
                    newSet.clear();
                }
                newSet.add(value);
            }
            return newSet;
        });
    }, [value, setSelectedOptions, limitToOne, preventEmpty]);

    return (
        <ToggleButtonOverride
            color="primary"
            value={value}
            selected={selectedOptions.has(value)}
            onChange={toggleButton}
            sx={{ width: "100%", ...sx }}>
            {Icon}
            <Box sx={{ mr: "auto", ml: "auto" }}>{children}</Box>
            {Icon ? <Box width={24} /> : null}
        </ToggleButtonOverride>
    );
}
