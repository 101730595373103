import T from "../../locallization/T";
import { metupalQuestionIcons, metupalQuestionIconsColorful } from "../../onboarding/OnboardingQuestionsMetupal";
import { METUPAL_OUTPUT_PROPS } from "../basic/OutputProps";
import OutputRow from "../basic/OutputRow";
import { useLocationName } from "../basic/useLocationName";
import { useShowSensitiveOutput } from "../basic/useShowSensitiveOutput";

export default function MetupalOutputLocation({ newUserInfo, colorful, ...props }: METUPAL_OUTPUT_PROPS) {
    const beautifiedLocation = useLocationName(newUserInfo?.locationName || "");
    const showSensitive = useShowSensitiveOutput({ targetUserInfo: newUserInfo, isTargetTherapist: false });
    if (!showSensitive) {
        return null;
    }

    return (
        <OutputRow
            {...props}
            title={<T>metupal output location</T>}
            value={beautifiedLocation || <T>output was not mentioned</T>}
            Icon={colorful ? metupalQuestionIconsColorful.location : metupalQuestionIcons.location}
        />
    );
}
