import { User } from "firebase/auth";
import React, { memo, useCallback, useContext, useMemo } from "react";

import { useFlags } from "../flags/useFlags";
import { useSessionObject } from "../flags/useSessionObject";
import { useTexts } from "../locallization/useTexts";
import ReauthenticateModal, { useReauthenticate } from "../user/Reauthenticate";
import { ADMIN_PROPS, useAdmin } from "../user/useAdmin";
import { useLastLogin } from "../user/useLastLogin";
import { useOnline } from "../user/useOnline";
import { USER_INFO } from "../user/userInfo";
import { useUser } from "../user/useUser";
import { useSetAppName } from "../webview/useIsWebviewApp";
import { useRegisterAppAdaptor } from "../webview/webviewAppAdaptor";

type CONTEXT = {
    user?: User | null;
    userInfo?: USER_INFO | null;
    userLoading?: boolean;
    userInfoLoading?: boolean;
    initiateDeleteUser: () => Promise<void>;
    initialDeleteUserInfo: () => Promise<void>;
    reloadUser: () => Promise<void>;
    reauthenthicate: () => Promise<any>;
    adminProps?: ADMIN_PROPS;
    texts?: { [key: string]: string };
    flags?: { [key: string]: boolean };
    locale?: string;
    getSessionValue: (key: string) => string;
    setSessionValue: (key: string, value: string) => void;
};

const AppContext = React.createContext<CONTEXT>({
    initiateDeleteUser: () => Promise.resolve(),
    initialDeleteUserInfo: () => Promise.resolve(),
    reloadUser: () => Promise.resolve(),
    reauthenthicate: () => Promise.resolve(),
    getSessionValue: () => "",
    setSessionValue: () => {},
});

export default memo(function AppContextProvider({ children }: { children: React.ReactNode }) {
    const {
        user,
        userInfo,
        loading: userLoading,
        userInfoLoading,
        initiateDeleteUser: initiateDeleteUserInner,
        initialDeleteUserInfo,
        reloadUser,
    } = useUser();
    const adminProps = useAdmin(user);
    const { reauthenthicate, modalProps } = useReauthenticate(user);
    const { texts, locale } = useTexts();
    const flags = useFlags();
    const { getSessionValue, setSessionValue } = useSessionObject();
    useOnline({ userInfo });
    useLastLogin({ userInfo });
    useSetAppName();
    useRegisterAppAdaptor();

    const initiateDeleteUser = useCallback(
        () => initiateDeleteUserInner(reauthenthicate),
        [initiateDeleteUserInner, reauthenthicate]
    );

    const contextValue = useMemo(() => {
        return {
            user,
            userInfo,
            userLoading,
            userInfoLoading,
            initiateDeleteUser,
            initialDeleteUserInfo,
            reloadUser,
            reauthenthicate,
            adminProps,
            texts,
            flags,
            locale,
            getSessionValue,
            setSessionValue,
        };
    }, [
        user,
        userInfo,
        userLoading,
        userInfoLoading,
        initiateDeleteUser,
        initialDeleteUserInfo,
        reloadUser,
        reauthenthicate,
        adminProps,
        texts,
        flags,
        locale,
        getSessionValue,
        setSessionValue,
    ]);

    return (
        <AppContext.Provider value={contextValue}>
            {children}
            <ReauthenticateModal {...modalProps} />
        </AppContext.Provider>
    );
});

export function useAppContext() {
    return useContext(AppContext);
}
