import T from '../../locallization/T';
import { shouldShowAge } from '../../onboarding/OnboardingQuestionsMetupal';
import SearchInputBasicYear from '../basic/SearchInputBasicYear';
import { SEARCH_INPUT_PROPS } from './SearchInputProps';

export default function SearchInputAge({ onData, newUserInfo }: SEARCH_INPUT_PROPS) {
    if (!shouldShowAge(newUserInfo)) {
        return null;
    }

    return (
        <SearchInputBasicYear
            onData={onData}
            newUserInfo={newUserInfo}
            userInfoKey="year"
            label={<T>search input age</T>}
        />
    );
}
