import { CheckOutlined, RefreshOutlined } from "@mui/icons-material";
import { Button, CircularProgress, Typography } from "@mui/material";
import { signOut } from "firebase/auth";
import { httpsCallable } from "firebase/functions";
import { useSnackbar } from "notistack";
import { useCallback, useEffect, useState } from "react";

import { getFirebaseAuth, getFirebaseFunctions } from "../../firebase/firebase";
import T from "../../locallization/T";
import { ROUTES } from "../../navigation/Router";
import { useAppContext } from "../../root/AppContext";
import { getSpacedButtonIconProps } from "../../theme/buttons";
import SignInFormContainer from "./SignInFormContainer";

const STORAGE_KEY_LAST_EMAIL = "last-email";

export function saveLastEmailInStorage(email: string) {
    try {
        localStorage.setItem(STORAGE_KEY_LAST_EMAIL, email);
    } catch (e) {}
}

export function getLastEmailInStorage() {
    try {
        return localStorage.getItem(STORAGE_KEY_LAST_EMAIL) || "";
    } catch (e) {}
    return "";
}
export function removeLastEmailInStorage() {
    try {
        localStorage.removeItem(STORAGE_KEY_LAST_EMAIL);
    } catch (e) {}
}

export function sendVerifyEmail(email: string) {
    saveLastEmailInStorage(email);
    const verifyEmailCallback = httpsCallable(getFirebaseFunctions(), "onCallVerifyEmail");

    return verifyEmailCallback();
}

export default function EmailVerifiedHolder() {
    const { enqueueSnackbar } = useSnackbar();
    const { user, reloadUser } = useAppContext();
    const [sendingCooldown, setSendingCooldown] = useState(true);
    const [cooldownTime, setCooldownTime] = useState(5000);
    const [reloadingCooldown, setReloadingCooldown] = useState(true);
    const [loading, setLoading] = useState(false);

    const onBack = useCallback(() => {
        signOut(getFirebaseAuth()).catch((error) => {
            console.error(error);
            enqueueSnackbar(<T params={{ code: 154 }}>general error</T>, { variant: "warning" });
        });
    }, [enqueueSnackbar]);

    const tryNow = useCallback(() => {
        setLoading(true);
        reloadUser()
            .then(() => {
                if (!getFirebaseAuth().currentUser?.emailVerified) {
                    setLoading(false);
                    enqueueSnackbar(<T>email verify not confirmed</T>, { variant: "warning" });
                }
                // success, it should move on, from router
            })
            .catch((error) => {
                setLoading(false);
                console.error(error);
                enqueueSnackbar(<T params={{ code: 186 }}>general error</T>, { variant: "warning" });
            });
    }, [enqueueSnackbar, reloadUser]);

    const sendAgain = useCallback(() => {
        setSendingCooldown(true);
        setLoading(true);
        sendVerifyEmail(user?.email || "")
            .then(() => {
                setLoading(false);
                enqueueSnackbar(<T params={{ email: user?.email }}>email verify form subtitle</T>, {
                    variant: "success",
                });
            })
            .catch((error) => {
                setLoading(false);
                console.error(error);
                if (error.message === "Too many attempts") {
                    enqueueSnackbar(<T>email verify error limit</T>, { variant: "warning" });
                } else {
                    enqueueSnackbar(<T params={{ code: 155 }}>general error</T>, { variant: "warning" });
                }
            });
    }, [enqueueSnackbar, user]);

    useEffect(() => {
        if (sendingCooldown) {
            const timerId = setTimeout(() => {
                setSendingCooldown(false);
                setCooldownTime(60000);
            }, cooldownTime);
            return () => clearTimeout(timerId);
        }
    }, [sendingCooldown, cooldownTime]);
    useEffect(() => {
        if (reloadingCooldown) {
            const timerId = setTimeout(() => {
                setReloadingCooldown(false);
            }, 5000);
            return () => clearTimeout(timerId);
        }
    }, [reloadingCooldown, cooldownTime]);

    return (
        <SignInFormContainer
            pageTitleKey="email verify form title"
            pagePath={ROUTES.EMAIL_VERIFIED_HOLDER}
            onBack={onBack}>
            <Typography>
                <T params={{ email: user?.email }}>email verify form subtitle</T>
            </Typography>
            <Button
                size="huge"
                color="primary"
                variant="contained"
                onClick={tryNow}
                {...getSpacedButtonIconProps({ sx: { mt: 5 } })}
                disabled={reloadingCooldown || loading}
                endIcon={loading ? <CircularProgress size={24} /> : <CheckOutlined />}>
                <T>email verify form refresh button</T>
            </Button>
            <Button
                size="huge"
                color="primary"
                variant="outlined"
                onClick={sendAgain}
                {...getSpacedButtonIconProps({ sx: { mt: 1 } })}
                disabled={sendingCooldown || loading}
                endIcon={loading ? <CircularProgress size={24} /> : <RefreshOutlined fontSize="small" />}>
                <T>email verify form try again button</T>
            </Button>
        </SignInFormContainer>
    );
}
