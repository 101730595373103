import { Box, Button, Container, Stack, Typography } from '@mui/material';
import { PureComponent, ReactNode } from 'react';

import ZigmondBunnyPng from '../login/png/zigmondBunny.png';

export default class ErrorBoundary extends PureComponent<{ children: ReactNode }, { hasError }> {
    constructor(props) {
        super(props);
        this.state = { hasError: false };
    }

    static getDerivedStateFromError(error) {
        return { hasError: true };
    }

    componentDidCatch(error, errorInfo) {
        console.error(error, errorInfo);
    }

    render() {
        if (this.state.hasError) {
            return (
                <Container>
                    <Stack alignItems="center" textAlign="center" pt={2} gap={3}>
                        <Box width={148} height={267} component="img" src={ZigmondBunnyPng} alt="Logo" />
                        <Typography variant="h1">יש בעיה, זה מורכב אבל אנחנו מטפלים בזה</Typography>
                        <Button variant="contained" onClick={() => this.setState({ hasError: false })}>
                            נסו שנית
                        </Button>
                    </Stack>
                </Container>
            );
        }
        return this.props.children;
    }
}
