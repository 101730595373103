import { useMemo } from 'react';

import { useTs } from '../../locallization/T';

export function useSortedReasons(reasonsList: number[]) {
    const reasonsKeys = useMemo(() => reasonsList.map((reason) => `reason ${reason}`), [reasonsList]);
    const reasonsTextsParts = useTs({ textKeys: reasonsKeys });
    const reasonsTexts = useMemo(
        () => reasonsTextsParts.map((reasonsTextsPart) => reasonsTextsPart.join("")),
        [reasonsTextsParts]
    );
    const reasonNumberByText = useMemo(() => {
        const obj = new Map<string, number>();
        for (let i = 0; i < reasonsList.length; i++) {
            const reason = reasonsList[i];
            obj.set(reasonsTexts[i], reason);
        }
        return obj;
    }, [reasonsList, reasonsTexts]);
    const sortedReasonsTexts = useMemo(() => [...reasonsTexts].sort((a, b) => a.localeCompare(b)), [reasonsTexts]);
    const sortedReasons = useMemo(
        () => sortedReasonsTexts.map((reasonText) => reasonNumberByText.get(reasonText) as number),
        [sortedReasonsTexts, reasonNumberByText]
    );
    return { sortedReasons };
}
