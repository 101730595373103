import { useSnackbar } from "notistack";
import { useEffect } from "react";

import { deleteStorage, getStorage } from "../common/storage";
import T from "../locallization/T";
import { STORAGE_KEY_REGISTER_AS_THERAPIST, STORAGE_KEY_REGISTER_AS_THERAPIST_TRUE } from "../login/sign/RegisterForm";
import AppSpinner from "../navigation/AppSpinner";
import { deleteOnboardingFromStorage, getOnboardingFromStorage } from "../onboarding/onboardingStorage";
import { useAppContext } from "../root/AppContext";
import { createUserInfo } from "./userUpdates";

export default function AccountCreater() {
    const { user, userInfo } = useAppContext();
    const { enqueueSnackbar } = useSnackbar();

    // this should be updated regardless if it's a new user or not
    useEffect(() => {
        if (user && !userInfo?.created) {
            const isTherapist =
                getStorage(STORAGE_KEY_REGISTER_AS_THERAPIST) === STORAGE_KEY_REGISTER_AS_THERAPIST_TRUE;
            const data = isTherapist ? { isTherapist } : { ...getOnboardingFromStorage(), isTherapist };
            createUserInfo(user, data)
                .then(() => {
                    deleteOnboardingFromStorage();
                    deleteStorage(STORAGE_KEY_REGISTER_AS_THERAPIST);
                })
                .catch((error) => {
                    console.error(error);
                    enqueueSnackbar(<T>login form error</T>, { variant: "warning" });
                });
        }
    }, [enqueueSnackbar, user, userInfo?.created]);

    return <AppSpinner />;
}
