import { Box, Button, Stack } from "@mui/material";

import T from "../../locallization/T";
import { THERAPIST_OUTPUT_PROPS } from "../basic/OutputProps";
import { getLink } from "./TherapistOutputArticlesLinks";
import { useSocials } from "./useSocials";

const SOCIAL_BUTTON_SIZE = 100;

const SocialButtonStyles = {
    width: SOCIAL_BUTTON_SIZE,
    height: SOCIAL_BUTTON_SIZE,
    flexDirection: "column",
    textTransform: "none",
    gap: 1,
    px: 1,
};

export default function TherapistOutputSocials({ newUserInfo }: THERAPIST_OUTPUT_PROPS) {
    const socials = useSocials({ newUserInfo });
    if (!socials.length) {
        return null;
    }
    return (
        <Stack flexDirection="row" gap={2} flexWrap="wrap">
            {socials.map(({ textKey, Image, size, value }, index) => (
                <Button
                    key={index}
                    color="secondary2"
                    variant="outlined"
                    size="large"
                    component="a"
                    href={getLink(value || "")}
                    target="_blank"
                    sx={SocialButtonStyles}>
                    <Box width={size} height={size} component="img" src={Image} alt="" />
                    <T>{textKey}</T>
                </Button>
            ))}
        </Stack>
    );
}
