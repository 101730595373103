import {
    ExpandMoreOutlined,
    KeyboardArrowLeft,
    KeyboardArrowRight,
    OpenInNewOutlined,
    PetsOutlined,
    SearchOutlined,
    SupportAgentOutlined,
    SwapVertOutlined,
} from "@mui/icons-material";
import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Divider,
    IconButton,
    InputAdornment,
    ListItemText,
    Menu,
    MenuItem,
    Stack,
    Tab,
    Tabs,
    TextField,
    Tooltip,
    Typography,
} from "@mui/material";
import {
    endBefore,
    getDocs,
    limit,
    limitToLast,
    orderBy,
    query,
    QueryConstraint,
    startAfter,
    where,
} from "firebase/firestore";
import { isEmpty, size } from "lodash";
import { useSnackbar } from "notistack";
import { MouseEvent, SyntheticEvent, useCallback, useEffect, useRef, useState } from "react";

import { useChatsUids } from "../../chat/useChatsUids";
import { DEFAULT_HOME_URL } from "../../common/useShare";
import T from "../../locallization/T";
import { usePaymentDue } from "../../matches/usePaymentDue";
import MetupalPagePreviewButton from "../../metupalim/MetupalPagePreviewButton";
import AppSpinner from "../../navigation/AppSpinner";
import { ROUTE_ID_THERAPIST_ID, ROUTES } from "../../navigation/Router";
import OutputRow from "../../outputs/basic/OutputRow";
import { useAppContext } from "../../root/AppContext";
import WithThemeIcon from "../../theme/WithThemeIcon";
import TherapistPagePreviewButton from "../../therapist_page/TherapistPagePreview";
import { USER_INFO } from "../../user/userInfo";
import { getUserCollection } from "../../user/userUpdates";
import AdminPaymentHistoryButton from "../payments/AdminPaymentHistoryButton";
import AdminBanPreviewButton from "./AdminBanPreviewButton";
import AdminCalculateMeasureButton from "./AdminCalculateMeasureButton";
import AdminCreateChatButton from "./AdminCreateChatButton";
import AdminRejectsButton from "./AdminRejectsButton";
import AdminSearchHistoryButton from "./AdminSearchHistoryButton";
import AdminStatusPreviewButton from "./AdminStatusPreviewButton";

const pageSize = 10;

type SEARCH_BY_TERM = "name" | "email" | "id";

const ZIGMOND_USER_EMAILS = new Set([
    "tzook10@gmail.com",
    "zigmond.rabbit@gmail.com",
    "mirizigmond@gmail.com",
    "zingboim.net@gmail.com",
]);

export default function UsersTableSecret() {
    const [value, setValue] = useState(0);

    const handleChange = useCallback((event, newValue: number) => {
        setValue(newValue);
    }, []);

    return (
        <>
            <Tabs value={value} onChange={handleChange}>
                <Tab label="מטפלים" />
                <Tab label="מטופלים" />
            </Tabs>

            {value === 0 && <UsersPartTable isTherapist={true} />}
            {value === 1 && <UsersPartTable isTherapist={false} />}
        </>
    );
}

function UsersPartTable({ isTherapist }) {
    const [userInfos, setUserInfos] = useState<USER_INFO[]>([]);
    const [loading, setLoading] = useState(true);
    const [paginationOffset, setPaginationOffset] = useState(0);
    const firstPointerRef = useRef({});
    const lastPointerRef = useRef({});
    const [directionForward, setDirectionForward] = useState(true);
    const [newestFirst, setNewestFirst] = useState(true);
    const [sortByLastLogin, setSortByLastLogin] = useState(true);
    const [searchTermType, setSearchTermType] = useState<SEARCH_BY_TERM>("name");
    const [searchValue, setSearchValue] = useState("");
    const [appliedSearchValue, setAppliedSearchValue] = useState("");
    const { enqueueSnackbar } = useSnackbar();
    const [sortAnchorEl, setSortAnchorEl] = useState<null | HTMLElement>(null);

    const handleShowSort = useCallback((event: MouseEvent<HTMLElement>) => {
        setSortAnchorEl(event.currentTarget);
    }, []);
    const handleHideSort = useCallback(() => {
        setSortAnchorEl(null);
    }, []);

    const [expanded, setExpanded] = useState<string | false>(false);

    const handleChange = (panel: string) => (event: SyntheticEvent, isExpanded: boolean) => {
        setExpanded(isExpanded ? panel : false);
    };

    const setNewestDirection = useCallback((newestFirst: boolean, sortByLastLogin: boolean) => {
        setDirectionForward(true);
        setSortAnchorEl(null);
        setPaginationOffset(0);
        lastPointerRef.current = {};
        firstPointerRef.current = {};
        setNewestFirst(newestFirst);
        setSortByLastLogin(sortByLastLogin);
    }, []);
    const setSearchByTerm = useCallback((term: SEARCH_BY_TERM) => {
        setDirectionForward(true);
        setSortAnchorEl(null);
        setPaginationOffset(0);
        lastPointerRef.current = {};
        firstPointerRef.current = {};
        setSearchTermType(term);
    }, []);

    const updateSearchValue = useCallback((event) => {
        setSearchValue(event.target.value);
    }, []);
    const updateAppliedSearchValue = useCallback(() => {
        setAppliedSearchValue(searchValue);
        setDirectionForward(true);
        setPaginationOffset(0);
        lastPointerRef.current = {};
        firstPointerRef.current = {};
    }, [searchValue]);

    useEffect(() => {
        setLoading(true);
        const usersRef = getUserCollection();
        const filters: QueryConstraint[] = [];
        filters.push(where(isTherapist ? "accountHasTherapist" : "accountHasMetupal", "==", true));
        if (appliedSearchValue) {
            if (searchTermType === "name") {
                filters.push(where("firstName", ">=", appliedSearchValue));
                filters.push(where("firstName", "<=", appliedSearchValue + "\uf8ff"));
                filters.push(orderBy("firstName", "desc"));
            } else if (searchTermType === "email") {
                filters.push(where("email", "==", appliedSearchValue));
                filters.push(orderBy("email", "desc"));
            } else {
                filters.push(where("uid", "==", appliedSearchValue));
                filters.push(orderBy("uid", "desc"));
            }
        }
        if (searchTermType === "name") {
            filters.push(orderBy(sortByLastLogin ? "lastLogin" : "created", newestFirst ? "desc" : "asc"));
        }
        if (directionForward) {
            if (!isEmpty(lastPointerRef.current)) {
                filters.push(startAfter(lastPointerRef.current));
            }
            filters.push(limit(pageSize));
        } else if (!directionForward) {
            filters.push(endBefore(firstPointerRef.current));
            filters.push(limitToLast(pageSize));
        }
        const q = query(usersRef, ...filters);

        getDocs<USER_INFO>(q)
            .then((querySnapshot) => {
                firstPointerRef.current = querySnapshot.docs[0];
                lastPointerRef.current = querySnapshot.docs[querySnapshot.docs.length - 1];
                setUserInfos(querySnapshot.docs.map((doc) => doc.data()));
                setLoading(false);
            })
            .catch((error) => {
                setUserInfos([]);
                setLoading(false);
                console.error(error);
                enqueueSnackbar(<T params={{ code: 181 }}>general error</T>, { variant: "warning" });
            });
    }, [
        enqueueSnackbar,
        paginationOffset,
        directionForward,
        appliedSearchValue,
        isTherapist,
        newestFirst,
        sortByLastLogin,
        searchTermType,
    ]);

    const clickPrevPage = useCallback(() => {
        setPaginationOffset((offset) => offset - pageSize);
        setDirectionForward(false);
    }, []);
    const clickNextPage = useCallback(() => {
        setPaginationOffset((offset) => offset + pageSize);
        setDirectionForward(true);
    }, []);

    return (
        <Stack gap={2}>
            {loading ? (
                <AppSpinner />
            ) : (
                <Stack>
                    {userInfos.map((currentUserInfo, index) => (
                        <UserTableRow
                            key={currentUserInfo.uid}
                            currentUserInfo={currentUserInfo}
                            isTherapist={isTherapist}
                            index={paginationOffset + index + 1}
                            expanded={expanded}
                            handleChange={handleChange}
                        />
                    ))}
                </Stack>
            )}
            <Stack mt={2} gap={1} flexDirection="row">
                <IconButton onClick={clickPrevPage} disabled={paginationOffset === 0} color="inherit" title={"אחורה"}>
                    <KeyboardArrowRight />
                </IconButton>
                <IconButton
                    onClick={clickNextPage}
                    disabled={userInfos.length < pageSize}
                    color="inherit"
                    title={"קדימה"}>
                    <KeyboardArrowLeft />
                </IconButton>
                <IconButton onClick={handleShowSort} color="inherit" title={"מיון"}>
                    <SwapVertOutlined />
                </IconButton>
                <Menu anchorEl={sortAnchorEl} open={Boolean(sortAnchorEl)} onClose={handleHideSort}>
                    <MenuItem onClick={() => setNewestDirection(true, true)}>
                        <ListItemText>מחוברים לאחרונה</ListItemText>
                    </MenuItem>
                    <MenuItem onClick={() => setNewestDirection(false, true)}>
                        <ListItemText>מחוברים מהסוף</ListItemText>
                    </MenuItem>
                    <MenuItem onClick={() => setNewestDirection(true, false)}>
                        <ListItemText>רשומים לאחרונה</ListItemText>
                    </MenuItem>
                    <MenuItem onClick={() => setNewestDirection(false, false)}>
                        <ListItemText>רשומים מהסוף</ListItemText>
                    </MenuItem>
                    <Divider />
                    <MenuItem onClick={() => setSearchByTerm("name")}>
                        <ListItemText>חיפוש על פי שם פרטי</ListItemText>
                    </MenuItem>
                    <MenuItem onClick={() => setSearchByTerm("email")}>
                        <ListItemText>חיפוש על פי אימייל</ListItemText>
                    </MenuItem>
                    <MenuItem onClick={() => setSearchByTerm("id")}>
                        <ListItemText>חיפוש על פי מזהה</ListItemText>
                    </MenuItem>
                </Menu>
                <TextField
                    label={
                        "חיפוש על פי " +
                        (searchTermType === "name" ? "שם פרטי" : searchTermType === "email" ? "אימייל" : "מזהה")
                    }
                    variant="outlined"
                    value={searchValue}
                    onChange={updateSearchValue}
                    InputProps={{
                        endAdornment: (
                            <InputAdornment position="end">
                                <IconButton onClick={updateAppliedSearchValue} edge="end">
                                    <SearchOutlined />
                                </IconButton>
                            </InputAdornment>
                        ),
                    }}
                />
            </Stack>
        </Stack>
    );
}

export function UserTableRow({
    currentUserInfo,
    isTherapist,
    index,
    expanded,
    handleChange,
}: {
    currentUserInfo: USER_INFO;
    isTherapist: boolean;
    index?: number;
    expanded: string | false;
    handleChange: (panel: string) => (event: SyntheticEvent, isExpanded: boolean) => void;
}) {
    const { userInfo, adminProps } = useAppContext();
    const chatIds = useChatsUids({ newUserInfo: currentUserInfo, isTherapist });
    const paymentDue = usePaymentDue({ targetUserInfo: currentUserInfo });

    return (   
        <Accordion expanded={expanded === currentUserInfo.uid} onChange={handleChange(currentUserInfo.uid || "")}>
            <AccordionSummary expandIcon={<ExpandMoreOutlined />}>
                {currentUserInfo.uid === userInfo?.uid ? (
                    <Tooltip title="זה אתה!">
                        <SupportAgentOutlined sx={{ height: "1em", mr: 1 }} fontSize="small" />
                    </Tooltip>
                ) : ZIGMOND_USER_EMAILS.has(currentUserInfo?.email || "") ? (
                    <Tooltip title="מנהל בזיגמונד!">
                        <PetsOutlined sx={{ height: "1em", mr: 1 }} fontSize="small" />
                    </Tooltip>
                ) : null}
                <Typography sx={{ wordBreak: "break-all" }}>
                    {typeof index === "number" ? `(${index}) ` : ""}
                    {currentUserInfo.firstName || currentUserInfo.lastName
                        ? `${currentUserInfo.firstName || "-"} ${currentUserInfo.lastName || "-"}`
                        : currentUserInfo.email}
                </Typography>
                {currentUserInfo.accountHasTherapist && (
                    <TherapistPagePreviewButton newUserInfo={currentUserInfo} asIcon sx={{ height: "1em" }} />
                )}
                {currentUserInfo.accountHasMetupal && (
                    <MetupalPagePreviewButton newUserInfo={currentUserInfo} asIcon sx={{ height: "1em" }} />
                )}
            </AccordionSummary>
            <AccordionDetails>
                <Stack gap={1}>
                    {adminProps?.adminUsersEdit && <OutputRow title={"uid"} value={currentUserInfo.uid || "-"} />}
                    <OutputRow title={"שם פרטי"} value={currentUserInfo.firstName || "-"} />
                    <OutputRow title={"שם משפחה"} value={currentUserInfo.lastName || "-"} />
                    <OutputRow title={"אימייל"} value={currentUserInfo.email} />
                    <OutputRow
                        title={"תאריך הרשמה"}
                        value={(currentUserInfo.created as any).toDate().toLocaleString("he")}
                    />
                    <OutputRow
                        title={"תאריך התחברות אחרון"}
                        value={
                            currentUserInfo.lastLogin
                                ? (currentUserInfo.lastLogin as any).toDate().toLocaleString("he")
                                : "לפני 15/4/2023"
                        }
                    />
                    {currentUserInfo.accountHasTherapist && (
                        <OutputRow
                            title={"דף מטפל"}
                            value={
                                <>
                                    <TherapistPagePreviewButton newUserInfo={currentUserInfo} asIcon />
                                    <IconButton
                                        href={`${DEFAULT_HOME_URL}${ROUTES.THERAPIST_PAGE_PUBLIC.replace(
                                            ROUTE_ID_THERAPIST_ID,
                                            currentUserInfo.uid || ""
                                        )}`}
                                        LinkComponent="a"
                                        target="_blank"
                                        color="primary">
                                        <WithThemeIcon Icon={OpenInNewOutlined} reverse />
                                    </IconButton>
                                </>
                            }
                        />
                    )}
                    {currentUserInfo.accountHasMetupal && (
                        <OutputRow
                            title={"דף מטופל"}
                            value={<MetupalPagePreviewButton newUserInfo={currentUserInfo} asIcon />}
                        />
                    )}
                    <OutputRow
                        title={"חיפושים"}
                        value={
                            <Stack alignItems="flex-start">
                                {isTherapist && (
                                    <Typography>
                                        {currentUserInfo?.therapistEnableSearch
                                            ? "זמין לקבל מטופלים"
                                            : "לא זמין לקבל מטופלים"}
                                    </Typography>
                                )}
                                {!isTherapist && (
                                    <Typography>
                                        {currentUserInfo?.messagePref === "zigmond"
                                            ? "סימן שמטפלים יפנו אליו"
                                            : "יפנה למטפלים לבד"}
                                    </Typography>
                                )}
                                {!isTherapist && <AdminSearchHistoryButton newUserInfo={currentUserInfo} />}
                                <AdminRejectsButton newUserInfo={currentUserInfo} isTherapist={isTherapist} />
                            </Stack>
                        }
                    />
                    {isTherapist ? (
                        <OutputRow title={"פניות פתוחות"} value={size(currentUserInfo?.matchedMetupalim)} />
                    ) : (
                        <OutputRow title={"התאמות כרגע"} value={size(currentUserInfo?.readyMatches)} />
                    )}
                    <OutputRow
                        title={"תשלומים"}
                        value={
                            <Stack>
                                <Typography>תשלומים בחוב כרגע: {size(paymentDue)}</Typography>
                                <Typography>
                                    תשלומים בחוב עתידי: {size(currentUserInfo?.paymentDue) - size(paymentDue)}
                                </Typography>
                                <AdminPaymentHistoryButton newUserInfo={currentUserInfo} />
                            </Stack>
                        }
                    />
                    <OutputRow
                        title={isTherapist ? "פניות שלא נקראו" : "התאמות שלא נקראו"}
                        value={size(currentUserInfo?.matchesCounts)}
                    />
                    <OutputRow
                        title={"צ'אטים"}
                        value={
                            // minus one because of the consult
                            chatIds.length - 1
                        }
                    />
                    <OutputRow title={"הודעות שלא נקראו"} value={size(currentUserInfo?.messagesCounts)} />
                    {adminProps?.adminChats && currentUserInfo.uid !== userInfo?.uid && (
                        <OutputRow
                            title={"~התאמות~"}
                            value={
                                <Stack alignItems="flex-start">
                                    <AdminCreateChatButton newUserInfo={currentUserInfo} isTherapist={isTherapist} />
                                    <AdminCalculateMeasureButton
                                        newUserInfo={currentUserInfo}
                                        isTherapist={isTherapist}
                                    />
                                </Stack>
                            }
                        />
                    )}
                    {adminProps?.adminUsersEdit && (
                        <OutputRow title={"~חסימה~"} value={<AdminBanPreviewButton newUserInfo={currentUserInfo} />} />
                    )}
                    {adminProps?.adminAdminsEdit && (
                        <OutputRow
                            title={"~אדמין?~"}
                            value={<AdminStatusPreviewButton newUserInfo={currentUserInfo} />}
                        />
                    )}                  
                </Stack>
            </AccordionDetails>
        </Accordion>
    );
}
