import { CHAT } from "../chat/chatUpdates";
import { useStaging } from "../firebase/firebase";

export const CONSULT_UID = useStaging ? "2e3q6EQNklhpJrmoLR6jdSSncZY2" : "QUitJbQqXOV1YvoVMYuyW0kacO63";

export function getConsultChat(uid: string, isTherapist: boolean): CHAT {
    return {
        metupalUids: [isTherapist ? CONSULT_UID : uid],
        therapistUid: isTherapist ? uid : CONSULT_UID,
    };
}
