import { useAppContext } from "../../root/AppContext";
import { BASIC_USER_INFO } from "../../user/userInfo";

export function useShowSensitiveOutput({
    targetUserInfo,
    isTargetTherapist,
}: {
    targetUserInfo?: BASIC_USER_INFO | null;
    isTargetTherapist?: boolean;
}) {
    const { userInfo } = useAppContext();
    if (isTargetTherapist || targetUserInfo?.consult || userInfo?.consult || targetUserInfo?.uid === userInfo?.uid) {
        return true;
    }
    const hasChatWithMetupal = !!(userInfo?.chatsWithMetupalim || {})[targetUserInfo?.uid || ""];
    return hasChatWithMetupal;
}
