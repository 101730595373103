import { Stack, Typography } from "@mui/material";

import PageTitle from "../common/PageTitle";
import T from "../locallization/T";
import { usePageText } from "../locallization/usePageTexts";
import AppSpinner from "../navigation/AppSpinner";
import PageContainer from "../navigation/PageContainer";
import { ROUTES } from "../navigation/Router";

export default function PrivacyPolicyPage() {
    const { text } = usePageText("privacy_policy");

    return (
        <PageContainer pageTitleKey="contact action privacy" hideBotNav pagePath={ROUTES.PRIVACY_POLICY} backgrounded>
            <Stack gap={2}>
                <PageTitle hasTopNav gap={2}>
                    <T>contact action privacy</T>
                </PageTitle>
                {text ? <Typography>{text}</Typography> : <AppSpinner />}
            </Stack>
        </PageContainer>
    );
}
