import { ArrowBack } from "@mui/icons-material";
import { Button, CircularProgress, TextField, Typography } from "@mui/material";
import { httpsCallable } from "firebase/functions";
import { useSnackbar } from "notistack";
import { useCallback, useState } from "react";
import { useLocation } from "react-router-dom";

import { getFirebaseFunctions } from "../../firebase/firebase";
import T from "../../locallization/T";
import { FORGOT_PASSWORD_EMAIL_STATE, ROUTES } from "../../navigation/Router";
import { getSpacedButtonIconProps } from "../../theme/buttons";
import WithThemeIcon from "../../theme/WithThemeIcon";
import SignInFormContainer from "./SignInFormContainer";

export default function ForgotPasswordForm() {
    const location = useLocation();
    const [loading, setLoading] = useState(false);
    const [finished, setFinished] = useState(false);
    const stateEmail = ((location.state as {}) || {})[FORGOT_PASSWORD_EMAIL_STATE];
    const [email, setEmail] = useState(stateEmail || "");
    const { enqueueSnackbar } = useSnackbar();

    const updateEmail = useCallback((event) => setEmail(event.target.value), []);
    const sendResetPassword = useCallback(
        (event) => {
            setLoading(true);

            const resetPasswordCallback = httpsCallable(getFirebaseFunctions(), "onCallResetPassword");

            resetPasswordCallback({ email })
                .then(() => {
                    enqueueSnackbar(<T>login form forgot subtitle</T>, { variant: "success" });
                    setLoading(false);
                    setFinished(true);
                })
                .catch((error) => {
                    setLoading(false);
                    console.error(error);
                    enqueueSnackbar(<T params={{ code: 156 }}>general error</T>, { variant: "warning" });
                });
        },
        [enqueueSnackbar, email]
    );

    return (
        <SignInFormContainer pageTitleKey="login form forgot title" pagePath={ROUTES.FORGOT_PASSWORD}>
            <TextField
                label={<T>login form input email</T>}
                variant="outlined"
                type="email"
                value={email}
                disabled={finished || loading}
                onChange={updateEmail}
            />
            <Button
                size="huge"
                color="primary"
                variant="contained"
                {...getSpacedButtonIconProps()}
                disabled={finished || loading || !email}
                endIcon={loading ? <CircularProgress size={24} /> : <WithThemeIcon Icon={ArrowBack} />}
                onClick={sendResetPassword}>
                <T>login details button continue</T>
            </Button>
            {finished && (
                <Typography variant="subtitle1">
                    <T>login form forgot subtitle</T>
                </Typography>
            )}
        </SignInFormContainer>
    );
}
