import { FlareOutlined, NightlightOutlined, WbSunnyOutlined, WbTwilightOutlined } from "@mui/icons-material";
import { SvgIcon } from "@mui/material";
import { useCallback } from "react";

import T from "../../locallization/T";
import { USER_INFO_TIME } from "../../user/userInfo";
import SearchInputBasicSelect from "../basic/SearchInputBasicSelect";
import { SEARCH_INPUT_PROPS } from "./SearchInputProps";

export const userInfoTimes: { value: USER_INFO_TIME; Icon: typeof SvgIcon }[] = [
    { value: "morning", Icon: WbSunnyOutlined },
    { value: "noon", Icon: FlareOutlined },
    { value: "afternoon", Icon: WbTwilightOutlined },
    { value: "evening", Icon: NightlightOutlined },
];

export default function SearchInputTime({ onData, newUserInfo }: SEARCH_INPUT_PROPS) {
    const getValueLabel = useCallback((value: string) => <T children={`search input time ${value}`} />, []);
    const getSelectedLabel = useCallback((value: string) => <T children={`search input time short ${value}`} />, []);
    return (
        <SearchInputBasicSelect
            onData={onData}
            newUserInfo={newUserInfo}
            userInfoKey="times"
            label={<T>search input time</T>}
            allValues={userInfoTimes}
            getValueLabel={getValueLabel}
            getSelectedLabel={getSelectedLabel}
            multiple
            convertToMap
        />
    );
}
