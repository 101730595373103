import { useCallback } from "react";

import T from "../../locallization/T";
import { userInfoAges } from "../../onboarding/questions/OnboardingQuestionTherapistAges";
import SearchInputBasicSelect from "../basic/SearchInputBasicSelect";
import { SEARCH_INPUT_PROPS } from "../inputs/SearchInputProps";

export default function SearchInputAges({ onData, newUserInfo }: SEARCH_INPUT_PROPS) {
    const getValueLabel = useCallback(
        (value: string) => <T children={`onboarding question therapist ages ${value}`} />,
        []
    );

    return (
        <SearchInputBasicSelect
            onData={onData}
            newUserInfo={newUserInfo}
            userInfoKey="therapistAges"
            label={<T>therapist output ages</T>}
            allValues={userInfoAges}
            getValueLabel={getValueLabel}
            multiple
            convertToMap
            preventEmpty
        />
    );
}
