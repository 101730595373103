import { CollectionReference, getDocs, query, QuerySnapshot, where } from "firebase/firestore";
import { chunk } from "lodash";
import { useSnackbar } from "notistack";
import { useEffect, useState } from "react";

import T from "../locallization/T";
import { BASIC_USER_INFO, METUPAL_USER_INFO, THERAPIST_USER_INFO, USER_INFO } from "../user/userInfo";
import { getMetupalCollection, getTherapistCollection, getUserCollection } from "../user/userUpdates";

export type LOAD_OPTIONS = {
    isPublic?: boolean;
    skip?: boolean;
    filterNulls?: boolean;
    admin?: boolean;
};

const cachedUserInfos = {
    therapists: new Map<string, THERAPIST_USER_INFO>(),
    metupalim: new Map<string, METUPAL_USER_INFO>(),
    users: new Map<string, USER_INFO>(),
};

export function useLoadUserInfos(
    uids: string[],
    isTherapists: true,
    options?: LOAD_OPTIONS
): { loading: boolean; userInfos: THERAPIST_USER_INFO[] };
export function useLoadUserInfos(
    uids: string[],
    isTherapists: false,
    options?: LOAD_OPTIONS
): { loading: boolean; userInfos: METUPAL_USER_INFO[] };
export function useLoadUserInfos(
    uids: string[],
    isTherapists: boolean,
    options?: LOAD_OPTIONS
): { loading: boolean; userInfos: (THERAPIST_USER_INFO | METUPAL_USER_INFO)[] };
export function useLoadUserInfos(uids: string[], isTherapists: boolean, options?: LOAD_OPTIONS) {
    const [loading, setLoading] = useState(true);
    const [userInfos, setUserInfos] = useState<(THERAPIST_USER_INFO | METUPAL_USER_INFO)[]>([]);
    const { enqueueSnackbar } = useSnackbar();

    useEffect(() => {
        setLoading(true);

        const promise = options?.admin
            ? loadUsers(uids, options)
            : isTherapists
            ? loadTherapists(uids, options)
            : loadMetupalim(uids, options);
        promise
            .then((loadedUserInfos) => {
                setLoading(false);
                setUserInfos(loadedUserInfos);
            })
            .catch((error) => {
                console.error(error);
                setLoading(false);
                enqueueSnackbar(<T params={{ code: 162 }}>general error</T>, { variant: "warning" });
            });
    }, [isTherapists, uids, enqueueSnackbar, options]);

    return { loading, userInfos };
}

function loadTherapists(userIds: string[], options?: LOAD_OPTIONS) {
    return loadUserInfos<THERAPIST_USER_INFO>(userIds, "therapists", getTherapistCollection(), options);
}
function loadMetupalim(userIds: string[], options?: LOAD_OPTIONS) {
    return loadUserInfos<METUPAL_USER_INFO>(userIds, "metupalim", getMetupalCollection(), options);
}
function loadUsers(userIds: string[], options?: LOAD_OPTIONS) {
    return loadUserInfos<USER_INFO>(userIds, "users", getUserCollection(), options);
}

async function loadUserInfos<T extends BASIC_USER_INFO>(
    userIds: string[],
    cacheKey: string,
    collectionRef: CollectionReference<T>,
    options: LOAD_OPTIONS = {}
) {
    const cacheMap: Map<string, T> = cachedUserInfos[cacheKey];

    const userIdsToLoad: string[] = [];
    for (const uid of userIds) {
        if (!cacheMap.has(uid)) {
            userIdsToLoad.push(uid);
        }
    }

    if (userIdsToLoad.length > 0 && !options.skip) {
        const userIdChunks = chunk(userIds, 10);

        const promises: Promise<QuerySnapshot<T>>[] = [];
        for (const userIdsChunk of userIdChunks) {
            const filters = [where("uid", "in", userIdsChunk), where("ban", "==", false)];
            if (options.isPublic) {
                filters.push(where("pageVisibility", "==", "public"));
            }
            const q = query(collectionRef, ...filters);

            promises.push(getDocs(q));
        }
        // errors are handled by the method above
        const results = await Promise.all(promises);
        results.forEach((querySnapshot) => {
            querySnapshot.forEach((doc) => {
                cacheMap.set(doc.data().uid || "", doc.data());
            });
        });
    }
    const result = userIds.map((uid) => cacheMap.get(uid));
    if (options.filterNulls) {
        return result.filter((x) => !!x);
    }
    return result;
}
