import { FemaleOutlined, MaleOutlined, TransgenderOutlined } from "@mui/icons-material";
import { Stack, SvgIcon, Typography } from "@mui/material";
import { useCallback, useEffect, useMemo, useState } from "react";

import T from "../../locallization/T";
import SearchInputTalkGender from "../../search/inputs/SearchInputTalkGender";
import SearchInputFirstName from "../../search/therapistInputs/SearchInputFirstName";
import { USER_INFO, USER_INFO_GENDER } from "../../user/userInfo";
import AppToggleButton from "../buttonGroup/AppToggleButton";
import { ONBOARDING_QUESTION_PROPS } from "./OnboardingQuestionProps";

export const userInfoGenders: { value: USER_INFO_GENDER; textKey: string; Icon: typeof SvgIcon }[] = [
    { value: "M", textKey: "gender M", Icon: MaleOutlined },
    { value: "F", textKey: "gender F", Icon: FemaleOutlined },
    { value: "O", textKey: "gender O", Icon: TransgenderOutlined },
];

export const userInfoTalkGenders: { value: USER_INFO_GENDER; textKey: string; Icon: typeof SvgIcon }[] = [
    { value: "M", textKey: "onboarding question gender male", Icon: MaleOutlined },
    { value: "F", textKey: "onboarding question gender female", Icon: FemaleOutlined },
];

export function alreadyHasGender(userInfo: USER_INFO) {
    return !!userInfo.gender && !!userInfo.firstName;
}

export default function OnboardingQuestionGender({ onDataProgress, newUserInfo }: ONBOARDING_QUESTION_PROPS) {
    const [firstName, setFirstName] = useState(newUserInfo?.firstName || "");
    const [talkGender, setTalkGender] = useState(newUserInfo?.talkGender);

    const [genderOptions, setGenderOptions] = useState(
        new Set<USER_INFO_GENDER>(newUserInfo?.gender ? [newUserInfo?.gender] : [])
    );

    const gender = useMemo(() => {
        const genderArrayValues = Array.from(genderOptions);
        return genderArrayValues.length > 0 ? genderArrayValues[0] : undefined;
    }, [genderOptions]);

    const updatedUserInfo = useMemo(
        () => ({ ...newUserInfo, gender, talkGender, firstName }),
        [newUserInfo, gender, talkGender, firstName]
    );

    useEffect(() => {
        if (gender && firstName) {
            onDataProgress({
                gender,
                firstName,
                talkGender,
            });
        } else {
            onDataProgress({});
        }
    }, [onDataProgress, gender, firstName, talkGender]);

    const onData = useCallback((updates: USER_INFO) => {
        if (updates.firstName !== undefined) {
            setFirstName(updates.firstName);
        }
        if (updates.talkGender !== undefined) {
            setTalkGender(updates.talkGender);
        }
    }, []);

    return (
        <Stack gap={4}>
            <Stack gap={2}>
                {userInfoGenders.map(({ value, textKey, Icon }) => (
                    <AppToggleButton
                        key={value}
                        value={value}
                        selectedOptions={genderOptions}
                        setSelectedOptions={setGenderOptions}
                        limitToOne
                        Icon={<Icon />}>
                        <T>{textKey}</T>
                    </AppToggleButton>
                ))}
            </Stack>
            <SearchInputTalkGender onData={onData} newUserInfo={updatedUserInfo} expanded />
            {(gender === "O" ? talkGender : gender) && (
                <Stack gap={2}>
                    <Typography variant="subtitle1">
                        <T>onboarding question name</T>
                    </Typography>
                    <SearchInputFirstName newUserInfo={updatedUserInfo} onData={onData} />
                </Stack>
            )}
        </Stack>
    );
}
