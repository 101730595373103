import { Stack } from "@mui/material";
import { ReactNode } from "react";

import { METUPAL_OUTPUT_PROPS } from "../basic/OutputProps";
import MetupalOutputAge from "./MetupalOutputAge";
import MetupalOutputCost from "./MetupalOutputCost";
import MetupalOutputKupa from "./MetupalOutputKupa";
import MetupalOutputLocation from "./MetupalOutputLocation";
import MetupalOutputTime from "./MetupalOutputTime";
import MetupalOutputTypes from "./MetupalOutputTypes";
import MetupalOutputZoom from "./MetupalOutputZoom";

export default function MetupalSummaryOutput({
    newUserInfo,
    short,
    dividers = null,
}: METUPAL_OUTPUT_PROPS & { dividers?: ReactNode }) {
    return (
        <Stack gap={short ? 1 : 2} divider={dividers}>
            <MetupalOutputLocation
                newUserInfo={newUserInfo}
                short={short}
                expanded={!!dividers}
                colorful={!!dividers}
            />
            {!short && (
                <MetupalOutputTime
                    newUserInfo={newUserInfo}
                    short={short}
                    expanded={!!dividers}
                    colorful={!!dividers}
                />
            )}
            <MetupalOutputTypes newUserInfo={newUserInfo} short={short} expanded={!!dividers} colorful={!!dividers} />
            {!short && (
                <MetupalOutputCost
                    newUserInfo={newUserInfo}
                    short={short}
                    expanded={!!dividers}
                    colorful={!!dividers}
                />
            )}
            {!short && (
                <MetupalOutputKupa
                    newUserInfo={newUserInfo}
                    short={short}
                    expanded={!!dividers}
                    colorful={!!dividers}
                />
            )}
            <MetupalOutputZoom newUserInfo={newUserInfo} short={short} expanded={!!dividers} colorful={!!dividers} />
            <MetupalOutputAge newUserInfo={newUserInfo} short={short} expanded={!!dividers} colorful={!!dividers} />
        </Stack>
    );
}
