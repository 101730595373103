import {
    CloseOutlined,
    Face2TwoTone,
    Face3TwoTone,
    Face4TwoTone,
    Face5TwoTone,
    Face6TwoTone,
    FaceTwoTone,
} from "@mui/icons-material";
import { Avatar, Box, CircularProgress, Fab, Stack, SvgIcon, SxProps, Theme } from "@mui/material";
import { useCallback, useState } from "react";

import AppDialog from "../../common/dialog/AppDialog";
import { PROFILE_IMAGE_SIZE } from "../../search/inputs/SearchInputPhoto";
import { COLOR_APP_PINK, COLOR_APP_PINK_LIGHT, COLOR_APP_PRIMARY } from "../../theme/theme";
import { useDownloadProfilePhotoUrl } from "../../uploads/imageUpload";
import { USER_INFO } from "../../user/userInfo";
import { BASIC_OUTPUT_PROPS } from "./OutputProps";
import { useShowSensitiveOutput } from "./useShowSensitiveOutput";

type PROFILE_PHOTO_ICON = { Icon: typeof SvgIcon; sx?: SxProps<Theme> };
const FEMALE_PROFILE_PHOTOS: PROFILE_PHOTO_ICON[] = [
    {
        // the 5 bounces that looks like a flower
        Icon: Face2TwoTone,
        sx: {
            marginBottom: "0.06em",
            marginRight: "0.02em",
            transform: "scale(0.85)",
        },
    },
    {
        // the straight line at the bottom
        Icon: Face3TwoTone,
        sx: {
            marginBottom: "0.18em",
            marginRight: "0.01em",
            transform: "scale(0.9)",
        },
    },
    {
        // the kuku at the top left
        Icon: Face4TwoTone,
        sx: {
            marginTop: "0.03em",
            marginRight: "0.06em",
            transform: "scale(0.9)",
        },
    },
];
const MALE_PROFILE_PHOTOS: PROFILE_PHOTO_ICON[] = [
    { Icon: FaceTwoTone },
    { Icon: Face5TwoTone },
    { Icon: Face6TwoTone },
];
const OTHER_PROFILE_PHOTOS: PROFILE_PHOTO_ICON[] = [{ Icon: FaceTwoTone }];

export default function OutputPhoto({
    newUserInfo,
    isTargetTherapist,
    pink = true,
    size = PROFILE_IMAGE_SIZE,
}: BASIC_OUTPUT_PROPS & { size?: number; isTargetTherapist?: boolean; pink?: boolean }) {
    const [showBig, setShowBig] = useState(false);
    const showSensitive = useShowSensitiveOutput({ targetUserInfo: newUserInfo, isTargetTherapist });

    const { url, loadingAsset: loadingPhoto } = useDownloadProfilePhotoUrl(newUserInfo, { skip: !showSensitive });

    const disableEvents = useCallback((event) => {
        event.stopPropagation();
        event.preventDefault();
    }, []);
    const toggleTrueShowBig = useCallback(
        (event) => {
            disableEvents(event);
            setShowBig(true);
        },
        [disableEvents]
    );
    const toggleFalseShowBig = useCallback(
        (event) => {
            disableEvents(event);
            setShowBig(false);
        },
        [disableEvents]
    );

    const allowShowBig = url && size < PROFILE_IMAGE_SIZE;

    const { Icon: ProfileIcon, sx } = getEmptyPhotoIcon(newUserInfo);

    return (
        <Stack alignItems="center">
            <Avatar
                sx={{
                    width: size,
                    height: size,
                    backgroundColor: url || loadingPhoto ? "transparent" : pink ? COLOR_APP_PINK_LIGHT : "white",
                    border: url || loadingPhoto || pink ? "" : `4px solid ${COLOR_APP_PRIMARY}`,
                    fontSize: size * 0.75,
                    cursor: allowShowBig ? "pointer" : "default",
                }}
                src={url}
                onClick={allowShowBig ? toggleTrueShowBig : undefined}>
                {loadingPhoto ? (
                    <CircularProgress size={24} />
                ) : (
                    <ProfileIcon htmlColor={pink ? COLOR_APP_PINK : COLOR_APP_PRIMARY} fontSize="inherit" sx={sx} />
                )}
            </Avatar>
            <AppDialog
                open={showBig}
                setOpen={setShowBig}
                onClick={toggleFalseShowBig}
                fullWidth={false}
                disableGutters
                PaperProps={{ sx: { overflow: "visible" } }}>
                <Fab sx={{ position: "absolute", transform: "translate(-50%, -50%)" }} size="small">
                    <CloseOutlined />
                </Fab>
                <Box component="img" src={url} />
            </AppDialog>
        </Stack>
    );
}

function getEmptyPhotoIcon(newUserInfo: USER_INFO) {
    const faces =
        newUserInfo?.gender === "O"
            ? OTHER_PROFILE_PHOTOS
            : newUserInfo?.gender === "F"
            ? FEMALE_PROFILE_PHOTOS
            : MALE_PROFILE_PHOTOS;
    const uid = newUserInfo?.uid || "";
    let result = 0;
    for (let i = 0; i < uid.length; i++) {
        result = result + uid.charCodeAt(i);
    }
    return faces[result % faces.length];
}
