import { ArrowBack } from "@mui/icons-material";
import { Button, Stack } from "@mui/material";
import { useMemo } from "react";
import { useParams } from "react-router-dom";

import { useCached } from "../common/useCached";
import T from "../locallization/T";
import DeletedStateCard from "../matches/DeletedStateCard";
import { useLoadUserInfos } from "../matches/userInfosService";
import AppSpinner from "../navigation/AppSpinner";
import PageContainer from "../navigation/PageContainer";
import { ROUTES } from "../navigation/Router";
import { useProfileName } from "../outputs/basic/useProfileName";
import { useAppContext } from "../root/AppContext";
import { getSpacedButtonIconProps } from "../theme/buttons";
import WithThemeIcon from "../theme/WithThemeIcon";
import TherapistPageView from "./TherapistPageView";

export default function TherapistPagePublic() {
    const { userInfo } = useAppContext();

    const params = useParams();
    const therapistUids = useMemo(() => {
        return [params.uid || ""];
    }, [params.uid]);
    const { loading, userInfos } = useLoadUserInfos(therapistUids, true, useCached({ isPublic: !userInfo }));

    const therapistUserInfo = userInfos[0];
    const name = useProfileName({
        targetUserInfo: therapistUserInfo,
        isTargetTherapist: true,
    });

    return (
        <PageContainer
            pageTitleKey="title therapist page public"
            pageTitleParams={{ name }}
            pagePath={ROUTES.THERAPIST_PAGE_PUBLIC}
            pageReady={!!therapistUserInfo}
            hideBotNav={!userInfo}
            hideTopNav
            disableGutters>
            <Stack gap={2}>
                {loading ? (
                    <AppSpinner />
                ) : therapistUserInfo ? (
                    <>
                        <TherapistPageView
                            therapistUserInfo={therapistUserInfo}
                            hideBack={!userInfo}
                            showLogo={!userInfo}>
                            {!userInfo && (
                                <Button
                                    variant="contained"
                                    size="huge"
                                    href={ROUTES.HOME}
                                    {...getSpacedButtonIconProps({ sx: { mt: 3 } })}
                                    endIcon={<WithThemeIcon Icon={ArrowBack} />}>
                                    <T>therapist page join button</T>
                                </Button>
                            )}
                        </TherapistPageView>
                    </>
                ) : (
                    <DeletedStateCard matchIsTherapist={true} sx={{ m: 2 }} />
                )}
            </Stack>
        </PageContainer>
    );
}
