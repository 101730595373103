import { isPlainObject, keys, sortBy } from "lodash";
import { useMemo } from "react";

export function useSortedFavorite(favoritesObject: {}) {
    return useMemo(() => {
        return sortBy(keys(favoritesObject || {}), (userId) => {
            let date = (favoritesObject || {})[userId];
            if (date === true) {
                // migrating data objects to be timestamps
                return 0;
            }
            if (isPlainObject(date) && date.date) {
                date = date.date;
            }
            const timestamp = typeof date === "number" ? date : date ? ((date as any).toDate() as Date).getTime() : 0;
            return -timestamp;
        });
    }, [favoritesObject]);
}
