import { useMemo } from "react";

import { useAppContext } from "../root/AppContext";
import { CONSULT_UID } from "../user/consult";

export function useChatsWithMetupalim() {
    const { userInfo } = useAppContext();
    return useMemo(() => {
        const chatsWithMetupalim = {};
        for (let uid in userInfo?.chatsWithMetupalim || {}) {
            if (
                !(userInfo?.rejectedMetupalim || {})[uid] &&
                !(userInfo?.rejectedByMetupalim || {})[uid] &&
                !(userInfo?.met || {})[uid] &&
                !(userInfo?.checkMet || {})[uid] &&
                uid !== CONSULT_UID
            ) {
                chatsWithMetupalim[uid] = (userInfo?.chatsWithMetupalim || {})[uid];
            }
        }
        return chatsWithMetupalim;
    }, [
        userInfo?.chatsWithMetupalim,
        userInfo?.met,
        userInfo?.checkMet,
        userInfo?.rejectedMetupalim,
        userInfo?.rejectedByMetupalim,
    ]);
}
