import { useSnackbar } from 'notistack';
import { useCallback } from 'react';

import T from '../locallization/T';

export const DEFAULT_HOME_URL = `https://${window.location.hostname}`;

export function useShare({ getUrl = () => DEFAULT_HOME_URL } = {}) {
    const { enqueueSnackbar } = useSnackbar();

    const copyLink = useCallback(() => {
        navigator.clipboard.writeText(getUrl());
        enqueueSnackbar(<T>therapist page link copy success</T>, { variant: "success" });
    }, [getUrl, enqueueSnackbar]);

    const shareLink = useCallback(() => {
        if (navigator.share) {
            navigator.share({ url: getUrl() }).catch(() => copyLink());
        } else {
            copyLink();
        }
    }, [getUrl, copyLink]);

    return shareLink;
}
