import {
    EmailOutlined,
    HandshakeOutlined,
    HearingOutlined,
    MessageOutlined,
    PrivacyTipOutlined,
    RuleOutlined,
} from "@mui/icons-material";
import { Stack, Typography } from "@mui/material";

import PageTitle from "../common/PageTitle";
import T from "../locallization/T";
import PageContainer from "../navigation/PageContainer";
import { ROUTES } from "../navigation/Router";
import SettingRowBase from "../profile/settingRows/SettingRowBase";
import { useAppContext } from "../root/AppContext";

export default function ContactPage() {
    const { userInfo } = useAppContext();

    return (
        <PageContainer pageTitleKey="profile setting contact" pagePath={ROUTES.CONTACT} backgrounded>
            <Stack gap={2}>
                <PageTitle hasTopNav gap={2}>
                    <T>profile setting contact</T>
                </PageTitle>
                <Typography>
                    <T>contact section contact title</T>
                </Typography>
                <SettingRowBase
                    text={<T>contact action chat</T>}
                    Icon={MessageOutlined}
                    href="https://wa.me/972533025849"
                />
                <SettingRowBase
                    text={<T>contact action email</T>}
                    Icon={EmailOutlined}
                    href="mailto:support@zigmond.me"
                />

                <Typography sx={{ mt: 1 }}>
                    <T>contact section info title</T>
                </Typography>
                <SettingRowBase
                    text={<T>contact action privacy</T>}
                    Icon={PrivacyTipOutlined}
                    href={ROUTES.PRIVACY_POLICY}
                />
                {userInfo?.isTherapist && (
                    <SettingRowBase
                        text={<T>contact action agreement</T>}
                        Icon={RuleOutlined}
                        href={ROUTES.TERMS_AGREEMENT}
                    />
                )}
                <SettingRowBase
                    text={<T>contact action usage</T>}
                    Icon={HandshakeOutlined}
                    href={ROUTES.TERMS_OF_USE}
                />
                <SettingRowBase
                    text={<T>contact action accessibility</T>}
                    Icon={HearingOutlined}
                    href={ROUTES.ACCESSIBILITY_ACKNOWLEDGE}
                />
            </Stack>
        </PageContainer>
    );
}
