import { AdminPanelSettingsOutlined, CloseOutlined, SaveOutlined } from "@mui/icons-material";
import { CircularProgress, FormControlLabel, IconButton, Stack, Switch } from "@mui/material";
import { httpsCallable } from "firebase/functions";
import { useSnackbar } from "notistack";
import { useCallback, useMemo, useState } from "react";

import { ActionButtonPrimary, ActionButtonSecondary } from "../../common/ActionButtons";
import AppDialog from "../../common/dialog/AppDialog";
import { getFirebaseFunctions } from "../../firebase/firebase";
import T from "../../locallization/T";
import AppSpinner from "../../navigation/AppSpinner";
import { ADMIN_PROPS, getAdminFromClaims } from "../../user/useAdmin";
import { USER_INFO } from "../../user/userInfo";

export default function AdminStatusPreviewButton({ newUserInfo }: { newUserInfo: USER_INFO }) {
    const [showPreview, setShowPreview] = useState(false);
    const [loading, setLoading] = useState(false);
    const [saving, setSaving] = useState(false);
    const [claims, setClaims] = useState<ADMIN_PROPS | undefined>(undefined);
    const { enqueueSnackbar } = useSnackbar();

    const adminsCallback = useMemo(
        () => httpsCallable<{ uid: string; claims?: ADMIN_PROPS }, ADMIN_PROPS>(getFirebaseFunctions(), "onCallAdmins"),
        []
    );

    const setTrueShowPreview = useCallback(() => {
        setShowPreview(true);
        if (!claims && !loading) {
            setLoading(true);
            adminsCallback({ uid: newUserInfo.uid || "" })
                .then((response) => {
                    setLoading(false);
                    setClaims(getAdminFromClaims(response.data));
                })
                .catch((error) => {
                    setLoading(false);
                    console.error(error);
                    enqueueSnackbar(<T params={{ code: 179 }}>general error</T>, { variant: "warning" });
                });
        }
    }, [enqueueSnackbar, loading, newUserInfo.uid, claims, adminsCallback]);

    const setFalseShowPreview = useCallback(() => {
        setShowPreview(false);
    }, []);

    const saveClaims = useCallback(() => {
        setSaving(true);
        adminsCallback({ uid: newUserInfo.uid || "", claims })
            .then((response) => {
                setSaving(false);
                enqueueSnackbar("המשתמש עודכן בהצלחה", { variant: "success" });
            })
            .catch((error) => {
                setSaving(false);
                console.error(error);
                enqueueSnackbar(<T params={{ code: 180 }}>general error</T>, { variant: "warning" });
            });
    }, [enqueueSnackbar, newUserInfo.uid, claims, adminsCallback]);

    return (
        <>
            <IconButton onClick={setTrueShowPreview} color="inherit">
                <AdminPanelSettingsOutlined />
            </IconButton>
            <AppDialog
                open={showPreview}
                setOpen={setShowPreview}
                fullScreen
                actions={
                    <>
                        <ActionButtonSecondary onClick={setFalseShowPreview} endIcon={<CloseOutlined />}>
                            <T>dialog button close</T>
                        </ActionButtonSecondary>
                        <ActionButtonPrimary
                            onClick={saveClaims}
                            disabled={loading || saving || !claims}
                            endIcon={saving ? <CircularProgress size={24} /> : <SaveOutlined />}>
                            <T>profile setting button save</T>
                        </ActionButtonPrimary>
                    </>
                }>
                {loading ? (
                    <AppSpinner />
                ) : (
                    <Stack gap={1}>
                        {Object.keys(claims || {}).map((claim) => (
                            <FormControlLabel
                                key={claim}
                                disabled={saving}
                                control={
                                    <Switch
                                        checked={!!(claims || {})[claim]}
                                        onChange={() => setClaims({ ...claims, [claim]: !(claims || {})[claim] })}
                                    />
                                }
                                label={claim}
                            />
                        ))}
                    </Stack>
                )}
            </AppDialog>
        </>
    );
}
