import {
    BabyChangingStationOutlined,
    BoyOutlined,
    ElderlyOutlined,
    EscalatorWarningOutlined,
    ManOutlined,
} from "@mui/icons-material";
import { Stack, SvgIcon } from "@mui/material";
import { useEffect, useState } from "react";

import T from "../../locallization/T";
import { convertToUserInfoMap, USER_INFO, USER_INFO_AGE } from "../../user/userInfo";
import AppToggleButton from "../buttonGroup/AppToggleButton";
import OnboardingQuestionMultiple from "./helpers/OnboardingQuestionMultiple";
import { ONBOARDING_QUESTION_PROPS } from "./OnboardingQuestionProps";

export function alreadyHasTherapistAges(userInfo: USER_INFO) {
    return !!userInfo.therapistAges;
}

export const userInfoAges: { value: USER_INFO_AGE; Icon: typeof SvgIcon }[] = [
    { value: "babies", Icon: BabyChangingStationOutlined },
    { value: "children", Icon: EscalatorWarningOutlined },
    { value: "teenagers", Icon: BoyOutlined },
    { value: "adults", Icon: ManOutlined },
    { value: "elderly", Icon: ElderlyOutlined },
];

export default function OnboardingQuestionTherapistAges({ onDataProgress }: ONBOARDING_QUESTION_PROPS) {
    const [selectedOptions, setSelectedOptions] = useState(new Set<USER_INFO_AGE>([]));

    useEffect(() => {
        if (selectedOptions.size > 0) {
            onDataProgress({
                therapistAges: convertToUserInfoMap(Array.from(selectedOptions)),
            });
        } else {
            onDataProgress({});
        }
    }, [selectedOptions, onDataProgress]);

    return (
        <Stack gap={2}>
            <OnboardingQuestionMultiple />
            {userInfoAges.map(({ value: age, Icon }) => (
                <AppToggleButton
                    key={age}
                    value={age}
                    selectedOptions={selectedOptions}
                    setSelectedOptions={setSelectedOptions}
                    Icon={<Icon />}>
                    <T children={`onboarding question therapist ages ${age}`} />
                </AppToggleButton>
            ))}
        </Stack>
    );
}
