import { Typography } from "@mui/material";

import T from "../locallization/T";
import { useAppContext } from "../root/AppContext";

export default function DeletedState({ matchIsTherapist }: { matchIsTherapist?: boolean }) {
    const { userInfo } = useAppContext();

    const isTherapist = typeof matchIsTherapist === "boolean" ? matchIsTherapist : !userInfo?.isTherapist;
    return (
        <Typography>{isTherapist ? <T>therapist page deleted note</T> : <T>match metupal deleted note</T>}</Typography>
    );
}
