import { useMemo } from 'react';

import T from '../../locallization/T';
import { userInfoZoom } from '../../onboarding/questions/OnboardingQuestionLocation';
import SearchInputBasicSelect from '../basic/SearchInputBasicSelect';
import { SEARCH_INPUT_PROPS } from './SearchInputProps';

export default function SearchInputZoom({ onData, newUserInfo, isTherapist }: SEARCH_INPUT_PROPS) {
    const zoomKey = useMemo(() => (isTherapist ? "therapistLocationZoom" : "locationZoom"), [isTherapist]);

    return (
        <SearchInputBasicSelect
            onData={onData}
            newUserInfo={newUserInfo}
            userInfoKey={zoomKey}
            label={<T>search input zoom</T>}
            allValues={userInfoZoom}
            defaultValue={userInfoZoom[1].value}
        />
    );
}
