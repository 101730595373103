import { CloseOutlined } from "@mui/icons-material";
import { colors, Grow, IconButton } from "@mui/material";
import { SnackbarProvider, useSnackbar } from "notistack";

import { useSmallScreen } from "../navigation/useSmallScreen";

export function SnackbarContainer({ children }) {
    const isSmallScreen = useSmallScreen();
    return (
        <SnackbarProvider
            autoHideDuration={3000}
            TransitionComponent={Grow}
            dense={isSmallScreen}
            action={(snackbarKey) => <SnackbarCloseButton snackbarKey={snackbarKey} />}>
            {children}
        </SnackbarProvider>
    );
}

function SnackbarCloseButton({ snackbarKey }) {
    const { closeSnackbar } = useSnackbar();

    return (
        <IconButton onClick={() => closeSnackbar(snackbarKey)}>
            <CloseOutlined fontSize="small" sx={{ color: colors.common.white }} />
        </IconButton>
    );
}
