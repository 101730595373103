import { findIndex, isArray, keys, sortBy } from "lodash";
import { useMemo } from "react";

export function useSortedOutput(list: {} | [] | undefined, orderList: any[], orderKey?: string): string[] {
    return useMemo(
        () =>
            sortBy(isArray(list) ? list : keys(list), [
                (item) => findIndex(orderList, (orderItem) => (orderKey ? orderItem[orderKey] : orderItem) === item),
            ]),
        [list, orderList, orderKey]
    );
}
