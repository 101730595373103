import { Card, Container, Stack } from "@mui/material";
import { SxProps, Theme } from "@mui/material/styles";
import { Box } from "@mui/system";
import { logEvent } from "firebase/analytics";
import { useEffect } from "react";

import { isLocalhost } from "../common/isLocalhost";
import { DEFAULT_HOME_URL } from "../common/useShare";
import { getFirebaseAnalytics } from "../firebase/firebase";
import { useT } from "../locallization/T";
import { COLOR_APP_BACKGROUND, COLOR_APP_PRIMARY_LIGHT_3_OPAQUE } from "../theme/theme";
import BottomNav from "./BottomNav";
import TopNav, { TopNavProps } from "./TopNav";
import { usePageSizes } from "./usePageSizes";
import { useSmallScreen } from "./useSmallScreen";

export default function PageContainer({
    children,
    pageTitleKey,
    pageTitleParams = {},
    pagePath,
    pageReady = true,
    TopNavComponent,
    BotNavComponent,
    hideTopNav = false,
    hideBotNav = false,
    sx = {},
    backgrounded = false,
    disableGutters = false,
    ...topNavProps
}: {
    children: React.ReactNode;
    pageTitleKey?: string;
    pageTitleParams?: {};
    pagePath?: string;
    pageReady?: boolean;
    TopNavComponent?: React.ReactElement;
    BotNavComponent?: React.ReactNode;
    hideTopNav?: boolean;
    hideBotNav?: boolean;
    backgrounded?: boolean | string;
    sx?: SxProps<Theme>;
    disableGutters?: boolean;
} & TopNavProps) {
    const isSmallScreen = useSmallScreen();
    const { containerSx, contentSx } = usePageSizes();

    const pageTitle = useT({ textKey: pageTitleKey, params: pageTitleParams }).join("");
    const fullTitle = useT({ textKey: pageTitle ? "title base" : "title bare", params: { title: pageTitle } }).join("");

    useEffect(() => {
        if (pageReady) {
            document.title = fullTitle;
        }
    }, [fullTitle, pageReady]);

    useEffect(() => {
        if (pageReady && !isLocalhost) {
            logEvent(getFirebaseAnalytics(), "page_view", {
                page_title: fullTitle,
                page_location: `${DEFAULT_HOME_URL}${pagePath}`,
                page_path: pagePath,
            });
        }
    }, [pagePath, fullTitle, pageReady]);

    const mixedSx: SxProps<Theme> = {
        ...containerSx,
        ...(isSmallScreen
            ? { height: "100%" }
            : {
                  position: "relative",
                  top: "50%",
                  transform: "translateY(-50%)",
              }),
        ...{
            background: backgrounded ? (typeof backgrounded === "string" ? backgrounded : COLOR_APP_BACKGROUND) : "",
        },
        ...sx,
    };

    return (
        <Box
            width="100%"
            height="100%"
            sx={{
                backgroundColor: isSmallScreen ? "" : COLOR_APP_PRIMARY_LIGHT_3_OPAQUE,
            }}>
            <Stack justifyContent="space-between" sx={mixedSx} component={isSmallScreen ? Box : Card}>
                {TopNavComponent || (hideTopNav ? <div /> : <TopNav {...topNavProps} />)}
                <Container
                    disableGutters={disableGutters}
                    sx={{
                        height: "100%",
                        WebkitOverflowScrolling: "touch",
                        overflow: "auto",
                        pt: disableGutters ? 0 : 1,
                    }}>
                    <Box sx={{ ...contentSx, "& > *:last-child": { pb: 2 } }}>{children}</Box>
                </Container>
                {BotNavComponent || (hideBotNav ? <div /> : <BottomNav />)}
            </Stack>
        </Box>
    );
}
