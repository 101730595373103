import { NotAccessibleOutlined, WheelchairPickupOutlined } from "@mui/icons-material";
import { Stack, SvgIcon, Typography } from "@mui/material";
import { useCallback, useEffect, useMemo, useState } from "react";

import T from "../../locallization/T";
import SearchInputTherapistCost from "../../search/therapistInputs/SearchInputTherapistCost";
import { USER_INFO, USER_INFO_ALLOW_CHARITY, USER_INFO_MAP, USER_INFO_THERAPY_TYPE } from "../../user/userInfo";
import AppToggleButton from "../buttonGroup/AppToggleButton";
import { ONBOARDING_QUESTION_PROPS } from "./OnboardingQuestionProps";

// Change in server too
export const DEFAULT_COST = 400;

export const userInfoCosts: { min: number; max: number; base: number; therapyType: USER_INFO_THERAPY_TYPE }[] = [
    { therapyType: "personal", min: 250, max: 600, base: DEFAULT_COST },
    { therapyType: "couple", min: 250, max: 800, base: DEFAULT_COST },
    { therapyType: "family", min: 250, max: 1500, base: DEFAULT_COST },
    { therapyType: "group", min: 250, max: 600, base: DEFAULT_COST },
    { therapyType: "parents", min: 250, max: 600, base: DEFAULT_COST },
];

export const userInfoAllowCharity: { value: USER_INFO_ALLOW_CHARITY; textKey: string; Icon: typeof SvgIcon }[] = [
    { value: "Y", textKey: "yes", Icon: WheelchairPickupOutlined },
    { value: "N", textKey: "no", Icon: NotAccessibleOutlined },
];

export function alreadyHasTherapistCost(userInfo: USER_INFO) {
    return !!userInfo.therapistCosts;
}

export default function OnboardingQuestionTherapistCost({ onDataProgress, newUserInfo }: ONBOARDING_QUESTION_PROPS) {
    const [costValues, setCostValues] = useState<USER_INFO_MAP<USER_INFO_THERAPY_TYPE, number>>({});
    const [selectedCharityOptions, setSelectedCharityOptions] = useState(new Set<USER_INFO_ALLOW_CHARITY>([]));

    const charityValue = useMemo(() => {
        const charityArrayValues = Array.from(selectedCharityOptions);
        return charityArrayValues.length > 0 ? charityArrayValues[0] : undefined;
    }, [selectedCharityOptions]);

    const updateCostValues = useCallback((updatedUserInfo: USER_INFO) => {
        setCostValues(updatedUserInfo.therapistCosts || {});
    }, []);

    useEffect(() => {
        onDataProgress({
            therapistCosts: costValues,
            therapistAllowCharity: charityValue,
        });
    }, [onDataProgress, costValues, charityValue]);

    return (
        <Stack>
            <SearchInputTherapistCost newUserInfo={newUserInfo} onData={updateCostValues} showTextInputs />

            <Typography variant="subtitle1" sx={{ mt: 5, mb: 2 }}>
                <T>onboarding question cost charity</T>
            </Typography>
            <Stack flexDirection="row" gap={1}>
                {userInfoAllowCharity.map(({ value: charityValue, textKey, Icon }) => (
                    <AppToggleButton
                        key={charityValue}
                        value={charityValue}
                        selectedOptions={selectedCharityOptions}
                        setSelectedOptions={setSelectedCharityOptions}
                        limitToOne
                        Icon={<Icon />}>
                        <T>{textKey}</T>
                    </AppToggleButton>
                ))}
            </Stack>
        </Stack>
    );
}
