import { CloseOutlined, PersonPinCircleOutlined } from "@mui/icons-material";
import { Button, IconButton, SxProps, Theme } from "@mui/material";
import { useCallback, useState } from "react";

import { ActionButtonSecondary } from "../common/ActionButtons";
import AppDialog from "../common/dialog/AppDialog";
import T from "../locallization/T";
import { USER_INFO } from "../user/userInfo";
import MetupalPageView from "./MetupalPageView";

export default function MetupalPagePreviewButton({
    newUserInfo,
    asIcon,
    sx = [],
}: {
    newUserInfo: USER_INFO;
    asIcon?: boolean;
    sx?: SxProps<Theme>;
}) {
    const [showPreview, setShowPreview] = useState(false);

    const toggleShowPreview = useCallback(() => {
        setShowPreview((show) => !show);
    }, []);

    return (
        <>
            {asIcon ? (
                <IconButton onClick={toggleShowPreview} sx={sx} color="inherit">
                    <PersonPinCircleOutlined />
                </IconButton>
            ) : (
                <Button
                    onClick={toggleShowPreview}
                    sx={sx}
                    variant="text"
                    color="inherit"
                    startIcon={<PersonPinCircleOutlined />}>
                    <T>metupal page preview button</T>
                </Button>
            )}
            <AppDialog
                open={showPreview}
                setOpen={setShowPreview}
                fullScreen
                disableGutters
                actions={
                    <ActionButtonSecondary onClick={toggleShowPreview} endIcon={<CloseOutlined />}>
                        <T>dialog button close</T>
                    </ActionButtonSecondary>
                }>
                <MetupalPageView metupalUserInfo={newUserInfo} onBack={toggleShowPreview} />
            </AppDialog>
        </>
    );
}
