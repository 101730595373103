import { Navigate, Route, Routes } from "react-router-dom";

import Chat from "../chat/Chat";
import Messages from "../chat/messages/Messages";
import History from "../history/History";
import Home from "../home/Home";
import AccessibilityAcknowledgePage from "../legal/AccessibilityAcknowledgePage";
import ContactPage from "../legal/ContactPage";
import PrivacyPolicyPage from "../legal/PrivacyPolicyPage";
import TermsAgreementPage from "../legal/TermsAgreementPage";
import TermsOfUsePage from "../legal/TermsOfUsePage";
import ResultsLoader from "../loading/ResultsLoader";
import LoggedOutHome from "../login/home/LoggedOutHome";
import EmailVerifiedHolder from "../login/sign/EmailVerifiedHolder";
import ForgotPasswordForm from "../login/sign/ForgotPasswordForm";
import LoginForm from "../login/sign/LoginForm";
import RegisterForm from "../login/sign/RegisterForm";
import OnboardingQuestionsMetupal from "../onboarding/OnboardingQuestionsMetupal";
import TherapistOnboarding from "../onboarding/therapist/TherapistOnboarding";
import ActionsResponder from "../profile/ActionsResponder";
import Profile from "../profile/Profile";
import { useAppContext } from "../root/AppContext";
import Search from "../search/Search";
import Secret from "../secret/Secret";
import TherapistPageEdit from "../therapist_page/TherapistPageEdit";
import TherapistPageIntro from "../therapist_page/TherapistPageIntro";
import TherapistPagePublic from "../therapist_page/TherapistPagePublic";
import AccountCreater from "../user/AccountCreater";

export const RESULTS_LOADING_SEARCH_STATE = "searched";
export const FORGOT_PASSWORD_EMAIL_STATE = "email";
export const RESULTS_LOADING_STATE = { state: { [RESULTS_LOADING_SEARCH_STATE]: true } };

export const LOGIN_STATE_CONTINUE_URL = "continueUrl";

export const PROFILE_STATE_OPEN_SETTING = "openSetting";
export const PROFILE_STATE_OPEN_SETTING_NOTIFICATIONS = "notifications";

export const ROUTE_ID_CHAT_ID = ":chatId";
export const ROUTE_ID_THERAPIST_ID = ":uid";

export const ROUTES = {
    // logged in.
    HOME: "/",
    SEARCH: "/search",
    PROFILE: "/profile",
    HISTORY: "/history",
    CHAT: "/chat",
    MESSAGES: `/chat/${ROUTE_ID_CHAT_ID}`,

    // logged in therapists
    THERAPIST_PAGE: "/therapist-page",

    // admins
    SECRET: "/secret",

    // logged out:
    LOGIN: "/login",
    REGISTER_THRAPIST: "/register-therapist",
    REGISTER: "/register",
    FORGOT_PASSWORD: "/forgot-password",
    ONBOARDING: "/onboarding",

    // anyone:
    THERAPIST_PAGE_PUBLIC: `/therapist/${ROUTE_ID_THERAPIST_ID}`,
    CONTACT: "/contact",
    TERMS_AGREEMENT: "/terms-agreement",
    TERMS_OF_USE: "/terms-of-use",
    PRIVACY_POLICY: "/privacy-policy",
    ACCESSIBILITY_ACKNOWLEDGE: "/accessibility",

    // new users:
    RESULTS_LOADER_INITIAL: "/results-loader",
    RESULTS_LOADER_EDIT: "/results-loader-update",

    // profile actions:
    ACTIONS: "/actions",

    // unused routes
    THERAPIST_PAGE_INTRO: "/therapist-page-intro",
    THERAPIST_ONBOARDING_START: "/therapist-onboarding-start",
    EMAIL_VERIFIED_HOLDER: "/verification-process",
    HOME_SIGNED_IN: "/home",
};

export default function Router() {
    const { adminProps, user, userInfo } = useAppContext();

    return (
        <Routes>
            {user ? (
                <>
                    {user.emailVerified ? (
                        userInfo ? (
                            <>
                                {userInfo.onboarded ? (
                                    <>
                                        {userInfo.timesSearched ? (
                                            <>
                                                {/* Onboarded users */}
                                                <Route path={ROUTES.PROFILE} element={<Profile />} />
                                                <Route path={ROUTES.MESSAGES} element={<Messages />} />
                                                <Route path={ROUTES.CHAT} element={<Chat />} />
                                                {!userInfo.isTherapist && (
                                                    <Route path={ROUTES.SEARCH} element={<Search />} />
                                                )}
                                                <Route path={ROUTES.HISTORY} element={<History />} />
                                                <Route path={ROUTES.HOME} element={<Home />} />
                                            </>
                                        ) : userInfo.isTherapist ? (
                                            <Route path={ROUTES.HOME} element={<TherapistPageIntro />} />
                                        ) : (
                                            <Route
                                                path={ROUTES.HOME}
                                                element={<Navigate to={ROUTES.RESULTS_LOADER_INITIAL} replace />}
                                            />
                                        )}
                                        {userInfo.isTherapist && (
                                            <Route path={ROUTES.THERAPIST_PAGE} element={<TherapistPageEdit />} />
                                        )}
                                        <Route
                                            path={ROUTES.RESULTS_LOADER_INITIAL}
                                            element={<ResultsLoader initial />}
                                        />
                                        <Route path={ROUTES.RESULTS_LOADER_EDIT} element={<ResultsLoader />} />
                                    </>
                                ) : /* Onboarding */
                                userInfo.isTherapist ? (
                                    <Route path={ROUTES.HOME} element={<TherapistOnboarding />} />
                                ) : (
                                    <Route path={ROUTES.HOME} element={<OnboardingQuestionsMetupal />} />
                                )}
                            </>
                        ) : (
                            <>
                                {/* Creates new account */}
                                <Route path={ROUTES.HOME} element={<AccountCreater />} />
                            </>
                        )
                    ) : (
                        /* Unverified users */
                        <Route path={ROUTES.HOME} element={<EmailVerifiedHolder />} />
                    )}

                    {/* Admins */}
                    {adminProps?.admin && <Route path={ROUTES.SECRET} element={<Secret />} />}
                </>
            ) : (
                <>
                    {/* Logged outs */}
                    <Route path={ROUTES.REGISTER_THRAPIST} element={<RegisterForm therapist />} />
                    <Route path={ROUTES.REGISTER} element={<RegisterForm />} />
                    <Route path={ROUTES.FORGOT_PASSWORD} element={<ForgotPasswordForm />} />
                    <Route path={ROUTES.HOME} element={<LoggedOutHome />} />
                    {/* Onboarding for logged out too */}
                    <Route path={ROUTES.ONBOARDING} element={<OnboardingQuestionsMetupal />} />
                </>
            )}
            {/* Actions from firebase */}
            <Route path={ROUTES.ACTIONS} element={<ActionsResponder />} />
            <Route path={ROUTES.THERAPIST_PAGE_PUBLIC} element={<TherapistPagePublic />} />
            <Route path={ROUTES.CONTACT} element={<ContactPage />} />
            <Route path={ROUTES.TERMS_AGREEMENT} element={<TermsAgreementPage />} />
            <Route path={ROUTES.TERMS_OF_USE} element={<TermsOfUsePage />} />
            <Route path={ROUTES.PRIVACY_POLICY} element={<PrivacyPolicyPage />} />
            <Route path={ROUTES.ACCESSIBILITY_ACKNOWLEDGE} element={<AccessibilityAcknowledgePage />} />
            {/* Keep login here, it internally redirects logged in users */}
            <Route path={ROUTES.LOGIN} element={<LoginForm />} />

            {/* Default */}
            <Route path="*" element={<Navigate to={ROUTES.HOME} replace />} />
        </Routes>
    );
}
