import { getTalkGender } from "../../common/gender/getTalkGender";
import SeparatedList from "../../common/SeparatedList";
import T from "../../locallization/T";
import { allSpecialties } from "../../search/therapistInputs/SearchInputSpecialty";
import { THERAPIST_OUTPUT_PROPS } from "../basic/OutputProps";
import { useSortedOutput } from "../basic/useSortedOutput";

export default function TherapistOutputSpecialty({ newUserInfo }: THERAPIST_OUTPUT_PROPS) {
    const sortedSpecialties = useSortedOutput(newUserInfo?.therapistSpecialties, allSpecialties);
    if (sortedSpecialties.length === 0) {
        return null;
    }

    return (
        <T
            overrideGender={getTalkGender(newUserInfo)}
            params={{
                specialty: (
                    <SeparatedList separator="; ">
                        {sortedSpecialties.map((specialty) => (
                            <T
                                key={specialty}
                                overrideGender={getTalkGender(newUserInfo)}
                                children={`specialty ${specialty}`}
                            />
                        ))}
                    </SeparatedList>
                ),
            }}>
            therapist page subtitle prefix
        </T>
    );
}
