import { Box, Stack, Typography } from "@mui/material";
import { ReactNode, useCallback } from "react";

import NonEmptyDivider from "../common/NonEmptyDivider";
import T from "../locallization/T";
import OutputPhoto from "../outputs/basic/OutputPhoto";
import { useNoNumberText } from "../outputs/basic/useNoNumberText";
import MetupalOutputOthers from "../outputs/metupal/MetupalOutputOthers";
import MetupalSummaryOutput from "../outputs/metupal/MetupalSummaryOutput";
import MetupalTitle from "../outputs/metupal/MetupalTitle";
import SearchInputBasicChips from "../search/basic/SearchInputBasicChips";
import { useSortedReasons } from "../search/inputs/useSortedReasons";
import HighlightTop from "../therapist_page/HighlightTop";
import InfoSection from "../therapist_page/info/InfoSection";
import { USER_INFO } from "../user/userInfo";

export default function MetupalPageView({
    metupalUserInfo,
    subtitle,
    onBack,
}: {
    metupalUserInfo: USER_INFO;
    subtitle?: ReactNode;
    onBack?: () => any;
}) {
    const { sortedReasons } = useSortedReasons(metupalUserInfo?.reasonsList || []);
    const reasonsText = useNoNumberText(metupalUserInfo?.uid, metupalUserInfo?.reasonsText);
    const getValueLabel = useCallback((reason: number) => <T children={`reason ${reason}`} />, []);

    return (
        <Box>
            <HighlightTop onBack={onBack}>
                <OutputPhoto newUserInfo={metupalUserInfo} isTargetTherapist={false} pink={false} />
                <MetupalTitle newUserInfo={metupalUserInfo} subtitle={subtitle} sx={{ mb: 3 }} />
            </HighlightTop>

            <Stack gap={3} pb={3} px={2}>
                <MetupalSummaryOutput newUserInfo={metupalUserInfo} dividers={<NonEmptyDivider sx={{ mx: -2 }} />} />

                {metupalUserInfo.reasonsList?.length || reasonsText ? (
                    <>
                        <NonEmptyDivider sx={{ mx: -2 }} />
                        <InfoSection title={<T>metupal output reasons</T>}>
                            <Stack gap={1}>
                                {sortedReasons.length ? (
                                    <SearchInputBasicChips
                                        allValues={sortedReasons}
                                        getValueLabel={getValueLabel}
                                        forceHorizontal={false}
                                    />
                                ) : null}
                                {reasonsText && <Typography>{reasonsText}</Typography>}
                            </Stack>
                        </InfoSection>
                    </>
                ) : null}
                {metupalUserInfo.othersList?.length ? (
                    <>
                        <NonEmptyDivider sx={{ mx: -2 }} />
                        <InfoSection title={<T>metupal output other</T>}>
                            <MetupalOutputOthers newUserInfo={metupalUserInfo} />
                        </InfoSection>
                    </>
                ) : null}
            </Stack>
        </Box>
    );
}
