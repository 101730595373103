import T from "../../locallization/T";
import SearchInputBasicTextarea from "../basic/SearchInputBasicTextarea";
import { SEARCH_INPUT_PROPS } from "../inputs/SearchInputProps";

const MAX_EXP_LENGTH = 750;

export default function SearchInputExp({ onData, newUserInfo }: SEARCH_INPUT_PROPS) {
    return (
        <SearchInputBasicTextarea
            onData={onData}
            newUserInfo={newUserInfo}
            label={<T>therapist page section title exp</T>}
            userInfoKey="therapistExp"
            maxLength={MAX_EXP_LENGTH}
        />
    );
}
