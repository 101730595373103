import { AddOutlined } from "@mui/icons-material";
import { Button, Stack } from "@mui/material";
import { SyntheticEvent, useMemo } from "react";

import T from "../locallization/T";
import { useLoadUserInfos } from "../matches/userInfosService";
import AppSpinner from "../navigation/AppSpinner";
import { useAppContext } from "../root/AppContext";
import { CONSULT_UID, getConsultChat } from "../user/consult";
import { USER_INFO } from "../user/userInfo";
import ChatCard from "./ChatCard";
import { useChatsService } from "./useChatsService";
import { useChatsUids } from "./useChatsUids";

export default function ChatsList({
    onChatClick,
    forceIsTherapist,
}: {
    onChatClick?: (event: SyntheticEvent, matchedUserInfo: USER_INFO) => void;
    forceIsTherapist?: boolean;
}) {
    const { userInfo } = useAppContext();
    const isTherapist = typeof forceIsTherapist === "boolean" ? forceIsTherapist : userInfo?.isTherapist;
    const chatObject = isTherapist ? userInfo?.chatsWithMetupalim : userInfo?.chats;
    const addConsult = !(chatObject || {})[CONSULT_UID];
    const uids = useChatsUids({ isTherapist });
    const { loading: loadingUserInfos, userInfos } = useLoadUserInfos(uids, !isTherapist);
    const { loading: loadingChats, chats, hasMoreChats, loadMoreChats } = useChatsService(!isTherapist);
    const displayChats = useMemo(() => {
        if (!addConsult || loadingChats) {
            return chats;
        }
        return chats.concat([getConsultChat(userInfo?.uid || "", !!isTherapist)]);
    }, [chats, addConsult, userInfo?.uid, isTherapist, loadingChats]);

    const userInfosById = useMemo(() => {
        const map = {};
        for (const info of userInfos) {
            map[info?.uid || ""] = info;
        }
        return map;
    }, [userInfos]);

    const loading = loadingUserInfos || loadingChats;

    return (
        <Stack gap={2}>
            <>
                {displayChats.map((chat) => (
                    <ChatCard
                        chat={chat}
                        onChatClick={onChatClick}
                        matchedUserInfo={
                            isTherapist ? userInfosById[chat?.metupalUids[0]] : userInfosById[chat?.therapistUid]
                        }
                        matchIsTherapist={!isTherapist}
                        key={isTherapist ? chat?.metupalUids[0] : chat?.therapistUid}
                    />
                ))}
            </>
            {loading ? (
                <AppSpinner />
            ) : hasMoreChats ? (
                <Button
                    sx={{ mt: 2, alignSelf: "center" }}
                    variant="contained"
                    size="large"
                    onClick={loadMoreChats}
                    startIcon={<AddOutlined />}>
                    <T>chats more button</T>
                </Button>
            ) : null}
        </Stack>
    );
}
