import { Chip, Link, Stack, Typography } from "@mui/material";
import { ReactNode, useCallback, useMemo, useState } from "react";

import T from "../locallization/T";
import AppSpinner from "../navigation/AppSpinner";
import { boldStyles } from "../theme/theme";
import MetupalMatchCard from "./MetupalMatchCard";
import TherapistMatchCard from "./TherapistMatchCard";
import { useLoadUserInfos } from "./userInfosService";

export default function MatchedList({
    initialMatchesCount,
    matchesPerPage,
    sortedUserIds,
    isTargetTherapist,
    title,
}: {
    initialMatchesCount: number;
    matchesPerPage: number;
    sortedUserIds: string[];
    isTargetTherapist: boolean;
    title?: ReactNode;
}) {
    const [page, setPage] = useState(0);
    const showMore = useCallback(() => {
        setPage((page) => page + 1);
    }, []);

    const userIdsToUse = useMemo(() => {
        const end = initialMatchesCount + (page - 1) * matchesPerPage + matchesPerPage;
        return sortedUserIds.slice(0, end);
    }, [sortedUserIds, page, initialMatchesCount, matchesPerPage]);

    const { loading: matchesLoading, userInfos } = useLoadUserInfos(userIdsToUse, isTargetTherapist);

    // it is setting loading in useEffect so it is async and there's a moment where it doesn't load properly.
    const loading = matchesLoading || userIdsToUse.length !== userInfos.length;

    return sortedUserIds.length > 0 ? (
        <Stack gap={2}>
            <>
                {title && (
                    <Stack flexDirection="row" gap={1} alignItems="center">
                        <Typography variant="h3" sx={{ ...boldStyles }}>
                            {title}
                        </Typography>
                        <Chip
                            label={sortedUserIds.length > 9 ? "9+" : sortedUserIds.length}
                            variant="filled"
                            size="small"
                            color="light"
                        />
                    </Stack>
                )}
                <Stack gap={2}>
                    {userInfos.map((targetUserInfo, index) =>
                        isTargetTherapist ? (
                            <TherapistMatchCard
                                therapistUserInfo={targetUserInfo}
                                key={targetUserInfo?.uid || userIdsToUse[index] || ""}
                                therapistUid={targetUserInfo?.uid || userIdsToUse[index] || ""}
                            />
                        ) : (
                            <MetupalMatchCard
                                metupalUserInfo={targetUserInfo}
                                key={targetUserInfo?.uid || userIdsToUse[index] || ""}
                                metupalUid={targetUserInfo?.uid || userIdsToUse[index] || ""}
                            />
                        )
                    )}
                </Stack>
                {sortedUserIds.length > userInfos.length && !loading && (
                    <Link component="a" onClick={showMore} sx={{ alignSelf: "center", cursor: "pointer" }}>
                        <Typography>
                            <T params={{ count: sortedUserIds.length - userInfos.length }}>matches show more</T>
                        </Typography>
                    </Link>
                )}
            </>
            {loading && <AppSpinner />}
        </Stack>
    ) : null;
}
