import { Chip, ChipProps, Stack } from "@mui/material";

import { useSmallScreen } from "../../navigation/useSmallScreen";

export default function SearchInputBasicChips<T>({
    allValues,
    getValueLabel,
    isValueSelected,
    onValueClick,
    onValueDelete,
    forceHorizontal,
}: {
    allValues: T[];
    getValueLabel: (value: T) => React.ReactNode;
    isValueSelected?: (value: T) => boolean;
    onValueClick?: (value: T) => void;
    onValueDelete?: (value: T) => void;
    forceHorizontal?: boolean;
} & ChipProps) {
    const isSmallScreen = useSmallScreen();

    return (
        <Stack
            gap={0.5}
            flexDirection="row"
            sx={
                (forceHorizontal === undefined ? isSmallScreen : forceHorizontal)
                    ? {
                          overflow: "auto",
                          pr: 1,
                          WebkitOverflowScrolling: "touch",
                          ...(isSmallScreen && {
                              "&::-webkit-scrollbar": {
                                  display: "none",
                              },
                          }),
                      }
                    : { flexWrap: "wrap" }
            }>
            {allValues.map((value, index) => (
                <Chip
                    key={index}
                    label={getValueLabel(value)}
                    color="primary"
                    variant={!isValueSelected || isValueSelected(value) ? "filled" : "outlined"}
                    onClick={onValueClick ? () => onValueClick(value) : undefined}
                    onDelete={onValueDelete ? () => onValueDelete(value) : undefined}
                    sx={{ border: "1px solid" }}
                />
            ))}
        </Stack>
    );
}
