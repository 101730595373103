import { RestoreOutlined } from "@mui/icons-material";
import { Button, CircularProgress } from "@mui/material";
import { useCallback, useMemo, useState } from "react";

import { formatMessageDate } from "../chat/messages/MessagesList";
import AppDialog from "../common/dialog/AppDialog";
import T from "../locallization/T";
import DeletedStateCard from "../matches/DeletedStateCard";
import MatchCard from "../matches/MatchCard";
import { useRestoreMetupal } from "../matches/useRestoreMetupal";
import MetupalPageView from "../metupalim/MetupalPageView";
import MetupalSummaryOutput from "../outputs/metupal/MetupalSummaryOutput";
import TherapistSummaryShortOutput from "../outputs/therapist/TherapistSummaryShortOutput";
import { useAppContext } from "../root/AppContext";
import TherapistPageView from "../therapist_page/TherapistPageView";
import { USER_INFO } from "../user/userInfo";
import { FAVORITE_VALUE } from "./History";

export default function FavoriteCard({
    targetUserInfo,
    date,
    favoriteValue,
}: {
    targetUserInfo?: USER_INFO;
    date: any;
    favoriteValue: FAVORITE_VALUE;
}) {
    const { userInfo } = useAppContext();
    const [showDialog, setShowDialog] = useState(false);
    const [loading, setLoading] = useState(false);

    const toggleShowDialog = useCallback(() => {
        setShowDialog((show) => !show);
    }, []);
    const { restoreMetupal } = useRestoreMetupal({
        metupalUid: targetUserInfo?.uid || "",
        setLoading: setLoading,
    });

    const formattedDate = useMemo(() => (date ? formatMessageDate(date) : ""), [date]);

    if (!targetUserInfo) {
        return <DeletedStateCard />;
    }
    if (favoriteValue === "rejected") {
        return (
            <MatchCard
                basicUserInfo={targetUserInfo}
                onClick={toggleShowDialog}
                subheader={formattedDate}
                action={
                    userInfo?.isTherapist ? (
                        <Button
                            sx={{ ml: "auto" }}
                            startIcon={loading ? <CircularProgress size={20} /> : <RestoreOutlined />}
                            disabled={loading}
                            onClick={restoreMetupal}
                            variant="contained"
                            size="medium">
                            <T>favorite restore match</T>
                        </Button>
                    ) : null
                }
            />
        );
    }

    return (
        <>
            <MatchCard basicUserInfo={targetUserInfo} onClick={toggleShowDialog} subheader={formattedDate}>
                {userInfo?.isTherapist ? (
                    <MetupalSummaryOutput newUserInfo={targetUserInfo} short />
                ) : (
                    <TherapistSummaryShortOutput newUserInfo={targetUserInfo} />
                )}
            </MatchCard>

            <AppDialog open={showDialog} setOpen={setShowDialog} fullScreen disableGutters>
                {userInfo?.isTherapist ? (
                    <MetupalPageView metupalUserInfo={targetUserInfo} onBack={toggleShowDialog} />
                ) : (
                    <TherapistPageView therapistUserInfo={targetUserInfo} onBack={toggleShowDialog} />
                )}
            </AppDialog>
        </>
    );
}
