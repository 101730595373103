import { ManageAccountsOutlined } from "@mui/icons-material";

import T from "../../locallization/T";
import SettingsAccount from "../settings/SettingsAccount";
import SettingRowBase from "./SettingRowBase";

export default function SettingRowAccount() {
    return (
        <SettingRowBase
            text={<T>profile setting account</T>}
            Icon={ManageAccountsOutlined}
            SettingContent={SettingsAccount}
            fullScreen
        />
    );
}
