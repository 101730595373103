import { Box, Stack, Typography } from "@mui/material";
import { lazy, Suspense, useState } from "react";

import ResultsBackgroundPng from "../loading/png/resultsBackground.png";
import T from "../locallization/T";
import AppSpinner from "../navigation/AppSpinner";
import { boldStyles } from "../theme/theme";

const LazyLottieBunnySearch = lazy(() => import("./loader/LottieBunnySearch"));

export default function SearchExisting() {
    const [animationReady, setAnimationReady] = useState(false);

    return (
        <Stack alignItems="center" justifyContent="center" height="100%" textAlign="center" position="relative">
            <Box
                width={375}
                maxWidth="100%"
                component="img"
                src={ResultsBackgroundPng}
                alt=""
                sx={{
                    position: "absolute",
                    left: 0,
                    right: 0,
                    top: -40,
                    margin: "auto",
                    zIndex: -1,
                }}
            />
            <Suspense>
                <LazyLottieBunnySearch onReady={setAnimationReady} />
            </Suspense>

            {animationReady ? (
                <>
                    <Typography variant="h2" sx={{ mt: 1, ...boldStyles }}>
                        <T>search new title</T>
                    </Typography>
                    <Typography variant="h4" sx={{ mt: 1 }}>
                        <T>search new subtitle</T>
                    </Typography>
                </>
            ) : (
                <AppSpinner sx={{ position: "absolute" }} />
            )}
        </Stack>
    );
}
