import { PaymentsOutlined } from "@mui/icons-material";
import { Button, CircularProgress, Stack, Typography } from "@mui/material";
import { httpsCallable } from "firebase/functions";
import { useSnackbar } from "notistack";
import { SyntheticEvent, useCallback, useMemo, useState } from "react";

import { formatMessageDate } from "../../chat/messages/MessagesList";
import AppDialog from "../../common/dialog/AppDialog";
import NonEmptyDivider from "../../common/NonEmptyDivider";
import { getFirebaseFunctions } from "../../firebase/firebase";
import { useSortedFavorite } from "../../history/useSortedFavorite";
import T from "../../locallization/T";
import { useLoadUserInfos } from "../../matches/userInfosService";
import TopNav from "../../navigation/TopNav";
import OutputRow from "../../outputs/basic/OutputRow";
import { useAppContext } from "../../root/AppContext";
import { USER_INFO } from "../../user/userInfo";
import { UserTableRow } from "../users/UsersTableSecret";

export default function AdminPaymentHistoryButton({ newUserInfo }: { newUserInfo: USER_INFO }) {
    const { adminProps } = useAppContext();
    const [saving, setSaving] = useState(false);
    const [showDialog, setShowDialog] = useState(false);
    const sortedPaymentDue = useSortedFavorite(newUserInfo?.paymentDue as {});
    const sortedPaid = useSortedFavorite(newUserInfo?.paid as {});
    const { enqueueSnackbar } = useSnackbar();
    const options = useMemo(() => ({ admin: true, skip: !showDialog }), [showDialog]);
    const { loading: loadingMetupalim, userInfos: metupalUserInfos } = useLoadUserInfos(
        sortedPaymentDue,
        false,
        options
    );
    const { loading: loadingPaidMetupalim, userInfos: paidMetupalUserInfos } = useLoadUserInfos(
        sortedPaid,
        false,
        options
    );

    const [expanded, setExpanded] = useState<string | false>(false);
    const handleChange = (panel: string) => (event: SyntheticEvent, isExpanded: boolean) => {
        setExpanded(isExpanded ? panel : false);
    };

    const setTrueShowDialog = useCallback(() => {
        setShowDialog(true);
    }, []);

    const setFalseShowDialog = useCallback(() => {
        setShowDialog(false);
    }, []);

    const updatePaidStatus = useCallback(
        (metupalUid: string, met: boolean) => {
            const updatePaidStatusCallback = httpsCallable<
                { therapistUid: string; met: boolean; metupalUid: string },
                null
            >(getFirebaseFunctions(), "onCallAdminUpdatePaidStatus");

            setSaving(true);
            updatePaidStatusCallback({
                therapistUid: newUserInfo?.uid || "",
                met,
                metupalUid,
            })
                .then((response) => {
                    setSaving(false);
                    enqueueSnackbar("הסטטוס עודכן בהצלחה", { variant: "success" });
                    const paymentDue = { ...newUserInfo.paymentDue };
                    delete paymentDue[metupalUid];
                    newUserInfo.paymentDue = paymentDue;
                })
                .catch((error) => {
                    setSaving(false);
                    console.error(error);
                    enqueueSnackbar(<T params={{ code: 195 }}>general error</T>, { variant: "warning" });
                });
        },
        [newUserInfo, enqueueSnackbar]
    );
    const markPaid = useCallback(
        (metupalUid: string) => {
            updatePaidStatus(metupalUid, true);
        },
        [updatePaidStatus]
    );
    const markDidNotMeet = useCallback(
        (metupalUid: string) => {
            updatePaidStatus(metupalUid, false);
        },
        [updatePaidStatus]
    );

    return (
        <>
            <Button onClick={setTrueShowDialog} color="info" startIcon={<PaymentsOutlined />}>
                היסטוריית תשלומים ({newUserInfo.payHistory?.length || 0})
            </Button>
            <AppDialog
                open={showDialog}
                setOpen={setShowDialog}
                fullScreen
                topNav={<TopNav onBack={setFalseShowDialog} title="היסטוריית תשלומים" />}>
                <Stack gap={3}>
                    <Stack gap={1}>
                        <Typography variant="h2">תשלומים שצריך לשלם</Typography>
                        {sortedPaymentDue.length > 0 ? (
                            <Stack gap={3} divider={<NonEmptyDivider sx={{ mx: -2 }} />}>
                                {sortedPaymentDue.map((metupalUid, index) => {
                                    return (
                                        <Stack gap={1} key={index}>
                                            <OutputRow
                                                title={"תאריך"}
                                                value={formatMessageDate((newUserInfo?.paymentDue || {})[metupalUid])}
                                                short
                                            />
                                            <OutputRow
                                                title={""}
                                                value={
                                                    metupalUserInfos.length && metupalUserInfos[index] ? (
                                                        <UserTableRow
                                                            currentUserInfo={metupalUserInfos[index]}
                                                            isTherapist={false}
                                                            expanded={expanded}
                                                            handleChange={handleChange}
                                                        />
                                                    ) : loadingMetupalim ? (
                                                        <CircularProgress size={24} />
                                                    ) : (
                                                        <Typography>{metupalUid} (משתמש מחוק)</Typography>
                                                    )
                                                }
                                            />
                                            {adminProps?.adminUsersEdit && (
                                                <OutputRow
                                                    title={"עדכון סטטוס"}
                                                    short
                                                    value={
                                                        <Stack gap={1} flexDirection="row">
                                                            <Button
                                                                onClick={() => markPaid(metupalUid)}
                                                                disabled={saving}
                                                                variant="contained"
                                                                endIcon={
                                                                    saving ? <CircularProgress size={16} /> : null
                                                                }>
                                                                סמן כשולם
                                                            </Button>
                                                            <Button
                                                                onClick={() => markDidNotMeet(metupalUid)}
                                                                disabled={saving}
                                                                variant="contained"
                                                                endIcon={
                                                                    saving ? <CircularProgress size={16} /> : null
                                                                }>
                                                                בטל תשלום
                                                            </Button>
                                                        </Stack>
                                                    }
                                                />
                                            )}
                                        </Stack>
                                    );
                                })}
                            </Stack>
                        ) : (
                            <Typography>אין תשלומים שצריך לשלם</Typography>
                        )}
                    </Stack>
                    <Stack gap={1}>
                        <Typography variant="h2">מטופלים ששולמו עליהם כבר</Typography>
                        {sortedPaid.length > 0 ? (
                            <Stack gap={3} divider={<NonEmptyDivider sx={{ mx: -2 }} />}>
                                {sortedPaid.map((metupalUid, index) => {
                                    return (
                                        <Stack gap={1} key={index}>
                                            <OutputRow
                                                title={"תאריך"}
                                                value={formatMessageDate((newUserInfo?.paid || {})[metupalUid])}
                                                short
                                            />
                                            <OutputRow
                                                title={""}
                                                value={
                                                    paidMetupalUserInfos.length && paidMetupalUserInfos[index] ? (
                                                        <UserTableRow
                                                            currentUserInfo={paidMetupalUserInfos[index]}
                                                            isTherapist={false}
                                                            expanded={expanded}
                                                            handleChange={handleChange}
                                                        />
                                                    ) : loadingPaidMetupalim ? (
                                                        <CircularProgress size={24} />
                                                    ) : (
                                                        <Typography>{metupalUid} (משתמש מחוק)</Typography>
                                                    )
                                                }
                                            />
                                        </Stack>
                                    );
                                })}
                            </Stack>
                        ) : (
                            <Typography>אין מטופלים ששולמו עליהם</Typography>
                        )}
                    </Stack>

                    <Stack gap={1}>
                        <Typography variant="h2">תשלומים ששולמו</Typography>
                        {newUserInfo.payHistory?.length ? (
                            <Stack gap={3} flexDirection="column-reverse" divider={<NonEmptyDivider sx={{ mx: -2 }} />}>
                                {newUserInfo.payHistory.map(({ date, type, amount }, index) => {
                                    return (
                                        <Stack gap={1} key={index}>
                                            <OutputRow title={"תאריך"} value={formatMessageDate(date)} short />
                                            <OutputRow title={"סוג תשלום"} value={type} short />
                                            <OutputRow title={"כמות תשלום"} value={amount} short />
                                        </Stack>
                                    );
                                })}
                            </Stack>
                        ) : (
                            <Typography>אין עדיין תשלומים</Typography>
                        )}
                    </Stack>
                </Stack>
            </AppDialog>
        </>
    );
}
