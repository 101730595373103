import { AddOutlined, DeleteForeverOutlined } from "@mui/icons-material";
import { Button, Card, CardActions, CardContent, Chip, CircularProgress, Stack, Typography } from "@mui/material";
import {
    collection,
    doc,
    DocumentData,
    getDocs,
    limit,
    orderBy,
    query,
    QuerySnapshot,
    startAfter,
    updateDoc,
    where,
} from "firebase/firestore";
import { isEmpty, without } from "lodash";
import { useSnackbar } from "notistack";
import { Fragment, SyntheticEvent, useCallback, useEffect, useMemo, useRef, useState } from "react";

import { formatMessageDate } from "../../chat/messages/MessagesList";
import { getFirebaseFirestoreDb } from "../../firebase/firebase";
import T from "../../locallization/T";
import { useLoadUserInfos } from "../../matches/userInfosService";
import AppSpinner from "../../navigation/AppSpinner";
import { boldStyles } from "../../theme/theme";
import { USER_INFO } from "../../user/userInfo";
import { UserTableRow } from "../users/UsersTableSecret";

const INVALIDATIONS_PER_PAGE = 10;

type INVALIDATION = {
    id: string;
    date: number;
    type: string;
    message: string;
    severity: string;
    targetUid?: string;
    metupalUids?: string[];
};

export default function InvalidationsListSecret() {
    const [loading, setLoading] = useState(true);
    const [invalidations, setInvalidations] = useState<INVALIDATION[]>([]);
    const [loadMoreInvalidationsIndex, setLoadMoreInvalidationsIndex] = useState(0);

    const lastInvalidationRef = useRef({});
    const { enqueueSnackbar } = useSnackbar();
    const [hasMoreInvalidations, setHasMoreInvalidations] = useState(false);
    const [therapistUids, setTherapistUids] = useState<string[]>([]);
    const [metupalUids, setMetupalUids] = useState<string[]>([]);

    const [expanded, setExpanded] = useState<string | false>(false);

    const handleChange = (panel: string) => (event: SyntheticEvent, isExpanded: boolean) => {
        setExpanded(isExpanded ? panel : false);
    };

    const options = useMemo(() => ({ filterNulls: true, admin: true }), []);

    const { loading: loadingTherapists, userInfos: therapistUserInfos } = useLoadUserInfos(
        therapistUids,
        true,
        options
    );
    const { loading: loadingMetupalim, userInfos: metupalUserInfos } = useLoadUserInfos(metupalUids, false, options);

    const therapistsByUid = useMemo(() => {
        const map: { [key: string]: USER_INFO } = {};
        for (const therapist of therapistUserInfos) {
            map[therapist.uid || ""] = therapist;
        }
        return map;
    }, [therapistUserInfos]);
    const metupalsByUid = useMemo(() => {
        const map: { [key: string]: USER_INFO } = {};
        for (const metupal of metupalUserInfos) {
            map[metupal.uid || ""] = metupal;
        }
        return map;
    }, [metupalUserInfos]);

    useEffect(() => {
        setLoading(true);

        const filters = [
            where("ack", "!=", true),
            limit(INVALIDATIONS_PER_PAGE),
            orderBy("ack", "desc"),
            orderBy("date", "desc"),
        ];
        if (!isEmpty(lastInvalidationRef.current)) {
            filters.push(startAfter(lastInvalidationRef.current));
        }
        const invalidationsCollection = collection(getFirebaseFirestoreDb(), "invalidations");
        const q = query(invalidationsCollection, ...filters);

        getDocs(q)
            .then((querySnapshot: QuerySnapshot<DocumentData>) => {
                if (querySnapshot.docs.length) {
                    lastInvalidationRef.current = querySnapshot.docs[querySnapshot.docs.length - 1];
                    setHasMoreInvalidations(querySnapshot.docs.length === INVALIDATIONS_PER_PAGE);
                } else {
                    lastInvalidationRef.current = {};
                    // if loaded 0 docs, no more Invalidations.
                    setHasMoreInvalidations(false);
                }
                setInvalidations((invalidations) =>
                    invalidations.concat(
                        querySnapshot.docs.map((doc) => ({ ...(doc.data() as INVALIDATION), id: doc.id }))
                    )
                );
                setLoading(false);
            })
            .catch((error) => {
                console.error(error);
                setLoading(false);
                enqueueSnackbar(<T params={{ code: 193 }}>general error</T>, { variant: "warning" });
            });
    }, [loadMoreInvalidationsIndex, enqueueSnackbar]);

    useEffect(() => {
        const therapistUidsSet = new Set<string>();
        const metupalUidsSet = new Set<string>();
        for (const invalidation of invalidations) {
            if (invalidation.targetUid) {
                therapistUidsSet.add(invalidation.targetUid);
            }
            for (const metupalUid of invalidation.metupalUids || []) {
                metupalUidsSet.add(metupalUid);
            }
        }
        setTherapistUids(Array.from(therapistUidsSet));
        setMetupalUids(Array.from(metupalUidsSet));
    }, [invalidations]);

    const deleteInvalidation = useCallback(
        (invalidation: INVALIDATION) => {
            const invalidationDoc = doc(getFirebaseFirestoreDb(), "invalidations", invalidation.id);

            setLoading(true);
            updateDoc(invalidationDoc, { ack: true })
                .then(() => {
                    setInvalidations((invalidations) => without(invalidations, invalidation));
                    enqueueSnackbar("החריגה נמחקה בהצלחה", { variant: "success" });
                    setLoading(false);
                })
                .catch((error) => {
                    console.error(error);
                    setLoading(false);
                    enqueueSnackbar(<T params={{ code: 194 }}>general error</T>, { variant: "warning" });
                });
        },
        [enqueueSnackbar]
    );

    return (
        <Stack gap={1}>
            {invalidations.map((invalidation) => (
                <Card key={invalidation.id}>
                    <CardContent>
                        <Stack gap={1}>
                            <bdi>
                                <Typography variant="h4" {...boldStyles}>
                                    {invalidation.type}
                                    <Chip
                                        sx={{ mr: 1 }}
                                        size="small"
                                        label={invalidation.severity}
                                        color={invalidation.severity === "critical" ? "error" : "warning"}
                                    />
                                </Typography>
                            </bdi>
                            <bdi>
                                <Typography>{invalidation.message}</Typography>
                            </bdi>
                            <bdi>
                                <Typography>{formatMessageDate(invalidation.date)}</Typography>
                            </bdi>
                            {invalidation.targetUid && (
                                <>
                                    <Typography>מטפל: </Typography>
                                    {therapistsByUid[invalidation.targetUid] ? (
                                        <UserTableRow
                                            currentUserInfo={therapistsByUid[invalidation.targetUid]}
                                            isTherapist={true}
                                            expanded={expanded}
                                            handleChange={handleChange}
                                        />
                                    ) : loadingTherapists ? (
                                        <CircularProgress size={24} />
                                    ) : (
                                        <Typography>{invalidation.targetUid} (משתמש מחוק)</Typography>
                                    )}
                                </>
                            )}
                            {invalidation.metupalUids && (
                                <>
                                    <Typography>מטופלים: </Typography>
                                    {invalidation.metupalUids.map((metupalUid) => (
                                        <Fragment key={metupalUid}>
                                            {metupalsByUid[metupalUid] ? (
                                                <UserTableRow
                                                    currentUserInfo={metupalsByUid[metupalUid]}
                                                    isTherapist={false}
                                                    expanded={expanded}
                                                    handleChange={handleChange}
                                                />
                                            ) : loadingMetupalim ? (
                                                <CircularProgress size={24} />
                                            ) : (
                                                <Typography>{metupalUid} (משתמש מחוק)</Typography>
                                            )}
                                        </Fragment>
                                    ))}
                                </>
                            )}
                        </Stack>
                    </CardContent>
                    <CardActions>
                        <Button
                            startIcon={loading ? <CircularProgress size={24} /> : <DeleteForeverOutlined />}
                            onClick={() => deleteInvalidation(invalidation)}
                            variant="text"
                            disabled={loading}>
                            מחיקת חריגה
                        </Button>
                    </CardActions>
                </Card>
            ))}
            {loading ? (
                <AppSpinner />
            ) : hasMoreInvalidations ? (
                <Button
                    sx={{ mt: 2, alignSelf: "center" }}
                    variant="contained"
                    size="large"
                    onClick={() => setLoadMoreInvalidationsIndex((index) => index + 1)}
                    startIcon={<AddOutlined />}>
                    טען עוד
                </Button>
            ) : invalidations.length === 0 ? (
                <Typography sx={{ alignSelf: "center" }}>אין חריגות חדשות.</Typography>
            ) : null}
        </Stack>
    );
}
