import isUaWebview from "is-ua-webview";
import { useMemo } from "react";
import { useSearchParams } from "react-router-dom";

import { getSessionStorage, saveSessionStorage } from "../common/storage";

const WEBVIEW_STORAGE_KEY = "webview_app";
const NO_APP_NAME = "none";

let appName: string;
export function useSetAppName() {
    const [searchParams] = useSearchParams();

    if (!appName) {
        appName = appName || searchParams.get("app") || getSessionStorage(WEBVIEW_STORAGE_KEY);
        if (appName) {
            saveSessionStorage(WEBVIEW_STORAGE_KEY, appName);
        } else {
            appName = NO_APP_NAME;
        }
    }
}

export function useIsAppWebview() {
    const isIos = useIsIos();
    const isAndroid = useIsAndroid();
    return isIos || isAndroid;
}
export function useIsIos() {
    return useIsAppWebviewSpecific("ios");
}
export function useIsAndroid() {
    return useIsAppWebviewSpecific("android");
}
export function useIsAppWebviewSpecific(specificAppName: string) {
    return appName === specificAppName;
}
export function useIsInWebview() {
    return useMemo(() => isUaWebview(navigator.userAgent) || appName !== NO_APP_NAME, []);
}
export function useIsNonAppWebview() {
    const isAppWebview = useIsAppWebview();
    const isWebview = useIsInWebview();
    return isWebview && !isAppWebview;
}
