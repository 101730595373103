import { LockOutlined, PublicOutlined } from '@mui/icons-material';
import { SvgIcon } from '@mui/material';
import { useCallback } from 'react';

import T from '../../locallization/T';
import { USER_INFO_PAGE_VISIBILITY } from '../../user/userInfo';
import SearchInputBasicSelect from '../basic/SearchInputBasicSelect';
import { SEARCH_INPUT_PROPS } from '../inputs/SearchInputProps';

const visibilities: { value: USER_INFO_PAGE_VISIBILITY; Icon: typeof SvgIcon }[] = [
    { value: "private", Icon: LockOutlined },
    { value: "public", Icon: PublicOutlined },
];

export default function SearchInputPageVisibility({ onData, newUserInfo }: SEARCH_INPUT_PROPS) {
    const getValueLabel = useCallback((value: string) => <T children={`page visibility ${value}`} />, []);

    return (
        <SearchInputBasicSelect
            onData={onData}
            newUserInfo={newUserInfo}
            userInfoKey="pageVisibility"
            label={<T>search input page visibility</T>}
            allValues={visibilities}
            defaultValue={visibilities[0].value}
            getValueLabel={getValueLabel}
        />
    );
}
