import { AccessibleOutlined } from "@mui/icons-material";
import { Stack } from "@mui/material";

import T from "../../locallization/T";
import {
    therapistQuestionIcons,
    therapistQuestionIconsColorful,
} from "../../onboarding/therapist/OnboardingQuestionsTherapist";
import WithThemeIcon from "../../theme/WithThemeIcon";
import { THERAPIST_OUTPUT_PROPS } from "../basic/OutputProps";
import OutputRow from "../basic/OutputRow";
import { useTherapistLocations } from "./useTherapistLocations";

export default function TherapistOutputLocation({ newUserInfo, colorful, ...props }: THERAPIST_OUTPUT_PROPS) {
    const locationNames = useTherapistLocations({ newUserInfo });

    return locationNames.length ? (
        <OutputRow
            {...props}
            title={<T>therapist output location</T>}
            value={
                <>
                    {locationNames.map((name, index) => (
                        <Stack key={index} flexDirection="row" gap={0.5}>
                            {name}
                            {(newUserInfo?.therapistLocationAccessibles || [])[index] && (
                                <WithThemeIcon Icon={AccessibleOutlined} reverse />
                            )}
                        </Stack>
                    ))}
                </>
            }
            Icon={
                colorful ? therapistQuestionIconsColorful.therapist_location : therapistQuestionIcons.therapist_location
            }
        />
    ) : null;
}
