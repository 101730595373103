import { CancelOutlined, CloudUploadOutlined } from "@mui/icons-material";
import { Button, CircularProgress, IconButton, Stack, Typography } from "@mui/material";
import { uploadBytes } from "firebase/storage";
import { useSnackbar } from "notistack";
import { useCallback, useState } from "react";

import T from "../../locallization/T";
import TherapistOutputVideo from "../../outputs/therapist/TherapistOutputVideo";
import { useAppContext } from "../../root/AppContext";
import { PHOTO_KEY_1, PHOTO_KEY_2 } from "../../uploads/imageUpload";
import {
    getTherapistVideoRef,
    getThreapistVideoThumbnailRef,
    useUploadVideo,
    VIDEO_KEY_1,
    VIDEO_KEY_2,
} from "../../uploads/videoUpload";
import { DEFAULT_MEDIA_CACHE } from "../inputs/SearchInputPhoto";
import { SEARCH_INPUT_PROPS } from "../inputs/SearchInputProps";
import HowToUploadVideosButton from "./HowToUploadVideosButton";

const MAX_VIDEO_MB = 40;
const MAX_VIDEO_SIZE = MAX_VIDEO_MB * 1024 * 1024;

export default function SearchInputTherapistVideo({ onData, newUserInfo }: SEARCH_INPUT_PROPS) {
    const { userInfo } = useAppContext();
    const [loading, setLoading] = useState(false);
    const { enqueueSnackbar } = useSnackbar();
    const { uploadVideoToCloudinary, downloadAssetsFromCloudinary, deleteVideoFromCloudinary } = useUploadVideo();

    const initiateUploadVideo = useCallback(
        ({ target }) => {
            if (!userInfo) {
                return;
            }
            if (target?.files?.length !== 1) {
                // cancelled.
                return;
            }
            if (!target.files[0].type.match("video.*")) {
                enqueueSnackbar(<T>search input therapist video not video</T>, {
                    variant: "warning",
                });
                return;
            }
            if (target.files[0].size > MAX_VIDEO_SIZE) {
                enqueueSnackbar(
                    <T
                        params={{
                            size: MAX_VIDEO_MB,
                            link: (
                                <a
                                    href="https://www.freeconvert.com/video-compressor"
                                    target="_blank"
                                    rel="noreferrer"
                                    style={{ textDecoration: "none" }}>
                                    &nbsp;freeconvert
                                </a>
                            ),
                        }}>
                        search input therapist video too big error
                    </T>,
                    {
                        variant: "warning",
                    }
                );
                return;
            }

            const oldPhotoKey = userInfo.therapistVideoThumbKey;
            const oldVideoKey = userInfo.therapistVideoKey;
            const newPhotoKey = oldPhotoKey === PHOTO_KEY_1 ? PHOTO_KEY_2 : PHOTO_KEY_1;
            const newVideoKey = oldVideoKey === VIDEO_KEY_1 ? VIDEO_KEY_2 : VIDEO_KEY_1;
            const imageRef = getThreapistVideoThumbnailRef(userInfo.uid || "temp", newPhotoKey);
            const videoRef = getTherapistVideoRef(userInfo.uid || "temp", newVideoKey);

            setLoading(true);
            onData({ therapistVideoThumbKey: "", therapistVideoKey: "" });

            let deleteToken = "";
            uploadVideoToCloudinary(target.files[0])
                .then((data) => {
                    deleteToken = data.delete_token;
                    return downloadAssetsFromCloudinary(data.secure_url);
                })
                .then(([videoData, imageData]) => {
                    return Promise.all([
                        uploadBytes(videoRef, videoData, {
                            cacheControl: DEFAULT_MEDIA_CACHE,
                        }),
                        uploadBytes(imageRef, imageData, {
                            cacheControl: DEFAULT_MEDIA_CACHE,
                        }),
                    ]);
                })
                .then(() => {
                    setLoading(false);
                    onData({ therapistVideoThumbKey: newPhotoKey, therapistVideoKey: newVideoKey });
                    // Don't return the delete token value, it shouldn't fail the operation.
                    deleteVideoFromCloudinary(deleteToken);
                })
                .catch((error) => {
                    setLoading(false);
                    console.error(error);
                    onData({ therapistVideoThumbKey: oldPhotoKey, therapistVideoKey: oldVideoKey });
                    enqueueSnackbar(<T params={{ code: 173 }}>general error</T>, { variant: "warning" });
                    if (deleteToken) {
                        deleteVideoFromCloudinary(deleteToken);
                    }
                });
        },
        [
            enqueueSnackbar,
            userInfo,
            onData,
            uploadVideoToCloudinary,
            downloadAssetsFromCloudinary,
            deleteVideoFromCloudinary,
        ]
    );

    const cancelVideo = useCallback(() => {
        onData({ therapistVideoThumbKey: "", therapistVideoKey: "" });
    }, [onData]);

    return (
        <Stack gap={1}>
            <Stack flexDirection="row" alignItems="center">
                <Button
                    variant="outlined"
                    component="label"
                    size="huge"
                    sx={{ justifyContent: "space-between", width: "100%" }}
                    disabled={loading}
                    className="label"
                    endIcon={loading ? <CircularProgress size={24} /> : <CloudUploadOutlined />}>
                    <T>search input therapist video caption</T>
                    <input hidden accept="video/*,.mkv;capture=Camcorder" type="file" onChange={initiateUploadVideo} />
                </Button>
                {newUserInfo?.therapistVideoKey && (
                    <IconButton color="inherit" onClick={cancelVideo} sx={{ flexShrink: 0 }}>
                        <CancelOutlined />
                    </IconButton>
                )}
            </Stack>
            {loading ? (
                <Typography variant="caption">
                    <T>therapist video upload loading long</T>
                </Typography>
            ) : (
                <HowToUploadVideosButton />
            )}
            <TherapistOutputVideo newUserInfo={newUserInfo} />
        </Stack>
    );
}
