import { Stack } from "@mui/material";
import { isEmpty } from "lodash";

import { useAppContext } from "../../root/AppContext";
import ProfileDeleteAccountButton from "../settingButtons/ProfileDeleteAccountButton";
import ProfileEditPasswordButton from "../settingButtons/ProfileEditPasswordButton";
import ProfileSignOutButton from "../settingButtons/ProfileSignOutButton";
import SettingRowHistory from "../settingRows/SettingRowHistory";
import { SETTINGS_PROPS } from "./SettingsProps";

export default function SettingsAccount({ onData, newUserInfo }: SETTINGS_PROPS) {
    const { userInfo } = useAppContext();

    return (
        <Stack gap={2}>
            <ProfileEditPasswordButton />
            <ProfileSignOutButton />
            <ProfileDeleteAccountButton />
            {(!isEmpty(userInfo?.blocked) || !isEmpty(userInfo?.rejectedMetupalim)) && <SettingRowHistory />}
        </Stack>
    );
}
