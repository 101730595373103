import { useMemo } from "react";

import { useAppContext } from "../../root/AppContext";
import { USER_INFO } from "../../user/userInfo";
import { useLocationName } from "../basic/useLocationName";

export function useTherapistLocations({ newUserInfo }: { newUserInfo?: USER_INFO }): string[] {
    const { userInfo } = useAppContext();

    const locationNames = useMemo(() => {
        const showSpecificLocation =
            userInfo?.uid &&
            userInfo?.uid !== newUserInfo?.uid &&
            userInfo?.locationGeo &&
            newUserInfo?.therapistLocationGeos?.length;

        if (showSpecificLocation) {
            // find the shortest distance from all locations
            let shortestDistance = Infinity;
            let shortestLocation = "";
            const therapistGeos = newUserInfo?.therapistLocationGeos || [];
            const therapistLocationNames = newUserInfo?.therapistLocationNames || [];

            for (let i = 0; i < therapistGeos.length; i++) {
                const locationGeo = therapistGeos[i];
                if (
                    !locationGeo?.latitude ||
                    !locationGeo?.longitude ||
                    !userInfo?.locationGeo?.latitude ||
                    !userInfo?.locationGeo?.longitude
                ) {
                    // invalid location, skip it.
                    continue;
                }
                const distance = getDistanceFromLatLonInKm(
                    userInfo?.locationGeo?.latitude,
                    userInfo?.locationGeo?.longitude,
                    locationGeo?.latitude,
                    locationGeo?.longitude
                );
                if (distance <= shortestDistance) {
                    shortestDistance = distance;
                    shortestLocation = therapistLocationNames[i];
                }
            }
            if (shortestLocation) {
                return [shortestLocation];
            }
        }
        return newUserInfo?.therapistLocationNames || [];
    }, [
        userInfo?.uid,
        userInfo?.locationGeo,
        newUserInfo?.uid,
        newUserInfo?.therapistLocationGeos,
        newUserInfo?.therapistLocationNames,
    ]);

    return useLocationName(locationNames);
}

// Inspired by haversine formula
function getDistanceFromLatLonInKm(lat1: number, lon1: number, lat2: number, lon2: number) {
    var radianOfEarthKm = 6371;
    var distanceLat = deg2rad(lat2 - lat1);
    var distanceLon = deg2rad(lon2 - lon1);
    var a =
        Math.sin(distanceLat / 2) * Math.sin(distanceLat / 2) +
        Math.cos(deg2rad(lat1)) * Math.cos(deg2rad(lat2)) * Math.sin(distanceLon / 2) * Math.sin(distanceLon / 2);
    var c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
    var distanceInKm = radianOfEarthKm * c;
    return distanceInKm;
}

function deg2rad(deg: number) {
    return deg * (Math.PI / 180);
}
