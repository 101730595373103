import { Stack, Typography } from '@mui/material';
import { Box } from '@mui/system';
import { GeoPoint } from 'firebase/firestore';
import { useCallback, useEffect, useMemo, useState } from 'react';

import T from '../../locallization/T';
import SearchInputTherapistLocations from '../../search/therapistInputs/SearchInputTherapistLocations';
import { USER_INFO, USER_INFO_ZOOM } from '../../user/userInfo';
import AppToggleButton from '../buttonGroup/AppToggleButton';
import { userInfoZoom } from './OnboardingQuestionLocation';
import { ONBOARDING_QUESTION_PROPS } from './OnboardingQuestionProps';

export function alreadyHasTherapistLocation(userInfo: USER_INFO) {
    return !!userInfo.therapistLocationGeos?.length || userInfo?.therapistLocationZoom === "Y";
}

export default function OnboardingQuestionTherapistLocation({
    onDataProgress,
    newUserInfo,
}: ONBOARDING_QUESTION_PROPS) {
    const [locationNames, setLocationNames] = useState<string[]>([]);
    const [geos, setGeos] = useState<GeoPoint[]>([]);
    const [accessibles, setAccessibles] = useState<boolean[]>([]);
    const [selectedZoomOptions, setSelectedZoomOptions] = useState(new Set<USER_INFO_ZOOM>([]));

    const zoomValue = useMemo(() => {
        const zoomArrayValues = Array.from(selectedZoomOptions);
        return zoomArrayValues.length > 0 ? zoomArrayValues[0] : undefined;
    }, [selectedZoomOptions]);

    const onLocationsData = useCallback((data: USER_INFO) => {
        setLocationNames(data.therapistLocationNames || []);
        setGeos(data.therapistLocationGeos || []);
        setAccessibles(data.therapistLocationAccessibles || []);
    }, []);

    useEffect(() => {
        if (geos?.length || zoomValue === "Y") {
            onDataProgress({
                therapistLocationGeos: geos,
                therapistLocationNames: locationNames,
                therapistLocationAccessibles: accessibles,
                therapistLocationZoom: zoomValue,
            });
        } else {
            onDataProgress({});
        }
    }, [onDataProgress, geos, locationNames, accessibles, zoomValue]);

    return (
        <Stack>
            <SearchInputTherapistLocations onData={onLocationsData} newUserInfo={newUserInfo} />

            <Box sx={{ mt: 3 }}>
                <Typography variant="subtitle1" sx={{ mb: 2 }}>
                    <T>onboarding question location therapist zoom</T>
                </Typography>
                <Stack flexDirection="row" gap={1}>
                    {userInfoZoom.map(({ value: zoomValue, textKey, Icon }) => (
                        <AppToggleButton
                            key={zoomValue}
                            value={zoomValue}
                            selectedOptions={selectedZoomOptions}
                            setSelectedOptions={setSelectedZoomOptions}
                            limitToOne
                            Icon={<Icon />}>
                            <T>{textKey}</T>
                        </AppToggleButton>
                    ))}
                </Stack>
            </Box>
        </Stack>
    );
}
