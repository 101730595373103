import { User } from "firebase/auth";
import { useEffect, useState } from "react";

import { ADMIN_HIDDEN_STORAGE_KEY } from "../secret/Secret";

export type ADMIN_PROPS = {
    admin?: boolean;
    adminAdminsEdit?: boolean;
    adminLangView?: boolean;
    adminLangEdit?: boolean;
    adminUsersView?: boolean;
    adminUsersEdit?: boolean;
    adminEmails?: boolean;
    adminChats?: boolean;
    adminFlagsView?: boolean;
    adminFlagsEdit?: boolean;
    adminZigmondMeasureEdit?: boolean;
    adminInvalidations?: boolean;
    adminChill?: boolean;
};

export function useAdmin(user?: User | null) {
    const [adminProps, setAdminProps] = useState<ADMIN_PROPS>({});

    useEffect(() => {
        if (user) {
            try {
                if (sessionStorage.getItem(ADMIN_HIDDEN_STORAGE_KEY)) {
                    return;
                }
            } catch {}

            user.getIdTokenResult()
                .then((idTokenResult) => {
                    setAdminProps(getAdminFromClaims(idTokenResult.claims as ADMIN_PROPS));
                })
                .catch((error) => {
                    console.error("Error loading token results", error);
                });
        }
        return () => setAdminProps({});
    }, [user]);

    return adminProps;
}

export function getAdminFromClaims(claims: ADMIN_PROPS) {
    return {
        admin: !!claims.admin,
        adminAdminsEdit: !!claims.adminAdminsEdit,
        adminLangView: !!claims.adminLangView,
        adminLangEdit: !!claims.adminLangEdit,
        adminUsersView: !!claims.adminUsersView,
        adminUsersEdit: !!claims.adminUsersEdit,
        adminEmails: !!claims.adminEmails,
        adminChats: !!claims.adminChats,
        adminFlagsView: !!claims.adminFlagsView,
        adminFlagsEdit: !!claims.adminFlagsEdit,
        adminZigmondMeasureEdit: !!claims.adminZigmondMeasureEdit,
        adminInvalidations: !!claims.adminInvalidations,
        adminChill: !!claims.adminChill,
    };
}
